import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isValidData: {
    email: false,
    NPI: false,
    FirstName: false,
    MiddleName: false,
    LastName: false,
    phone: false,
    TimeZone: false,
    workPhone: false,
    EffectiveDate: false,
    PrimaryCommunication: false,
    Notes: false,
    Type: false,
    Products: false,
    rpmAlert: false,

    webAccess: false,
  },
  data: {
    email: "",
    NPI: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    phone: "",
    TimeZone: "",
    workPhone: "",
    EffectiveDate: "",
    PrimaryCommunication: "",
    Notes: "",
    Type: "",
    Products: "",
    rpmAlert: "",

    webAccess: "",
  },
};

const providerDataSlice = createSlice({
  name: "providerData",
  initialState: initialState,
  reducers: {
    updateData(state, action) {
      const { fieldName, value, validityStatus } = action.payload;
      state.data[fieldName] = value;
      state.isValidData[fieldName] = validityStatus;
      state.basicDataValidation = Object.keys(state.isValidData).reduce(
        (prev, curr) => prev && state.isValidData[curr],
        true
      );
    },
  },
});

export const providerDataActions = providerDataSlice.actions;

export default providerDataSlice.reducer;
