import { apiCollection } from "./apiCollection";

const adminApi = apiCollection.injectEndpoints({
  endpoints: (build) => ({
    addAdmin: build.mutation({
      query: (payload) => {
        return {
          url: "/admin/signup",
          method: "POST",
          body: payload,
        };
      },
      //   transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
    getAdminList: build.query({
      query: () => ({
        url: "/admin/allProfile",
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("RLH_token")}`,
        // },
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useAddAdminMutation, useGetAdminListQuery } = adminApi;
