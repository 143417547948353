import React from "react";

const InputComponent = ({ onClickFn, value, type }) => {
  return (
    <div className="textAreaComponent">
      <input
        type="input"
        onChange={(e) => onClickFn(e.target.value, [type])}
        value={value}
      />
    </div>
  );
};

export default InputComponent;
