import { createSlice } from "@reduxjs/toolkit";
// import { v4 as uuidv4 } from "uuid";

export const tabChildrenList = ["Patient Details", "Insurance", "Program"];
const initialState = {
  valid: false,
  currentTab: tabChildrenList[0],
  isValidData: {
    // PatientId: false,
    FacilityId: false,
    ProviderId: false,
    PrimaryCoordinator: false,
    SSN: false,
    MedicareEnrolmentDate: false,
    FirstName: false,
    MiddleName: true,
    PatientEHRId: true,
    LastName: false,
    Suffix: false,
    DOB: false,
    maritalStatus: false,
    email: false,
    emailAlternative: false,
    phone: false,
    homePhone: false,
    workPhone: false,
    Ethnicity: false,
    PreferredLanguage: false,
    PreferredCommunication: false,
    street1: false,
    street2: false,
    city: false,
    state: false,
    country: true,
    postalCode: false,
    Gender: false,
    emergencyContactName: false,
    emergencyContactPhone: false,
    emergencyContactRelation: false,
    patientPhoto: true,
  },
  data: {
    // PatientId: "",
    FacilityId: "",
    ProviderId: "",
    PrimaryCoordinator: "",
    SSN: "",
    MedicareEnrolmentDate: "",
    FirstName: "",
    MiddleName: "",
    PatientEHRId: "",
    LastName: "",
    Suffix: "",
    DOB: "",
    maritalStatus: "",
    email: "",
    emailAlternative: "",
    phone: "",
    homePhone: "",
    workPhone: "",
    Ethnicity: "",
    PreferredLanguage: "",
    PreferredCommunication: "",
    street1: "",
    street2: "",
    city: "",
    state: "",
    country: "USA",
    postalCode: "",
    Gender: "",
    emergencyContactName: "",
    emergencyContactPhone: "",
    emergencyContactRelation: "",
    patientPhoto: "",
  },
  basicDataValidation: false,
  noEmptyUpdateAllowedPatientDetail: false,
  noEmptyUpdateAllowedInsurance: false,

  // for carePlan stopWatch
  // startTimer:false
};
const patientsDataSlice = createSlice({
  name: "patientsData",
  initialState: initialState,
  reducers: {
    updateCurrentTab(state, action) {
      state.currentTab = action.payload;
      if (action.payload === tabChildrenList[0]) {
        state.noEmptyUpdateAllowedPatientDetail = true;
        // console.log("patients")
      }
      if (action.payload === tabChildrenList[1]) {
        state.noEmptyUpdateAllowedInsurance = true;
        // console.log("insurance");
      }
    },
    updateData(state, action) {
      const { fieldName, value, validityStatus } = action.payload;
      if (state.noEmptyUpdateAllowedPatientDetail) {
        if (!value) return;
        state.noEmptyUpdateAllowedPatientDetail = false;
      }

      state.data[fieldName] = value;
      state.isValidData[fieldName] = validityStatus;
      state.basicDataValidation = Object.keys(state.isValidData).reduce(
        (prev, curr) => prev && state.isValidData[curr],
        true
      );
    },
    resetData(state, action) {
      Object.keys(initialState).forEach((el) => {
        state[el] = initialState[el];
      });
    },
    updatePatientPhoto(state, action) {
      state.data["patientPhoto"] = action.payload;
    },
  },
});

export const patientsDataActions = patientsDataSlice.actions;

export default patientsDataSlice.reducer;
