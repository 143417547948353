import { createSlice } from "@reduxjs/toolkit";

export const goToLoginPage = () => {
  return "loginPage";
};

export const goToTwoFA_Page = () => {
  return "twoFA_Page";
};

export const goToForgotPasswordPage = () => {
  return "forgotPasswordPage";
};

export const goToResetPasswordPage = () => {
  return "resetPasswordPage";
};

const loginDataSlice = createSlice({
  name: "loginPageState",
  initialState: {
    currentPage: "loginPage", // Allowed values loginPage ,twoFA_Page
    username: "",
    password: "",
    twoFA: "",
    twoFA_AutoSubmit: true,
  },

  reducers: {
    switchPage(state, action) {
      console.log(action);
      state.currentPage = action.payload;
    },
    updateUsername(state, action) {
      state.username = action.payload;
    },
    updatePassword(state, action) {
      state.password = action.payload;
    },
    updateTwoFA(state, action) {
      state.twoFA = action.payload;
    },
    submitTwoFA(state, action) {
      console.log("submitting twoFA");
      state.twoFA_AutoSubmit = false;
    },
    resetTwoFA(state, action) {
      state.twoFA = "";
      state.twoFA_AutoSubmit = true;
    },
  },
});

export const loginDataActions = loginDataSlice.actions;

export default loginDataSlice.reducer;
