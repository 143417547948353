import { createSlice } from "@reduxjs/toolkit";

var initialState = {};

const token = localStorage.getItem("RLH_token");
const userType = localStorage.getItem("RLH_userType");
const user = localStorage.getItem("RLH_user");

if (!token) {
  localStorage.removeItem("RLH_token");
}

if (!userType) localStorage.removeItem("RLH_userType");
if (!user) localStorage.removeItem("RLH_user");
if (token && userType && user) {
  initialState = { token, userType, user };
}

const authDataSlice = createSlice({
  name: "authData",
  initialState: initialState,
  reducers: {
    auth(state, action) {
      localStorage.setItem("RLH_token", action.payload.token);
      localStorage.setItem("RLH_userType", action.payload.userType);
      localStorage.setItem("RLH_user", action.payload.name);

      state.token = action.payload.token;
      state.userType = action.payload.userType;
      state.user = action.payload.name;
    },
    logOut(state, action) {
      localStorage.removeItem("RLH_token");
      localStorage.removeItem("RLH_userType");
      localStorage.removeItem("RLH_user");
      state.token = null;
      state.userType = null;
      state.user = null;
      console.log("logout");
    },
  },
});

export const authDataActions = authDataSlice.actions;

export const logOut = authDataActions.logOut;

export default authDataSlice.reducer;
