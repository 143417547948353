import React from "react";
import "./viewCalendar.scss";

import Calendar from "./Calendar";
const ViewCalendar = () => {
  return (
    <div className="calendarCont">
      <p className="pageTitle">Schedule Calendar</p>
      <Calendar />
    </div>
  );
};

export default ViewCalendar;
