import React, { useEffect, useState } from "react";
import "./patientInteractionPage.scss";
import { Icon } from "@iconify/react";
import { generalHandleValueChanges } from "../../patients/Careteam/helperfunctions";
import SelectComponent from "../../components/SelectComponent";
import { useNavigate } from "react-router-dom";
import LoaderComponent from "../../components/LoaderComponent";
import { useGetPatientSelectedProfileMutation } from "../../app/api/patientsApi";
const PatientInteractionPage = ({
  eventData,
  setCallPatientPopUp,
  setEventData,
  id,
}) => {
  const closeFn = () => {
    setCallPatientPopUp(false);
    // setEventData("");
  };

  const navigate = useNavigate();

  const initialState = {
    callTask: "",
    interactionType: "Phone",
    selectCarrier: "Other Carrier",
    patientpreferenceForCallRecording: "",
    invalidNumber: "",
    callStatus: "",
    purposeoftheCall: "",
    patientRequestedtoExit: "",
    callTask: "",
  };
  const [patientInteractionData, setPatientInteractionData] =
    useState(initialState);
  const [showFollowupQuestionBtn, setShowFollowupQuestionBtn] = useState(false);
  const [getselectedProfileFn, { data: selectedProfile, isLoading }] =
    useGetPatientSelectedProfileMutation();
  const onClickNavigate = () => {
    navigate("/patients/careplan-Call", {
      state: {
        id: id,
        type: patientInteractionData?.interactionType,
      },
    });
  };

  useEffect(() => {
    if (!id) return;
    getselectedProfileFn({ PatientId: id });
  }, [id]);
  return (
    <div className="PatientInteractionPage">
      <div className="PatientInteractionPageContent">
        <Icon
          onClick={closeFn}
          className="closeIcon"
          icon="iconamoon:close-bold"
          width="26"
          height="26"
        />
        <p className="title">Patient Interaction Selection</p>
        <div className="interactionContent">
          <LoaderComponent isLoading={isLoading} />
          <div className="detailBoxHead">Patient Name</div>
          <div className="detailBoxBody colSpan2">
            {selectedProfile?.FirstName} {selectedProfile?.MiddleName}{" "}
            {selectedProfile?.LastName}{" "}
          </div>

          <div className="detailBoxHead">Preferred Language</div>
          <div className="detailBoxBody colSpan2">
            {selectedProfile?.PreferredLanguage}
          </div>
          <div className="detailBoxHead">Patient Time Zone</div>
          <div className="detailBoxBody colSpan2">
            {selectedProfile?.PatientName}
          </div>
          <div className="detailBoxHead">Physician Name</div>
          <div className="detailBoxBody colSpan2">
            {selectedProfile?.ProviderName}
            {console.log(selectedProfile)}
          </div>
          <div className="detailBoxHead">Create "Call Task" Every Month</div>
          <div className="detailBoxBody colSpan2">
            <input type="checkbox" />
          </div>
          <div className="detailBoxHead">Interaction Type</div>
          <div className="detailBoxBody colSpan2">
            <div className="checkboxes">
              <span>
                <input
                  type="checkbox"
                  onChange={(event) =>
                    generalHandleValueChanges(
                      event,
                      setPatientInteractionData,
                      "interactionType"
                    )
                  }
                  value="Phone"
                  checked={patientInteractionData?.interactionType === "Phone"}
                />
                <p>Phone</p>
              </span>
              <span>
                <input
                  type="checkbox"
                  onChange={(event) =>
                    generalHandleValueChanges(
                      event,
                      setPatientInteractionData,
                      "interactionType"
                    )
                  }
                  value="In-Person"
                  checked={
                    patientInteractionData?.interactionType === "In-Person"
                  }
                />
                <p>In-Person </p>
              </span>
              <span>
                <input
                  type="checkbox"
                  onChange={(event) =>
                    generalHandleValueChanges(
                      event,
                      setPatientInteractionData,
                      "interactionType"
                    )
                  }
                  value="Documentation"
                  checked={
                    patientInteractionData?.interactionType === "Documentation"
                  }
                />
                <p>Documentation</p>
              </span>
            </div>
          </div>
          {patientInteractionData?.interactionType === "Phone" ? (
            <>
              <div className="detailBoxHead">Select Carrier</div>
              <div className="detailBoxBody colSpan2">
                <div className="checkboxes">
                  {/* <span>
                    <input
                      type="checkbox"
                      onChange={(event) =>
                        generalHandleValueChanges(
                          event,
                          setPatientInteractionData,
                          "selectCarrier"
                        )
                      }
                      checked={
                        patientInteractionData?.selectCarrier === "Plivo"
                      }
                      value="Plivo"
                    />
                    <p>Plivo </p>
                  </span>
                  <span>
                    <input
                      type="checkbox"
                      onChange={(event) =>
                        generalHandleValueChanges(
                          event,
                          setPatientInteractionData,
                          "selectCarrier"
                        )
                      }
                      checked={
                        patientInteractionData?.selectCarrier === "Nextiva"
                      }
                      value="Nextiva"
                    />
                    <p>Nextiva </p>
                  </span> */}
                  <span>
                    <input
                      type="checkbox"
                      // disabled={true}
                      // onChange={(event) =>
                      //   generalHandleValueChanges(
                      //     event,
                      //     setPatientInteractionData,
                      //     "selectCarrier"
                      //   )
                      // }
                      checked={
                        patientInteractionData?.selectCarrier ===
                        "Other Carrier"
                      }
                      value="Other Carrier"
                    />
                    <p>Other Carrier</p>
                  </span>
                </div>{" "}
              </div>
              {/* {patientInteractionData?.selectCarrier === "Plivo" ? (
                <>
                  <div className="detailBoxHead">
                    Patient preference for call recording
                  </div>
                  <div className="detailBoxBody colSpan2">
                    <input type="checkbox" />
                  </div>
                  <div className="detailBoxHead">Facility Name</div>
                  <div className="detailBoxBody colSpan2">
                    {eventData?.patientId?.PatientName}
                  </div>
                </>
              ) : patientInteractionData?.selectCarrier === "Nextiva" ? (
                <>
                  <div className="detailBoxHead">Nextiva Username</div>
                  <div className="detailBoxBody colSpan2">
                    vesoundar27318@nextiva.com
                  </div>
                </>
              ) : (
                <>
                  <div className="detailBoxHead">Phone Carrier Info</div>
                  <div className="detailBoxBody phoneCarrier colSpan2">
                    <input className="" />
                    <input />
                  </div>
                </>
              )} */}

              <div className="detailBoxHead">Patient Contact Number</div>
              <div className="detailBoxBody colSpan2">
                <button
                  className="noStyle"
                  onClick={() => setShowFollowupQuestionBtn(true)}
                >
                  {selectedProfile?.phone}
                  <Icon icon="ion:call" color="#4fe34f" />
                </button>
                <span className="invalidNumber">
                  Invalid Number <input type="checkbox" />
                </span>
              </div>
              {showFollowupQuestionBtn && (
                <div className="colSpan3 bigBtn">
                  <button onClick={onClickNavigate}>
                    Get Follow-Up Question Response
                  </button>
                </div>
              )}

              {/* <div className="detailBoxHead">Call Status</div>
              <div className="detailBoxBody colSpan2">
                <div className="callStatusBox">
                  <SelectComponent
                    OptionsArray={[
                      "Call In Progress",
                      "No Answer",
                      "Left Message",
                      "Do Not Call",
                      "Wrong Number",
                      "Reschedule",
                      "Cancelled By Patient",
                      "Not Interested In Program",
                    ]}
                    // valueData={addProgram.ProgramName}
                    // fn={generalHandleValueChanges}
                    // setvalue={setAddProgram}
                    // name={"ProgramName"}
                  />
                  <button>Call Status Remark</button>
                </div> */}
              {/* </div> */}
              <div className="detailBoxHead">Purpose of the call</div>
              <div className="detailBoxBody colSpan2">
                {eventData?.callPurpose}
              </div>
              <div className="detailBoxHead">Patient Requested to Exit CCM</div>
              <div className="detailBoxBody colSpan2">
                <input type="checkbox" />
              </div>
              <div className="buttonBox colSpan3">
                {/* <button disabled={true}>Save</button>
                <button disabled={true}>Cancel</button> */}
              </div>
            </>
          ) : patientInteractionData?.interactionType === "In-Person" ? (
            <div className="colSpan3 bigBtn">
              <button onClick={onClickNavigate}>Get Question Response</button>
            </div>
          ) : (
            <div className="colSpan3 bigBtn">
              <button onClick={onClickNavigate}>Documentation</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default PatientInteractionPage;
