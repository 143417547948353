import { Icon } from "@iconify/react";
import { parse } from "papaparse";
import React, { useEffect, useRef, useState } from "react";
import { useGetPatientsOptionsQuery } from "../app/api/patientSelctorOptionApi";
import { useBulkUploadPatientsMutation } from "../app/api/patientsApi";
import FloatingNotifications from "../utilities/FloatingNotifications";
import { selectorValidateCheck } from "../utilities/validationFunction";
import InputSelectorField2 from "../widgets/InputSelectorField2";

const LoadEligiblePatientForm = () => {
  const [facilityId, setFacilityId] = useState(null);
  const [providerId, setProviderId] = useState(null);
  const [clinicianId, setClinicianId] = useState(null);

  const facilityRef = useRef();
  const providerRef = useRef();
  const clinicianRef = useRef();

  const { data: fpcList } = useGetPatientsOptionsQuery();
  const [
    bulkUploadPatientFn,
    { data: bulkUploadResult, error: bulkUploadError },
  ] = useBulkUploadPatientsMutation();

  const onSubmit = (e) => {
    const callBack = (results) => {
      // console.log(new Date(), results.data);
      bulkUploadPatientFn({
        FacilityId: facilityId,
        ProviderId: providerId,
        PrimaryCoordinator: clinicianId,
        data: results.data,
      });
    };
    if (!facilityId || !providerId || !clinicianId) {
      FloatingNotifications("error", "Please fill all fields");
      return;
    }
    // console.log("good");

    const file = e.target.files[0];
    parse(file, {
      header: true,
      complete: callBack,
    });
  };

  useEffect(() => {
    if (!bulkUploadResult) return;
    // console.log(bulkUploadResult);
    facilityRef.current?.reset_data();
    providerRef.current?.reset_data();
    clinicianRef.current?.reset_data();
    FloatingNotifications("success", "Success");
  }, [bulkUploadResult]);

  useEffect(() => {
    if (!bulkUploadError) return;
    console.log(bulkUploadError);
    FloatingNotifications("error", bulkUploadError.data.message);
  }, [bulkUploadError]);

  return (
    <>
      <InputSelectorField2
        tabIndex="1"
        element={"FacilityId"}
        ref={facilityRef}
        title="Facility"
        superTitle="*"
        // autoFocus={true}
        validation={selectorValidateCheck}
        optionsDictionay={fpcList?.FacilityList ?? []}
        selectorName={"FacilityName"}
        selectorValue={"FacilityId"}
        // error_message={error_message["FacilityId"]}
        storeData={(value, validity) => {
          setFacilityId(value);
        }}
      />
      <InputSelectorField2
        tabIndex="2"
        element={"providerId"}
        ref={providerRef}
        title="Physician"
        superTitle="*"
        // autoFocus={true}
        validation={selectorValidateCheck}
        optionsDictionay={fpcList?.ProviderList ?? []}
        selectorName={"ProviderName"}
        selectorValue={"ProviderId"}
        // error_message={error_message["FacilityId"]}
        storeData={(value, validity) => {
          setProviderId(value);
        }}
      />
      <InputSelectorField2
        tabIndex="3"
        element={"clinicianId"}
        ref={clinicianRef}
        title="Clinician"
        superTitle="*"
        // autoFocus={true}
        validation={selectorValidateCheck}
        optionsDictionay={fpcList?.ClinicianList ?? []}
        selectorName={"ClinicianName"}
        selectorValue={"ClinicianId"}
        // error_message={error_message["FacilityId"]}
        storeData={(value, validity) => {
          setClinicianId(value);
        }}
      />
      <label className="chooseCsv">
        {/* {true && <div class="spinner"></div>} */}
        <Icon icon="ic:sharp-upload" width="26" height="26" />
        Choose the CSV File
        <input
          type="file"
          accept={".csv"}
          onChange={onSubmit}
          value={""}
        ></input>
      </label>
    </>
  );
};

export default LoadEligiblePatientForm;
