import React, { useEffect, forwardRef, useImperativeHandle } from "react";
import classNames from "classnames";
import useInputSelector1 from "../hooks/use-inputSelector1";
import { ReactComponent as Info } from "../images/info.svg";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import "../widget-sass/inputSelectorField1.scss";

const InputSelectorField2 = forwardRef((props, ref) => {
  const name = props?.selectorName;
  const id = props?.selectorValue;
  const {
    value,
    hasError,
    isValid,
    valueChangeHandler,
    submitClickHandler,
    reset,
    storeValue,
    storeDefaultValue,
  } = useInputSelector1(props.validation);
  useImperativeHandle(ref, () => ({
    reset_data() {
      reset();
    },
    init_data(data) {
      if (props.optionsArray) {
        storeValue(data);
      } else {
        storeValue(data);
      }
    },
    default_data(data) {
      storeDefaultValue(data);
    },
    submit() {
      submitClickHandler();
    },
  }));

  useEffect(() => {
    if (value === undefined) return;
    props.storeData(value, isValid);
  }, [value, isValid]);
  console.log(value);
  return (
    <div
      className={classNames(
        "inputSelectorContainer1",
        props.containerClassName
      )}
    >
      <label
        className={classNames("inputSelector", props.className, {
          invalid: hasError,
        })}
      >
        <div className="title">
          {props.title}
          <sup>{props.superTitle ?? ""}</sup>
          {hasError && (
            <Info className="error-icon" data-tooltip-id={props.element} />
          )}
        </div>
        {/* {console.log(props.element, value)} */}
        <select
          tabIndex={props.tabIndex ?? ""}
          type={props.type ?? "text"}
          value={value}
          onChange={valueChangeHandler}
          placeholder={props.placeholder ?? ""}
          disabled={props.disabled ?? false}
          autoFocus={props.autoFocus ?? false}
        >
          <option className="optionsdefault" value=""></option>
          {props.optionsDictionay?.map((el) => (
            <option className="options" value={el[id]}>
              {el[name]}
            </option>
          ))}
          {/* <div className="selectDropdown"> */}
          {props.optionsArray?.map((el) => (
            <option className="options" value={el}>
              {el}
            </option>
          ))}
          {/* </div> */}
        </select>
      </label>
      <Tooltip
        id={props.element}
        content={props.error_message}
        place="bottom"
      />
    </div>
  );
});

export default React.memo(InputSelectorField2);
