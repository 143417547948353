import React from "react";
import "./carePlanDisplayGeneral.scss";
import { useState } from "react";
import IconContainer from "../iconContainer/iconContainer";
import { careplanview } from "../helperfunctions";
const CarePlanDisplayGeneral = (props) => {
  const { contentArray, data, updateDatafn, setEditArray, id, name, dataSet } =
    props;
  const [popUpDelete, setPopUpDelete] = useState(false);
  const [dataItemToDelete, setDataItemToDelete] = useState();
  const deletefn = (dataItem) => {
    const newArray = props.array.map((el) =>
      el === dataItem ? { ...el, Status: false } : el
    );

    updateDatafn({ PatientId: id, [name]: newArray });
  };
  const onClickDeleteBtn = (dataItem) => {
    setPopUpDelete(true);
    setDataItemToDelete(dataItem);
  };
  const onDeleteConfirmed = () => {
    deletefn(dataItemToDelete);
    setPopUpDelete(false);
    setDataItemToDelete();
  };

  return (
    <div>
      {popUpDelete && (
        <div className="popUpDelete">
          <div className="popupContent">
            <p>Are you sure you want to delete this object?</p>
            <div className="popupActions">
              <button onClick={onDeleteConfirmed}>Delete</button>
              <button onClick={() => setPopUpDelete(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      <>
        {data
          ?.slice()
          .reverse()
          .map((dataItem) => (
            <div className="containerGrid">
              <div className="displayGrid">
                {contentArray.map((el) => (
                  <div className={`displayGnrlElement ${el[2]}`}>
                    <p className="displayGnrlTitle">{el[0]}</p>

                    <p className="displayGnrlContent">
                      {el[3] ? el[1](dataItem) : dataItem[el[1]]}
                    </p>
                  </div>
                ))}
              </div>
              {props.Type === "history" ? (
                <></>
              ) : (
                <div className="displayBtnGrid">
                  <IconContainer
                    iconsList={
                      props.iconsList ?? [
                        {
                          value: "uil:edit",
                          onClickFn: () =>
                            careplanview(
                              props.values[0],
                              props.values[1],
                              dataItem,
                              dataSet,
                              setEditArray
                            ),
                        },
                        {
                          value: "carbon:delete",
                          onClickFn: () => onClickDeleteBtn(dataItem),
                        },
                      ]
                    }
                    bckColorwhite={true}
                  />
                </div>
              )}
            </div>
          ))}
      </>
    </div>
  );
};

export default CarePlanDisplayGeneral;
