import React, { useEffect, useState } from "react";
import attemptedFailIcon from "./../../src/images/icons/attempted.svg";
import attemptedSuccessIcon from "./../../src/images/icons/notattempted.svg";
import attemptedNotIcon from "./../../src/images/icons/cancelled.svg";
import { Icon } from "@iconify/react";
import { startOfWeek, endOfWeek, addDays, format } from "date-fns";
import classNames from "classnames";
import "./performance.scss";
function Performance({ popUpOpen, setPopUpOpen }) {
  const cancelfn = () => {
    setPopUpOpen(false);
  };
  const [currentDate, setCurrentDate] = useState(new Date());
  const presentDay = new Date();
  const [weekDates, setWeekDates] = useState([]);
  const [displayDate, setdisplayDate] = useState([]);

  useEffect(() => {
    let startDateOfWeek = startOfWeek(currentDate);
    let endDateOfWeek = endOfWeek(currentDate);
    let week = [];
    let date = startDateOfWeek;
    while (date <= endDateOfWeek) {
      week.push(format(date, "yyyy-MM-dd").slice(-2));
      date = addDays(date, 1);
    }
    setWeekDates(week);
    let displayDate = "";
    if (format(startDateOfWeek, "yyyy") === format(endDateOfWeek, "yyyy")) {
      if (format(startDateOfWeek, "MMM") === format(endDateOfWeek, "MMM")) {
        displayDate = `${format(endDateOfWeek, "MMM yyyy")}`;
      } else {
        displayDate = `${format(startDateOfWeek, "MMM")}-${format(
          endDateOfWeek,
          "MMM yyyy"
        )}`;
      }
    } else {
      displayDate = `${format(startDateOfWeek, "MMM yyyy")}-${format(
        endDateOfWeek,
        "MMM yyyy"
      )}`;
    }
    setdisplayDate(displayDate);
  }, [currentDate]);

  const prevDate = () => {
    setCurrentDate(addDays(currentDate, -7));
  };
  const nextDate = () => {
    setCurrentDate(addDays(currentDate, 7));
  };

  return (
    <>
      {popUpOpen && (
        <div className="performance">
          <div className="viewPerformance">
            <Icon
              onClick={cancelfn}
              className="closeIcon"
              icon="iconamoon:close-bold"
              width="26"
              height="26"
            />
            <p className="headtitle">Clinician/Physician name Performance</p>
            <div className="performanceGrid">
              <div className="colSpan4">
                <p className="gnrltxt ">
                  Clinician Role: Primary RPM Clinician (00 Patients)
                </p>
              </div>
              <div className="colSpan4">
                <p className="gnrltxt blue">Total Patient Access Count : 000</p>
                <p className="gnrltxt blue">
                  Total Care Team RPM Patient Count : 000
                </p>
              </div>
              <div className="colSpan4">
                <p className="gnrltxt blue">
                  Total RPM Patient Access Count : 000
                </p>
                <p className="gnrltxt blue">
                  Total My Assigned RPM Primary Clinician : 000
                </p>
              </div>
              <div className="colSpan12">
                <div className="DashCompo">
                  <div className="compoTitle">
                    <div className="title-icon">
                      <Icon icon="bxs:down-arrow" />
                      <p className="title">
                        Current Month Billable Minutes By Call Attempts{" "}
                      </p>
                    </div>
                  </div>
                  <div className="compoBody">
                    <div className="boxes">
                      <div className="box">
                        <p className="boldnum" style={{ color: "#7A5DDA" }}>
                          00
                        </p>
                        <p className="txt">
                          00:01-10:00 <br />
                          (Min:Sec)
                        </p>
                      </div>
                      <div className="box">
                        <p className="boldnum" style={{ color: "##FF8DA0" }}>
                          00
                        </p>
                        <p className="txt">
                          00:01-10:00 <br /> (Min:Sec)
                        </p>
                      </div>
                      <div className="box">
                        <p className="boldnum" style={{ color: "#ECD34F" }}>
                          00
                        </p>
                        <p className="txt">
                          00:01-10:00 <br /> (Min:Sec)
                        </p>
                      </div>
                      <div className="box">
                        <p className="boldnum" style={{ color: "#5AE09B" }}>
                          00
                        </p>
                        <p className="txt">
                          00:01-10:00 <br /> (Min:Sec)
                        </p>
                      </div>
                      <div className="box">
                        <p className="boldnum" style={{ color: "#70B9FC" }}>
                          00
                        </p>
                        <p className="txt">
                          00:01-10:00 <br /> (Min:Sec)
                        </p>
                      </div>
                      <div className="box">
                        <p className="boldnum" style={{ color: "#FF914E" }}>
                          00
                        </p>
                        <p className="txt">
                          00:01-10:00 <br /> (Min:Sec)
                        </p>
                      </div>
                    </div>
                    <div className="grid7">
                      <div className="gridHead">
                        Call <br />
                        Attempts
                      </div>
                      <div className="gridHead">
                        00:00
                        <br /> (Min:Sec)
                      </div>
                      <div className="gridHead">
                        00:01-10:00
                        <br /> (Min:Sec)
                      </div>
                      <div className="gridHead">
                        10:01-19:59 <br />
                        (Min:Sec)
                      </div>
                      <div className="gridHead">
                        20:00-39:59
                        <br /> (Min:Sec)
                      </div>
                      <div className="gridHead">
                        40:00-59:59 <br />
                        (Min:Sec)
                      </div>
                      <div className="gridHead">
                        60:00 & above
                        <br /> (Min:Sec)
                      </div>
                      <div className="gridHead"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="colSpan12">
                <div className="rigthtext">
                  <p className="gnrltxt">
                    {" "}
                    <div
                      className="cir"
                      style={{ backgroundColor: "#7A5DDA" }}
                    ></div>
                    My Patients (Primary RPM Clinician)
                  </p>
                  <p className="gnrltxt">
                    <div
                      className="cir"
                      style={{ backgroundColor: "#A99C58" }}
                    ></div>
                    Other Patients
                  </p>
                  <p className="gnrltxt">
                    <div
                      className="cir"
                      style={{ backgroundColor: "#FF4030" }}
                    ></div>
                    Cancelled
                  </p>
                </div>
              </div>
              <div className="colSpan8">
                <div className="cla">
                  <div className="DashCompo">
                    <div className="compoTitle">
                      <div className="title-icon">
                        <p className="title">Weekly Calendar</p>
                      </div>
                    </div>
                    <div className="compoBody">
                      <div className="calender">
                        <Icon
                          icon="material-symbols:arrow-left"
                          width="20"
                          height="20"
                          onClick={prevDate}
                        />
                        <p className="caldate">{displayDate}</p>
                        <Icon
                          icon="material-symbols:arrow-right"
                          width="20"
                          height="20"
                          onClick={nextDate}
                        />
                      </div>
                      <div className="calBody">
                        <div className="grid7">
                          <div className="gridcnt">
                            <p className="day">SUN</p>
                            <p
                              className={classNames(
                                "date",
                                weekDates[0] ===
                                  format(presentDay, "yyyy-MM-dd").slice(-2) &&
                                  "currentDate"
                              )}
                            >
                              {weekDates[0]}
                            </p>
                          </div>
                          <div className="gridcnt">
                            <p className="day">MON</p>
                            <p
                              className={classNames(
                                "date",
                                weekDates[1] ===
                                  format(presentDay, "yyyy-MM-dd").slice(-2) &&
                                  "currentDate"
                              )}
                            >
                              {weekDates[1]}
                            </p>
                          </div>
                          <div className="gridcnt">
                            <p className="day">TUE</p>
                            <p
                              className={classNames(
                                "date",
                                weekDates[2] ===
                                  format(presentDay, "yyyy-MM-dd").slice(-2) &&
                                  "currentDate"
                              )}
                            >
                              {weekDates[2]}
                            </p>
                          </div>
                          <div className="gridcnt">
                            <p className="day">WED</p>
                            <p
                              className={classNames(
                                "date",
                                weekDates[3] ===
                                  format(presentDay, "yyyy-MM-dd").slice(-2) &&
                                  "currentDate"
                              )}
                            >
                              {weekDates[3]}
                            </p>
                          </div>
                          <div className="gridcnt">
                            <p className="day">THU</p>
                            <p
                              className={classNames(
                                "date",
                                weekDates[4] ===
                                  format(presentDay, "yyyy-MM-dd").slice(-2) &&
                                  "currentDate"
                              )}
                            >
                              {weekDates[4]}
                            </p>
                          </div>
                          <div className="gridcnt">
                            <p className="day">FRI</p>
                            <p
                              className={classNames(
                                "date",
                                weekDates[5] ===
                                  format(presentDay, "yyyy-MM-dd").slice(-2) &&
                                  "currentDate"
                              )}
                            >
                              {weekDates[5]}
                            </p>
                          </div>
                          <div className="gridcnt">
                            <p className="day">SAT</p>
                            <p
                              className={classNames(
                                "date",
                                weekDates[6] ===
                                  format(presentDay, "yyyy-MM-dd").slice(-2) &&
                                  "currentDate"
                              )}
                            >
                              {weekDates[6]}
                            </p>
                          </div>
                          <div className="gridbtn">
                            <div className="actionbtn clr1">00</div>
                            <div className="actionbtn clr2">00</div>
                            <div className="actionbtn clr3">00</div>
                          </div>
                          <div className="gridbtn">
                            <div className="actionbtn clr1">00</div>
                            <div className="actionbtn clr2">00</div>
                            <div className="actionbtn clr3">00</div>
                          </div>
                          <div className="gridbtn">
                            <div className="actionbtn clr1">00</div>
                            <div className="actionbtn clr2">00</div>
                            <div className="actionbtn clr3">00</div>
                          </div>
                          <div className="gridbtn">
                            <div className="actionbtn clr1">00</div>
                            <div className="actionbtn clr2">00</div>
                            <div className="actionbtn clr3">00</div>
                          </div>
                          <div className="gridbtn">
                            <div className="actionbtn clr1">00</div>
                            <div className="actionbtn clr2">00</div>
                            <div className="actionbtn clr3">00</div>
                          </div>
                          <div className="gridbtn">
                            <div className="actionbtn clr1">00</div>
                            <div className="actionbtn clr2">00</div>
                            <div className="actionbtn clr3">00</div>
                          </div>
                          <div className="gridbtn">
                            <div className="actionbtn clr1">00</div>
                            <div className="actionbtn clr2">00</div>
                            <div className="actionbtn clr3">00</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="colSpan4">
                <div className="cla">
                  <div className="DashCompo">
                    <div className="compoTitle">
                      <div className="title-icon">
                        <p className="title">Past Schedule Status</p>
                      </div>
                    </div>
                    <div className="compoBody">
                      <div className="past">
                        <div className="pastShedule">
                          <div className="sheduleDiv red">
                            <img
                              src={attemptedSuccessIcon}
                              alt="atempt failed"
                            />
                            <p className="simTxt">Not Attempted</p>
                          </div>
                          <div className="pastShedulebtn">
                            {" "}
                            <div className="actionbtn clr1">00</div>
                            <div className="actionbtn clr2">00</div>
                          </div>
                        </div>
                        <div className="pastShedule">
                          <div className="sheduleDiv green">
                            <img src={attemptedFailIcon} alt="atempt failed" />
                            <p className="simTxt">Attempted</p>
                          </div>
                          <div className="pastShedulebtn">
                            {" "}
                            <div className="actionbtn clr1">00</div>
                            <div className="actionbtn clr2">00</div>
                          </div>
                        </div>
                        <div className="pastShedule">
                          <div className="sheduleDiv yellow">
                            {" "}
                            <img src={attemptedNotIcon} alt="atempt failed" />
                            <p className="simTxt">Cancelled</p>
                          </div>
                          <div className="pastShedulebtn">
                            {" "}
                            <div className="actionbtn clr1">00</div>
                            <div className="actionbtn clr2">00</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="colSpan4">
                <div className="cla">
                  <div className="DashCompo">
                    <div className="compoTitle">
                      <div className="title-icon">
                        <p className="title">Unplanned Monthly Call Task</p>
                      </div>
                    </div>
                    <div className="compoBody">
                      <div className="past colourless">
                        <div className="pastShedule clrless">
                          <div className="sheduleDiv ">
                            <Icon
                              icon="majesticons:list-box-line"
                              color="#888"
                              width="25"
                              heigth="25"
                            />
                            <p className="simTxt">To Do</p>
                          </div>
                          <p className="gnrrl">00</p>
                        </div>
                        <div className="pastShedule clrless">
                          <div className="sheduleDiv ">
                            <Icon
                              icon="zondicons:pause-solid"
                              color="#888"
                              width="25"
                              heigth="25"
                            />{" "}
                            <p className="simTxt">Hold</p>
                          </div>
                          <p className="gnrrl">00</p>
                        </div>
                        <div className="pastShedule clrless">
                          <div className="sheduleDiv ">
                            {" "}
                            <Icon
                              icon="zondicons:close-solid"
                              color="#888"
                              width="25"
                              heigth="25"
                            />{" "}
                            <p className="simTxt">Closed</p>
                          </div>
                          <p className="gnrrl">00</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="colSpan8">
                <div className="cla">
                  <div className="DashCompo">
                    <div className="compoTitle">
                      <div className="title-icon">
                        <p className="title">Weekly Calendar</p>
                      </div>
                    </div>
                    <div className="compoBody">
                      <div className="cla">
                        <div className="gridfor4">
                          <div className="elem greencall">
                            <div className="iconbtn ">
                              <Icon
                                icon="mingcute:phone-call-fill"
                                className="weeklyIcon"
                                color="#10ac50"
                                width="25"
                                heigth="25"
                              />
                              <p className="Txt1">Call Completed</p>
                            </div>
                            <p className="num1">00</p>
                          </div>
                          <div className="elem greencall">
                            <div className="iconbtn">
                              <Icon
                                icon="fluent:call-transfer-32-filled"
                                className="weeklyIcon"
                                color="#10ac50"
                                width="25"
                                heigth="25"
                              />
                              <p className="Txt1">Call Follow Up</p>
                            </div>
                            <p className="num1">00</p>
                          </div>
                          <div className="elem yellowcall">
                            <div className="iconbtn">
                              <Icon
                                icon="solar:call-chat-bold"
                                className="weeklyIcon"
                                color="#eaca1f"
                                width="25"
                                heigth="25"
                              />
                              <p className="Txt1">Left Message</p>
                            </div>
                            <p className="num1">00</p>
                          </div>
                          <div className="elem yellowcall">
                            <div className="iconbtn">
                              <Icon
                                icon="material-symbols:call-quality"
                                className="weeklyIcon"
                                color="#eaca1f"
                                width="25"
                                heigth="25"
                              />
                              <p className="Txt1">No Answer</p>
                            </div>
                            <p className="num1">00</p>
                          </div>
                          <div className="elem redcall">
                            <div className="iconbtn">
                              <Icon
                                icon="ant-design:stop-outlined"
                                className="weeklyIcon"
                                color="#ff4030"
                                width="25"
                                heigth="25"
                              />
                              <p className="Txt1">Not Interested</p>
                            </div>
                            <p className="num1">00</p>
                          </div>
                          <div className="elem redcall">
                            <div className="iconbtn">
                              <Icon
                                icon="fluent:call-dismiss-24-filled"
                                color="#ff4030"
                                className="weeklyIcon"
                                width="25"
                                heigth="25"
                              />
                              <p className="Txt1">Do Not Call</p>
                            </div>
                            <p className="num1">00</p>
                          </div>
                          <div className="elem yellowcall">
                            <div className="iconbtn">
                              <Icon
                                icon="mingcute:close-fill"
                                color="#eaca1f"
                                className="weeklyIcon"
                                width="25"
                                heigth="25"
                              />
                              <p className="Txt1">Wrong Number</p>
                            </div>
                            <p className="num1">00</p>
                          </div>
                          <div className="elem darkcall">
                            <div className="iconbtn">
                              <Icon
                                className="weeklyIcon"
                                icon="material-symbols:call"
                                color="#888"
                                width="25"
                                heigth="25"
                              />
                              <p className="Txt1">Others</p>
                            </div>
                            <p className="num1">00</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="colSpan12">
                <div className="DashCompo">
                  <div className="compoTitle">
                    <div className="title-icon">
                      <Icon icon="bxs:down-arrow" />
                      <p className="title">Contribution</p>
                    </div>
                  </div>
                  <div className="compoBody">
                    <div className="contributionElem">
                      <div className="elemBox">
                        <p className="txtHead">
                          Contribution of <br /> Billable Services
                        </p>
                        <div className="contridiv">
                          <div className="contribox ">
                            <p className="contiBoxtxt greybox">
                              Partial Contribution
                            </p>
                            <p className="num">00</p>
                            <p className="txt4">Patients</p>
                            <p className="txt4">00:00:00 (hh:mm:ss)</p>
                          </div>
                          <div className="contribox">
                            <p className="contiBoxtxt ligthbox">
                              Full Contribution
                            </p>
                            <p className="num">00</p>
                            <p className="txt4">Patients</p>
                            <p className="txt4">00:00:00 (hh:mm:ss)</p>
                          </div>
                        </div>
                      </div>
                      <div className="elemBox">
                        <p className="bigNum">00</p>
                        <div className="smallertxt">
                          #Patients 00:00:00 (hh:mm:ss)
                        </div>
                        <div className="smaltxtc">MY PATIENTS</div>
                        <div className="contridiv gridChange">
                          <div className="contribox ">
                            <p className="contiBoxtxt greybox">&lt;20 Mins</p>
                            <div className="smallertxt">
                              00:00:00 (hh:mm:ss)
                            </div>
                            <p className="num">00</p>
                            <p className="txt4" style={{ marginBottom: "0" }}>
                              Patients
                            </p>
                            <p className="txt4" style={{ color: "#FF4030" }}>
                              Incomplete
                            </p>
                          </div>
                          <div className="contribox">
                            <p className="contiBoxtxt ligthbox">
                              &gt;= 20 Mins
                            </p>
                            <div className="contiseparation">
                              <div>
                                <div className="smallertxt">
                                  00:00:00 <br />
                                  (hh:mm:ss)
                                </div>
                                <p className="num">00</p>
                                <p className="txt4">Patients</p>
                                <p
                                  className="txt4"
                                  style={{ color: "#A99C58" }}
                                >
                                  Completed with other's help
                                </p>
                              </div>
                              <div>
                                <div className="smallertxt">
                                  00:00:00 <br />
                                  (hh:mm:ss)
                                </div>
                                <p className="num">00</p>
                                <p className="txt4">Patients</p>
                                <p
                                  className="txt4"
                                  style={{ color: "#7A5DDA" }}
                                >
                                  Completed with Full Contribution
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="elemBox">
                        <p className="bigNum">00</p>
                        <div className="smallertxt">
                          #Patients 00:00:00 (hh:mm:ss)
                        </div>
                        <div className="smaltxtc">MY PATIENTS</div>
                        <div className="contridiv">
                          <div className="contribox ">
                            <p className="contiBoxtxt greybox">&lt;20 Mins</p>
                            <div className="smallertxt">
                              00:00:00 (hh:mm:ss)
                            </div>
                            <p className="num">00</p>
                            <p className="txt4" style={{ marginBottom: "0" }}>
                              Patients
                            </p>
                            <p className="txt4" style={{ color: "#FF4030" }}>
                              Incomplete
                            </p>
                          </div>
                          <div className="contribox">
                            <p className="contiBoxtxt ligthbox">
                              &gt;= 20 Mins
                            </p>
                            <div className="contiseparation">
                              <div>
                                <div className="smallertxt">
                                  00:00:00 <br /> (hh:mm:ss)
                                </div>
                                <p className="num">00</p>
                                <p className="txt4">Patients</p>
                                <p
                                  className="txt4"
                                  style={{ color: "#A99C58" }}
                                >
                                  Completed with other's help
                                </p>
                              </div>
                              <div>
                                <div className="smallertxt">
                                  00:00:00 <br /> (hh:mm:ss)
                                </div>
                                <p className="num">00</p>
                                <p className="txt4">Patients</p>
                                <p
                                  className="txt4"
                                  style={{ color: "#7A5DDA" }}
                                >
                                  Completed with Full Contribution
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="colSpan12">
                <div className="DashCompo">
                  <div className="compoTitle">
                    <div className="title-icon">
                      <Icon icon="bxs:down-arrow" />
                      <p className="title">System vs Manual Tracking</p>
                    </div>
                  </div>
                  <div className="compoBody">
                    <div className="contributionElem grid552">
                      <div className="elemBox">
                        <p className="txtHead">
                          System Vs <br /> Manual
                        </p>
                        <div className="contridiv">
                          <div className="contribox ">
                            <p className="contiBoxtxt greybox">
                              <div
                                className="padd"
                                style={{ backgroundColor: "#ccc" }}
                              >
                                <Icon
                                  icon="ic:sharp-tv"
                                  color="#222"
                                  width="18"
                                  height="18"
                                />
                              </div>
                              Tracked By System
                            </p>
                            <p className="num">00</p>
                            <p className="txt4">Patients</p>
                            <p className="txt4">00:00:00 (hh:mm:ss)</p>
                          </div>
                          <div className="contribox">
                            <p className="contiBoxtxt ligthbox">
                              <div
                                className="padd"
                                style={{ backgroundColor: "#bdaeed" }}
                              >
                                <Icon
                                  icon="mingcute:contacts-fill"
                                  className="contriboxIcon"
                                  color="#7a5dda"
                                  width="18"
                                  height="18"
                                />
                              </div>
                              Tracked Manually
                            </p>
                            <p className="num">00</p>
                            <p className="txt4">Patients</p>
                            <p className="txt4">00:00:00 (hh:mm:ss)</p>
                          </div>
                        </div>
                      </div>
                      <div className="elemBox">
                        <p className="bigNum">00</p>
                        <div className="smallertxt">
                          Patients 00:00:00 (hh:mm:ss)
                        </div>
                        <div className="smaltxtc">Tracked By System</div>
                        <div className="contridiv grid3col">
                          <div className="contribox ">
                            <p
                              className="contiBoxtxt"
                              style={{ backgroundColor: "#10AC5040" }}
                            >
                              <div
                                className="padd"
                                style={{ backgroundColor: "#88d6a8" }}
                              >
                                <Icon
                                  icon="ic:baseline-phone"
                                  color="#10AC50"
                                  width="18"
                                  height="18"
                                />
                              </div>
                            </p>
                            <div className="smallertxt">
                              00:00:00 (hh:mm:ss)
                            </div>
                            <p className="num">00</p>
                            <p className="txt4">
                              Phone Communication to Patient
                            </p>
                          </div>
                          <div className="contribox ">
                            <p
                              className="contiBoxtxt"
                              style={{ backgroundColor: "#7A5DDA40" }}
                            >
                              <div
                                className="padd"
                                style={{ backgroundColor: "#bdaeed" }}
                              >
                                <Icon
                                  icon="majesticons:list-box-line"
                                  color="#7A5DDA"
                                  width="18"
                                  height="18"
                                />
                              </div>
                            </p>
                            <div className="smallertxt">
                              00:00:00 (hh:mm:ss)
                            </div>
                            <p className="num">00</p>
                            <p className="txt4">Call Documentation</p>
                          </div>
                          <div className="contribox ">
                            <p
                              className="contiBoxtxt"
                              style={{ backgroundColor: "#eee" }}
                            >
                              <div
                                className="padd"
                                style={{ backgroundColor: "#ccc" }}
                              >
                                <Icon
                                  icon="mingcute:contacts-fill"
                                  color="#222"
                                  width="18"
                                  height="18"
                                />
                              </div>
                            </p>
                            <div className="smallertxt">
                              00:00:00 (hh:mm:ss)
                            </div>
                            <p className="num">00</p>
                            <p className="txt4">In-Person Visit</p>
                          </div>
                        </div>
                      </div>
                      <div className="elemBox">
                        <div className="smaltxtc">Total Tracked Manually</div>
                        <p className="bigNum">00</p>
                        <div className="smaltxtc">Patients</div>
                        <div className="smallertxt">
                          Patients 00:00:00 (hh:mm:ss)
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Performance;
