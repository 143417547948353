import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logOut } from "../features/authSlice";
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,

  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = localStorage.getItem("RLH_token");
    console.log(getState());

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 401) {
    console.log("sending refresh token", result);
    api.dispatch(logOut());
    // send refresh token to get new access token
    // const refreshResult = await baseQuery("/refresh", api, extraOptions);
    // console.log(refreshResult);
    // if (refreshResult?.data) {
    //   const user = api.getState().componentSlice.user;
    //   // store the new token
    //   api.dispatch(setCredentials({ ...refreshResult.data, user }));
    //   // retry the original query with new access token
    //   result = await baseQuery(args, api, extraOptions);
    // } else {
    //   api.dispatch(logOut());
    // }
  }

  return result;
};
// initialize an empty api service that we'll inject endpoints into later as needed
export const apiCollection = createApi({
  reducerPath: "api",
  // baseQuery: fetchBaseQuery({ baseUrl: "https://rlhapi.herokuapp.com" }),
  baseQuery: baseQueryWithReauth,

  endpoints: () => ({}),
  // endpoints: (build) => ({
  //   getAccessLevel: build.query({
  //     query: () => "/facility/accessLevel",
  //   }),
  // }),
});
