import React from "react";
import PopUp from "../patients/patientViewPage/PopUp";
import AddShedule from "./AddShedule";
import { useEffect, useState } from "react";
import { format, parseISO, isAfter } from "date-fns";
import "./weekElement.scss";
import EventViewer from "./EventViewer";

export function parseTimeString(timeString) {
  const [hourString, period] = timeString?.split(" ");
  const string = hourString + ":" + "00";
  const [hour, minute] = string?.split(":")?.map(Number);

  let adjustedHour = hour;
  if (period === "PM" && hour !== 12) {
    adjustedHour += 12;
  } else if (period === "AM" && hour === 12) {
    adjustedHour = 0;
  }

  const parsedDate = new Date();
  parsedDate.setHours(adjustedHour, minute, 0, 0);
  parsedDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

  // return format(parsedDate, "KK:mm a");
  return parsedDate.getTime();
}
export function changeTime(time) {
  const [hourString, period] = time?.split(" ");
  const [hour, minute] = hourString?.split(":")?.map(Number);

  let adjustedHour = parseInt(hour);
  if (period === "PM" && adjustedHour !== 12) {
    adjustedHour += 12;
  } else if (period === "AM" && adjustedHour === 12) {
    adjustedHour = 0;
  }

  const parsedDate = new Date();
  parsedDate.setHours(adjustedHour, parseInt(minute), 0, 0);
  parsedDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

  return parsedDate.getTime();
}
const WeekElement = ({
  upper,
  lower,
  dif,
  weekDates,
  sheduleData,
  setSheduleData,
  setCallPatientPopUp,
  setEventData,
}) => {
  let presentDay = new Date();
  const [viewEvent, setViewEvent] = useState();
  const [sheduleDate, setSheduleDate] = useState([]);

  const [shedulePopUp, setShedulePopUp] = useState(false);
  const cancelfn = () => {
    setShedulePopUp(false);
  };
  const closeFn = () => {
    setViewEvent();
  };
  const onClickCallPatient = () => {
    setCallPatientPopUp(true);
    closeFn();
  };

  const viewEventFn = (e, event) => {
    e.stopPropagation();
    setEventData(event);
    setViewEvent(event);
  };
  const clickForShedule = (day) => {
    if (isAfter(parseISO(format(presentDay, "yyyy-MM-dd")), parseISO(day)))
      return;
    setSheduleDate(day);
    setShedulePopUp(true);
  };

  return (
    <>
      <div className="weekTime">
        {shedulePopUp && (
          <PopUp cancelfn={cancelfn} width={"80vw"}>
            <AddShedule
              cancelfn={cancelfn}
              sheduleDate={sheduleDate}
              setSheduleData={setSheduleData}
              lower={lower}
              upper={upper}
              sheduleData={sheduleData}
            />
          </PopUp>
        )}
        {viewEvent && (
          <EventViewer
            viewEvent={viewEvent}
            closeFn={closeFn}
            onClickCallPatient={onClickCallPatient}
          />
        )}

        <p className="upper">{upper} </p>
        {dif === "last" && <p className="lower">{lower}</p>}
      </div>
      <div className="weekElem">
        {weekDates.map((day) => (
          <div className="ele" onClick={() => clickForShedule(day)}>
            {" "}
            <div className="eventComp">
              {sheduleData
                .filter(
                  (ele) =>
                    ele.Date === day &&
                    changeTime(ele.time) < parseTimeString(lower) &&
                    changeTime(ele.time) >= parseTimeString(upper)
                )
                .map((event) => (
                  <p
                    className="eventTitle"
                    onClick={(e) => viewEventFn(e, event)}
                  >
                    {event.callPurpose}
                  </p>
                ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default WeekElement;
