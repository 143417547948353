import CharData from "../data_json/CharData.json";

export const alwaysValidated = (value) => {
  return true;
};
export const isNotEmpty = (value) => {
  return value?.trim() !== "";
};
export const isNotEmptyList = (value) => {
  return value?.length > 0;
};

export const isNotEmptyDict = (value) => {
  return Object.keys(value).length !== 0;
};

export const emailValidation = (value) => {
  return value?.includes(".com") && value.includes("@");
};

export const alternateEmailValidation = (value) => {
  if (value === "") {
    return true;
  }
  return value?.includes(".com") && value.includes("@");
};

export const phNoValidation = (value) => {
  return value?.length === 10;
};
export const phNoOrEmptyValidationorEmpty = (value) => {
  if (value?.length === 0) {
    return true;
  }
  return value?.length === 10;
};

export const zipValidity = (value) => {
  return value?.length === 5;
};
export const zipOrEmptyValidity = (value) => {
  if (value?.length === 0) {
    return true;
  }
  return value?.length === 5;
};

export const idValueValidation = (id_type, value) => {
  // console.log(value, id_type);
  if (id_type === "SSN#") {
    return value.length === 9;
  } else if (
    id_type === "Driving License#" ||
    id_type === "State ID#" ||
    id_type === "Passport#"
  ) {
    return isNotEmpty(value);
  } else if (id_type === "No ID" || id_type.trim() === "") {
    return true;
  }

  return false;
};

export const insuranceIdValidation = (bill_type, value) => {
  if (bill_type === "Insurance") {
    return isNotEmpty(value);
  }
  return true;
};
// Char Validate Functions
export const alphaValidate = (charValue, add_list = []) => {
  const allowedValues = [
    ...CharData.smallAlpha,
    ...CharData.capsAlpha,
    ...add_list,
    " ",
    null,
  ];
  if (allowedValues.includes(charValue)) {
    return true;
  }
  return false;
};

export const noSpaceAlphaValidate = (charValue) => {
  const allowedValues = [...CharData.smallAlpha, ...CharData.capsAlpha, null];
  if (allowedValues.includes(charValue)) {
    return true;
  }
  return false;
};

export const numericValidate = (charValue) => {
  const allowedValues = [...CharData.numerical, null];
  if (allowedValues.includes(charValue)) {
    return true;
  }
  return false;
};

export const alphaNumericValidate = (charValue, add_list = []) => {
  const allowedValues = [
    ...CharData.smallAlpha,
    ...CharData.capsAlpha,
    ...CharData.numerical,
    ...add_list,
    " ",
    null,
  ];
  return allowedValues.includes(charValue);
};

export const noCharValidateCheck = (charValue) => {
  return true;
};

export const cityCharValidate = (charValue, value) => {
  if (alphaValidate(charValue)) {
    return true;
  }
  return false;
};

export const otpCharValidate = (charValue, value) => {
  if (numericValidate(charValue) && value.length < 7) {
    return true;
  }
  return false;
};

export const phNoCharValidation = (charValue, value) => {
  if (charValue === undefined) {
    if (value.length > 10) return false;
    const eachCharCheck = value.split.reduce(
      (prev, curr) => prev && numericValidate(curr),
      true
    );
    return eachCharCheck;
  }
  return numericValidate(charValue) && value.length <= 10;
};
export const faxCharValidation = (charValue, value) => {
  if (charValue === undefined) {
    const eachCharCheck = value.split.reduce(
      (prev, curr) => prev && numericValidate(curr),
      true
    );
    return eachCharCheck;
  }
  return numericValidate(charValue);
};

export const zipCharValidate = (charValue, value) => {
  if (numericValidate(charValue) && value.length <= 5) {
    return true;
  }
  return false;
};

export const idValueCharValidation = (id_type, charValue, value) => {
  // console.log(value, id_type);
  if (id_type === "SSN#") {
    return value.length <= 9 && numericValidate(charValue);
  } else if (id_type === "Driving License#") {
    return alphaNumericValidate(charValue, ["-"]);
  } else if (id_type === "State ID#" || id_type === "Passport#") {
    return alphaNumericValidate(charValue, ["-", "#"]);
  } else if (id_type === "No ID" || id_type.trim() === "") {
    return true;
  }

  return false;
};

export const insuranceIdCharValidation = (charValue, value) => {
  return alphaNumericValidate(charValue, ["-"]);
};
// Files validate
export const fileValidateCheck = (errorMessage, files, isTouched) => {
  const individualFileMaxSize = 5; //sizes in MB
  const totalFileMaxSize = 5; //sizes in MB
  let cumFileSize = 0;

  if (files.length === 0) {
    if (isTouched) {
      return [false, errorMessage];
    }
    return [false, ""];
  }
  for (let i in files) {
    // console.log(files[i]);
    if (files[i].size > individualFileMaxSize) {
      return [false, `File size exceeds ${individualFileMaxSize}MB`];
    }
    cumFileSize = cumFileSize + files[i].size;
  }
  if (cumFileSize > totalFileMaxSize) {
    return [false, `Total cumulative file size exceeds ${totalFileMaxSize}MB`];
  }
  // console.log(files);
  return [true, ""];
};

export const conditionalFilevalidateCheck = (data, files, isTouched) => {
  // console.log(data, files, isTouched);
  if (data["noCheck"]) {
    return [true, ""];
  } else {
    return fileValidateCheck(data["errorMessage"], files, isTouched);
  }
};
export const filealwaysValidated = (files, isTouched) => {
  return [true, ""];
};

//Date validation
export const dateValidateCheck = (data) => {
  return data instanceof Date;
};
export const dateorEmptyValidateCheck = (data) => {
  if (data === "") {
    console.log(data);
    return true;
  }
  return data instanceof Date;
};

export const selectorValidateCheck = (data) => {
  if (data === "") {
    return false;
  } else return true;
};

export const fileSizeValidation = (data) => {
  const totalFileMaxSize = 5; //sizes in MB

  if (data.size > totalFileMaxSize) {
    return false;
  }
  return true;
};
