import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import SelectComponent from "../../components/SelectComponent";
import { Icon } from "@iconify/react";
import { useGetPatientsAllProfilesQuery } from "../../app/api/patientsApi";
import { useState, useEffect } from "react";
import PatientInteractionPage from "../../schedule/patientInteractionSelection/PatientInteractionPage";
import { useLocation } from "react-router-dom";
import { generalHandleValueChanges } from "../Careteam/helperfunctions";
import NativeSelect from "@mui/material/NativeSelect";
import SearchIcon from "@mui/icons-material/Search";
import PopUp from "../patientViewPage/PopUp";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import AddService from "./AddService";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import LoaderComponent from "../../components/LoaderComponent";
import FormControl from "@mui/material/FormControl";

import "./patientsPreviewPage.scss";

const useStyles = {
  TableContainer: {
    border: "0.5px solid #6D6D71",
    boxShadow: "none",
    height: "500px",
    overflowY: "auto",
  },
  tableHead: {
    backgroundColor: "#EEEEEE",
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  tableHeaderTitle: {
    fontSize: "calc(6.32px + 0.632 * 1vw)",
    color: "#222222",
    borderRight: "0.5px solid #6D6D71",
    borderBottom: "2px solid #6D6D71",
    fontWeight: "600",
  },
  tableBodyContent: {
    fontSize: "calc(6.32px + 0.632 * 1vw)",
    borderRight: "0.5px solid #6D6D71",
    padding: 0,
    paddingLeft: "1rem",
  },
  formControl: {
    "& .MuiInputBase-input": {
      fontSize: "  calc(6.32px + 0.632 * 1vw)",
    },
  },

  labelSelect: {
    fontSize: "  calc(6.32px + 0.632 * 1vw)",
  },

  select: {
    "&.Mui-focused": {
      color: "", // to overwrite the default behaviour
    },
    background: "white",
    color: "#222222",
    borderColor: " #6D6D71",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "4px",
    paddingLeft: "10px",
    // paddingTop: '14px',
    // paddingBottom: '15px',
    "&:hover": {},
    "&:focus": {
      borderRadius: "4px",
      background: "white",
    },
  },
};

const PatientsPreviewPage = (props) => {
  const location = useLocation();
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [IdToSent, setIdToSent] = useState(false);
  const [callPatientPopUp, setCallPatientPopUp] = useState(false);

  const onClickEditBtn = (value) => {
    navigate("/patients/Add-Patient", { state: { id: value, type: "Edit" } });
  };
  const onClickViewBtn = (value) => {
    navigate("/patients/View-Patient", { state: { id: value } });
  };

  const navigate = useNavigate();
  const { data: dataFromApi, isLoading } = useGetPatientsAllProfilesQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  // const {onClickEditBtn,onClickViewBtn}=props
  const [data, setData] = useState([]);
  const [programType, setProgramType] = useState(
    location?.state?.type ?? "All Patients"
  );

  const [patientDataForService, setPatientDataForService] = useState("");

  const [dataForFilter, setDataForFilter] = useState([]);

  useEffect(() => {
    if (!dataFromApi) return;
    setData(dataFromApi);
  }, [dataFromApi]);

  useEffect(() => {
    if (!location?.state?.type) return;
    setProgramType(location?.state?.type);
  }, [location]);
  // const classes = useStyles();

  const onClickCarePlan = (value) => {
    navigate("/patients/careplan", { state: { id: value } });
  };
  const onClickRpm = (value) => {
    navigate("/patients/rpm", { state: { id: value } });
  };
  const onClickCall = (value) => {
    setIdToSent(value);
    setCallPatientPopUp(true);
    // navigate("/patients/careplanCall", { state: { id: value } });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();

    if (searchValue === "") {
      if (programType === "All Patients") {
        setDataForFilter(data);
      } else {
        const filteredData = data.filter((x) =>
          x.enrolledPrograms.some(
            (program) => program.programName === programType
          )
        );
        setDataForFilter(filteredData);
      }
      return;
    }

    const filteredData = data.filter((x) => {
      const matchesProgramType =
        programType === "All Patients" ||
        x.enrolledPrograms.some(
          (program) => program.programName === programType
        );

      const matchesSearch = x.Name.toLowerCase().includes(searchValue);

      return matchesProgramType && matchesSearch;
    });

    setDataForFilter(filteredData);
  };

  const cancelfn = () => {
    setPopUpOpen(false);
  };
  const openfn = (patientData) => {
    setPatientDataForService(patientData);
    setPopUpOpen(true);
  };
  const handleprogramChange = (opt) => {
    setProgramType(opt);
    // if (opt === "All Patients") {
    //   setDataForFilter(data);
    //   return;
    // }
    // const filtertdata = data.filter((x) => x.programs === opt);
    // setDataForFilter(filtertdata);
  };
  useEffect(() => {
    if (!data) return;
    if (programType === "All Patients") {
      setDataForFilter(data);
      return;
    }
    let a = data.filter((x) =>
      x.enrolledPrograms.some((program) => program.programName === programType)
    );

    setDataForFilter(a);
  }, [data, programType]);
  return (
    <div className="container-patients">
      <LoaderComponent isLoading={isLoading} />

      {popUpOpen && (
        <PopUp cancelfn={cancelfn}>
          <AddService
            patientDataForService={patientDataForService}
            cancelfn={cancelfn}
          />
        </PopUp>
      )}
      {callPatientPopUp && (
        <PatientInteractionPage
          // eventData={eventData}
          id={IdToSent}
          setCallPatientPopUp={setCallPatientPopUp}
          // setEventData={setEventData}
        />
      )}
      <div className="pageTitle">All Patients</div>
      <div
        className="formElementsCntainer"
        style={{
          display: "flex",
          gap: "1rem",
          marginBottom: "1rem",
          position: "relative",
        }}
      >
        <div className="selectWrap">
          <SelectComponent
            OptionsArray={["CCM", "RPM", "BHI", "All Patients"]}
            valueData={programType}
            func={handleprogramChange}
          />
        </div>

        <FormControl variant="standard">
          {/* <InputLabel htmlFor="outlined-adornment-password">Pass</InputLabel> */}
          <Input
            sx={{
              borderColor: " #6D6D71",
              borderStyle: "solid",
              borderWidth: "1px",
              // borderRadius: "4px",
              height: "100%",
              fontSize: "  calc(6.32px + 0.632 * 1vw)",
              padding: "0 7px 0 7px",
              marginTop: "18px",
              "&:focus": {
                outline: 0,
              },
            }}
            id="outlined-adornment-password"
            placeholder="Search..."
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end">
                  <SearchIcon style={{ width: "30", height: "30" }} />
                </IconButton>
              </InputAdornment>
            }
            onChange={(e) => handleSearch(e)}
          />
        </FormControl>
        <IconButton
          style={{
            position: "absolute",
            right: "2%",
            top: "19%",
            borderRadius: "4px",
            padding: "1rem",
            fontSize: "  calc(6.32px + 0.632 * 1vw)",
            color: "#fff",
            fontWeight: "600",
            backgroundColor: "#7A5DDA",
          }}
          className="addPatients"
          onClick={() => navigate("/patients/Add-Patient")}
        >
          <Icon icon="uil:plus" />
          Add Patient
        </IconButton>
      </div>
      <TableContainer sx={useStyles.TableContainer} component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={useStyles.tableHeaderTitle}>S.no</TableCell>
              <TableCell sx={useStyles.tableHeaderTitle}>Name</TableCell>
              <TableCell sx={useStyles.tableHeaderTitle}>
                Phone Number
              </TableCell>
              <TableCell sx={useStyles.tableHeaderTitle}>SSN</TableCell>
              <TableCell sx={useStyles.tableHeaderTitle}>
                Preferred Language
              </TableCell>
              <TableCell sx={useStyles.tableHeaderTitle}>Gender</TableCell>
              <TableCell sx={useStyles.tableHeaderTitle}>
                Date Of Birth
              </TableCell>
              <TableCell sx={useStyles.tableHeaderTitle}>
                Enrolled Program
              </TableCell>
              <TableCell sx={useStyles.tableHeaderTitle}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataForFilter.map((row, index) => (
              <TableRow key={row.PatientId}>
                <TableCell sx={useStyles.tableBodyContent}>
                  {index + 1}
                </TableCell>
                <TableCell
                  sx={{
                    ...useStyles.tableBodyContent,
                    "&:hover": {
                      color: " #7a5dda",
                      cursor: "pointer",
                      textDecoration: "underline #7a5dda 1px",
                    },
                  }}
                  onClick={() => onClickViewBtn(row.PatientId)}
                >
                  {row["Name"]}
                </TableCell>
                <TableCell sx={useStyles.tableBodyContent}>
                  {row["Phone Number"]}
                </TableCell>
                <TableCell sx={useStyles.tableBodyContent}>
                  {row["SSN"]}
                </TableCell>
                <TableCell sx={useStyles.tableBodyContent}>
                  {row["Preferred Language"]}
                </TableCell>
                <TableCell sx={useStyles.tableBodyContent}>
                  {row["Gender"]}
                </TableCell>
                <TableCell sx={useStyles.tableBodyContent}>
                  {row["Date Of Birth"]}
                </TableCell>
                <TableCell sx={useStyles.tableBodyContent}>
                  {row["enrolledPrograms"]
                    ?.map((program) => program?.programName)
                    .join(", ")}
                </TableCell>
                <TableCell sx={useStyles.tableBodyContent}>
                  <IconButton onClick={() => onClickCarePlan(row.PatientId)}>
                    <Icon
                      icon="material-symbols:contact-page-outline"
                      style={{ height: "2rem", width: "2rem" }}
                    />
                  </IconButton>
                  <IconButton onClick={() => openfn(row)}>
                    <Icon
                      icon="ph:plus-bold"
                      style={{ height: "2rem", width: "2rem" }}
                    />
                  </IconButton>
                  <IconButton onClick={() => onClickCall(row.PatientId)}>
                    <Icon
                      icon="ic:round-call"
                      style={{ height: "2rem", width: "2rem" }}
                    />
                  </IconButton>
                  <IconButton onClick={() => onClickViewBtn(row.PatientId)}>
                    <Icon
                      icon="gridicons:fullscreen"
                      style={{ height: "2rem", width: "2rem" }}
                    />
                  </IconButton>
                  <IconButton onClick={() => onClickEditBtn(row.PatientId)}>
                    <Icon
                      icon="material-symbols:edit-square-outline-sharp"
                      style={{ height: "2rem", width: "2rem" }}
                    />
                  </IconButton>
                  {row["enrolledPrograms"]?.find(
                    (program) => program.programName === "RPM"
                  ) && (
                    <IconButton onClick={() => onClickRpm(row.PatientId)}>
                      <Icon
                        icon="streamline:health-medical-heart-rate-health-beauty-information-data-beat-pulse-monitor-heart-rate-info"
                        style={{ height: "2rem", width: "2rem" }}
                      />
                    </IconButton>
                  )}{" "}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PatientsPreviewPage;
