import React, { useEffect, useState } from "react";
import PopUp from "../patients/patientViewPage/PopUp";
import SelectComponent from "../components/SelectComponent";
import {
  useGetUserListMutation,
  useResendLoginMutation,
} from "../app/api/userManagementApi";
import "./adminPages.scss";
import { Icon } from "@iconify/react";
import LoaderComponent from "../components/LoaderComponent";
import { generalHandleValueChanges } from "../patients/Careteam/helperfunctions";
const ResendLoginCredentials = () => {
  const [shedulePopUp, setShedulePopUp] = useState(false);

  const [userData, setUserData] = useState();
  const [getUserListFn, { data: userList, isLoading }] =
    useGetUserListMutation();
  const [ResendLoginFn, { data }] = useResendLoginMutation();
  const [keyword, setKeyword] = useState({ role: "All", name: "" });
  const [user, setUser] = useState("");

  const cancelfn = () => {
    setUser("");
    setShedulePopUp(false);
  };
  const onClickResend = (user) => {
    setUser(user);
    setShedulePopUp(true);
  };
  const onClickConfirmed = () => {
    if (!user) return;
    ResendLoginFn({
      profileId: user._id,
    });
    setShedulePopUp(false);
  };
  const rolefilterFunction = (key) => {
    if (key === "All") {
      return () => true;
    } else {
      return (user) => user.userType.toLowerCase() === key.toLowerCase();
    }
  };
  const searchfilterFunction = (key) => {
    return (user) => user?.Name?.toLowerCase().includes(key.toLowerCase());
  };
  const ResendLogin = () => {};

  useEffect(() => {
    if (!userList) {
      return;
    }
    setUserData(userList);
  }, [userList, keyword]);
  useEffect(() => {
    getUserListFn();
  }, [getUserListFn]);
  return (
    <div className="mainLoyout">
      {shedulePopUp && (
        <PopUp cancelfn={cancelfn}>
          <div>
            <p>Resend Login Credentials for {user?.Name} </p>
            <div className="buttonsContainer">
              <button onClick={onClickConfirmed}>Send</button>
              <button onClick={cancelfn}>cancel</button>
            </div>
          </div>
        </PopUp>
      )}
      <LoaderComponent isLoading={isLoading} />
      <div className="title">Resend Login Credentials</div>
      <div className="filterAndContent">
        <div className="filter">
          <div className="filterElemt">
            <p>Show</p>
            <SelectComponent
              OptionsArray={[
                "All",
                "Admin",
                "Clinician",
                "Provider",
                "Facility Admin",
                "Physician",
              ]}
              valueData={keyword.role}
              fn={generalHandleValueChanges}
              setvalue={setKeyword}
              name={"role"}
            />
          </div>
          <div className="filterElemt">
            <p>Search By Name</p>
            <input
              value={keyword.name}
              onChange={(e) => setKeyword({ ...keyword, name: e.target.value })}
            />
          </div>
        </div>
      </div>
      <div className="contentBox">
        <div className="contentGrid header">
          <div className="griElement colSpan1">S.No</div>
          <div className="griElement colSpan2">Name</div>
          <div className="griElement colSpan2">Role</div>
          <div className="griElement colSpan3">Email</div>
          <div className="griElement colSpan2">Phone Number</div>
          <div className="griElement colSpan2">Action</div>
        </div>
        {userData &&
          userData
            .filter(rolefilterFunction(keyword.role))
            .filter(searchfilterFunction(keyword.name))
            .map((user, index) => (
              <div key={index} className="contentGrid contentBody">
                <div className="griElement colSpan1">{index + 1}</div>
                <div className="griElement colSpan2">{user.Name}</div>
                <div className="griElement colSpan2">{user.userType}</div>
                <div className="griElement colSpan3">{user.email}</div>
                <div className="griElement colSpan2">
                  {user["Phone Number"]}
                </div>

                <div className="griElement buttonBox colSpan2">
                  <button onClick={() => onClickResend(user)}>
                    <Icon icon="mdi:email-resend" width="18" height="18" />
                  </button>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default ResendLoginCredentials;
