import React, { useState, useEffect } from "react";
import "./callingCarePlan.scss";
import CareBodyComponent from "../CareBodyComponent";
import {
  useGetPatientsCarePlanMutation,
  useUpdateCarePlanMutation,
} from "../../../app/api/patientsApi";
const CallingCarePlan = ({ id }) => {
  const [selectedPatient, setSelectedPatient] = useState();

  const [updateDatafn, { data: updatedData }] = useUpdateCarePlanMutation();

  const [carePlanCallFn, { data: Patientdetails }] =
    useGetPatientsCarePlanMutation();

  useEffect(() => {
    carePlanCallFn({
      PatientId: id,
    });
  }, [carePlanCallFn, id]);

  useEffect(() => {
    if (!Patientdetails) return;
    setSelectedPatient(Patientdetails);
  }, [Patientdetails]);

  useEffect(() => {
    if (!updatedData) return;
    setSelectedPatient(updatedData);
  }, [updatedData]);

  return (
    <div className="callingCarePlan">
      {selectedPatient && (
        <CareBodyComponent
          id={id}
          selectedPatient={selectedPatient}
          updateDatafn={updateDatafn}
        />
      )}
    </div>
  );
};

export default CallingCarePlan;
