import { apiCollection } from "./apiCollection";

const ScheduleCallsApi = apiCollection.injectEndpoints({
  endpoints: (build) => ({
    createSchedule: build.mutation({
      query: (payload) => {
        return {
          url: "callschedule/create",
          method: "POST",
          body: payload,
        };
      },
    }),
    getScheduleList: build.mutation({
      query: (payload) => {
        return {
          url: "callschedule/getScheduleList",
          method: "POST",
          body: payload,
        };
      },
    }),
    updateNotes: build.mutation({
      query: (payload) => {
        return {
          url: "callschedule/updateNotes",
          method: "POST",
          body: payload,
        };
      },
    }),
    getNotes: build.mutation({
      query: (payload) => {
        return {
          url: "callschedule/getNotes",
          method: "POST",
          body: payload,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateScheduleMutation,
  useGetScheduleListMutation,
  useGetNotesMutation,
  useUpdateNotesMutation,
} = ScheduleCallsApi;
