import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  goToLoginPage,
  loginDataActions,
} from "../app/features/loginDataSlice";
import LoginDoll from "../images/login/heartIllustration.png";
import LoginBg from "../images/login/loginBackground.png";
import LoginHeader from "../images/login/loginHeader.png";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import ResetPassword from "./ResetPassword";
import TwoFA from "./TwoFA";
import "./loginLayout.scss";

const LoginLayout = () => {
  const dispatch = useDispatch();
  const currentLoginPage = useSelector((state) => state.loginData.currentPage);
  console.log("In login layout ", currentLoginPage);

  useEffect(() => {
    dispatch(loginDataActions.switchPage(goToLoginPage()));
  }, []);

  return (
    <div className="login">
      <div className="loginDesignContainer1">
        <img className="bg" src={LoginBg} alt="" />
        <img className="loginDoll" src={LoginDoll} alt="" />
      </div>
      <div className="loginContainer1">
        <div className="loginContainer1_1">
          <div className="loginLeftContainer1">
            <div className="loginLeftContainer1_1">
              <div className="loginImg">
                <img src={LoginHeader} alt="" />
              </div>
              {currentLoginPage === "loginPage" && <Login />}
              {currentLoginPage === "twoFA_Page" && <TwoFA />}
              {currentLoginPage === "forgotPasswordPage" && <ForgotPassword />}
              {currentLoginPage === "resetPasswordPage" && <ResetPassword />}
            </div>
          </div>
          {/* twoFA_Page */}
          <div className="loginRightContainer1">
            <div className="title">Welcome Back...</div>
            <div className="descContainer">
              <div className="desc">
                A Better Way to Access <span>Healthcare</span>,
              </div>
              <div className="desc">From the Comfort of Your Home!</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
