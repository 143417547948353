import React from "react";
import { useState, useEffect } from "react";
import PopUp from "../../patientViewPage/PopUp";
import SelectComponent from "../../../components/SelectComponent";
import {
  generalHandleValueChanges,
  areObjectsEqual,
} from "../../Careteam/helperfunctions";
import FloatingNotifications from "../../../utilities/FloatingNotifications";
import "./addPatient.scss";

const requiredprogramsMapping = {
  CCM: {
    Eligible: ["EvaluationType", "MedicarePatient"],
    "Not Interested": ["NotInterestedReason"],
    "Consent Received": ["ConsentType", "MedicarePatient"],
    "Follow Up": ["FollowUpDate", "FollowUpNotes", "MedicarePatient"],
  },
  RPM: {
    Eligible: ["EligibleNotes"],
    "Not Interested": ["NotInterestedReason"],
    "Consent Received": ["ConsentType", "ConcernReceivedNotes"],
    "Follow Up": ["FollowUpDate", "FollowUpNotes"],
  },
};
const programMapping = {
  CCM: {
    Eligible: [
      "EvaluationType",
      "EvaluationDate",
      "EligibleNotes",
      "MedicarePatient",
    ],
    "Not Interested": [
      "EvaluationType",
      "EvaluationDate",
      "NotInterestedReason",
      "MedicarePatient",
    ],
    "Consent Received": [
      "EvaluationType",
      "EvaluationDate",
      "ConsentType",
      "ConcernReceivedNotes",
      "MedicarePatient",
      "ConsentRecordedDate",
      "ConsentSignedDate",
      "ConsentFile",
    ],
    "Follow Up": [
      "EvaluationType",
      "EvaluationDate",
      "FollowUpDate",
      "FollowUpNotes",
      "MedicarePatient",
    ],
  },
  RPM: {
    Eligible: ["EligibleNotes"],
    "Not Interested": ["NotInterestedReason"],
    "Consent Received": ["ConsentType", "ConcernReceivedNotes"],
    "Follow Up": ["FollowUpDate", "FollowUpNotes"],
  },
};

const AddPatient = ({
  selectedData,
  updatePatientfn,
  id,
  popUpOpen,
  setPopUpOpen,
  updateCallValue,
  enrolledData,
  setTriggerEffect,
  triggerEffect,
  setEnrolledData,
  setUpdateValue,
}) => {
  const storedData = localStorage.getItem("RLH_user");

  const initialState = {
    ProgramName: "",
    ProgramStatus: "Eligible",
    EvaluationDate: "",
    EvaluationType: "",
    EligibleNotes: {
      note: "",
      createdBy: storedData ?? "",
      createdDateAndTime: new Date(),
    },
    MedicarePatient: "",
    NotInterestedReason: "",
    FollowUpNotes: "",
    ConsentType: "",
    ConcernReceivedNotes: "",
    ConsentRecordedDate: "",
    ConsentSignedDate: "",
    ConsentFile: "",
    FollowUpDate: "",
    Status: "active",
  };
  const [programList, setProgramList] = useState();
  const [updateData, setUpdateData] = useState();
  const [addProgram, setAddProgram] = useState(initialState);
  const [mandatoryFields, setMandatoryFields] = useState();

  const cancelfn = () => {
    setAddProgram(initialState);
    setUpdateData("");
    setPopUpOpen(false);
    if (setUpdateValue) {
      setUpdateValue(null);
    }
  };
  // console.log(enrolledData);
  // console.log(addProgram?.ConsentFile);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size > 5 * 1024 * 1024) {
      FloatingNotifications("error", "File should be less than 5 MB");
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAddProgram((prev) => ({
          ...prev,
          ConsentFile: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };
  const updatebtnfn = (object) => {
    if (enrolledData) {
      let data = enrolledData.filter((el) => !areObjectsEqual(el, object));
      setUpdateData(data);
      setPopUpOpen(true);
      setAddProgram(object);
      return;
    } else {
      const { _id, ...remObject } = object;
      const { enrolledPrograms, ...res } = selectedData;
      const rem = enrolledPrograms?.map((el) => {
        let { _id, ...r } = el;
        return r;
      });
      let data = rem.filter((el) => !areObjectsEqual(el, remObject));
      setUpdateData(data);
      setAddProgram(remObject);

      setPopUpOpen(true);
    }
  };
  const saveProgram = () => {
    if (addProgram["ProgramName"] === "") {
      FloatingNotifications(
        "error",
        "To proceed, all fields must be filled out."
      );

      return;
    }
    let rem = "";
    if (selectedData) {
      const { enrolledPrograms, _id, ...res } = selectedData;
      rem = enrolledPrograms?.map((el) => {
        let { _id, ...r } = el;
        return r;
      });
    }
    if (updateData) {
      rem = updateData;
    }
    const allFields =
      programMapping[addProgram.ProgramName]?.[addProgram.ProgramStatus];

    const requiredFields =
      requiredprogramsMapping[addProgram.ProgramName]?.[
        addProgram.ProgramStatus
      ];

    if (!requiredFields) return;

    const missingFields = requiredFields.filter((field) => !addProgram[field]);
    const readableFields = missingFields.map((field) => {
      switch (field) {
        case "EvaluationType":
          return "Evaluation Type";
        case "MedicarePatient":
          return "Medicare Patient";
        case "NotInterestedReason":
          return "Not Interested Reason";
        case "ConsentType":
          return "Consent Type";
        case "FollowUpDate":
          return "Follow Up Date";
        case "FollowUpNotes":
          return "Follow Up Notes";
        case "ConcernReceivedNotes":
          return "Concern Received Notes";

        default:
          return field;
      }
    });

    if (readableFields.length > 0) {
      FloatingNotifications(
        "error",
        `To proceed,${readableFields.join(", ")} must be filled out.`
      );

      return;
    }
    if (
      addProgram?.ProgramName === "CCM" &&
      addProgram.ProgramStatus === "Consent Received"
    ) {
      if (addProgram?.ConsentType === "Verbal") {
        delete addProgram["ConsentSignedDate"];
        delete addProgram["ConsentFile"];
      } else if (addProgram?.ConsentType === "Written") {
        delete addProgram["ConsentRecordedDate"];
      }
    }

    const updatedProgram = {
      ProgramName: addProgram["ProgramName"],
      ProgramStatus: addProgram["ProgramStatus"],
      Status: addProgram["Status"],
    };

    allFields.forEach((field) => {
      updatedProgram[field] = addProgram[field];
    });
    console.log(updatedProgram);
    console.log(addProgram);

    if (setEnrolledData) {
      if (updateData) {
        setEnrolledData([...rem, addProgram]);
        setPopUpOpen(false);
        setAddProgram(initialState);

        return;
      }
      setEnrolledData((prev) => {
        if (prev) {
          return [...prev, updatedProgram];
        } else {
          return [updatedProgram];
        }
      });
    } else {
      updatePatientfn({
        PatientId: id,

        enrolledPrograms: [...rem, updatedProgram],
      });
    }
    setPopUpOpen(false);
    if (!enrolledData) {
      FloatingNotifications("success", "Program added successfully");
    }
    setAddProgram(initialState);
    if (setUpdateValue) {
      setUpdateValue(null);
    }
  };
  // useEffect(() => {
  //   setAddProgram((prev) => {
  //     return {
  //       ...prev,
  //       EligibleNotes: `${creationData.data} ${creationData.createdBy}`,
  //     };
  //   });
  // }, [creationData]);
  // console.log();
  useEffect(() => {
    let options = ["CCM", "RPM"];
    if (enrolledData) {
      let r = enrolledData?.map((el) => el?.ProgramName);
      let p = [...new Set(r)];
      let updatedOption = options.filter((el) => !p.includes(el));
      setProgramList(updatedOption);
      return;
    }
    let r = selectedData?.enrolledPrograms?.map((el) => el?.ProgramName);
    let p = [...new Set(r)];
    let updatedOption = options.filter((el) => !p.includes(el));
    setProgramList(updatedOption);
    // setProgramList(options);
  }, [selectedData, enrolledData]);

  useEffect(() => {
    if (!updateCallValue) return;
    updatebtnfn(updateCallValue);
    setTriggerEffect(false);
  }, [triggerEffect]);

  useEffect(() => {
    setMandatoryFields(
      requiredprogramsMapping[addProgram.ProgramName]?.[
        addProgram.ProgramStatus
      ]
    );
  }, [addProgram.ProgramName, addProgram.ProgramStatus]);

  return (
    <div>
      {popUpOpen && (
        <PopUp cancelfn={cancelfn}>
          <div className="popUp">
            <p className="popTitle">
              Add Program for {selectedData?.FirstName}
            </p>
            <div className="addContainer">
              <div>
                <div className="popElement">
                  <p className="popLabel">Search ProgramName *</p>
                  <SelectComponent
                    OptionsArray={programList}
                    valueData={addProgram.ProgramName}
                    fn={generalHandleValueChanges}
                    setvalue={setAddProgram}
                    name={"ProgramName"}
                  />
                </div>
                {addProgram.ProgramName === "CCM" &&
                  (addProgram.ProgramStatus === "Eligible" ||
                    addProgram.ProgramStatus === "Not Interested" ||
                    addProgram.ProgramStatus === "Consent Received" ||
                    addProgram.ProgramStatus === "Follow Up") && (
                    <>
                      <div className="popElement">
                        <p className="popLabel">
                          Evaluation Type
                          {Array.isArray(mandatoryFields) &&
                          mandatoryFields.includes("EvaluationType")
                            ? " *"
                            : ""}
                        </p>
                        <SelectComponent
                          OptionsArray={[
                            "Evaluation And Management",
                            "Annual Wellness Visit",
                            "Initial Preventive Physical Examination",
                            "Verbal",
                          ]}
                          valueData={addProgram.EvaluationType}
                          fn={generalHandleValueChanges}
                          setvalue={setAddProgram}
                          name={"EvaluationType"}
                        />
                      </div>
                      <div className="popElement">
                        <p className="popLabel">
                          Medicare Patient
                          {Array.isArray(mandatoryFields) &&
                          mandatoryFields.includes("MedicarePatient")
                            ? " *"
                            : ""}
                        </p>
                        <div className="radio">
                          <div className="radio-align">
                            <input
                              type="radio"
                              name="MedicarePatient"
                              id="MedicarePatientYes"
                              value="Yes"
                              checked={addProgram.MedicarePatient === "Yes"}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setAddProgram,
                                  "MedicarePatient"
                                )
                              }
                            />
                            <label for="MedicarePatientYes">Yes</label>
                          </div>
                          <div className="radio-align">
                            <input
                              type="radio"
                              name="MedicarePatient"
                              id="MedicarePatientNo"
                              value="No"
                              checked={addProgram.MedicarePatient === "No"}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setAddProgram,
                                  "MedicarePatient"
                                )
                              }
                            />
                            <label for="MedicarePatientNo">No</label>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                {addProgram.ProgramName &&
                  addProgram.ProgramStatus === "Consent Received" && (
                    <div className="popElement">
                      <p className="popLabel">
                        Consent Received Notes
                        {Array.isArray(mandatoryFields) &&
                        mandatoryFields.includes("ConcernReceivedNotes")
                          ? " *"
                          : ""}
                      </p>
                      <textarea
                        type="textarea"
                        value={addProgram.ConcernReceivedNotes}
                        className="viewElement "
                        onChange={(event) =>
                          generalHandleValueChanges(
                            event,
                            setAddProgram,
                            "ConcernReceivedNotes"
                          )
                        }
                      />
                    </div>
                  )}
                {addProgram.ProgramName &&
                  addProgram.ProgramStatus === "Follow Up" && (
                    <>
                      <div className="popElement">
                        <p className="popLabel">
                          Follow Up Call Notes
                          {Array.isArray(mandatoryFields) &&
                          mandatoryFields.includes("FollowUpNotes")
                            ? " *"
                            : ""}
                        </p>
                        <textarea
                          type="textarea"
                          value={addProgram.FollowUpNotes}
                          className="viewElement "
                          onChange={(event) =>
                            generalHandleValueChanges(
                              event,
                              setAddProgram,
                              "FollowUpNotes"
                            )
                          }
                        />
                      </div>
                    </>
                  )}
                {addProgram?.ProgramName === "CCM" &&
                  addProgram.ProgramStatus === "Consent Received" &&
                  addProgram?.ConsentType === "Verbal" && (
                    <div className="popElement">
                      <p className="popLabel">
                        Consent Recorded date Field{" "}
                        {Array.isArray(mandatoryFields) &&
                        mandatoryFields.includes("ConsentRecordedDate")
                          ? " *"
                          : ""}
                      </p>
                      <input
                        type="datetime-local"
                        id="meeting-time"
                        name="meeting-time"
                        value={addProgram?.ConsentRecordedDate?.slice(0, 16)}
                        className="viewElement "
                        onChange={(event) =>
                          generalHandleValueChanges(
                            event,
                            setAddProgram,
                            "ConsentRecordedDate"
                          )
                        }
                      />
                    </div>
                  )}
                {addProgram?.ProgramName === "CCM" &&
                  addProgram.ProgramStatus === "Consent Received" &&
                  addProgram?.ConsentType === "Written" && (
                    <>
                      <div className="popElement">
                        <p className="popLabel">
                          Consent Signed Date
                          {Array.isArray(mandatoryFields) &&
                          mandatoryFields.includes("ConsentSignedDate")
                            ? " *"
                            : ""}
                        </p>
                        <input
                          type="datetime-local"
                          id="meeting-time"
                          name="meeting-time"
                          value={addProgram?.ConsentSignedDate?.slice(0, 16)}
                          className="viewElement "
                          onChange={(event) =>
                            generalHandleValueChanges(
                              event,
                              setAddProgram,
                              "ConsentSignedDate"
                            )
                          }
                        />
                      </div>
                      <div className="popElement">
                        <p className="popLabel">
                          Upload File
                          {Array.isArray(mandatoryFields) &&
                          mandatoryFields.includes("ConsentFile")
                            ? " *"
                            : ""}
                        </p>
                        <input
                          type="file"
                          value={addProgram?.consentFile}
                          className="viewElement "
                          onChange={(event) => handleFileChange(event)}
                        />
                      </div>
                    </>
                  )}
              </div>
              <div>
                <div className="popElement">
                  <p className="popLabel">ProgramStatus*</p>
                  <SelectComponent
                    OptionsArray={[
                      "Eligible",
                      "Not Interested",
                      "Consent Received",
                      "Follow Up",
                    ]}
                    valueData={addProgram.ProgramStatus}
                    fn={generalHandleValueChanges}
                    setvalue={setAddProgram}
                    name={"ProgramStatus"}
                  />
                </div>
                {addProgram.ProgramName === "CCM" &&
                  (addProgram.ProgramStatus === "Eligible" ||
                    addProgram.ProgramStatus === "Not Interested" ||
                    addProgram.ProgramStatus === "Consent Received" ||
                    addProgram.ProgramStatus === "Follow Up") && (
                    <div className="popElement">
                      <p className="popLabel">
                        Evaluation Date
                        {Array.isArray(mandatoryFields) &&
                        mandatoryFields.includes("EvaluationDate")
                          ? " *"
                          : ""}
                      </p>
                      <input
                        type="datetime-local"
                        id="meeting-time"
                        name="meeting-time"
                        value={addProgram?.EvaluationDate?.slice(0, 16)}
                        className="viewElement "
                        onChange={(event) =>
                          generalHandleValueChanges(
                            event,
                            setAddProgram,
                            "EvaluationDate"
                          )
                        }
                      />
                    </div>
                  )}
                {addProgram.ProgramName &&
                  addProgram.ProgramStatus === "Follow Up" && (
                    <>
                      <div className="popElement">
                        <p className="popLabel">
                          Follow Up Call Date
                          {Array.isArray(mandatoryFields) &&
                          mandatoryFields.includes("FollowUpDate")
                            ? " *"
                            : ""}
                        </p>
                        <input
                          type="datetime-local"
                          id="meeting-time"
                          name="meeting-time"
                          value={addProgram?.FollowUpDate?.slice(0, 16)}
                          className="viewElement "
                          onChange={(event) =>
                            generalHandleValueChanges(
                              event,
                              setAddProgram,
                              "FollowUpDate"
                            )
                          }
                        />
                      </div>
                    </>
                  )}
                {addProgram.ProgramName &&
                  addProgram.ProgramStatus === "Consent Received" && (
                    <>
                      <div className="popElement">
                        <p className="popLabel">
                          Consent Type
                          {Array.isArray(mandatoryFields) &&
                          mandatoryFields.includes("ConsentType")
                            ? " *"
                            : ""}
                        </p>
                        <div className="radio">
                          <div className="radio-align">
                            <input
                              type="radio"
                              id="verbal"
                              name="fav_language"
                              value="Verbal"
                              checked={addProgram?.ConsentType === "Verbal"}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setAddProgram,
                                  "ConsentType"
                                )
                              }
                            />
                            <label for="verbal">Verbal</label>
                          </div>
                          <div className="radio-align">
                            <input
                              type="radio"
                              id="Written"
                              name="fav_language"
                              value="Written"
                              checked={addProgram?.ConsentType === "Written"}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setAddProgram,
                                  "ConsentType"
                                )
                              }
                            />
                            <label for="Written">Written</label>
                          </div>
                        </div>
                      </div>
                      <p className="notesSmall">
                        <b>Note:</b> In case of Verbal consent, it should be
                        documented in the patient's medical record that consent
                        was received from the patient.
                      </p>
                    </>
                  )}

                {addProgram.ProgramName &&
                  addProgram.ProgramStatus === "Eligible" && (
                    <div className="popElement">
                      <p className="popLabel">
                        Eligible Notes
                        {Array.isArray(mandatoryFields) &&
                        mandatoryFields.includes("EligibleNotes")
                          ? " *"
                          : ""}
                      </p>
                      <textarea
                        type="textarea"
                        value={addProgram?.EligibleNotes?.note}
                        className="viewElement "
                        onChange={(event) =>
                          setAddProgram((prev) => ({
                            ...prev,
                            EligibleNotes: {
                              ...prev.EligibleNotes,
                              note: event?.target?.value,
                            },
                          }))
                        }
                      />
                    </div>
                  )}
                {addProgram.ProgramName &&
                  addProgram.ProgramStatus === "Not Interested" && (
                    <div className="popElement">
                      <p className="popLabel">
                        Not Interested Reason
                        {Array.isArray(mandatoryFields) &&
                        mandatoryFields.includes("NotInterestedReason")
                          ? " *"
                          : ""}
                      </p>
                      <textarea
                        type="textarea"
                        value={addProgram?.NotInterestedReason}
                        className="viewElement "
                        onChange={(event) =>
                          generalHandleValueChanges(
                            event,
                            setAddProgram,
                            "NotInterestedReason"
                          )
                        }
                      />
                    </div>
                  )}
              </div>
            </div>
            <div className="popBtn">
              <button onClick={saveProgram}>Save</button>
              <button onClick={cancelfn}>Save without any Programs</button>
            </div>
          </div>
        </PopUp>
      )}
    </div>
  );
};

export default AddPatient;
