import React from "react";
import { useEffect, useState } from "react";
import SelectComponent from "../components/SelectComponent";
import PopUp from "../patients/patientViewPage/PopUp";
import "./adminPages.scss";
import { generalHandleValueChanges } from "../patients/Careteam/helperfunctions";
import {
  useGetUserListMutation,
  useUpdateUserStatusMutation,
} from "../app/api/userManagementApi";
import LoaderComponent from "../components/LoaderComponent";
import { Icon } from "@iconify/react";

const ActivateAndDeactivateUser = () => {
  const [getUserListFn, { data: userList, isLoading }] =
    useGetUserListMutation();
  const [userData, setUserData] = useState();
  const [changeUserStatusFn, { data: updatedUserList }] =
    useUpdateUserStatusMutation();
  const [userToDelete, setUserToDelete] = useState("");
  const [keyword, setKeyword] = useState({ role: "All", name: "" });
  const [shedulePopUp, setShedulePopUp] = useState(false);

  const cancelfn = () => {
    setUserToDelete("");
    setShedulePopUp(false);
  };
  const onClickDelete = (user) => {
    setUserToDelete(user);
    setShedulePopUp(true);
  };
  const onClickDeleteConfirmed = () => {
    if (!userToDelete) return;
    changeUserStatusFn({
      username: userToDelete.email,
      userType: userToDelete.userType.toLowerCase(),
    });
    setShedulePopUp(false);
  };

  useEffect(() => {
    if (!userList) {
      return;
    }
    setUserData(userList);
  }, [userList, keyword]);

  const rolefilterFunction = (key) => {
    if (key === "All") {
      return () => true;
    } else {
      return (user) => user.userType.toLowerCase() === key.toLowerCase();
    }
  };
  const searchfilterFunction = (key) => {
    return (user) => user?.Name?.toLowerCase().includes(key.toLowerCase());
  };

  useEffect(() => {
    getUserListFn();
  }, [getUserListFn, updatedUserList]);

  return (
    <div className="mainLoyout">
      {shedulePopUp && (
        <PopUp cancelfn={cancelfn}>
          <div>
            <p>
              Are you sure you want to deactivate the user{" "}
              {userToDelete?.username}
            </p>
            <div className="buttonsContainer">
              <button onClick={onClickDeleteConfirmed}>Deactivate</button>
              <button onClick={cancelfn}>cancel</button>
            </div>
          </div>
        </PopUp>
      )}
      <LoaderComponent isLoading={isLoading} />
      <div className="title">Activate & Deactivate Users</div>
      <div className="filterAndContent">
        <div className="filter">
          {/* <div className="filterElemt">
            <p>Show</p>
            <SelectComponent
              OptionsArray={[10, 25, 50, 100]}
                valueData={addProgram.ProgramName}
                fn={generalHandleValueChanges}
                setvalue={setAddProgram}
                name={"ProgramName"}
            />
          </div> */}
          <div className="filterElemt">
            <p>Show</p>
            <SelectComponent
              OptionsArray={[
                "All",
                "Admin",
                "Clinician",
                "Provider",
                "Facility Admin",
                "Physician",
              ]}
              valueData={keyword.role}
              fn={generalHandleValueChanges}
              setvalue={setKeyword}
              name={"role"}
            />
          </div>
          <div className="filterElemt">
            <p>Search By Name</p>
            <input
              value={keyword.name}
              onChange={(e) => setKeyword({ ...keyword, name: e.target.value })}
            />
          </div>
        </div>
      </div>
      <div className="contentBox">
        <div className="contentGrid header">
          <div className="griElement colSpan1">S.No</div>
          <div className="griElement colSpan3">Name</div>
          <div className="griElement colSpan1">Role</div>
          <div className="griElement colSpan3">Email</div>
          <div className="griElement colSpan2">Phone Number</div>
          <div className="griElement colSpan2">Action</div>
        </div>
        {userData &&
          userData
            .filter(rolefilterFunction(keyword.role))
            .filter(searchfilterFunction(keyword.name))
            .map((user, index) => (
              <div key={index} className="contentGrid contentBody">
                <div className="griElement colSpan1">{index + 1}</div>
                <div className="griElement colSpan3">{user.Name}</div>
                <div className="griElement colSpan1">{user.userType}</div>
                <div className="griElement colSpan3">{user.email}</div>
                <div className="griElement colSpan2">
                  {user["Phone Number"]}
                </div>
                <div className="griElement buttonBox colSpan2">
                  <button onClick={() => onClickDelete(user)}>
                    <Icon
                      icon="fluent-mdl2:deactivate-orders"
                      width="18"
                      height="18"
                    />
                  </button>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default ActivateAndDeactivateUser;
