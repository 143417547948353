import { useEffect, forwardRef, useImperativeHandle } from "react";
import classNames from "classnames";
import useDatePicker1 from "../hooks/use-datePicker1";
import { ReactComponent as Info } from "../images/info.svg";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import DatePicker from "react-widgets/DatePicker";
import "react-widgets/styles.css";
import "../widget-sass/datePickerField1.scss";
// import "../../scss/datePickerField.scss";

const DatePickerField1 = forwardRef((props, ref) => {
  const {
    value,
    hasError,
    isValid,
    valueChangeHandler,
    submitClickHandler,
    reset,
    storeValue,
  } = useDatePicker1(props.validation);
  useImperativeHandle(ref, () => ({
    reset_data() {
      reset();
    },
    init_data(data) {
      storeValue(data);
    },
    submit() {
      submitClickHandler();
    },
  }));

  useEffect(() => {
    props.storeData(value, isValid);
    // moment(a).format("MM/DD/YYYY");
  }, [value, isValid]);
  return (
    <div
      className={classNames(
        "datePickerFieldContainer",
        props.containerClassName
      )}
    >
      <label
        className={classNames("datePickerField", props.className, {
          invalid: hasError,
        })}
      >
        <div className="title">
          {props.title}
          <sup>{props.superTitle ?? ""}</sup>
          {hasError && (
            <Info className="error-icon" data-tooltip-id={props.element} />
          )}
        </div>
        <DatePicker
          tabIndex={props.tabIndex ?? ""}
          value={value}
          valueEditFormat={{ dateStyle: "short" }}
          valueDisplayFormat={{ dateStyle: "medium" }}
          onChange={valueChangeHandler}
          disabled={props.disabled ?? false}
          parse={[
            "MM dd yy",
            "MM dd yyyy",
            "MM-dd-yy",
            "MM-dd-yyyy",
            "MM/dd/yy",
            "MM/dd/yyyy",
            "MMM dd yy",
            "MMM dd yyyy",
            "MMM,dd yy",
            "MMM,dd yyyy",
          ]}
          min={new Date(1900, 0, 1)}
          max={props.maxValue ?? false}
          autoFocus={props.autoFocus ?? false}
        />
      </label>
      <Tooltip
        id={props.element}
        content={props.error_message}
        place="bottom"
      />
    </div>
  );
});

export default DatePickerField1;
