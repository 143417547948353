import React, { useEffect, useState } from "react";
import "./percentageCompnt.scss";

function PercentageCompnt({ value, color }) {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    let intervalId = null;
    if (counter < value) {
      intervalId = setInterval(() => {
        setCounter((prevCounter) => prevCounter + 1);
      }, 5);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [counter, value]);

  const radius = 35;
  const circumference = Math.PI * 2 * radius;
  const strokeDashoffset = circumference - (circumference * counter) / 100;

  return (
    <div className="percentage">
      <div className="outer">
        <div className="inner">
          <div id="number">{counter}%</div>
        </div>
      </div>
      <svg
        className="svg"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="80px"
        height="80px"
      >
        <circle
          className="circle"
          cx="40"
          cy="40"
          r={radius}
          strokeLinecap="round"
          strokeWidth="10"
          style={{
            stroke: `${color}`,
            strokeDasharray: circumference,
            strokeDashoffset: strokeDashoffset,
          }}
        />
      </svg>
    </div>
  );
}

export default PercentageCompnt;
