import React from "react";
import { useDispatch } from "react-redux";
import { navigationActions } from "../app/features/navigationSlice";
import { authDataActions } from "../app/features/authSlice";
import Menu from "../images/topBar/menu.svg";
import Logo from "../images/logo.svg";
import Mail from "../images/topBar/mail.svg";
import Task from "../images/topBar/task.svg";
import Plus from "../images/topBar/plus.svg";
import UserImg from "../images/topBar/account_circle.svg";
import { ReactComponent as LogOut } from "../images/topBar/power.svg";
import Navcomponent from "../components/Navcomponent";
import "./topBar.scss";

const TopBar = () => {
  const dispatch = useDispatch();
  const title = localStorage.getItem("RLH_user");
  const designation = localStorage.getItem("RLH_userType");

  const onMenuClick = () => {
    dispatch(navigationActions.openNavBar());
  };

  const onLogOutClick = () => {
    dispatch(authDataActions.logOut());
  };

  return (
    <div className="topBar">
      <div className="cont">
        <div className="topBarContainer">
          <div className="topBarContainer-left">
            <img
              src={Menu}
              alt=""
              className="menu"
              onMouseEnter={onMenuClick}
            />
            <img src={Logo} alt="" className="logo" />
          </div>
          <div className="topBarContainer-right">
            <div className="userDescContainer">
              <img src={UserImg} alt="" />
              <div className="descText">
                <div className="title">{title}</div>
                <div className="designation">{designation}</div>
              </div>
            </div>
            <div className="logoutBox" onClick={onLogOutClick}>
              <div className="text">Logout</div>
              <LogOut />
            </div>
            <div className="iconContainer">
              <img src={Mail} alt="" />
              <img src={Task} alt="" />
              <img src={Plus} alt="" />
            </div>
          </div>
        </div>
        <Navcomponent />
      </div>
    </div>
  );
};

export default TopBar;
