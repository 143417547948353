import React from "react";
import "./callTaskListViewTable.scss";
const CallTaskListViewTable = () => {
  return (
    <div className="ViewTable">
      <div className="HeaderTop">
        <div className="elementBox">
          <div className="head">Show</div>
          <select></select>
        </div>
        <div className="elementBox">
          <div className="head">Patient Name</div>
          <input />
        </div>
        <div className="elementBox">
          <div className="head">Facility Name</div>
          <select></select>
        </div>
        <div className="elementBox">
          <div className="head">Physician Name</div>
          <select></select>
        </div>
        <div className="elementBox">
          <div className="head">Clinician Name</div>
          <select></select>
        </div>
      </div>
      <div className="prevNext">
        <div className="prevBtn">
          <button>Previous</button>
          <button>Next</button>
        </div>
      </div>
      <div className="viewTableGrid">
        <div className="colSpan1 columnHead">S.No</div>
        <div className="colSpan1 columnHead">Patient Name</div>
        <div className="colSpan1 columnHead">Patient DOB</div>
        <div className="colSpan1 columnHead">Care Plan Physician</div>
        <div className="colSpan1 columnHead">Care Plan Physician</div>
        <div className="colSpan2 columnHead">Schedule Date & Time</div>
        <div className="colSpan1 columnHead">Schedule Type</div>
        <div className="colSpan2 columnHead">Purpose of the Call</div>
        <div className="colSpan1 columnHead">Schedule Status</div>
        <div className="colSpan2 columnHead">Billable Minutes (HH:MI:SS)</div>
        <div className="colSpan1 columnHead">Action</div>
      </div>
      <div className="prevNext">
        <p>Showing 0 to 0 of 0 entries</p>
        <div className="prevBtn">
          <button>Previous</button>
          <button>Next</button>
        </div>
      </div>
    </div>
  );
};

export default CallTaskListViewTable;
