import "./patientsViewPage.scss";
import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useGetPatientSelectedProfileMutation } from "../../app/api/patientsApi";
import { useEffect } from "react";
import TabWidget from "../../components/TabWidget";
import TabElement from "../../components/TabElement";
import Important from "./Important";
import Demographics from "./Demographics";
import Insurance from "./Insurance";
import Program from "./Program";
import PopUp from "./PopUp";
import FloatingNotifications from "../../utilities/FloatingNotifications";
import AddPatient from "../parientsPreviewPage/addPatientComponent/AddPatient";
import { generalHandleValueChanges } from "../Careteam/helperfunctions";
import SelectComponent from "../../components/SelectComponent";
import { useUpdatePatientsMutation } from "../../app/api/patientsApi";
import { areObjectsEqual } from "../Careteam/helperfunctions";

const tabChildrenList = [
  "Important",
  "Demographics",
  "Program",
  "Insurances1",
  "Insurances2",
  "Insurances3",
];
const PatientViewPage = () => {
  const location = useLocation();
  const [id, setId] = useState(location?.state?.id);
  const [selectedProfilefn, { data: Data }] =
    useGetPatientSelectedProfileMutation();
  const [triggerEffect, setTriggerEffect] = useState(false);
  const [updatePatientfn, { data: updatedData, error: errorUpdating }] =
    useUpdatePatientsMutation();
  const [selectedData, setSelectedData] = useState();
  const [currentTab, setCurrentTab] = useState("Important");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [updateCallValue, setUpdateValue] = useState("");

  const updatebtnCall = (value) => {
    setUpdateValue(value);
    setTriggerEffect(true);

    setPopUpOpen(true);
  };

  useEffect(() => {
    if (!id) return;
    selectedProfilefn({
      PatientId: id,
    });
  }, [id, selectedProfilefn]);
  useEffect(() => {
    setSelectedData(Data);
  }, [Data]);

  useEffect(() => {
    setSelectedData(updatedData);
  }, [updatedData]);
  useEffect(() => {
    if (!errorUpdating) return;
    FloatingNotifications("error", "Updation failed");
  }, [errorUpdating]);
  return (
    <div className="ViewPageContainer">
      <AddPatient
        selectedData={selectedData}
        updatePatientfn={updatePatientfn}
        id={id}
        triggerEffect={triggerEffect}
        setTriggerEffect={setTriggerEffect}
        updateCallValue={updateCallValue}
        setUpdateValue={setUpdateValue}
        popUpOpen={popUpOpen}
        setPopUpOpen={setPopUpOpen}
      />
      <p className="Title">Patient Profile</p>
      <div className="tableContainer">
        <div className="tableelement detailsHead">
          <div className="primaryDetails">
            <div className="contact">
              <Icon
                icon="basil:contacts-solid"
                color="darkgray"
                height="100%"
              />
            </div>
            <div className="details">
              <div>
                <p className="name">{selectedData?.FirstName}</p>
                <p className="name">{selectedData?.PatientEHRId}</p>
                <p className="dob">
                  ({selectedData?.Gender}|AGMKLMLK|
                  {Date(selectedData?.DOB).substring(0, 15)})
                </p>
                <p className="cordinator">
                  Primary Coordinator :{"  "}
                  {selectedData?.PrimaryCoordinatorName}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="tableelement">
          <p className="detailelements">Email: {selectedData?.email}</p>
          <p className="detailelements">Phone: {selectedData?.phone}</p>
          <p className="detailelements">Address: {selectedData?.street1}</p>
        </div>
        <div className="tableelement">
          <p className="detailelements">
            Date Added: {selectedData?.createdAt?.slice(0, 10)}
          </p>
          <p className="detailelements">Last Scheduled Appt:</p>
          <p className="detailelements">Next Scheduled Appt:</p>
        </div>
        <div className="btns"></div>
      </div>
      <div className="tabWidgetContainer">
        <TabWidget>
          {tabChildrenList.map((el) => (
            <TabElement
              key={`patients_${el}`}
              title={el}
              active={currentTab === el}
              onClick={() => setCurrentTab(el)}
            />
          ))}
        </TabWidget>
        {currentTab === "Important" && (
          <Important
            selectedData={selectedData}
            updatePatientfn={updatePatientfn}
            id={id}
            updatedData={updatedData}
          />
        )}{" "}
        {currentTab === "Demographics" && (
          <Demographics
            selectedData={selectedData}
            updatePatientfn={updatePatientfn}
            id={id}
            updatedData={updatedData}
          />
        )}
        {currentTab === "Program" && (
          <Program
            selectedData={selectedData}
            updatePatientfn={updatePatientfn}
            id={id}
            updatedData={updatedData}
            setPopUpOpen={setPopUpOpen}
            updatebtnCall={updatebtnCall}
          />
        )}
        {currentTab === "Insurances1" && (
          <Insurance
            insuranceType={"firstInsuranceData"}
            selectedData={selectedData}
            updatePatientfn={updatePatientfn}
            id={id}
            updatedData={updatedData}
          />
        )}
        {currentTab === "Insurances2" && (
          <Insurance
            insuranceType={"secondInsuranceData"}
            selectedData={selectedData}
            updatePatientfn={updatePatientfn}
            id={id}
            updatedData={updatedData}
          />
        )}
        {currentTab === "Insurances3" && (
          <Insurance
            insuranceType={"thirdInsuranceData"}
            selectedData={selectedData}
            updatePatientfn={updatePatientfn}
            id={id}
            updatedData={updatedData}
          />
        )}
      </div>
    </div>
  );
};
export default PatientViewPage;
