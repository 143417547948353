import React from "react";

const RadioComponent = ({ question, onClickFn, id, value }) => {
  return (
    <div className="inputComponent">
      {question.options.map((option) => (
        <span>
          <input
            type="radio"
            id={`${id}-${option}`}
            onClick={(e) => onClickFn([option], "answer")}
            checked={value[0] === option}
          />
          <label htmlFor={`${id}-${option}`}>{option}</label>
        </span>
      ))}
    </div>
  );
};

export default RadioComponent;
