import React from "react";
import { Icon } from "@iconify/react";
import "./popUp.scss";
const PopUp = ({ children, cancelfn, width }) => {
  return (
    <div className="viewPopUpCont">
      <div className="viewPopContent" style={{ width: width }}>
        <Icon
          onClick={cancelfn}
          className="closeIcon"
          icon="iconamoon:close-bold"
          width="26"
          height="26"
        />
        <div className="popchild">{children}</div>
      </div>
    </div>
  );
};

export default PopUp;
