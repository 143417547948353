import React from "react";
import classNames from "classnames";
import "../../sass/careplanSidebar.scss";
const CareplanSidebar = (props) => {
  const { clickfn } = props;
  return (
    <div className="sidemenu">
      {props.sidebarArray?.map((el) => (
        <button
          className={classNames("element", {
            activeSideBar: props.window === el,
          })}
          onClick={clickfn.bind(null, el)}
        >
          {el}
        </button>
      ))}
    </div>
  );
};

export default CareplanSidebar;
