import {
  StyleSheet,
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  Font,
} from "@react-pdf/renderer";

const fontURL =
  "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap";

// Font.register({ family: "Roboto" });
Font.register({
  family: "Roboto1",
  // src: fontURL,
  fontStyle: "normal",
  // fontWeight: "normal",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgVxFIzIFKw.woff2",
      fontWeight: "100",
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2",
      fontWeight: "300",
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu72xKOzY.woff2",
      fontWeight: "400",
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2",
      fontWeight: "500",
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2",
      fontWeight: "700",
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfCRc4EsA.woff2",
      fontStyle: "normal",
      fontWeight: "heavy",
    },
  ],
});

const color = "#E4E4E4";
// Create styles
const styles = StyleSheet.create({
  page: {
    padding: "2cm 2cm",
    // fontFamily: "Roboto",
    // display: "flex",
  },
  title: {
    position: "absolute",
    fontSize: 12,
    top: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    // color,

    // width: "100%",
    // textAlign: "center",
    // fontWeight: "extrabold",
    // fontSize: "0.5cm",
    // backgroundColor: color,
    // marginBottom: "0.8cm",
  },
  subTitle: {
    width: "100%",
    textAlign: "center",
    backgroundColor: color,
    marginTop: "0.5cm",
    marginBottom: "0.3cm",
    padding: "0.2cm",
    fontSize: "0.48cm",
    fontWeight: "900",
  },
  patientDetails: {
    display: "flex",
    flexDirection: "row",
    fontSize: "0.4cm",
    border: "3pt solid #ddd",
    padding: "0.3cm",
    marginBottom: "0.3cm",
  },
  patientDetailsLeft: {
    width: "80%",
  },
  borderContainer: {
    border: "1pt solid #ddd",
    padding: "0.3cm",
    backgroundColor: "#fff",
  },
  subHeadingBox: {
    fontSize: "0.5cm",
    display: "flex",
    flexDirection: "row",
  },
  subHeadingText: {
    backgroundColor: color,
    padding: "0.2cm",
  },
  patientTitle: {
    fontWeight: "ultrabold",
  },
  patientContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  test: {
    backgroundColor: "red",
  },
  bulletinContainer: {
    display: "flex",
    flexDirection: "row",
  },
  bulletinPoint: {
    width: "0.15cm",
    height: "0.15cm",
    backgroundColor: color,
    margin: "1.7mm",
    marginRight: "0.2cm",
  },
  normalData: {
    marginBottom: "0.1cm",
    fontSize: "0.4cm",
    fontFamily: "Roboto1",
    fontStyle: "normal",
    fontWeight: "heavy",
  },
  space: {
    padding: "2mm",
  },
  termsAndCond: {
    fontSize: 10,
  },
  signatureContainer: {
    display: "flex",
    flexDirection: "row",
    fontSize: 12,
  },
  signatureChild: {
    width: "50%",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    // color: "grey",
  },
  dateFooter: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    right: 0,
    marginRight: "2cm",
  },
  patientFooter: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    marginLeft: "2cm",
  },
});

const convertDateTostring = (date) => {
  // console.log(date, typeof date, date.replace("T", " ").replace(".000Z", ""));

  const res = date.replace("T", " ").replace(".000Z", "");

  return res;
};
const formatAMPM = (date) => {
  if (date === undefined) return "";
  if (typeof date === "string") {
    date = new Date(date);
  }
  var dd = date.getDate(date);
  var mm = date.getMonth(date) + 1;

  const yy = date.getFullYear(date);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  dd = dd < 10 ? "0" + dd : dd;
  mm = mm < 10 ? "0" + mm : mm;
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = `${mm}/${dd}/${yy} ${hours}:${minutes} ${ampm}`;
  return strTime;
};

const Bulletin = ({ children }) => {
  return (
    <View style={styles.bulletinContainer}>
      <View style={styles.bulletinPoint}></View>
      <Text style={styles.normalData}>{children}</Text>
    </View>
  );
};

const ContentBox = ({ heading, normalData, BulletinData }) => {
  return (
    <View wrap={false}>
      <View style={styles.subHeadingBox}>
        <Text style={[styles.subHeadingText]}>{heading}</Text>
      </View>
      <View style={styles.borderContainer}>
        {normalData?.length &&
          normalData.map((el) => <Text style={styles.normalData}>{el}</Text>)}
        {BulletinData?.length &&
          BulletinData.map((el) => (
            <Bulletin>
              <>{el}</>
            </Bulletin>
          ))}

        {/* <Bulletin></Bulletin>
        <Bulletin />
        <Bulletin /> */}
      </View>
    </View>
  );
};
const Space = () => {
  return <View style={styles.space}></View>;
};
const doc = ({ data }) => {
  console.log(data);
  // <PDFViewer>
  const padEnd = 20;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber}`}
          fixed
        />
        <Text
          style={[styles.title]}
          render={() =>
            `Care Plan For Test Test Pt as of ${formatAMPM(
              new Date(data.updatedAt ?? new Date())
            )}`
          }
          fixed
        />
        <Text
          style={[styles.dateFooter]}
          render={() => `Print date : ${formatAMPM(new Date())}`}
          fixed
        />
        <Text
          style={[styles.patientFooter]}
          render={() => `${data.Name}`}
          fixed
        />
        <View style={styles.patientDetails}>
          <View style={styles.patientDetailsLeft}>
            <Text>
              <View style={styles.patientTitle}>
                <Text>{`${"Patient            "} `}</Text>
              </View>
              : {data?.Name ?? ""}
            </Text>
            <Text>
              <View style={styles.patientTitle}>
                <Text>{`${"Date of Birth   "} `}</Text>
              </View>
              : {data?.["Date Of Birth"]}
            </Text>
            <Text>
              <View style={styles.patientTitle}>
                <Text>{`${"Email              "} `}</Text>
              </View>
              : OLDSHVOUSHV
            </Text>
            <Text>
              <View style={styles.patientTitle}>
                <Text>{`${"Care Status    "} `}</Text>
              </View>
              : OLDSHVOUSHV
            </Text>
          </View>
          <View style={styles.patientDetailsLeft}>
            <Text>
              <View style={styles.patientTitle}>
                <Text>{`${"Gender        "} `}</Text>
              </View>
              : {data?.Gender ?? ""}
            </Text>
            <Text>
              <View style={styles.patientTitle}>
                <Text>{`${"Phone          "} `}</Text>
              </View>
              : {data?.["Phone Number"] ?? " -"}
            </Text>
            <Text>
              <View style={styles.patientTitle}>
                <Text>{`${"Address       "} `}</Text>
              </View>
              : OLDSHVOUSHV
            </Text>
            <Text>
              <View style={styles.patientTitle}>
                <Text>{`${"Facility         "} `}</Text>
              </View>
              : {data?.FacilityId?.FacilityName ?? " -"}
            </Text>
          </View>
        </View>
        <View style={[styles.subTitle]}>
          <Text>CARE TEAM</Text>
        </View>
        <View style={styles.borderContainer}>
          {/* <ContentBox
            heading="Care Plan Physician"
            normalData={["sfvwsef"]}
            BulletinData={[
              "sfgswrgv",
              "aegwgwef",
              <>
                asdgvsrsw <Text>sdvsg</Text>
              </>,
            ]}
          /> */}
          <ContentBox
            heading="Care Plan Physician"
            BulletinData={[data?.ProviderId?.ProviderName ?? " -"]}
          />
          <Space />
          <ContentBox
            heading="Primary Care Coordinator"
            BulletinData={[data?.PrimaryCoordinator?.ClinicianName ?? " -"]}
          />
          <Space />
          <ContentBox
            heading="CCM Care Team"
            normalData={["Primary CCM Clinician"]}
            BulletinData={["Test Clinician"]}
          />
        </View>
        <View style={[styles.subTitle]}>
          <Text>CARE PLAN</Text>
        </View>
        <View>
          {(data?.Diagnosis?.length ?? 0 > 0) && (
            <>
              <ContentBox
                heading="Diagnosis"
                BulletinData={data?.Diagnosis?.map((el) => (
                  <>
                    {el.ICD_Description}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{` - (${el.Type})`}</Text>
                  </>
                ))}
              />
              <Space />
            </>
          )}

          {(data?.Symptoms?.length ?? 0 > 0) && (
            <>
              <ContentBox
                heading="Symptoms"
                BulletinData={data?.Symptoms?.map((el) => (
                  <>
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`${el.Symptoms}`}</Text>
                    {`(For ${el.DurationValue} ${el.Duration})`}
                  </>
                ))}
              />
              <Space />
            </>
          )}

          {(data?.Prognosis?.length ?? 0 > 0) && (
            <>
              <ContentBox
                heading="Expected Outcomes and Prognosis"
                BulletinData={data?.Prognosis?.map((el) => (
                  <>{el.Notes}</>
                ))}
              />
              <Space />
            </>
          )}

          {(data?.Concerns?.length ?? 0 > 0) && (
            <>
              <ContentBox
                heading="Concerns"
                BulletinData={data?.Concerns?.map((el) => (
                  <>{el.Notes}</>
                ))}
              />
              <Space />
            </>
          )}

          {(data?.Goal?.length ?? 0 > 0) && (
            <>
              <ContentBox
                heading="Goals"
                BulletinData={data?.Goal?.map((el) => (
                  <>
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.Goal}) `}</Text>
                    - {el.Description}
                  </>
                ))}
              />
              <Space />
            </>
          )}

          {(data?.FamilyHistory?.length ?? 0 > 0) && (
            <>
              <ContentBox
                heading="Family History"
                BulletinData={data?.FamilyHistory?.map((el) => (
                  <>
                    {`${el.Code} (${el.Condition}) - `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.ConditionExistsForRelationship.join(",")})`}</Text>
                  </>
                ))}
              />
              <Space />
            </>
          )}

          {(data?.OtherRecommendations?.length ?? 0 > 0) && (
            <>
              <ContentBox
                heading="Other Recommendations"
                BulletinData={data?.OtherRecommendations?.map((el) => (
                  <>
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.Type})`}</Text>
                    {` : ${el.Recommendations}`}
                  </>
                ))}
              />
              <Space />
            </>
          )}

          {(data?.LifestyleRecommendations?.length ?? 0 > 0) && (
            <>
              <ContentBox
                heading="Lifestyle Recommendations"
                BulletinData={data?.LifestyleRecommendations?.map((el) => (
                  <>
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.Type})`}</Text>
                    {` : ${el.Description}`}
                  </>
                ))}
              />
              <Space />
            </>
          )}

          {(data?.Barriers?.length ?? 0 > 0) && (
            <>
              <ContentBox
                heading="Barriers"
                BulletinData={data?.Barriers?.map((el) => (
                  <>{`${el.Recommendations}`}</>
                ))}
              />
              <Space />
            </>
          )}

          {(data?.PreventiveCareAndSchedule?.length ?? 0 > 0) && (
            <>
              <ContentBox
                heading="Preventive Care and Schedule"
                BulletinData={data?.PreventiveCareAndSchedule?.map((el) => (
                  <>{`${formatAMPM(el.ScheduledDate)} : ${
                    el.Recommendations
                  }`}</>
                ))}
              />
              <Space />
            </>
          )}

          {(data?.Medications?.length ?? 0 > 0) && (
            <>
              <ContentBox
                heading="Medications"
                BulletinData={data?.Medications?.map((el) => (
                  <>
                    {el.Medication} {el.Quantity} - {el.Frequency} - From{" "}
                    {convertDateTostring(el.RecordedDate)}
                  </>
                ))}
              />
              <Space />
            </>
          )}

          {(data?.Allergies?.length ?? 0 > 0) && (
            <>
              <ContentBox
                heading="Allergies"
                BulletinData={data?.Allergies?.map((el) => (
                  <>
                    {`${el.Description} `}
                    {`(Type : `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.AllergyType})`}</Text>
                    {`, Subtype : `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.AllergySubType})`}</Text>
                    {`, Reaction : `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.Reaction})`}</Text>
                    {`, Severity : `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.Severity})`}</Text>
                    {`) from ${formatAMPM(el.ScheduledDate)}`}
                  </>
                ))}
              />
              <Space />
            </>
          )}

          {(data?.Immunization?.length ?? 0 > 0) && (
            <>
              <ContentBox
                heading="Immunization"
                BulletinData={data?.Immunization?.map((el) => (
                  <>
                    {`Vaccination Name : `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.VaccinationName})`}</Text>
                    {` ${el.Dose} administered by `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.AdministeredPhysician})`}</Text>
                    {` on `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.VaccinationAdministeredOn})`}</Text>
                    {`, Route : `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.RoutesOfAdministration})`}</Text>
                    {`, Site : `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.Site})`}</Text>
                    {`, Manufacturer : `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.NameOfManufacturer})`}</Text>
                    {`, Lot Number : `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.LotNumber})`}</Text>
                    {`, Expiration Date & Time : `}
                    <Text style={{ fontWeight: "heavy" }}>{`(${formatAMPM(
                      el.ExpirationDate
                    )})`}</Text>
                    {`, Vaccination Reason : `}
                    <Text style={{ fontWeight: "heavy" }}>{`(${formatAMPM(
                      el.VaccinationReason
                    )})`}</Text>
                  </>
                ))}
              />
              <Space />
            </>
          )}

          {(data?.MedicalEvents?.length ?? 0 > 0) && (
            <>
              <ContentBox
                heading="Significant Medical Events"
                BulletinData={data?.MedicalEvents?.map((el) => (
                  <>{`${formatAMPM(el.EventDate)} ${el.eventType} - ${
                    el.Description
                  } - ${el.Notes}`}</>
                ))}
              />
              <Space />
            </>
          )}

          {(data?.Vitals?.length ?? 0 > 0) && (
            <>
              <ContentBox
                heading={`Vitals (${formatAMPM(data?.Vitals[0].createdAt)})`}
                BulletinData={[
                  `Height (in)                            : ${data?.Vitals[0].Height}`,
                  `Weight (lb)                            : ${data?.Vitals[0].Weight}`,
                  // `BMI                                 : ${data?.Vitals[0].}`,
                  `Blood Pressure (mm/Hg)                 : ${data?.Vitals[0].BloodPressure}`,
                  `Temperature (°F)                       : ${data?.Vitals[0].Temperature}`,
                  `Pulse (bpm)                            : ${data?.Vitals[0].Pulse}`,
                  `Respiration Rate (bpm)                 : ${data?.Vitals[0].RespirationRate}`,
                  `O2 Saturation (bpm)                    : ${data?.Vitals[0].O2Saturation}`,
                  `Body Pain (bpm)                        : ${data?.Vitals[0].BodyPain}`,
                  `Head Circumference (cm)                : ${data?.Vitals[0].HeadCircumference}`,
                  `Fasting Plasma Glucose (mg/dL)         : ${data?.Vitals[0].FastingPlasmaGlucose}`,
                  `2-hour Postprandial Glucose (mg/dL)    : ${data?.Vitals[0].PostprandialGlucose}`,
                  `LDL Cholesterol (mg/dL)                : ${data?.Vitals[0].LDLCholesterol}`,
                  // `HDL Cholesterol (mg/dL)             : ${data?.Vitals[0].HDLCholesterol}`,
                  // `Triglycerides (mg/dL)               : ${data?.Vitals[0].Triglycerides}`,
                  // `Total Cholesterol (mg/dL)           : ${data?.Vitals[0].TotalCholesterol}`,
                  `2-hour Postprandial Glucose (mg/dL)    : ${data?.Vitals[0].PostprandialGlucose}`,
                  `Hemoglobin A1C (%)                     : ${data?.Vitals[0].HemoglobinA1C}`,
                ]}
              />
              <Space />
            </>
          )}

          {(data?.LifestyleRemarks?.length ?? 0 > 0) && (
            <>
              <ContentBox
                heading={`Lifestyle (${formatAMPM(
                  data?.LifestyleRemarks[0].RecordedDate
                )})`}
                BulletinData={[
                  `Alcohol               :  ${data?.LifestyleRemarks[0].Alcohol}(${data?.LifestyleRemarks[0].AlcoholRemarks})`,
                  `Caffeine              :  ${data?.LifestyleRemarks[0].Caffeine}(${data?.LifestyleRemarks[0].CaffeineRemarks})`,
                  `Physical Activity     :  ${data?.LifestyleRemarks[0].PhysicalActivity}(${data?.LifestyleRemarks[0].PhysicalActivityRemarks})`,
                  `Recreational Drugs    :  ${data?.LifestyleRemarks[0].RecreationalDrugs}(${data?.LifestyleRemarks[0].RecreationalDrugsRemarks})`,
                  `Smoking               :  ${data?.LifestyleRemarks[0].Smoking}(${data?.LifestyleRemarks[0].SmokingRemarks})`,
                ]}
              />
              <Space />
            </>
          )}
        </View>

        <View style={styles.termsAndCond}>
          <View>
            <Text>I agree to do the following</Text>
            <Text>
              - Discuss concerns I have about taking any of my medications with
              my primary care provider (PCP) and/or pharmacist.
            </Text>
            <Text>
              - Advise my PCP if I choose to stop my medication(s), including my
              reasons for stopping, and discuss potential alternatives,
            </Text>
            <Text>
              - Advise my PCP of bothersome side effects from my medication(s)
            </Text>
            <Text>
              - Inform my PCP if new medications are added by other providers.
            </Text>
            <Space />
            <Space />
            <Text>
              I agree that I have reviewed the current medication list (see
              above) and confirm that it is accurate.
            </Text>
          </View>
        </View>
        <Space />
        <Space />
        <Space />
        <Space />
        <View style={styles.signatureContainer}>
          <Text style={styles.signatureChild}>Signature :</Text>
        </View>
        <Space />
        <View style={styles.signatureContainer}>
          <Text style={styles.signatureChild}>Reviewed By :</Text>
          <Text style={styles.signatureChild}>Date :</Text>
        </View>
      </Page>
    </Document>
  );
};

export default doc;
