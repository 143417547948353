import React, { useEffect } from "react";
import { patientsInsuranceDataActions } from "../../app/features/patientsInsuranseDataSlice";
import { patientsDataActions } from "../../app/features/patientsDataSlice";
import { useRef, useCallback } from "react";
import { insuranceTypeTabList } from "../../app/features/patientsInsuranseDataSlice";
import { useSelector, useDispatch } from "react-redux";
import InputTextArea1 from "../../widgets/InputTextArea1";
import InputTextField1 from "../../widgets/InputTextField1";
import InputSelectorField1 from "../../widgets/InputSelectorField1";
import TabElement2 from "../../components/TabElement2";
import TabWidget2 from "../../components/TabWidget2";
import PatientsInsSubcomponent from "./PatientsInsSubcomponent";
import ButtonComponent from "../../components/ButtonComponent";
import FloatingNotifications from "../../utilities/FloatingNotifications";

// import {
//   isNotEmpty,
//   alwaysValidated,
//   noCharValidateCheck,
//   zipValidity,
//   zipCharValidate,
//   dateValidateCheck,
//   phNoValidation,
//   phNoCharValidation,
//   faxCharValidation,
// } from "../../utilities/validationFunction";

import "./patientsInsurance.scss";
import { useState } from "react";

const PatientsInsurance = ({ loadedData }) => {
  // console.log('Rendering insurance data')
  const [resetRefs, setResetRefs] = useState({
    firstInsuranceData: {
      insuranceCompany: useRef(null),
      payerID: useRef(null),
      tplCode: useRef(null),
      insuranceID: useRef(null),
      groupName: useRef(null),
      groupNumber: useRef(null),
      notes: useRef(null),
    },
    secondInsuranceData: {
      insuranceCompany: useRef(null),
      payerID: useRef(null),
      tplCode: useRef(null),
      insuranceID: useRef(null),
      groupName: useRef(null),
      groupNumber: useRef(null),
      notes: useRef(null),
    },
    thirdInsuranceData: {
      insuranceCompany: useRef(null),
      payerID: useRef(null),
      tplCode: useRef(null),
      insuranceID: useRef(null),
      groupName: useRef(null),
      groupNumber: useRef(null),
      notes: useRef(null),
    },
  });
  const dispatch = useDispatch();
  const patientsTabChange = useSelector(
    (state) => state.patientsData.noEmptyUpdateAllowedInsurance
  );
  const patientsInsuranceData = useSelector(
    (state) => state.patientsInsuranceData
  );
  const {
    insurance: inputData,
    basicDataValidation,
    insuranceType,
    isValidData,
    firstInsuranceDataValid,
    secondInsuranceDataValid,
    thirdInsuranceDataValid,
  } = patientsInsuranceData;

  function areAllFieldsEmpty(obj) {
    return Object.values(obj).every((value) => {
      return value === "";
    });
  }
  function areAllFieldsTrue(obj) {
    console.log(obj);
    return Object.values(obj).every((value) => {
      return value === true;
    });
  }

  const onClickContinue = () => {
    console.log(areAllFieldsTrue(isValidData[insuranceType]));

    Object.keys(resetRefs).forEach((value) => {
      resetRefs[value]?.current?.submit?.();
    });

    if (areAllFieldsEmpty(inputData.firstInsuranceData)) {
      dispatch(patientsDataActions.updateCurrentTab("Program"));
    } else {
      if (areAllFieldsTrue(isValidData[insuranceType])) {
        dispatch(patientsDataActions.updateCurrentTab("Program"));
      } else {
        console.log(areAllFieldsTrue(isValidData[insuranceType]));
        FloatingNotifications(
          "error",
          "insurance Company and insuranceID are mandatory"
        );
      }
    }
  };

  useEffect(() => {
    if (patientsTabChange) {
      dispatch(patientsInsuranceDataActions.updateEmptyTab());
    }
  }, [patientsTabChange]);
  // useEffect(()=>{
  //   if (patientsData.currentTab === tabChildrenList[0]) {
  //     InitData()
  //   }
  // },[patientsData.currentTab])

  const tabinsuranceHandler = (el) => {
    console.log(firstInsuranceDataValid, inputData.firstInsuranceData);
    if (
      (el === "thirdInsuranceData" && thirdInsuranceDataValid) ||
      (el === "secondInsuranceData" && secondInsuranceDataValid) ||
      (el === "firstInsuranceData" && firstInsuranceDataValid) ||
      (el === "thirdInsuranceData" && secondInsuranceDataValid) ||
      (el === "secondInsuranceData" && firstInsuranceDataValid)
    ) {
      // Object.keys(resetRefs[el]).forEach((value) => {
      //   let d = resetRefs[el];
      //   console.log(d, value);
      //   d.current.init_data();
      // });

      dispatch(patientsInsuranceDataActions.updateCurrentTab(el));
    }
  };

  return (
    <div>
      <TabWidget2>
        {insuranceTypeTabList?.map((el) => (
          <TabElement2
            key={`patients_${el}`}
            title={el}
            active={insuranceType === el}
            onClick={() => tabinsuranceHandler(el)}
          />
        ))}
      </TabWidget2>
      {insuranceTypeTabList?.map((el) =>
        insuranceType === el ? (
          <PatientsInsSubcomponent
            insuranceType={el}
            ref={resetRefs[el]}
            loadedData={loadedData}
          />
        ) : (
          <></>
        )
      )}

      <ButtonComponent
        tabIndex="2"
        containerClassName="buttonContainer"
        text="Continue"
        onClick={onClickContinue}
        // disabled={isLoading}
      />
    </div>
  );
};

export default PatientsInsurance;
