import React from "react";
import { useState, useEffect } from "react";
import DropDownContent from "../../../components/DropDownContent";
import ActiveHistoryDropDown2 from "../../../components/ActiveHistoryDropDown2";
import AddDetailGeneral from "../addDetailsGeneral/AddDetailGeneral";
import CarePlanDisplayGeneral from "../CarePlanDisplayGeneral/CarePlanDisplayGeneral";
import SelectWithSearch from "../../searchandselect component/SearchAndSelect";
import TabElement from "../../../components/TabElement";
import TabWidget from "../../../components/TabWidget";
import FloatingNotifications from "../../../utilities/FloatingNotifications";
import {
  resetfn,
  basicValidation,
  areArraysSame,
  careplanview,
  generalHandleValueChanges,
  generalSaveButton,
  cancleOperationFn,
  statusfinderFn,
} from "../helperfunctions";
import CarePlanErrorMessage from "../CarePlanErrorMessage";
const VaccinationList = [
  "Adenovirus Type 4 and Type 7 Vaccine, Live, Oral - No Trade Name",
  "Anthrax Vaccine Adsorbed - Biothrax",
  "BCG Live - BCG Vaccine",
  "BCG Live - TICE BCG",
  "Cholera Vaccine Live Oral - Vaxchora",
  "COVID-19 Vaccine, mRNA - Comirnaty",
  "COVID-19 Vaccine, mRNA - SPIKEVAX",
  "Dengue Tetravalent Vaccine, Live - DENGVAXIA",
  "Diphtheria & Tetanus Toxoids Adsorbed - No Trade Name",
  "Diphtheria & Tetanus Toxoids & Acellular Pertussis Vaccine Adsorbed - Infanrix",
  "Diphtheria & Tetanus Toxoids & Acellular Pertussis Vaccine Adsorbed - DAPTACEL",
  "Diphtheria & Tetanus Toxoids & Acellular Pertussis Vaccine Adsorbed, Hepatitis B (recombinant) and Inactivated  - Pediarix",
  "Poliovirus Vaccine Combined",
  "Diphtheria and Tetanus Toxoids and Acellular Pertussis Adsorbed and Inactivated Poliovirus Vaccine - KINRIX",
  "Diphtheria and Tetanus Toxoids and Acellular Pertussis Adsorbed and Inactivated Poliovirus Vaccine - Quadracel",
  "Diphtheria and Tetanus Toxoids and Acellular Pertussis Adsorbed, Inactivated Poliovirus, Haemophilus b Conjugate  - VAXELIS",
  "[Meningococcal Protein Conjugate] and Hepatitis B [Recombinant] Vaccine",
  "Diphtheria and Tetanus Toxoids and Acellular Pertussis Adsorbed, Inactivated Poliovirus and Haemophilus b Conjugate  - Pentacel",
  "(Tetanus Toxoid Conjugate) Vaccine",
  "Ebola Zaire Vaccine, Live - ERVEBO",
  "Haemophilus b Conjugate Vaccine (Meningococcal Protein Conjugate) - PedvaxHIB",
  "Haemophilus b Conjugate Vaccine (Tetanus Toxoid Conjugate) - ActHIB",
  "Haemophilus b Conjugate Vaccine (Tetanus Toxoid Conjugate) - Hiberix",
  "Hepatitis A Vaccine, Inactivated - Havrix",
  "Hepatitis A Vaccine, Inactivated - VAQTA",
  "Hepatitis A Inactivated and Hepatitis B (Recombinant) Vaccine - Twinrix",
  "Hepatitis B Vaccine (Recombinant) - Recombivax HB",
  "Hepatitis B Vaccine (Recombinant) - PREHEVBRIO",
  "Hepatitis B Vaccine (Recombinant) - Engerix-B",
  "Hepatitis B Vaccine (Recombinant), Adjuvanted - HEPLISAV-B",
  "Human Papillomavirus Quadrivalent (Types 6, 11, 16, 18) Vaccine, Recombinant - Gardasil",
  "Human Papillomavirus 9-valent Vaccine, Recombinant - Gardasil 9",
  "Human Papillomavirus Bivalent (Types 16, 18) Vaccine, Recombinant - Cervarix",
  "Influenza A (H1N1) 2009 Monovalent Vaccine - No Trade Name",
  "Influenza A (H1N1) 2009 Monovalent Vaccine - No Trade Name",
  "Influenza A (H1N1) 2009 Monovalent Vaccine - No Trade Name",
  "Influenza A (H1N1) 2009 Monovalent Vaccine - No Trade Name",
  "Influenza A (H1N1) 2009 Monovalent Vaccine - No Trade Name",
  "Influenza Virus Vaccine, H5N1 (for National Stockpile) - No Trade Name",
  "Influenza A (H5N1) Virus Monovalent Vaccine, Adjuvanted - No Trade Name",
  "Influenza A (H5N1) Monovalent Vaccine, Adjuvanted - AUDENZ",
  "Influenza Vaccine, Adjuvanted - Fluad Quadrivalent",
  "Influenza Vaccine, Adjuvanted - Fluad",
  "Influenza Vaccine -  Afluria Quadrivalent, Afluria Quadrivalent Southern Hemisphere",
  "Influenza Vaccine - Flucelvax Quadrivalent",
  "Influenza Vaccine - Flulaval Quadrivalent",
  "Influenza Virus Vaccine (Trivalent, Types A and B) - Afluria, Afluria Southern Hemisphere",
  "Influenza Virus Vaccine (Trivalent, Types A and B) - FluLaval",
  "Influenza Vaccine, Live, Intranasal (Trivalent, Types A and B) - FluMist",
  "Influenza Virus Vaccine (Trivalent, Types A and B) - Fluarix",
  "Influenza Virus Vaccine (Trivalent, Types A and B) - Fluvirin",
  "Influenza Virus Vaccine (Trivalent, Types A and B) - Agriflu",
  "Influenza Virus Vaccine (Trivalent, Types A and B) - Fluzone, Fluzone High-Dose and Fluzone Intradermal",
  "Influenza Virus Vaccine (Trivalent, Types A and B) - Flucelvax",
  "Influenza Vaccine (Trivalent) - Flublok",
  "Influenza Vaccine (Quadrivalent) - Flublok Quadrivalent",
  "Influenza Vaccine,Live, Intranasal (Quadrivalent, Types A and Types B) - FluMist Quadrivalent",
  "Influenza Virus Vaccine (Quadrivalent, Types A and Types B) - Fluarix Quadrivalent",
  "Influenza Virus Vaccine (Quadrivalent, Types A and Types B) - Fluzone Quadrivalent",
  "Japanese Encephalitis Virus Vaccine, Inactivated, Adsorbed - Ixiaro",
  "Measles, Mumps and Rubella Vaccine, Live - PRIORIX",
  "Measles, Mumps, and Rubella Virus Vaccine, Live - M-M-R II",
  "Measles, Mumps, Rubella and Varicella Virus Vaccine Live - ProQuad",
  "Meningococcal (Groups A, C, Y, and W-135) Oligosaccharide Diphtheria CRM197 Conjugate Vaccine - MENVEO",
  "Meningococcal (Groups A, C, Y and W-135) Polysaccharide Diphtheria Toxoid Conjugate Vaccine - Menactra",
  "Meningococcal Group B Vaccine - BEXSERO",
  "Meningococcal Group B Vaccine - TRUMENBA",
  "Meningococcal Polysaccharide Vaccine, Groups A, C, Y and W-135 Combined - Menomune-A/C/Y/W-135",
  "Meningococcal (Groups A, C, Y, W) Conjugate Vaccine - MenQuadfi",
  "Plague Vaccine - No trade name",
  "Pneumococcal Vaccine, Polyvalent - Pneumovax 23",
  "Pneumococcal 13-valent Conjugate Vaccine (Diphtheria CRM197 Protein) - Prevnar 13",
  "Pneumococcal 15-valent Conjugate Vaccine - VAXNEUVANCE",
  "Pneumococcal 20-valent Conjugate Vaccine - Prevnar 20",
  "Poliovirus Vaccine Inactivated (Human Diploid Cell) - Poliovax",
  "Poliovirus Vaccine Inactivated (Monkey Kidney Cell) - IPOL",
  "Rabies Vaccine - Imovax",
  "Rabies Vaccine - RabAvert",
  "Rabies Vaccine Adsorbed - No Trade Name",
  "Rotavirus Vaccine, Live, Oral - ROTARIX",
  "Rotavirus Vaccine, Live, Oral, Pentavalent - RotaTeq",
  "Respiratory Syncytial Virus Vaccine - ABRYSVO",
  "Respiratory Syncytial Virus Vaccine, Adjuvanted - AREXVY",
  "Smallpox and Monkeypox Vaccine, Live, Non-Replicating - JYNNEOS",
  "Smallpox (Vaccinia) Vaccine, Live - ACAM2000",
  "Tetanus & Diphtheria Toxoids, Adsorbed - TDVAX",
  "Tetanus & Diphtheria Toxoids Adsorbed for Adult Use - TENIVAC",
  "Tetanus Toxoid Adsorbed - No Trade Name",
  "Tetanus Toxoid, Reduced Diphtheria Toxoid and Acellular Pertussis Vaccine, Adsorbed - Adacel",
  "Tetanus Toxoid, Reduced Diphtheria Toxoid and Acellular Pertussis Vaccine, Adsorbed - Boostrix",
  "Tick-Borne Encephalitis Vaccine - TICOVAC",
  "Typhoid Vaccine Live Oral Ty21a - Vivotif",
  "Typhoid Vi Polysaccharide Vaccine - TYPHIM Vi",
  "Varicella Virus Vaccine Live - Varivax",
  "Yellow Fever Vaccine - YF-Vax",
  "Zoster Vaccine, Live, (Oka/Merck) - Zostavax",
  "Zoster Vaccine Recombinant, Adjuvanted - SHINGRIX",
];
const Immunization = (props) => {
  const { selectedPatient, id, updateDatafn } = props;
  const [immunizationWindow, setImmunizationWindow] = useState("");
  const addImmunizationDetail = () => {
    setImmunizationWindow("addDetails");
    resetfn(immunization, setImmunization);
  };
  const [immunizationArray, setImmunizationArray] = useState([]);
  const [editArray, setEditArray] = useState();
  const [active, setActive] = useState("Active");

  const [immunization, setImmunization] = useState({
    VaccinationName: "",
    Site: "",
    NameOfManufacturer: "",
    VaccinationAdministeredOn: "",
    ExpirationDate: "",
    AdministeredPhysician: "",
    Dose: "",
    LotNumber: "",
    RoutesOfAdministration: "",
    VaccinationReason: "",
    Description: "",
    Status: true,
  });
  const onClickFn = () => {
    if (basicValidation(immunization)) {
      generalSaveButton(
        immunizationWindow,
        setImmunizationWindow,
        immunization,
        setImmunizationArray,
        editArray,
        setEditArray
      );
    } else {
      FloatingNotifications("error", "Fill mandatory fields to proceed");
    }
  };
  useEffect(() => {
    if (
      basicValidation(immunization) &&
      !areArraysSame(selectedPatient?.Immunization, immunizationArray)
    ) {
      updateDatafn({ PatientId: id, Immunization: immunizationArray });
    }
  }, [immunizationArray]);

  useEffect(() => {
    if (selectedPatient) {
      setImmunizationArray(selectedPatient.Immunization);
    }
  }, [selectedPatient]);
  return (
    <div>
      <DropDownContent title={"Immunization"}>
        <div>
          <TabWidget>
            {["Active", "History"]?.map((el) => (
              <TabElement
                key={`patients_${el}`}
                title={el}
                active={active === el}
                onClick={() => {
                  setActive(el);
                }}
              />
            ))}
          </TabWidget>
          {active === "Active" && (
            <ActiveHistoryDropDown2
              type={"Active"}
              iconsList={[
                {
                  value: "ic:twotone-plus",
                  onClickFn: addImmunizationDetail,
                },
                {
                  value: "mingcute:save-line",
                  onClickFn:
                    immunizationWindow === "addDetails"
                      ? () => onClickFn()
                      : false,
                },
                {
                  value: "majesticons:multiply-line",
                  onClickFn: () => cancleOperationFn(setImmunizationWindow),
                },
              ]}
            >
              <div>
                {immunizationWindow === "addDetails" ? (
                  <AddDetailGeneral data={selectedPatient}>
                    <>
                      <p className="displayTitleGnrl">
                        Immunization Information
                      </p>
                      <div className="display-sec-Gnrl">
                        <div className="display-content-Gnrl">
                          <label className="display-element-Gnrl">
                            Search Vaccination Name*
                          </label>
                          <SelectWithSearch
                            options={VaccinationList}
                            valuedata={immunization.VaccinationName}
                            fn={generalHandleValueChanges}
                            setvalue={setImmunization}
                            name={"VaccinationName"}
                          />

                          <label className="display-element-Gnrl">
                            Vaccination Administered on*
                          </label>
                          <input
                            className="display-element-Gnrl "
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            value={immunization.VaccinationAdministeredOn?.slice(
                              0,
                              16
                            )}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setImmunization,
                                "VaccinationAdministeredOn"
                              )
                            }
                          />

                          <label className="display-element-Gnrl">
                            Administered Physician*
                          </label>
                          <select
                            className="display-element-Gnrl"
                            value={immunization.AdministeredPhysician}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setImmunization,
                                "AdministeredPhysician"
                              )
                            }
                          >
                            <option className="default" value=""></option>
                            <option value="KLDSNJLKEMNDLAD">lewohdoiu </option>
                            <option value="KLDSNJLKEMNDLAD">lewohdoiu</option>
                            <option value="KLDSNJLKEMNDLAD">lewo</option>
                          </select>

                          <div className="display-element-Gnrl doubleSideElement ">
                            <div>
                              <label className="display-element-Gnrl ">
                                Dose*
                              </label>
                              <input
                                type="text"
                                className="display-element-Gnrl "
                                value={immunization.Dose}
                                onChange={(event) =>
                                  generalHandleValueChanges(
                                    event,
                                    setImmunization,
                                    "Dose"
                                  )
                                }
                              />
                            </div>
                            <div>
                              <label className="display-element-Gnrl ">
                                fjfjf
                              </label>
                              <input
                                type="text"
                                className="display-element-Gnrl "
                              />
                            </div>
                          </div>
                          <label className="display-element-Gnrl">Notes*</label>
                          <div className="displayDescription">
                            <textarea
                              className="display-element-Gnrl"
                              value={immunization.Description}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setImmunization,
                                  "Description"
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="display-content-Gnrl">
                          <label className="display-element-Gnrl ">
                            Routes of Administration*
                          </label>
                          <input
                            type="text"
                            className="display-element-Gnrl "
                            value={immunization.RoutesOfAdministration}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setImmunization,
                                "RoutesOfAdministration"
                              )
                            }
                          />
                          <label className="display-element-Gnrl ">Site*</label>
                          <input
                            type="text"
                            className="display-element-Gnrl "
                            value={immunization.Site}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setImmunization,
                                "Site"
                              )
                            }
                          />
                          <label className="display-element-Gnrl ">
                            Name of Manufracturer*
                          </label>
                          <input
                            type="text"
                            className="display-element-Gnrl "
                            value={immunization.NameOfManufacturer}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setImmunization,
                                "NameOfManufacturer"
                              )
                            }
                          />
                          <label className="display-element-Gnrl ">
                            Lot Number*
                          </label>
                          <input
                            type="text"
                            className="display-element-Gnrl "
                            value={immunization.LotNumber}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setImmunization,
                                "LotNumber"
                              )
                            }
                          />
                          <label className="display-element-Gnrl ">
                            Expiration Date*
                          </label>
                          <input
                            className="display-element-Gnrl "
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            value={immunization.ExpirationDate?.slice(0, 16)}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setImmunization,
                                "ExpirationDate"
                              )
                            }
                          />
                          <label className="display-element-Gnrl ">
                            Vaccination Reason*
                          </label>
                          <input
                            type="text"
                            className="display-element-Gnrl "
                            value={immunization.VaccinationReason}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setImmunization,
                                "VaccinationReason"
                              )
                            }
                          />
                        </div>
                      </div>
                    </>{" "}
                  </AddDetailGeneral>
                ) : (
                  <>
                    {statusfinderFn("active", selectedPatient.Immunization)
                      ?.length === 0 ? (
                      <CarePlanErrorMessage msg="Patient doesn’t have any Immunization yet!" />
                    ) : (
                      <CarePlanDisplayGeneral
                        updateDatafn={updateDatafn}
                        name={"Immunization"}
                        array={immunizationArray}
                        id={id}
                        careplanview={careplanview}
                        values={[setImmunizationWindow, setImmunization]}
                        data={statusfinderFn(
                          "active",
                          selectedPatient.Immunization
                        )}
                        dataSet={selectedPatient?.Immunization ?? []}
                        setEditArray={setEditArray}
                        contentArray={[
                          ["Vaccination Name:", "VaccinationName", "colSpan6"],
                          ["Site:", "Site", "colSpan6"],
                          [
                            "Vaccination Administered On:",
                            "VaccinationAdministeredOn",
                            "colSpan6",
                          ],
                          [
                            "Name Of Manufacturer:",
                            "NameOfManufacturer",
                            "colSpan6",
                          ],
                          [
                            "Administered Physician:",
                            "AdministeredPhysician",
                            "colSpan6",
                          ],
                          ["Lot Number:", "LotNumber", "colSpan6"],
                          ["Dose:", "Dose", "colSpan6"],
                          ["Expiration Date:", "ExpirationDate", "colSpan6"],
                          [
                            "Routes Of Administration:",
                            "RoutesOfAdministration",
                            "colSpan6",
                          ],
                          [
                            "Vaccination Reason:",
                            "VaccinationReason",
                            "colSpan6",
                          ],
                          ["Description:", "Description", "colSpan6"],
                        ]}
                      />
                    )}
                  </>
                )}
              </div>
            </ActiveHistoryDropDown2>
          )}
          {active === "History" && (
            <ActiveHistoryDropDown2 type={"History"}>
              <>
                {" "}
                {statusfinderFn("history", selectedPatient.Immunization)
                  ?.length === 0 ? (
                  <></>
                ) : (
                  <CarePlanDisplayGeneral
                    Type={"history"}
                    data={statusfinderFn(
                      "history",
                      selectedPatient.Immunization
                    )}
                    contentArray={[
                      ["Vaccination Name:", "VaccinationName", "colSpan6"],
                      ["Site:", "Site", "colSpan6"],
                      [
                        "Vaccination Administered On:",
                        "VaccinationAdministeredOn",
                        "colSpan6",
                      ],
                      [
                        "Name Of Manufacturer:",
                        "NameOfManufacturer",
                        "colSpan6",
                      ],
                      [
                        "Administered Physician:",
                        "AdministeredPhysician",
                        "colSpan6",
                      ],
                      ["Lot Number:", "LotNumber", "colSpan6"],
                      ["Dose:", "Dose", "colSpan6"],
                      ["Expiration Date:", "ExpirationDate", "colSpan6"],
                      [
                        "Routes Of Administration:",
                        "RoutesOfAdministration",
                        "colSpan6",
                      ],
                      ["Vaccination Reason:", "VaccinationReason", "colSpan6"],
                      ["Description:", "Description", "colSpan6"],
                    ]}
                  />
                )}
              </>
            </ActiveHistoryDropDown2>
          )}
        </div>
      </DropDownContent>
    </div>
  );
};

export default Immunization;
