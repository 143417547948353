import { apiCollection } from "./apiCollection";

const UserManagementApi = apiCollection.injectEndpoints({
  endpoints: (build) => ({
    timerfinalapi: build.mutation({
      query: (payload) => {
        return {
          url: "timetracker/AddServiceLog",
          method: "POST",
          body: payload,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useTimerfinalapiMutation } = UserManagementApi;
