import { useReducer } from "react";

const initialInputState = {
  value: null,
  submitClicked: false,
};

const inputStateReducer = (state, action) => {
  if (action.type === "INPUT") {
    return { value: action.value, submitClicked: state.submitClicked };
  } else if (action.type === "SUBMIT") {
    return { value: state.value, submitClicked: true };
  } else if (action.type === "RESET") {
    return { value: null, submitClicked: false };
  } else if (action.type === "SET") {
    return { value: action.value, submitClicked: state.submitClicked };
  } else if (action.type === "DEFAULT") {
    return { value: action.value, submitClicked: state.submitClicked };
  }
  return initialInputState;
};

const useDatePicker1 = (validateValue) => {
  const [inputState, dispatch] = useReducer(
    inputStateReducer,
    initialInputState
  );

  const valueIsValid = validateValue(inputState.value);
  const hasError = !valueIsValid && inputState.submitClicked;

  const valueChangeHandler = (data) => {
    if (data === "") {
      dispatch({ type: "INPUT", value: null });
    } else {
      dispatch({ type: "INPUT", value: data });
    }
  };

  const submitClickHandler = () => {
    dispatch({ type: "SUBMIT" });
  };

  const reset = () => {
    dispatch({ type: "RESET" });
  };

  const storeValue = (value) => {
    console.log(value);
    if (value === "") {
      dispatch({ type: "SET", value: null });
    } else if (typeof value === "string") {
      dispatch({ type: "SET", value: new Date(value) });
    } else {
      dispatch({ type: "SET", value: value });
    }
  };

  return {
    value: inputState.value,
    isValid: valueIsValid,
    hasError,
    valueChangeHandler,
    submitClickHandler,
    reset,
    storeValue,
  };
};

export default useDatePicker1;
