import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { Icon } from "@iconify/react";
import Stopwatch from "../../components/StopWatch";
import { useUpdateCarePlanMutation } from "../../app/api/patientsApi";
import { useGetPatientsCarePlanMutation } from "../../app/api/patientsApi";
import { useGetPatientsOptionsQuery } from "../../app/api/patientSelctorOptionApi";
import CareplanSidebar from "../Careteam/CareplanSidebar";
import RcmAndCcm from "../Careteam/RcmAndCcm/RcmAndCcm";
import VitalsReading from "./VitalsReading";
const sidebarArray = ["Care Team", "Vital Readings"];

const RpmView = () => {
  const navigate = useNavigate();
  const [timerNotification, setTimerNotification] = useState(true);
  const [startTimer, setStartTimer] = useState(false);

  const location = useLocation();
  const id = location?.state?.id;
  const [selectedPatient, setSelectedPatient] = useState();
  const [updateDatafn, { data: updatedData }] = useUpdateCarePlanMutation();
  const [carePlanCallFn, { data: Patientdetails }] =
    useGetPatientsCarePlanMutation();
  const [window, setWindow] = useState("Care Team");

  const updateFunction = (value) => {
    updateDatafn(value);
  };

  const [totalTime, setTotalTime] = useState(
    selectedPatient?.TotalTime ?? "00:00:00"
  );
  const onClickSideBar = (value) => {
    setWindow(value);
  };
  const onClickSave = (apiName, selectedOption) => {
    let ls = apiName.split(".");
    ls.reverse();
    const res = ls.reduce((prev, curr, idx) => {
      if (!prev) {
        return { [curr]: selectedOption };
      } else {
        return { [curr]: prev };
      }
    }, null);
    updateDatafn({ PatientId: id, ...res });
  };
  const getDate = (value) => {
    if (!value) return;
    let date = `${new Date(value)}`;
    let words = date.split(" ");
    let updatedWords = words[1] + " " + words[2] + " " + words[3];

    return updatedWords;
  };
  // console.log(getDate(selectedPatient?.updatedAt));
  const dob = selectedPatient
    ? `${getDate(selectedPatient["Date Of Birth"])}`
    : "";

  const { data: fpcList } = useGetPatientsOptionsQuery();

  const FacilityList = fpcList?.FacilityList ?? [];
  const ProviderList = fpcList?.ProviderList ?? [];
  const ClinicianList = fpcList?.ClinicianList ?? [];

  const timersettingFn = (val) => {
    if (val) {
      setStartTimer(true);
    } else {
      setStartTimer(false);
    }
    setTimerNotification(false);
  };
  useEffect(() => {
    if (selectedPatient) {
      setTotalTime(selectedPatient?.TotalTime);
    }
  }, [selectedPatient]);
  useEffect(() => {
    if (!Patientdetails) return;
    setSelectedPatient(Patientdetails);
  }, [Patientdetails]);
  useEffect(() => {
    if (!updatedData) return;
    setSelectedPatient(updatedData);
  }, [updatedData]);

  useEffect(() => {
    carePlanCallFn({
      PatientId: id,
    });
  }, [carePlanCallFn, id]);

  useEffect(() => {
    if (!id) {
      navigate("/patients");
    }
  }, [id]);

  return (
    <>
      {timerNotification && selectedPatient && (
        <div className="timerPopUp">
          <div className="timerContentPopUp">
            <p className="content-text">
              Do you wish to start the timer for monitoring physiological
              parameters?
            </p>
            <div className="dialog">
              <button className="btn " onClick={() => timersettingFn(true)}>
                Ok
              </button>
              <button className="btn " onClick={() => timersettingFn(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {selectedPatient && (
        <div className="carePlanWrap">
          <div className="careTeamContainer">
            <p className="pageTitle"></p>
            <div className="headerSection">
              <div className="colSpan1 element element1">
                <div className="profile-icon-wrap">
                  <Icon
                    className="profile"
                    icon="iconamoon:profile-fill"
                    width="30"
                    height="30"
                    color="#eee"
                  />
                </div>
                <div className="details">
                  <p className="name">{selectedPatient["Name"]}</p>
                  <p className="sub">
                    ( {selectedPatient["Gender"]}| {selectedPatient["Age"]}yrs
                    old |{dob} )
                  </p>
                </div>
              </div>
              <div className="colSpan1 element element2">
                <p className="sub">Care Plan Physician:</p>
                <p className="name">
                  {selectedPatient?.ProviderId?.ProviderName}
                </p>
              </div>
              <div className="colSpan1 element element3">
                <p className="sub">Primary Care Coordinator:</p>
                <p className="name">
                  {selectedPatient?.PrimaryCoordinator?.ClinicianName}
                </p>
              </div>
              <div className="colSpan1 element element4">
                <p className="sub">Facility:</p>
                <p className="name">
                  {selectedPatient?.FacilityId?.FacilityName}
                </p>
              </div>
              <div className="colSpan1 element element6">
                {/* <button onClick={DownloadPDF}>Download Careplan</button> */}
                <div className="download-button"></div>
              </div>
              <div className="colSpan1"></div>
              <div className="colSpan1  element element7">
                <div className="time">Total Time Spent</div>
                <p className="time">{totalTime}</p>
              </div>
              <div className="colSpan1  element element8">
                <div className="stopwatch">
                  <Stopwatch
                    id={id}
                    startTimer={startTimer}
                    setStartTimer={setStartTimer}
                    serviceName={"Monitoring physiological parameters"}
                    totalTime={totalTime}
                  />
                </div>
              </div>
            </div>
            <div className="carePlanBodySection">
              <CareplanSidebar
                window={window}
                sidebarArray={sidebarArray}
                clickfn={onClickSideBar}
              />

              <div className="content-section">
                <div className="date-section">
                  <div>
                    <p className="sub">Care Plan Created Date & Time </p>
                    <p className="date">
                      {getDate(selectedPatient?.createdAt)}
                    </p>
                  </div>
                  <div>
                    <p className="sub">Care Plan Updated Date & Time </p>
                    <p className="date">
                      {getDate(selectedPatient?.updatedAt)}
                    </p>
                  </div>
                </div>
                {window === "Care Team" && (
                  <RcmAndCcm
                    selectedPatient={selectedPatient}
                    onClickSave={onClickSave}
                    ProviderList={ProviderList}
                    ClinicianList={ClinicianList}
                  />
                )}
                {window === "Vital Readings" && <VitalsReading id={id} />}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RpmView;
