import React from "react";
import { useState, useEffect } from "react";
import FloatingNotifications from "../utilities/FloatingNotifications";
import SelectComponent from "../components/SelectComponent";
import { useAddAdminMutation } from "../app/api/adminApi";
import {
  basicValidation,
  generalHandleValueChanges,
} from "../patients/Careteam/helperfunctions";
import "./addAdmin.scss";
const AddAdmin = ({ editData, cancelfn, refetch }) => {
  const [loading, setLoading] = useState(false);
  const initialState = {
    username: "",
    password: "",
    email: "",
    phone: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    street1: "",
    street2: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
  };
  const [adminData, setAdminData] = useState(initialState);
  const [addAdminFn, { data: adminLIstAfterUpdation, error }] =
    useAddAdminMutation(undefined, {
      refetchOnMountOrArgChange: true,
    });
  const saveProgram = async () => {
    setLoading(true);
    if (!basicValidation(adminData)) {
      FloatingNotifications(
        "error",
        "To proceed, all fields must be filled out."
      );
      setLoading(false);
      return;
    }

    // if (editData) {
    //   updateServiceFn({
    //     PatientId: patientDataForService?.PatientId,
    //     programName: service.programName,
    //     acutalTimeSpent: service?.acutalTimeSpent,
    //     startTime: new Date(service.startTime).toISOString(),
    //     endTime: endTime,
    //     remark: service.remark,
    //     serviceType: editData?.serviceType,
    //     serviceName: editData?.serviceName,
    //     serviceId: editData?.serviceId,
    //   });

    //   return;
    // }
    const updatedService = {
      ...adminData,
    };
    addAdminFn(updatedService);
  };

  useEffect(() => {
    if (!adminLIstAfterUpdation) return;
    console.log("here");
    setAdminData(initialState);
    if (refetch) {
      refetch();
    }
    FloatingNotifications("success", "Service added successfully");
    cancelfn();
  }, [adminLIstAfterUpdation]);

  //   useEffect(() => {
  //     if (!updatedData) return;
  //     setService(initialState);
  //     if (getBillingHistory) {
  //       getBillingHistory();
  //     }
  //     cancelfn();
  //   }, [updatedData]);

  useEffect(() => {
    if (!editData) return;
    setAdminData(editData);
  }, [editData]);

  useEffect(() => {
    if (error) {
      FloatingNotifications("error", "Add service failed.");
    }
  }, [error]);
  return (
    <div className="addAdmin">
      <p className="popTitle">Add Admin </p>
      <div className="addContainer">
        <div>
          <div className="popElement">
            <p className="popLabel">First Name</p>
            <input
              type="text"
              className="viewElement "
              value={adminData.FirstName}
              onChange={(event) =>
                generalHandleValueChanges(event, setAdminData, "FirstName")
              }
            />
          </div>
          <div className="popElement">
            <p className="popLabel">Middle Name</p>
            <input
              type="text"
              className="viewElement "
              value={adminData.MiddleName}
              onChange={(event) =>
                generalHandleValueChanges(event, setAdminData, "MiddleName")
              }
            />
          </div>
          <div className="popElement">
            <p className="popLabel">Last Name</p>
            <input
              type="text"
              className="viewElement "
              value={adminData.LastName}
              onChange={(event) =>
                generalHandleValueChanges(event, setAdminData, "LastName")
              }
            />
          </div>

          <div className="popElement">
            <p className="popLabel">username</p>
            <input
              type="text"
              className="viewElement "
              value={adminData?.username}
              onChange={(event) =>
                generalHandleValueChanges(event, setAdminData, "username")
              }
            />
          </div>
          <div className="popElement">
            <p className="popLabel">password</p>
            <input
              type="password"
              className="viewElement "
              value={adminData.password}
              onChange={(event) =>
                generalHandleValueChanges(event, setAdminData, "password")
              }
            />
          </div>
          <div className="popElement">
            <p className="popLabel">Phone</p>
            <input
              type="number"
              className="viewElement "
              value={adminData.phone}
              onChange={(event) =>
                generalHandleValueChanges(event, setAdminData, "phone")
              }
            />
          </div>
          <div className="popElement">
            <p className="popLabel">Email</p>
            <input
              type="email"
              className="viewElement "
              value={adminData.email}
              onChange={(event) =>
                generalHandleValueChanges(event, setAdminData, "email")
              }
            />
          </div>
        </div>
        <div>
          <div className="popElement">
            <p className="popLabel">street1</p>
            <input
              type="text"
              className="viewElement "
              value={adminData.street1}
              onChange={(event) =>
                generalHandleValueChanges(event, setAdminData, "street1")
              }
            />
          </div>
          <div className="popElement">
            <p className="popLabel">street2</p>
            <input
              type="text"
              className="viewElement "
              value={adminData.street2}
              onChange={(event) =>
                generalHandleValueChanges(event, setAdminData, "street2")
              }
            />
          </div>
          <div className="popElement">
            <p className="popLabel">city</p>
            <input
              type="text"
              className="viewElement "
              value={adminData.city}
              onChange={(event) =>
                generalHandleValueChanges(event, setAdminData, "city")
              }
            />
          </div>
          <div className="popElement">
            <p className="popLabel">state</p>
            <input
              type="text"
              className="viewElement "
              value={adminData.state}
              onChange={(event) =>
                generalHandleValueChanges(event, setAdminData, "state")
              }
            />
          </div>

          <div className="popElement">
            <p className="popLabel">country</p>
            <input
              type="text"
              className="viewElement "
              value={adminData.country}
              onChange={(event) =>
                generalHandleValueChanges(event, setAdminData, "country")
              }
            />
          </div>
          <div className="popElement">
            <p className="popLabel">postalCode</p>
            <input
              type="number"
              className="viewElement "
              value={adminData.postalCode}
              onChange={(event) =>
                generalHandleValueChanges(event, setAdminData, "postalCode")
              }
            />
          </div>
        </div>
      </div>
      <div className="popBtn">
        <button onClick={saveProgram} disabled={loading}>
          Save
        </button>
        <button onClick={cancelfn}>Cancel</button>
      </div>
    </div>
  );
};

export default AddAdmin;
