import React from "react";
import { Icon } from "@iconify/react";
import "./careplanErrorMessage.scss";

function CarePlanErrorMessage({ msg }) {
  return (
    <div className="errorMessageContainer">
      <Icon icon="material-symbols:error-outline" color="#888" />
      <p className="careTeamerrorMessage">{msg}</p>
    </div>
  );
}

export default CarePlanErrorMessage;
