import React from "react";
import { useState, useEffect } from "react";
import SelectComponent2 from "../components/SelectCompoent2";
import { useGetPatientsOptionsQuery } from "../app/api/patientSelctorOptionApi";
import {
  useSearchPatientsByFPCMutation,
  useUpdateFPCMutation,
} from "../app/api/patientsApi";
import ActiveHistoryDropDown from "../components/ActiveHistoryDropDown";
import { generalHandleValueChanges } from "../patients/Careteam/helperfunctions";
import { useNavigate } from "react-router-dom";

import {
  useGetFacilityAllProfilesQuery,
  useGetFacilityAccessLevelByIdMutation,
} from "../app/api/facilityApi";
import { IconButton } from "@mui/material";
import SelectComponent from "../components/SelectComponent";
import PatientTransferBox from "./PatientTransferBox";
import "./transferPatients.scss";
import FloatingNotifications from "../utilities/FloatingNotifications";
const dataFiltersInitialState = {
  facility: "",
  provider: "",
  clinician: "",
  facility2: "",
  provider2: "",
  clinician2: "",
  transferType: "",
  clinicianRole: "",
};

const TransferPatients = () => {
  const [disabled, setDisabled] = useState(false);
  const [searchPatientsByFPC, { data: patientDataByFPC, error, isLoading }] =
    useSearchPatientsByFPCMutation();

  const [
    getAccessLevelData,
    { data: accessLevelData, error: errorGettingAccessData },
  ] = useGetFacilityAccessLevelByIdMutation();
  const [updateFPC, { data: updateData, error: errorUpdating }] =
    useUpdateFPCMutation();

  const [selectedDataLeft, setSelectedDataLeft] = useState([]);
  const [selectedDataRigth, setSelectedDataRigth] = useState([]);

  const [dataLeft, setDataLeft] = useState([]);
  const [dataRigth, setDataRigth] = useState([]);
  const { data: fasilityData } = useGetFacilityAllProfilesQuery();
  const [dataFilters, setDataFilters] = useState(dataFiltersInitialState);
  const FacilityList =
    fasilityData?.map((facility) => [facility.Name, facility.FacilityId]) ?? [];

  const [providerList, setProviderList] = useState([]);
  const [clinicianList, setClinicianList] = useState([]);

  const searchPatientsFn = () => {
    if (
      dataFilters.facility === "" ||
      dataFilters.provider === "" ||
      dataFilters.clinician === "" ||
      dataFilters.clinicianRole === ""
    ) {
      FloatingNotifications("error", "Select required fields to proceed");
      return;
    }
    let role;

    if (
      dataFilters.clinicianRole === "All" ||
      dataFilters.clinicianRole === "Primary Care Coordinator"
    ) {
      role = {
        PrimaryCoordinator: dataFilters.clinician2,
      };
    } else if (dataFilters.clinicianRole === "primary RPM Clinician") {
      role = {
        primaryRPMClinician: dataFilters.clinician,
      };
    } else if (dataFilters.clinicianRole === "primary CCM Clinician") {
      role = {
        primaryCCMClinician: dataFilters.clinician,
      };
    }
    searchPatientsByFPC({
      FacilityId: dataFilters.facility,
      ProviderId: dataFilters.provider,
      ...role,
    });
  };
  const checkBoxFn = (type, event, moveAll, id) => {
    if (type === "left") {
      if (event.target.checked) {
        if (moveAll) {
          setSelectedDataLeft(dataLeft.map((patient) => patient.PatientId));
          return;
        }
        setSelectedDataLeft((prev) => {
          if (!prev.includes(id)) {
            return [...prev, id];
          }
          return prev;
        });
      } else {
        if (moveAll) {
          setSelectedDataLeft([]);
          return;
        }
        setSelectedDataLeft((prev) => {
          if (prev.includes(id)) {
            return prev.filter((item) => item !== id);
          }
          return prev;
        });
      }
    } else if (type === "rigth") {
      if (event.target.checked) {
        if (moveAll) {
          setSelectedDataRigth(dataRigth.map((patient) => patient.PatientId));
          return;
        }
        setSelectedDataRigth((prev) => {
          if (!prev.includes(id)) {
            return [...prev, id];
          }
          return prev;
        });
      } else {
        if (moveAll) {
          setSelectedDataRigth([]);
          return;
        }
        setSelectedDataRigth((prev) => {
          if (prev.includes(id)) {
            return prev.filter((item) => item !== id);
          }
          return prev;
        });
      }
    }
  };

  const transferSelectedData = (type) => {
    var transferData;

    if (type === "rigth") {
      transferData = dataLeft.filter((item) =>
        selectedDataLeft.includes(item.PatientId)
      );
      setDataLeft((prev) => {
        return prev.filter(
          (item) => !selectedDataLeft.includes(item.PatientId)
        );
      });
      setDataRigth((prev) => [...prev, ...transferData]);
      setSelectedDataLeft([]);
      setDataFilters((prev) => ({
        ...prev,
        facility2: prev.facility,
        provider2: prev.provider,
        clinician2: prev.clinician,
      }));
    } else if (type === "left") {
      transferData = dataRigth.filter((item) =>
        selectedDataRigth.includes(item.PatientId)
      );
      setDataRigth((prev) => {
        return prev.filter(
          (item) => !selectedDataRigth.includes(item.PatientId)
        );
      });
      setDataLeft((prev) => [...prev, ...transferData]);
      setSelectedDataRigth([]);
    }
  };
  const transferDataFn = () => {
    let role;

    if (
      dataFilters.clinicianRole === "All" ||
      dataFilters.clinicianRole === "Primary Care Coordinator"
    ) {
      role = {
        PrimaryCoordinator: dataFilters.clinician2,
      };
    } else if (dataFilters.clinicianRole === "primary RPM Clinician") {
      role = {
        primaryRPMClinician: dataFilters.clinician,
      };
    } else if (dataFilters.clinicianRole === "primary CCM Clinician") {
      role = {
        primaryCCMClinician: dataFilters.clinician,
      };
    }
    updateFPC({
      PatientIds: dataRigth.map((patient) => patient.PatientId),
      FacilityId: dataFilters.facility2,
      ProviderId: dataFilters.provider2,
      ...role,
    });
  };
  useEffect(() => {
    if (dataFilters.facility === "") return;
    getAccessLevelData({ FacilityId: dataFilters.facility });
  }, [dataFilters.facility]);

  useEffect(() => {
    if (!accessLevelData) return;

    // Extract ProviderList
    const updatedProviderList =
      accessLevelData.ProviderList?.map((provider) => [
        provider.ProviderName,
        provider.ProviderId,
      ]) ?? [];

    // Extract ClinicianList
    const updatedClinicianList =
      accessLevelData.ClinicianList?.map((clinician) => [
        clinician.ClinicianName,
        clinician.ClinicianId,
      ]) ?? [];

    // Update state
    setProviderList(updatedProviderList);
    setClinicianList(updatedClinicianList);
  }, [accessLevelData]);

  useEffect(() => {
    if (!patientDataByFPC) return;
    setDataLeft(patientDataByFPC);
    if (patientDataByFPC.length > 0) {
      setDisabled(true);
    }
  }, [patientDataByFPC]);
  useEffect(() => {
    if (!updateData) return;
    FloatingNotifications("success", "Patient transfered successfully");
    window.location.reload();
  }, [updateData]);

  const resetFilterFn = () => {
    window.location.reload();
  };
  useEffect(() => {
    if (error) {
      FloatingNotifications("error", "Error getting patients data");
    } else if (errorGettingAccessData) {
      FloatingNotifications("error", "Error getting access level data");
    } else if (errorUpdating) {
      FloatingNotifications("error", "Error transfering patients");
    }
  }, [error, errorGettingAccessData, errorUpdating]);
  return (
    <div className="transferPatients">
      <div className="transferPatientstitle">Patient Transfer</div>

      <div className="body">
        <ActiveHistoryDropDown type={"Unprocessed Records"}>
          <div className="patientTransferBody">
            <div className="filter1">
              <div className="box">
                <p className="head">Facility*</p>
                <SelectComponent2
                  disabled={dataFilters.transferType === "" ? true : false}
                  OptionsDict={FacilityList}
                  fn={generalHandleValueChanges}
                  valueData={
                    FacilityList.find(
                      (facility) => facility[1] === dataFilters?.facility
                    )?.[0]
                  }
                  setvalue={setDataFilters}
                  name={"facility"}
                />
              </div>
              <div className="box">
                <p className="head">Physician*</p>
                <SelectComponent2
                  disabled={dataFilters.transferType === "" ? true : false}
                  OptionsDict={providerList}
                  fn={generalHandleValueChanges}
                  valueData={
                    providerList?.find(
                      (provider) => provider[1] === dataFilters?.provider
                    )?.[0]
                  }
                  setvalue={setDataFilters}
                  name={"provider"}
                />
              </div>
              <div className="box">
                <p className="head">Clinician*</p>
                <SelectComponent2
                  disabled={dataFilters.transferType === "" ? true : false}
                  OptionsDict={clinicianList}
                  fn={generalHandleValueChanges}
                  valueData={
                    clinicianList?.find(
                      (Clinician) => Clinician[1] === dataFilters?.clinician
                    )?.[0]
                  }
                  setvalue={setDataFilters}
                  name={"clinician"}
                />
              </div>
              {dataFilters.clinician !== "" && (
                <div className="box">
                  <p className="head">Clinician Role*</p>
                  <SelectComponent
                    OptionsArray={[
                      "All",
                      "Primary Care Coordinator",
                      "primary CCM Clinician",
                      "primary RPM Clinician",
                    ]}
                    fn={generalHandleValueChanges}
                    valueData={dataFilters.clinicianRole}
                    setvalue={setDataFilters}
                    name={"clinicianRole"}
                  />
                </div>
              )}
              <div className="transfer-button">
                <IconButton
                  style={{
                    borderRadius: "4px",
                    padding: "1rem",
                    fontSize: "calc(6.32px + 0.632 * 1vw)",
                    color: "#fff",
                    fontWeight: "600",
                    backgroundColor: "#7A5DDA",
                  }}
                  //   disabled={isLoading}
                  className="btn-manualData"
                  onClick={() => resetFilterFn()}
                >
                  Reset Filters
                </IconButton>
                <IconButton
                  style={{
                    borderRadius: "4px",
                    padding: "1rem",
                    fontSize: "calc(6.32px + 0.632 * 1vw)",
                    color: "#fff",
                    fontWeight: "600",
                    backgroundColor: "#7A5DDA",
                  }}
                  //   disabled={isLoading}
                  className="btn-manualData"
                  onClick={() => searchPatientsFn()}
                >
                  Search Patients
                </IconButton>
              </div>
            </div>
            <div></div>
            <div className="filter1">
              <div className="box">
                <p className="head">Facility</p>
                <SelectComponent2
                  disabled={
                    dataRigth.length === 0 ||
                    dataFilters.transferType === "Only Physician" ||
                    dataFilters.transferType ===
                      "Only Primary Care Coordinator" ||
                    dataFilters.transferType ===
                      "Only Program Primary Clinician"
                  }
                  OptionsDict={FacilityList}
                  di
                  fn={generalHandleValueChanges}
                  valueData={
                    FacilityList.find(
                      (facility) => facility[1] === dataFilters?.facility2
                    )?.[0]
                  }
                  setvalue={setDataFilters}
                  name={"facility2"}
                />
              </div>
              <div className="box">
                <p className="head">Physician</p>
                <SelectComponent2
                  disabled={
                    dataRigth.length === 0 ||
                    dataFilters.transferType === "Only Facility" ||
                    dataFilters.transferType ===
                      "Only Primary Care Coordinator" ||
                    dataFilters.transferType ===
                      "Only Program Primary Clinician"
                  }
                  OptionsDict={providerList}
                  fn={generalHandleValueChanges}
                  valueData={
                    providerList?.find(
                      (provider) => provider[1] === dataFilters?.provider2
                    )?.[0]
                  }
                  setvalue={setDataFilters}
                  name={"provider2"}
                />
              </div>
              <div className="box">
                <p className="head">Clinician</p>
                <SelectComponent2
                  disabled={
                    dataRigth.length === 0 ||
                    dataFilters.transferType === "Only Facility" ||
                    dataFilters.transferType === "Only Physician"
                  }
                  OptionsDict={clinicianList}
                  fn={generalHandleValueChanges}
                  valueData={
                    clinicianList?.find(
                      (Clinician) => Clinician[1] === dataFilters?.clinician2
                    )?.[0]
                  }
                  setvalue={setDataFilters}
                  name={"clinician2"}
                />
              </div>
              <div className="transfer-button">
                <IconButton
                  style={{
                    borderRadius: "4px",
                    padding: "1rem",
                    fontSize: "calc(6.32px + 0.632 * 1vw)",
                    color: "#fff",
                    fontWeight: "600",
                    backgroundColor: "#7A5DDA",
                  }}
                  //   disabled={isLoading}
                  className="btn-manualData"
                  onClick={() => transferDataFn()}
                >
                  Transfer Patients
                </IconButton>
              </div>
            </div>
            <div className="patientDeatailSec">
              <PatientTransferBox
                data={dataLeft}
                type={"left"}
                checkBoxFn={checkBoxFn}
                selectedData={selectedDataLeft}
              />
            </div>
            <div className="patientTransferSec">
              <div className="box">
                <p className="head">Select Transfer Type</p>
                <SelectComponent
                  disabled={disabled}
                  OptionsArray={[
                    "Only Facility",
                    "Only Physician",
                    "Only Primary Care Coordinator",
                    "Only Program Primary Clinician",
                    "Multiple",
                  ]}
                  fn={generalHandleValueChanges}
                  valueData={dataFilters.transferType}
                  setvalue={setDataFilters}
                  name={"transferType"}
                />
              </div>
              <div className="transferBtns">
                <IconButton
                  style={{
                    borderRadius: "4px",
                    padding: "1rem",
                    fontSize: "calc(6.32px + 0.632 * 1vw)",
                    color: "#fff",
                    fontWeight: "600",
                    backgroundColor: "#7A5DDA",
                  }}
                  //   disabled={isLoading}
                  className="btn-manualData"
                  onClick={() => transferSelectedData("rigth")}
                >
                  {"Move Right ==>>"}
                </IconButton>
                <IconButton
                  style={{
                    borderRadius: "4px",
                    padding: "1rem",
                    fontSize: "calc(6.32px + 0.632 * 1vw)",
                    color: "#fff",
                    fontWeight: "600",
                    backgroundColor: "#7A5DDA",
                  }}
                  //   disabled={isLoading}
                  className="btn-manualData"
                  onClick={() => transferSelectedData("left")}
                >
                  {"<== Move Left"}
                </IconButton>
              </div>
            </div>
            <div className="patientDeatailSec">
              <PatientTransferBox
                data={dataRigth}
                type={"rigth"}
                checkBoxFn={checkBoxFn}
                selectedData={selectedDataRigth}
              />
            </div>
          </div>
        </ActiveHistoryDropDown>
        <ActiveHistoryDropDown type={"Transferred Records"} open={false}>
          <div className="divWrap">nbmn</div>
        </ActiveHistoryDropDown>
      </div>
    </div>
  );
};

export default TransferPatients;
