import React from "react";
import { useNavigate } from "react-router";
import "./../sass/navComponent.scss";
const Navcomponent = () => {
  const navigate = useNavigate();
  const path = window.location.pathname;
  const s = path.split("/");
  console.log(s);
  const newArray = s.filter((item) => item !== "");

  const navFun = (el) => {
    if (el === s[s.length - 1]) return;
    if (el === "clinical") {
      navigate("/dashboard");
      return;
    }
    const indexOfElement = s.indexOf(el);
    const elementsBeforeElement = s.slice(0, indexOfElement + 1);
    const url = elementsBeforeElement.join("/");
    navigate(url);
  };
  const navHome = () => {
    navigate("/dashboard");
  };
  return (
    <div className="navComponent">
      <div className="navContent">
        <p className="link" onClick={navHome}>
          &nbsp;&nbsp;/&nbsp;&nbsp;Home
        </p>

        {s[s.length - 1] === "dashboard"
          ? ""
          : newArray.map((el) => (
              <p className="link" onClick={() => navFun(el)}>
                &nbsp;&nbsp;/&nbsp;&nbsp;{el.replace(/-/g, " ")}
              </p>
            ))}
      </div>
    </div>
  );
};

export default Navcomponent;
