import { createSlice } from "@reduxjs/toolkit";

// export const tabChildrenList = ["Basics", "Billing", "Schedule"];
export const tabChildrenList = ["Basics"];

const initialState = {
  valid: false,
  currentTab: tabChildrenList[0],
  isValidData: {
    NPI: false,
    FacilityName: false,
    FacilityType: false,
    BusinessType: false,
    phone: false,
    TimeZone: false,
    Fax: false,
    EffectiveDate: false,
    WorkingDays: [false, false, false, false],
    Notes: false,
    street1: false,
    street2: false,
    postalCode: false,
    city: false,
    state: false,
  },
  data: {
    NPI: "",
    FacilityName: "",
    FacilityType: "",
    BusinessType: "",
    phone: "",
    TimeZone: "",
    Fax: "",
    EffectiveDate: new Date().toISOString().slice(0, 10),
    WorkingDays: ["", "", "", ""],
    Notes: "",
    street1: "",
    street2: "",
    postalCode: "",
    city: "",
    state: "",
  },
  basicDataValidation: false,
};

const facilityDataSlice = createSlice({
  name: "facilityData",
  initialState: initialState,
  reducers: {
    updateCurrentTab(state, action) {
      state.currentTab = action.payload;
    },
    updateData(state, action) {
      const { fieldName, value, validityStatus } = action.payload;
      state.data[fieldName] = value;
      state.isValidData[fieldName] = validityStatus;
      state.basicDataValidation = Object.keys(state.isValidData).reduce(
        (prev, curr) => prev && state.isValidData[curr],
        true
      );
    },
    updateWorkingData(state, action) {
      const { name, index, value, validityStatus } = action.payload;
      state.data[name][[index]] = value;
      state.isValidData[name][[index]] = validityStatus;
      state.basicDataValidation = Object.keys(state.isValidData).reduce(
        (prev, curr) => prev && state.isValidData[curr],
        true
      );
    },
    resetData(state, action) {
      Object.keys(initialState).forEach((el) => {
        state[el] = initialState[el];
      });
    },
  },
});

export const facilityDataActions = facilityDataSlice.actions;

export default facilityDataSlice.reducer;
