import { createSlice } from "@reduxjs/toolkit";

const initialState = { openModal: false, notesopenModal: false };
const callingPageDataSlice = createSlice({
  name: "callingPageData",
  initialState: initialState,
  reducers: {
    closeModal(state, action) {
      state.openModal = false;
    },
    openModal(state, action) {
      state.openModal = true;
    },
    closeModalnotes(state, action) {
      state.notesopenModal = false;
    },
    openModalnotes(state, action) {
      state.notesopenModal = true;
    },
  },
});

export const callingPageDataActions = callingPageDataSlice.actions;

export default callingPageDataSlice.reducer;
