import React, { useState } from "react";
import { Icon } from "@iconify/react";
import OutsideClickHandler from "react-outside-click-handler";
import "../sass/dropDownSelectorComponent.scss";

function DropDownSelectorComponent(props) {
  const [showOptions, setShowOptions] = useState(false);

  const toggleShow = () => {
    setShowOptions((prev) => !prev);
  };
  // nthi

  const [selectedOption, setSelectedOption] = useState();
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const name = props.selectorName;
  const id = props.selectorValue;
  const apiName = props.apiName;
  // console.log(apiName)

  return (
    <div className="dropDownSelectorContainer">
      <div className="selector">
        {/* <select  >
        <option className='default' value="">{props.placeHolder ?? ""}</option>
        {props.options.map(el=>(
        <option value=""><div className='options'> {el}</div></option>

        )
        
        )}
      </select> */}
        <OutsideClickHandler
          onOutsideClick={() => {
            setShowOptions(false);
          }}
        >
          <div className="title" onClick={toggleShow}>
            <p> {props.placeHolder}</p>

            {showOptions ? (
              <button
                onClick={() => props.onClickSave(apiName, selectedOption)}
              >
                Save
              </button>
            ) : (
              <Icon
                className="icon"
                height={20}
                icon="material-symbols:edit-square-outline"
                color="#7a5dda"
              />
            )}
          </div>
          <div className="details">
            {showOptions ? (
              <select value={selectedOption} onChange={handleSelectChange}>
                <option className="default" value=""></option>
                {props.optionsDictionay?.map((el) => (
                  //  console.log(props.optionsDictionay)
                  <option key={el[id]} className="options" value={el[id]}>
                    {el[name]}
                  </option>
                ))}{" "}
              </select>
            ) : (
              <p className="name">{props.name}</p>
            )}
          </div>
        </OutsideClickHandler>
      </div>
    </div>
  );
}

export default DropDownSelectorComponent;

//   <div className="select">
//     <div className="title" onClick={toggleShow}>
//       {props.placeHolder}
//       <Icon
//   className="icon"
//   icon="material-symbols:edit-square-outline"
//   color="#7a5dda"
// />
//     </div>

//     {showOptions && (
//       <div className="optionList">
//         {props.options.map((el) => (
//           <div className="options" onClick={() => {}}>

//             {el}
//           </div>
//         ))}
//       </div>
//     )}
//   </div>
