import React, { useEffect } from "react";
import "./listEligiblePatients.scss";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import RemarksViewer from "../components/RemarksViewer";
import AddPatient from "../patients/parientsPreviewPage/addPatientComponent/AddPatient";

import EligiblePatientsFilter from "./EligiblePatientsFilter";
import { useUpdatePatientsMutation } from "../app/api/patientsApi";

import { useGetListAllEligiblePatientsQuery } from "../app/api/patientsApi";
import { useState } from "react";
const ListEligiblePatients = () => {
  const { data: listOfEligiblePatients } = useGetListAllEligiblePatientsQuery();
  const [eligiblePatientsData, setEligiblePatientsData] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState("");
  const [id, setId] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [updateCallValue, setUpdateValue] = useState();
  const [triggerEffect, setTriggerEffect] = useState(false);
  const navigate = useNavigate();

  const [updatePatientfn, { data: updatedData }] = useUpdatePatientsMutation();
  const changeStatus = (patient, pgm) => {
    setId(patient?.PatientId);
    setSelectedData(patient);
    if (pgm) {
      setUpdateValue(pgm);
      setTriggerEffect(true);
    }
    setPopUpOpen(true);
  };
  useEffect(() => {
    if (!listOfEligiblePatients) return;
    setEligiblePatientsData(listOfEligiblePatients);
  }, [listOfEligiblePatients]);

  return (
    <div className="ListEligiblePatients">
      <AddPatient
        selectedData={selectedData}
        updatePatientfn={updatePatientfn}
        id={id}
        triggerEffect={triggerEffect}
        setTriggerEffect={setTriggerEffect}
        updateCallValue={updateCallValue}
        setUpdateValue={setUpdateValue}
        popUpOpen={popUpOpen}
        setPopUpOpen={setPopUpOpen}
      />
      <p className="title">List Eligible Patient & Program</p>
      <EligiblePatientsFilter
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        eligiblePatientsData={eligiblePatientsData}
      />
      <div className="body">
        <p className="dataFile">
          Data File Batch Id: june20231_8789786545_06-14-2023_13:56:02_EST
        </p>
        <div className="buttonsContainer">
          <button onClick={() => navigate("/patients/Add-Patient")}>
            <Icon icon="typcn:plus" width="25" height="25" />
            Enroll Patient & Program
          </button>
        </div>
        <div className="eligibleTable">
          <div className="head colSpan1">S.No</div>
          <div className="head colSpan3">Patient Name</div>
          <div className="head colSpan2"> Date of Birth</div>
          <div className="head colSpan2">Patient Status</div>
          <div className="head colSpan2">Program</div>
          <div className="head colSpan2">Program Status</div>
          <div className="head colSpan6">Validation Message</div>
          <div className="head colSpan2">Action</div>
          {filteredData?.map((patient, index) => (
            <>
              <div className="cntnt colSpan1">{index + 1}</div>
              <div className="cntnt colSpan3">
                <div className="patient">
                  <p>{patient.Name}</p>
                  <p>
                    <span>Preferred Language: </span>
                    {patient?.["Preferred Language"]}
                  </p>
                  <p>
                    <span>Facility: </span> {patient?.FacilityId?.FacilityName}
                  </p>
                  <p>
                    <span>Care Plan Physician: </span>
                    {patient?.ProviderId?.ProviderName}
                  </p>
                  <p>
                    <span>Primary Care Coordinator: </span>
                    {patient?.PrimaryCoordinator?.ClinicianName}
                  </p>
                </div>
              </div>
              <div className="cntnt colSpan2"> {patient["Date Of Birth"]}</div>
              <div className="cntnt colSpan2">Patient Status</div>
              <div className="cntnt pgmcontainer colSpan4">
                {patient.enrolledPrograms?.map((pgm) =>
                  pgm.ProgramStatus === "Consent Received" ? (
                    <>
                      <span className="pgm disabled ">{pgm.ProgramName}</span>
                      <span className="pgm disabled">Enrolled</span>
                    </>
                  ) : (
                    <>
                      <span className="pgm">{pgm.ProgramName}</span>
                      <span className="inp">
                        {/* <input
                      value={pgm.ProgramStatus}
                      onClick={() => changeStatus(patient, pgm)}
                    /> */}
                        <button
                          // value={pgm.ProgramStatus}
                          onClick={() => changeStatus(patient, pgm)}
                        >
                          <p>{pgm.ProgramStatus}</p>
                          <Icon icon="ep:arrow-down" />
                        </button>
                      </span>
                    </>
                  )
                )}
                <div className="btnWrap colSpan2">
                  <button onClick={() => changeStatus(patient, null)}>
                    <Icon icon="ic:round-plus" />
                    Add Program
                  </button>
                </div>
              </div>

              <div className="cntnt colSpan6">Validation Message</div>
              <div className="cntnt colSpan2">
                <span className="action">
                  <RemarksViewer
                    content={{
                      PatientName: patient?.Name,
                      remarks: patient?.enrolledPrograms?.map((el) => ({
                        service: el?.ProgramName,
                        remark:
                          el?.ConcernReceivedNotes ??
                          el?.FollowUpNotes ??
                          el?.NotInterestedReason ??
                          el?.EligibleNotes ??
                          "",
                      })),
                    }}
                  />

                  {/* <button>
                    <Icon icon="ion:call" />
                  </button> */}
                </span>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ListEligiblePatients;
