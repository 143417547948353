import React from "react";

const DownloadCSVButton = () => {
  const handleDownload = () => {
    const csvData = [
      [
        "PatientEHRId",
        "DOB",
        "FirstName",
        "LastName",
        "Gender",
        "street1",
        "street2",
        "city",
        "state",
        "postalCode",
        "phone",
        "homePhone",
        "PreferredLanguage",
        "InsuranceCompany1",
        "insuranceID1",
        "InsuranceCompany2",
        "insuranceID2",
        "Program1",
        "Program2",
        "Program3",
        "Program4",
      ],
      [
        "10000",
        "01/21/1999",
        "Sam",
        "S",
        "Male",
        "123 main road",
        "2nd street",
        "",
        "",
        "65741",
        "9048333800",
        "4942683409",
        "English",
        "",
        "",
        "",
        "",
        "CCM",
        "RPM",
        "",
        "",
      ],
    ];

    const csvContent = csvData.map((e) => e.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;

    link.download = "template.csv";

    document.body.appendChild(link);

    link.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  return <button onClick={handleDownload}>Download CSV template</button>;
};

export default DownloadCSVButton;
