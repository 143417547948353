import { useEffect, forwardRef, useImperativeHandle } from "react";
import "./loginInputTextField.scss";
import classNames from "classnames";
import useLoginInputTextbox from "../hooks/use-loginInputTextbox";

const LoginInputTextboxField1 = forwardRef((props, ref) => {
  const { value, hasError, isValid, valueChangeHandler, reset, submitClicked } =
    useLoginInputTextbox(
      props.validation,
      props?.charValidation ??
        (() => {
          return true;
        })
    );

  useImperativeHandle(ref, () => ({
    reset_data() {
      console.log("reset_data called");
      reset();
    },
    submitClick() {
      console.log("Submit clicked");

      submitClicked();
    },
  }));

  useEffect(() => {
    props.storeData(value, isValid);
  }, [value, isValid]);
  // console.log(props.title, hasError, isValid);

  return (
    <div className="loginInputTextFieldContainer">
      <label
        className={classNames("loginInputTextField1", props.className, {
          invalid: hasError,
        })}
      >
        <div className="title">{props.title}</div>
        <input
          type={props.type ?? "text"}
          value={value}
          onChange={valueChangeHandler}
          // placeholder={props.placeholder ?? props.title}
          autoFocus={props.autoFocus ?? false}
        />
      </label>
    </div>
  );
});

export default LoginInputTextboxField1;
