import React, { useState, useEffect, useCallback, useRef } from "react";
import InputTextField1 from "../../widgets/InputTextField1";
import InputTextArea1 from "../../widgets/InputTextArea1";
import InputCheckField1 from "../../widgets/InputCheckField1";
import DatePickerField1 from "../../widgets/DatePickerField1";
import ButtonComponent from "../../components/ButtonComponent";
import FloatingNotifications from "../../utilities/FloatingNotifications";
import {
  useProviderCreationMutation,
  useUpdateProviderMutation,
} from "../../app/api/providerApi";
import TechnicianAlotmentTab from "../../components/TechnicianAlotmentTab";
import InputSelectorField1 from "../../widgets/InputSelectorField1";
import { useGetProviderAccessLevelQuery } from "../../app/api/providerAccessLevelApi";
import {
  isNotEmpty,
  alwaysValidated,
  noCharValidateCheck,
  selectorValidateCheck,
  dateValidateCheck,
  phNoValidation,
  emailValidation,
  phNoCharValidation,
} from "../../utilities/validationFunction";
import { providerDataActions } from "../../app/features/providerDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { useGetSelectedProviderMutation } from "../../app/api/providerApi";
import ProviderPreviewPage from "./ProviderPreviewPage";
import "./provider.scss";
import { Icon } from "@iconify/react";
import IconButton from "@mui/material/IconButton";
import { useLocation } from "react-router-dom";

const error_message = {
  email: "email should not be empty",
  NPI: "NPI should not be empty",
  FirstName: "First name should not be empty",
  MiddleName: "Middle name should not be empty",
  LastName: "Last name should not be empty",
  phone: "Phone no should not be empty",
  TimeZone: "TimeZone should not be empty",
  workPhone: "Workphone should not be empty",
  EffectiveDate: "EffectiveDate should not be empty",
  PrimaryCommunication: "PrimaryCommunication should not be empty",
  Notes: "Notes should not be empty",
  Type: "Type should not be empty",
  Products: "Products should not be empty",
  rpmAlert: "rpmAlert should not be empty",
  mobileAccess: "mobileAccess should not be empty",
  webAccess: "webAccess should not be empty",
};

const Provider = () => {
  const location = useLocation();
  const [id, setId] = useState(location?.state?.id);
  const [type, setType] = useState(location?.state?.type);

  const dispatch = useDispatch();
  const providerData = useSelector((state) => state.providerData);
  const storedData = localStorage?.getItem("RLH_user");
  const createdDateAndTime = new Date();
  const { data: inputData, isValidData } = providerData;
  const [accessDataFacility, setAccessDataFacility] = useState();

  const { data: rawAccessLevelData } = useGetProviderAccessLevelQuery();

  const [createProvider, { data, error, isLoading }] =
    useProviderCreationMutation();

  const [
    updateProvider,
    { data: updateData, error: updateError, isLoading: updateIsLoading },
  ] = useUpdateProviderMutation();

  const [selectedProviderCallFn, { data: selectedProvider }] =
    useGetSelectedProviderMutation();

  const [resetRefs, setResetRefs] = useState({
    email: useRef(null),
    NPI: useRef(null),
    FirstName: useRef(null),
    MiddleName: useRef(null),
    LastName: useRef(null),
    phone: useRef(null),
    TimeZone: useRef(null),
    workPhone: useRef(null),
    EffectiveDate: useRef(null),
    PrimaryCommunication: useRef(null),
    Notes: useRef(null),
    Type: useRef(null),
    Products: useRef(null),
    rpmAlert: useRef(null),
    webAccess: useRef(null),
  });

  const storeData = useCallback((fieldName, value, validityStatus) => {
    dispatch(
      providerDataActions.updateData({ fieldName, value, validityStatus })
    );
  }, []);

  useEffect(() => {
    if (!rawAccessLevelData) return;
    if (selectedProvider) return;
    setAccessDataFacility(rawAccessLevelData);
  }, [rawAccessLevelData]);

  const InitData = () => {
    Object.keys(resetRefs).forEach((value) => {
      // if (value === "insuranceCompany") return;
      if (selectedProvider[value]) {
        resetRefs[value].current?.init_data?.(selectedProvider[value]);
      }
    });
  };

  const onClick = () => {
    Object.keys(resetRefs).forEach((value) => {
      resetRefs[value].current.submit();
    });
    const valid = Object.keys(isValidData).reduce(
      (prev, curr) => prev && isValidData[curr],
      true
    );
    if (!valid) return;
    let accessDataFacilityUpdated = accessDataFacility.filter(
      (object) => object.Selected === true
    );
    if (selectedProvider) {
      updateProvider({
        ProviderId: id,
        FacilityList: accessDataFacilityUpdated,
        ...inputData,
      });
    } else {
      createProvider({ ...inputData, FacilityList: accessDataFacilityUpdated });
    }
  };
  const onClickReset = () => {
    resetBasicInfo();
  };

  useEffect(() => {
    if (!selectedProvider) return;
    Object.keys(resetRefs).forEach((value) => {
      if (selectedProvider[value]) {
        resetRefs[value]?.current?.init_data?.(selectedProvider[value]);
      }
    });
    setAccessDataFacility(selectedProvider.FacilityList);
  }, [resetRefs, selectedProvider]);

  const resetBasicInfo = () => {
    Object.keys(resetRefs).forEach((value) => {
      resetRefs[value].current.reset_data();
    });
    setAccessDataFacility(rawAccessLevelData);
    resetRefs["EffectiveDate"]?.current?.init_data?.(new Date());
    resetRefs["Products"]?.current?.init_data?.("CCM");
  };

  useEffect(() => {
    if (!data) return;
    FloatingNotifications("success", "Provider creation successful");
    resetBasicInfo();
  }, [data]);

  useEffect(() => {
    if (!updateData) return;
    FloatingNotifications("success", "Facility updation successful");
    resetBasicInfo();
  }, [updateData]);

  useEffect(() => {
    if (!id) return;
    console.log("id:", id);

    selectedProviderCallFn({
      ProviderId: id,
    });
  }, [id, selectedProviderCallFn]);

  useEffect(() => {
    if (!selectedProvider) return;
    InitData();
  }, [selectedProvider]);

  useEffect(() => {
    if (selectedProvider) return;
    resetRefs["EffectiveDate"]?.current?.init_data?.(new Date());
    resetRefs["Products"]?.current?.init_data?.("CCM");
  }, []);

  useEffect(() => {
    if (!error || !updateError) return;
    if (error) {
      FloatingNotifications("error", error?.data?.message);
    } else {
      FloatingNotifications("error", updateError?.data?.message);
    }
  }, [error, updateError]);

  return (
    <div className="provider">
      <div className="providerContainer">
        <div className="pageTitle">Provider</div>

        <>
          <div className="providerContainer1">
            <InputTextField1
              disabled={type === "View"}
              tabIndex="1"
              element={"NPI"}
              ref={resetRefs["NPI"]}
              containerClassName="colSpan2"
              title="NPI"
              superTitle="*"
              validation={isNotEmpty}
              charValidate={noCharValidateCheck}
              autoFocus={true}
              error_message={error_message["NPI"]}
              storeData={storeData.bind(null, "NPI")}
            />
            <InputTextField1
              disabled={type === "View"}
              tabIndex="1"
              element={"FirstName"}
              ref={resetRefs["FirstName"]}
              containerClassName="colSpan4"
              title="First Name"
              superTitle="*"
              validation={isNotEmpty}
              charValidate={noCharValidateCheck}
              error_message={error_message["FirstName"]}
              storeData={storeData.bind(null, "FirstName")}
            />
            <InputTextField1
              disabled={type === "View"}
              tabIndex="1"
              element={"MiddleName"}
              ref={resetRefs["MiddleName"]}
              containerClassName="colSpan3"
              title="Middle Name"
              validation={alwaysValidated}
              charValidate={noCharValidateCheck}
              error_message={error_message["MiddleName"]}
              storeData={storeData.bind(null, "MiddleName")}
            />
            <InputTextField1
              disabled={type === "View"}
              tabIndex="1"
              element={"LastName"}
              ref={resetRefs["LastName"]}
              containerClassName="colSpan3"
              title="Last Name"
              superTitle="*"
              validation={isNotEmpty}
              charValidate={noCharValidateCheck}
              error_message={error_message["LastName"]}
              storeData={storeData.bind(null, "LastName")}
            />
            <InputTextField1
              disabled={type === "View"}
              element={"email"}
              tabIndex="1"
              ref={resetRefs["email"]}
              containerClassName="colSpan3"
              title="Email"
              superTitle="*"
              validation={emailValidation}
              charValidate={noCharValidateCheck}
              error_message={error_message["email"]}
              storeData={storeData.bind(null, "email")}
            />
            <InputTextField1
              disabled={type === "View"}
              element={"workPhone"}
              tabIndex="1"
              ref={resetRefs["workPhone"]}
              containerClassName="colSpan2"
              title="Work Phone"
              validation={alwaysValidated}
              charValidate={phNoCharValidation}
              error_message={error_message["workPhone"]}
              storeData={storeData.bind(null, "workPhone")}
            />
            <InputTextField1
              disabled={type === "View"}
              element={"phone"}
              tabIndex="1"
              ref={resetRefs["phone"]}
              containerClassName="colSpan2"
              title="Mobile Phone"
              validation={alwaysValidated}
              charValidate={phNoCharValidation}
              error_message={error_message["phone"]}
              storeData={storeData.bind(null, "phone")}
            />
            <InputSelectorField1
              disabled={type === "View"}
              element={"Type"}
              tabIndex="1"
              ref={resetRefs["Type"]}
              containerClassName="colSpan3"
              title="Type"
              superTitle="*"
              optionsArray={["General physician", "specialist"]}
              validation={selectorValidateCheck}
              charValidate={noCharValidateCheck}
              error_message={error_message["Type"]}
              storeData={storeData.bind(null, "Type")}
            />
            <InputSelectorField1
              disabled={type === "View"}
              element={"TimeZone"}
              tabIndex="1"
              ref={resetRefs["TimeZone"]}
              containerClassName="colSpan2"
              title="TimeZone"
              optionsArray={[
                "Eastern Time",
                "Central Time",
                "Mountain Time",
                "Arizona Time(No Daylight Saving)",
                "Pacific Time",
                "Alaska Time",
                "Hawai Time",
              ]}
              validation={alwaysValidated}
              error_message={error_message["TimeZone"]}
              storeData={storeData.bind(null, "TimeZone")}
            />

            <InputSelectorField1
              disabled={type === "View"}
              element={"PrimaryCommunication"}
              tabIndex="1"
              ref={resetRefs["PrimaryCommunication"]}
              containerClassName="colSpan4"
              title="Preferred Communication "
              optionsArray={["Mobile Phone", "Work Phone", "email"]}
              superTitle="*"
              validation={selectorValidateCheck}
              error_message={error_message["PrimaryCommunication"]}
              storeData={storeData.bind(null, "PrimaryCommunication")}
            />
            <InputSelectorField1
              disabled={type === "View"}
              element={"Products"}
              tabIndex="1"
              ref={resetRefs["Products"]}
              containerClassName="colSpan3"
              title="Products"
              superTitle="*"
              optionsArray={["CCM", "RPM"]}
              validation={selectorValidateCheck}
              error_message={error_message["Products"]}
              storeData={storeData.bind(null, "Products")}
            />
            <DatePickerField1
              disabled={type === "View"}
              element={"EffectiveDate"}
              tabIndex="1"
              ref={resetRefs["EffectiveDate"]}
              containerClassName="colSpan2"
              title="Effective Date"
              superTitle="*"
              validation={dateValidateCheck}
              error_message={error_message["EffectiveDate"]}
              storeData={storeData.bind(null, "EffectiveDate")}
            />
            <InputSelectorField1
              disabled={type === "View"}
              element={"rpmAlert"}
              tabIndex="1"
              ref={resetRefs["rpmAlert"]}
              containerClassName="colSpan4"
              title="RPM Alert"
              optionsArray={["All Patients", "Assigned Patients"]}
              superTitle="*"
              validation={selectorValidateCheck}
              error_message={error_message["rpmAlert"]}
              storeData={storeData.bind(null, "rpmAlert")}
            />
          </div>
          <div className="providerContainer2">
            <div className="colSpan1 rowSpan3">
              <TechnicianAlotmentTab
                title="Facility"
                accessData={accessDataFacility}
                setAccessData={setAccessDataFacility}
                idKey={"FacilityId"}
                nameKey={"FacilityName"}
                disabled={type === "View"}
              />
            </div>
            {/* <div className="rowSpan3"></div> */}
            <InputTextArea1
              disabled={type === "View"}
              element={"Notes"}
              tabIndex="1"
              ref={resetRefs["Notes"]}
              title="Notes"
              heightMultiplier={3}
              validation={alwaysValidated}
              charValidate={noCharValidateCheck}
              error_message={error_message["Notes"]}
              storeData={storeData.bind(null, "Notes")}
            />
            <div></div>
            {/* <InputCheckField1
              disabled={type === "View"}
              element={"mobileAccess"}
              tabIndex="1"
              ref={resetRefs["mobileAccess"]}
              containerClassName="ase"
              title="RPM Alert"
              text={"Enable Mobile Access"}
              validation={alwaysValidated}
              error_message={error_message["mobileAccess"]}
              storeData={storeData.bind(null, "mobileAccess")}
            /> */}
            <InputCheckField1
              disabled={type === "View"}
              element={"webAccess"}
              tabIndex="1"
              ref={resetRefs["webAccess"]}
              containerClassName="ase"
              // title="RPM Alert"
              text={"Enable web Access"}
              validation={alwaysValidated}
              error_message={error_message["webAccess"]}
              storeData={storeData.bind(null, "webAccess")}
            />
            <div className="created">
              <p>createdBy: {storedData}</p>
              <p>createdDate: {createdDateAndTime?.toString().slice(0, 24)}</p>
            </div>
          </div>
          <div className="btnWrap">
            <ButtonComponent
              tabIndex="2"
              containerClassName="buttonContainer"
              text="Save"
              onClick={onClick}
              disabled={type === "View" || isLoading}
            />
            <ButtonComponent
              tabIndex="2"
              containerClassName="buttonContainer"
              text="Reset"
              onClick={onClickReset}
              disabled={type === "View" || isLoading}
            />
          </div>
        </>
      </div>
    </div>
  );
};

export default Provider;
