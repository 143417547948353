import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import "./sideBarSectionContainer.scss";
import { FOLD } from "../app/features/navigationSlice";
import { useSelector, useDispatch } from "react-redux";
import { navigationActions } from "../app/features/navigationSlice";
import { ReactComponent as Arrow } from "../images/sideBar/arrow-forward.svg";
const SideBarSectionContainer = ({
  title,
  // openedSection,
  type,
  onClick,
  children,
  Icon,
}) => {
  const dispatch = useDispatch();
  const navBarState = useSelector((state) => state.navigationData.navBarOpen);
  const openedSection = useSelector(
    (state) => state.navigationData.openedSection
  );

  const onTitleClick = () => {
    if (title === openedSection[type]) return;
    dispatch(navigationActions.updateOpenedSection({ type, data: title }));
    // onClick(type, title);
  };

  return (
    <>
      <div
        className={classNames("sideBarSectionContainer", {
          "first-fold-props": type === FOLD[1],
          "second-fold-props": type === FOLD[2],
          currentlySelected: openedSection[type] === title,
        })}
        onMouseEnter={onTitleClick}
      >
        <div className="titleContainer">
          <div className="title">
            {Icon && <Icon className="icon" />}
            <div>{title}</div>
          </div>
          <Arrow />
        </div>
        {openedSection[type] === title && (
          <div
            className={classNames("sideBarSectionContainer_children", {
              hidden: openedSection[type] !== title,
            })}
          >
            {children}
          </div>
        )}
      </div>
    </>
  );
};

export default SideBarSectionContainer;
