import React from "react";

const CheckBoxComponent = ({ question, CheckBoxFn, id, value }) => {
  return (
    <div className="inputComponent">
      {question.options.map((option, key) => (
        <span>
          <input
            type="checkBox"
            id={`${id}-${option}`}
            onChange={(e) => CheckBoxFn(option, e.target.checked)}
            checked={value.includes(option)}
          />
          <label htmlFor={`${id}-${option}`}>{option}</label>
        </span>
      ))}
    </div>
  );
};

export default CheckBoxComponent;
