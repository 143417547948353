import React from "react";
import { useState, useEffect } from "react";
import DropDownContent from "../../../components/DropDownContent";
import ActiveHistoryDropDown2 from "../../../components/ActiveHistoryDropDown2";
import AddDetailGeneral from "../addDetailsGeneral/AddDetailGeneral";
import SelectWithSearch from "../../searchandselect component/SearchAndSelect";
import CarePlanDisplayGeneral from "../CarePlanDisplayGeneral/CarePlanDisplayGeneral";
import TabElement from "../../../components/TabElement";
import FloatingNotifications from "../../../utilities/FloatingNotifications";
import TabWidget from "../../../components/TabWidget";
import {
  resetfn,
  basicValidation,
  areArraysSame,
  careplanview,
  generalHandleValueChanges,
  generalSaveButton,
  cancleOperationFn,
  statusfinderFn,
} from "../helperfunctions";
import CarePlanErrorMessage from "../CarePlanErrorMessage";
const AlleryList = ["Drug", "Environment", "Food", "Animal", "Other"];
const Allergies = (props) => {
  const { selectedPatient, id, updateDatafn } = props;
  const [active, setActive] = useState("Active");

  const [allergiesWindow, setAllergiesWindow] = useState("");
  const addAllergiesDetail = () => {
    setAllergiesWindow("addDetails");
    resetfn(allergies, setAllergies);
  };
  const [allergiesArray, setAllergiesArray] = useState([]);
  const [editArray, setEditArray] = useState();

  const [allergies, setAllergies] = useState({
    AllergyType: "",
    Reaction: "",
    Severity: "",
    AllergySubtype: "",
    ScheduledDate: "",
    CompletedDate: "",
    Recommendations: "",
    Status: true,
  });
  const onClickFn = () => {
    if (basicValidation(allergies)) {
      generalSaveButton(
        allergiesWindow,
        setAllergiesWindow,
        allergies,
        setAllergiesArray,
        editArray,
        setEditArray
      );
    } else {
      FloatingNotifications("error", "Fill mandatory fields to proceed");
    }
  };
  useEffect(() => {
    if (
      basicValidation(allergies) &&
      !areArraysSame(selectedPatient?.Allergies, allergiesArray)
    ) {
      updateDatafn({ PatientId: id, Allergies: allergiesArray });
    }
  }, [allergiesArray]);

  useEffect(() => {
    if (selectedPatient) {
      setAllergiesArray(selectedPatient.Allergies);
    }
  }, [selectedPatient]);
  return (
    <div>
      <DropDownContent
        title={"Allergies"}
        children={
          <div>
            <TabWidget>
              {["Active", "History"]?.map((el) => (
                <TabElement
                  key={`patients_${el}`}
                  title={el}
                  active={active === el}
                  onClick={() => {
                    setActive(el);
                  }}
                />
              ))}
            </TabWidget>
            {active === "Active" && (
              <ActiveHistoryDropDown2
                type={"Active"}
                iconsList={[
                  {
                    value: "ic:twotone-plus",
                    onClickFn: addAllergiesDetail,
                  },
                  {
                    value: "mingcute:save-line",
                    onClickFn:
                      allergiesWindow === "addDetails"
                        ? () => onClickFn()
                        : false,
                  },
                  {
                    value: "majesticons:multiply-line",
                    onClickFn: () => cancleOperationFn(setAllergiesWindow),
                  },
                ]}
              >
                {" "}
                <div>
                  {allergiesWindow === "addDetails" ? (
                    <AddDetailGeneral data={selectedPatient}>
                      <>
                        <p className="displayTitleGnrl">
                          Allergies Information
                        </p>
                        <div className="display-sec-Gnrl">
                          <div className="display-content-Gnrl">
                            <label className="display-element-Gnrl">
                              Allergy Type*
                            </label>
                            <SelectWithSearch
                              options={AlleryList}
                              valuedata={allergies.AllergyType}
                              fn={generalHandleValueChanges}
                              setvalue={setAllergies}
                              name={"AllergyType"}
                            />
                            {/* <select
                          className="display-element-Gnrl"
                          value={allergies.AllergyType}
                          onChange={(event) =>
                            generalHandleValueChanges(
                              event,
                              setAllergies,
                              "AllergyType"
                            )
                          }
                        >
                          <option className="default" value=""></option>
                          {AlleryList.map((el) => (
                            <option value={el}>{el}</option>
                          ))}
                        </select> */}
                            <label className="display-element-Gnrl">
                              Allergy Subtype*
                            </label>
                            <input
                              type="input"
                              className="display-element-Gnrl "
                              value={allergies.AllergySubtype}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setAllergies,
                                  "AllergySubtype"
                                )
                              }
                            />

                            <label className="display-element-Gnrl">
                              Reaction*
                            </label>
                            <input
                              type="input"
                              className="display-element-Gnrl "
                              value={allergies.Reaction}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setAllergies,
                                  "Reaction"
                                )
                              }
                            />
                            <label className="display-element-Gnrl">
                              Severity*
                            </label>
                            <input
                              className="display-element-Gnrl "
                              type="input"
                              value={allergies.Severity}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setAllergies,
                                  "Severity"
                                )
                              }
                            />
                          </div>
                          <div className="display-content-Gnrl">
                            <label className="display-element-Gnrl">
                              Scheduled Date & Time*
                            </label>
                            <input
                              className="display-element-Gnrl "
                              type="datetime-local"
                              id="meeting-time"
                              name="meeting-time"
                              value={allergies.ScheduledDate?.slice(0, 16)}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setAllergies,
                                  "ScheduledDate"
                                )
                              }
                            />
                            <label className="display-element-Gnrl">
                              Completed Date & Time*
                            </label>
                            <input
                              className="display-element-Gnrl "
                              type="datetime-local"
                              id="meeting-time"
                              name="meeting-time"
                              value={allergies.CompletedDate?.slice(0, 16)}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setAllergies,
                                  "CompletedDate"
                                )
                              }
                            />

                            <label className="display-element-Gnrl">
                              Description*
                            </label>
                            <div className="displayDescription">
                              <textarea
                                className="display-element-Gnrl"
                                value={allergies.Recommendations}
                                onChange={(event) =>
                                  generalHandleValueChanges(
                                    event,
                                    setAllergies,
                                    "Recommendations"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </>{" "}
                    </AddDetailGeneral>
                  ) : (
                    <>
                      {statusfinderFn("active", selectedPatient.Allergies)
                        ?.length === 0 ? (
                        <CarePlanErrorMessage msg="Patient doesn’t have any active Allergies yet!" />
                      ) : (
                        <CarePlanDisplayGeneral
                          updateDatafn={updateDatafn}
                          name={"Allergies"}
                          array={allergiesArray}
                          id={id}
                          careplanview={careplanview}
                          values={[setAllergiesWindow, setAllergies]}
                          data={statusfinderFn(
                            "active",
                            selectedPatient.Allergies
                          )}
                          dataSet={selectedPatient?.Allergies ?? []}
                          setEditArray={setEditArray}
                          contentArray={[
                            ["Allergy Type:", "AllergyType", "colSpan6"],
                            ["Reaction:", "Reaction", "colSpan6"],
                            ["Allergy Subtype:", "AllergySubtype", "colSpan6"],
                            ["Severity:", "Severity", "colSpan6"],
                            [
                              "Scheduled Date & Time:",
                              "ScheduledDate",
                              "colSpan6",
                            ],
                            [
                              "Completed Date & Time:",
                              "CompletedDate",
                              "colSpan6",
                            ],
                            [
                              "Recommendations:",
                              "Recommendations",
                              "colSpan12",
                            ],
                          ]}
                        />
                      )}
                    </>
                  )}
                </div>
              </ActiveHistoryDropDown2>
            )}
            {active === "History" && (
              <ActiveHistoryDropDown2 type={"History"}>
                <>
                  {statusfinderFn("history", selectedPatient.Allergies)
                    ?.length === 0 ? (
                    <></>
                  ) : (
                    <CarePlanDisplayGeneral
                      Type={"history"}
                      data={statusfinderFn(
                        "history",
                        selectedPatient.Allergies
                      )}
                      contentArray={[
                        ["Allergy Type:", "AllergyType", "colSpan6"],
                        ["Reaction:", "Reaction", "colSpan6"],
                        ["Allergy Subtype:", "AllergySubtype", "colSpan6"],
                        ["Severity:", "Severity", "colSpan6"],
                        ["Scheduled Date & Time:", "ScheduledDate", "colSpan6"],
                        ["Completed Date & Time:", "CompletedDate", "colSpan6"],
                        ["Recommendations:", "Recommendations", "colSpan12"],
                      ]}
                    />
                  )}
                </>
              </ActiveHistoryDropDown2>
            )}
          </div>
        }
      />
    </div>
  );
};

export default Allergies;
