import React, { useState, useEffect } from "react";
import { useGetPatientsOptionsQuery } from "../app/api/patientSelctorOptionApi";
import "./eligiblePatientsFilter.scss";
import { generalHandleValueChanges } from "../patients/Careteam/helperfunctions";
import SelectComponent from "../components/SelectComponent";
const EligiblePatientsFilter = ({
  setFilteredData,
  filteredData,
  eligiblePatientsData,
}) => {
  const { data: fpcList } = useGetPatientsOptionsQuery();
  const FacilityList =
    fpcList?.FacilityList?.map((facility) => [
      facility.FacilityName,
      facility.FacilityId,
    ]) ?? [];
  FacilityList.unshift(["All", ""]);
  const ProviderList =
    fpcList?.ProviderList?.map((provider) => [
      provider.ProviderName,
      provider.ProviderId,
    ]) ?? [];
  ProviderList.unshift(["All", ""]);
  const ClinicianList =
    fpcList?.ClinicianList?.map((clinician) => [
      clinician.ClinicianName,
      clinician.ClinicianId,
    ]) ?? [];
  ClinicianList.unshift(["All", ""]);
  const dataFiltersInitialState = {
    facility: "",
    provider: "",
    clinician: "",
    patient: "",
    prgmStatus: "",
  };
  const [dataFilters, setDataFilters] = useState(dataFiltersInitialState);
  useEffect(() => {
    if (!eligiblePatientsData) return;
    setFilteredData(eligiblePatientsData);
  }, [eligiblePatientsData]);
  console.log(eligiblePatientsData);
  useEffect(() => {
    if (!eligiblePatientsData) return;

    let dummyData = eligiblePatientsData;
    if (dataFilters.facility !== "") {
      dummyData = dummyData?.filter(
        (data) => data?.FacilityId?._id === dataFilters.facility
      );
    }
    if (dataFilters.provider) {
      dummyData = dummyData?.filter(
        (data) => data?.ProviderId?._id === dataFilters.provider
      );
    }
    if (dataFilters.clinician) {
      dummyData = dummyData?.filter(
        (data) => data?.PrimaryCoordinator?._id === dataFilters.clinician
      );
    }
    if (dataFilters.patient) {
      dummyData = dummyData?.filter((data) =>
        data?.Name?.toLowerCase().includes(dataFilters?.patient?.toLowerCase())
      );
    }
    if (dataFilters.prgmStatus) {
      dummyData = dummyData?.filter((data) =>
        data?.enrolledPrograms.some(
          (program) => program.ProgramStatus === dataFilters.prgmStatus
        )
      );
      console.log(dummyData);
    }
    setFilteredData(dummyData);
  }, [dataFilters, eligiblePatientsData]);
  console.log(filteredData);
  return (
    <div className="EligiblePatientsFilter">
      <div className="box colSpan2">
        <p className="head">Facility</p>
        <SelectComponent
          OptionsDict={FacilityList}
          fn={generalHandleValueChanges}
          valueData={dataFilters.facility}
          setvalue={setDataFilters}
          name={"facility"}
        />
      </div>
      <div className="box colSpan2">
        <p className="head">Care Plan Physician</p>
        <SelectComponent
          OptionsDict={ProviderList}
          fn={generalHandleValueChanges}
          valueData={dataFilters.provider}
          setvalue={setDataFilters}
          name={"provider"}
        />
      </div>
      <div className="box colSpan2">
        <p className="head">Primary Care Coordinator</p>
        <SelectComponent
          OptionsDict={ClinicianList}
          fn={generalHandleValueChanges}
          valueData={dataFilters.clinician}
          setvalue={setDataFilters}
          name={"clinician"}
        />
      </div>
      <div className="box colSpan2">
        <p className="head">Secondary Insurance Status</p>
        <SelectComponent />
      </div>

      <div className="box colSpan2">
        <p className="head">Program Status</p>
        <SelectComponent
          OptionsArray={[
            "Eligible",
            "Not Interested",
            "Consent Received",
            "Follow Up",
          ]}
          fn={generalHandleValueChanges}
          valueData={dataFilters.clinician}
          setvalue={setDataFilters}
          name={"prgmStatus"}
        />
      </div>
      <div className="box colSpan2">
        <p className="head">Search</p>
        <input
          value={dataFilters.patient}
          onChange={(e) => {
            setDataFilters((prev) => ({
              ...prev,
              patient: e.target.value,
            }));
          }}
        />{" "}
      </div>
    </div>
  );
};

export default EligiblePatientsFilter;
