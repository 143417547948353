// import "./addDevices.scss";

import React from "react";
import { useState, useEffect } from "react";
import PopUp from "../../patientViewPage/PopUp";

import DropContainer from "./dropContainer/DropContainer";
const AddDevices = ({ popUpOpen, setPopUpOpen, id }) => {
  const initialState = {};
  const [updateData, setUpdateData] = useState();
  const [addProgram, setAddProgram] = useState({});
  const cancelfn = () => {
    setAddProgram(initialState);
    setUpdateData("");
    setPopUpOpen(false);
  };

  return (
    <div className="addDevices">
      {popUpOpen && (
        <PopUp cancelfn={cancelfn} width={"80vw"} id={id}>
          <DropContainer type={"Active Devices"} id={id} />
          <DropContainer type={"Inactive Devices"} />
        </PopUp>
      )}
    </div>
  );
};

export default AddDevices;
