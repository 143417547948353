import React, { useEffect, forwardRef, useImperativeHandle } from "react";
import classNames from "classnames";
import useTwoFA_Input from "../hooks/use-twoFA_Input";
import "./twoFA_InputField.scss";

const TwoFA_InputField = forwardRef((props, ref) => {
  const {
    value,
    isValid,
    valueChangeHandler,
    reset,
    submitClicked,
    storeValue,
  } = useTwoFA_Input();

  const inputRef = React.useRef(null);

  const handlePaste = (event) => {
    if (props.onPaste) {
      props.onPaste(event);
    }
  };

  useEffect(() => {
    props.storeData(value);
  }, [value]);

  useImperativeHandle(ref, () => ({
    init_data(data) {
      storeValue(data);
    },
    focusData() {
      inputRef.current.focus();
    },
  }));

  return (
    <input
      type={props.type ?? "text"}
      value={value}
      ref={inputRef}
      onChange={valueChangeHandler}
      autoFocus={props.autoFocus ?? false}
      className="twoFA_InputFieldContainer"
      onPaste={handlePaste}
    />
  );
});

export default React.memo(TwoFA_InputField);
