import { useReducer } from "react";
import { isNotEmpty } from "../utilities/validationFunction";

const initialInputState = {
  value: "",
  submitClicked: false,
};

const inputStateReducer = (state, action) => {
  if (action.type === "INPUT") {
    return { value: action.value, submitClicked: false };
  }

  if (action.type === "RESET") {
    return { value: "", submitClicked: false };
  }
  if (action.type === "SET") {
    return { value: action.value, submitClicked: false };
  }
  if (action.type === "SUBMIT") {
    return { value: state.value, submitClicked: true };
  }
  return initialInputState;
};

const useTwoFA_Input = () => {
  const [inputState, dispatch] = useReducer(
    inputStateReducer,
    initialInputState
  );

  const valueIsValid = isNotEmpty(inputState.value);
  const hasError = !valueIsValid && inputState.submitClicked;

  const valueChangeHandler = (event) => {
    var data = event.target.value;
    if (data.length === 2) {
      data = data.replace(inputState.value, "");
    }
    const len = data.length;
    if (len <= 1) {
      dispatch({ type: "INPUT", value: data });
    }
  };

  const reset = () => {
    dispatch({ type: "RESET" });
  };
  const storeValue = (value) => {
    dispatch({ type: "SET", value: value });
  };
  const submitClicked = () => {
    dispatch({ type: "SUBMIT" });
  };
  return {
    value: inputState.value,
    isValid: valueIsValid,
    hasError,
    valueChangeHandler,
    reset,
    storeValue,
    submitClicked,
  };
};

export default useTwoFA_Input;
