import React from "react";
import { useState, useEffect } from "react";
import DropDownContent from "../../../../components/DropDownContent";
import ActiveHistoryDropDown2 from "../../../../components/ActiveHistoryDropDown2";
import { v4 as uuid } from "uuid";
import { statusfinderFn } from "../../helperfunctions";
import classNames from "classnames";
import Diagnosis from "./Diagnosis";
import DiagnosticLifeStyle from "./DiagnosticsLifeStyle";
import DiagnosticGoal from "./DianosticGoals";
import CarePlanDisplay from "../../CarePlanDisplay/CarePlanDisplay";
import { cancleOperationFn } from "../../helperfunctions";
import TabWidget from "../../../../components/TabWidget";
import TabElement from "../../../../components/TabElement";
import "./patientDiagnosis.scss";

import CarePlanErrorMessage from "../../CarePlanErrorMessage";

const PatientDiagnosis = (props) => {
  const { selectedPatient, id, updateDatafn } = props;

  const [uniqueId, setUniqueId] = useState(uuid());
  const creatnewID = () => {
    setUniqueId(uuid());
    setDiagnosticWindow(false);
    changeWindow(1);
  };
  const [selectedId, setSelectedId] = useState();
  const editfn = (value) => {
    let r = selectedPatient?.Diagnosis?.filter(
      (el) => el.Diagnosis_id === value
    );
    setSelectedId(r[0]);
    setIcd(r[0].ICD_Code);
    setUniqueId(value);
    changeWindow(1);
    setDiagnosticWindow(true);
  };

  const deletefn = (val) => {
    let updatedDiagnosis = selectedPatient?.Diagnosis?.map((el) =>
      el.Diagnosis_id === val ? { ...el, Status: false } : el
    );
    let updatedGoalInformation = selectedPatient?.GoalInformation?.map((el) =>
      el.Diagnosis_id === val ? { ...el, Status: false } : el
    );
    let updatedLifeStyle = selectedPatient?.LifeStyle?.map((el) =>
      el.Diagnosis_id === val ? { ...el, Status: false } : el
    );

    updateDatafn({
      PatientId: id,
      Diagnosis: updatedDiagnosis,
      GoalInformation: updatedGoalInformation,
      LifeStyle: updatedLifeStyle,
    });
  };

  const [diagnosticWindow, setDiagnosticWindow] = useState(false);
  const [window, setWindow] = useState(1);
  const [Icd, setIcd] = useState();
  const [active, setActive] = useState("Active");

  const icdfn = (value) => {
    setIcd(value?.split(" ")[0]);
  };
  const addDiagnosticDetail = () => {
    setDiagnosticWindow((prev) => !prev);
    setSelectedId();
  };
  const changeWindow = (el) => {
    setWindow(el);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <DropDownContent title={"Patient Diagnosis"}>
        {" "}
        <div>
          <TabWidget>
            {["Active", "History"]?.map((el) => (
              <TabElement
                key={`patients_${el}`}
                title={el}
                active={active === el}
                onClick={() => {
                  setActive(el);
                }}
              />
            ))}
          </TabWidget>
          {active === "Active" && (
            <ActiveHistoryDropDown2
              type={"Active"}
              iconsList={[
                {
                  value: "ic:twotone-plus",
                  onClickFn: addDiagnosticDetail,
                },
                {
                  value: "majesticons:multiply-line",
                  onClickFn: () => {
                    cancleOperationFn(setDiagnosticWindow);
                    creatnewID();
                    changeWindow(1);
                  },
                },
              ]}
            >
              <div>
                {diagnosticWindow ? (
                  <div className="addDetailsContainer">
                    <div className="head">
                      <div
                        className={classNames("element", {
                          active: window === 1,
                        })}
                      >
                        1
                      </div>
                      <div
                        className={classNames("element", {
                          active: window === 2,
                        })}
                      >
                        2
                      </div>
                      <div
                        className={classNames("element", {
                          active: window === 3,
                        })}
                      >
                        3
                      </div>
                      <div
                        className={classNames("element", {
                          active: window === 4,
                        })}
                      >
                        4
                      </div>
                    </div>

                    {/* page 1 */}
                    {/* ************************************************************************* */}
                    {window === 1 && (
                      <Diagnosis
                        changeWindow={changeWindow}
                        id={id}
                        icdfn={icdfn}
                        updateDatafn={updateDatafn}
                        selectedPatient={selectedPatient}
                        uniqueId={uniqueId}
                        selectedId={selectedId}
                      />
                    )}
                    {/* ************************************************************************* */}

                    {/* page 2 */}
                    {/* ************************************************************************* */}
                    {window === 2 && (
                      <DiagnosticGoal
                        changeWindow={changeWindow}
                        Icd={Icd}
                        id={id}
                        updateDatafn={updateDatafn}
                        selectedPatient={selectedPatient}
                        uniqueId={uniqueId}
                        editfn={editfn}
                      />
                    )}

                    {/* ************************************************************************* */}

                    {/* page 3*/}
                    {/* ************************************************************************* */}
                    {window === 3 && (
                      <DiagnosticLifeStyle
                        changeWindow={changeWindow}
                        Icd={Icd}
                        id={id}
                        updateDatafn={updateDatafn}
                        selectedPatient={selectedPatient}
                        uniqueId={uniqueId}
                        creatnewID={creatnewID}
                      />
                    )}

                    {/* ************************************************************************* */}
                  </div>
                ) : (
                  <>
                    {statusfinderFn("active", selectedPatient?.Diagnosis)
                      ?.length === 0 ? (
                      <CarePlanErrorMessage
                        msg={"Patient doesn’t have any Diagnosis yet!"}
                      />
                    ) : (
                      <CarePlanDisplay
                        deletefn={deletefn}
                        type={"active"}
                        selecteddata={selectedPatient}
                        editfn={editfn}
                        updateDatafn={updateDatafn}
                      />
                    )}
                  </>
                )}
              </div>
            </ActiveHistoryDropDown2>
          )}
          {active === "History" && (
            <ActiveHistoryDropDown2 type={"History"}>
              <CarePlanDisplay
                type={"History"}
                selecteddata={selectedPatient}
                editfn={editfn}
              />
            </ActiveHistoryDropDown2>
          )}
        </div>
      </DropDownContent>
    </div>
  );
};

export default PatientDiagnosis;
