import { createSlice } from "@reduxjs/toolkit";
export const insuranceTypeTabList = [
  "firstInsuranceData",
  "secondInsuranceData",
  "thirdInsuranceData",
];

const initialState = {
  valid: false,
  insuranceType: insuranceTypeTabList[0],
  isValidData: {
    firstInsuranceData: {
      insuranceCompany: false,
      payerID: true,
      tplCode: true,
      insuranceID: false,
      groupName: true,
      groupNumber: true,
      notes: true,
    },
    secondInsuranceData: {
      insuranceCompany: false,
      payerID: true,
      tplCode: true,
      insuranceID: false,
      groupName: true,
      groupNumber: true,
      notes: true,
    },
    thirdInsuranceData: {
      insuranceCompany: false,
      payerID: true,
      tplCode: true,
      insuranceID: false,
      groupName: true,
      groupNumber: true,
      notes: true,
    },
  },
  firstInsuranceDataValid: false,
  secondInsuranceDataValid: false,
  thirdInsuranceDataValid: false,

  insurance: {
    firstInsuranceData: {
      insuranceCompany: "",
      payerID: "",
      tplCode: "",
      insuranceID: "",
      groupName: "",
      groupNumber: "",
      notes: "",
    },
    secondInsuranceData: {
      insuranceCompany: "",
      payerID: "",
      tplCode: "",
      insuranceID: "",
      groupName: "",
      groupNumber: "",
      notes: "",
    },
    thirdInsuranceData: {
      insuranceCompany: "",
      payerID: "",
      tplCode: "",
      insuranceID: "",
      groupName: "",
      groupNumber: "",
      notes: "",
    },
  },
  basicDataValidation: false,
  noEmptyUpdateAllowed: false,
  noEmptyUpdateAllowedInsuranceTab: false,
};

const patientsInsuranceDataSlice = createSlice({
  name: "patientsInsuranceData",
  initialState: initialState,
  reducers: {
    updateCurrentTab(state, action) {
      state.insuranceType = action.payload;
      // if(action.payload === insuranceTypeTabList[0] ){

      //   state.noEmptyUpdateAllowed = true;
      //   console.log('setting noEmptyUpdateAllowed as true')
      // }
    },
    updateEmptyTab(state, action) {
      console.log("hii");
      state.noEmptyUpdateAllowedInsuranceTab = true;
    },
    updateData(state, action) {
      const { fieldName, value, validityStatus } = action.payload;
      // noEmptyUpdateAllowedInsuranceTab
      const insuranceType = state.insuranceType;
      if (state.noEmptyUpdateAllowedInsuranceTab) {
        if (!value) return;
        state.noEmptyUpdateAllowedInsuranceTab = false;
      }
      state.insurance[insuranceType][fieldName] = value;
      state.isValidData[insuranceType][fieldName] = validityStatus;
      state.firstInsuranceDataValid = Object.keys(
        state.isValidData.firstInsuranceData
      ).reduce(
        (prev, curr) => prev && state.isValidData.firstInsuranceData[curr],
        true
      );
      state.secondInsuranceDataValid = Object.keys(
        state.isValidData.secondInsuranceData
      ).reduce(
        (prev, curr) => prev && state.isValidData.secondInsuranceData[curr],
        true
      );
      state.thirdInsuranceDataValid = Object.keys(
        state.isValidData.thirdInsuranceData
      ).reduce(
        (prev, curr) => prev && state.isValidData.thirdInsuranceData[curr],
        true
      );
      // state.basicDataValidation = Object.keys(state.isValidData).reduce(
      //   (prev, curr) => prev && state.isValidData[curr],
      //   true
      // );
    },
    resetData(state, action) {
      Object.keys(initialState).forEach((el) => {
        state[el] = initialState[el];
      });
    },
  },
});

export const patientsInsuranceDataActions = patientsInsuranceDataSlice.actions;

export default patientsInsuranceDataSlice.reducer;
