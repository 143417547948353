import { useCallback, useEffect, useRef, useState } from "react";
import LoginInputTextboxField from "../fieldComponent/LoginInputTextboxField1";
import { isNotEmpty } from "../utilities/validationFunction";

import { useDispatch } from "react-redux";
import ButtonComponent from "../components/ButtonComponent";

import { useForgotPasswordMutation } from "../app/api/loginApi";
import {
  goToResetPasswordPage,
  loginDataActions,
} from "../app/features/loginDataSlice";
import FloatingNotifications from "../utilities/FloatingNotifications";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [valid, setValid] = useState(false);

  const [forgotPassword, { data, loading, error }] =
    useForgotPasswordMutation();

  const storeData = useCallback((fieldName, value, validityStatus) => {
    setInputData((prevState) => {
      return { ...prevState, [fieldName]: value };
    });
    if (inputDataValidity.hasOwnProperty(fieldName)) {
      setInputDataValidity((prevState) => ({
        ...prevState,
        [fieldName]: validityStatus,
      }));
    }
  }, []);

  const resetRefs = {
    username: useRef(null),
  };

  const [inputData, setInputData] = useState({
    username: "",
  });

  const [inputDataValidity, setInputDataValidity] = useState({
    username: false,
  });

  useEffect(() => {
    const data = Object.keys(inputDataValidity).reduce(
      (prev, cur) => prev && inputDataValidity[cur],
      true
    );
    setValid(data);
  }, [inputDataValidity]);

  const onSubmitHandler = () => {
    if (valid) {
      forgotPassword(inputData);
    } else {
      FloatingNotifications("error", "Please enter username");
    }
  };

  useEffect(() => {
    if (data) {
      FloatingNotifications("success", "OTP sent to email");
      dispatch(loginDataActions.updateUsername(inputData.username));
      dispatch(loginDataActions.switchPage(goToResetPasswordPage()));
    }
  }, [data]);

  useEffect(() => {
    // console.log(error.data.msg);
    if (error) {
      FloatingNotifications("error", error?.data?.msg ?? "Unknown error");
    }
  }, [error]);

  return (
    <>
      <div className="title">Forgot Password</div>
      <div className="desc">Please enter your user name</div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmitHandler();
        }}
      >
        <LoginInputTextboxField
          ref={resetRefs["username"]}
          title="User Name"
          validation={isNotEmpty}
          storeData={storeData.bind(null, "username")}
          superText=""
          autoComplete="off"
          autoFocus={true}
        />
        <ButtonComponent
          containerClassName="buttonContainer"
          // onClick={onSubmitHandler}
          text="Submit"
          disabled={loading}
        />
      </form>
    </>
  );
};

export default ForgotPassword;
