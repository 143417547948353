import React, { useState } from "react";
import { Icon } from "@iconify/react";
import "./thresholdDropContainer.scss";

function ThresholdDropContainer({ title, children, open, icons, setWindow }) {
  const [showContent, setShowContent] = useState(open ?? true);
  const onClickHandler = () => {
    setShowContent((prev) => !prev);
  };
  return (
    <>
      <div className="DropContainerContainer">
        <div className="title">
          <span className="fl" onClick={onClickHandler}>
            {showContent ? (
              <Icon icon="ic:round-minus" />
            ) : (
              <Icon icon="uil:plus" />
            )}
            <p> {title}</p>
          </span>
          {icons && (
            <span className="btnsflex">
              <button className="boxBtn" onClick={() => setWindow("addetails")}>
                <Icon
                  icon="octicon:plus-16"
                  color="white"
                  width="20"
                  height="20"
                />
              </button>
              <button className="boxBtn">
                <Icon
                  icon="mingcute:save-line"
                  color="white"
                  width="20"
                  height="20"
                />
              </button>
              <button className="boxBtn" onClick={() => setWindow("display")}>
                <Icon
                  icon="iconamoon:close-bold"
                  color="white"
                  width="20"
                  height="20"
                />
              </button>
            </span>
          )}
        </div>
        {showContent && <div className="body"> {children}</div>}
      </div>
    </>
  );
}

export default ThresholdDropContainer;
