import React from "react";
import LoadEligiblePatientForm from "./LoadEligiblePatientForm";
import "./loadEligiblePatients.scss";
import DownloadCSVButton from "../components/DownloadTemplate";
const LoadEligiblePatients = () => {
  return (
    <div className="loadeligible">
      <p className="title">Load Eligible Patient & Program</p>
      <div className="tableBox">
        <div className="box">
          <div className="note">
            <span>Note:</span> Template must be downloaded and Data must be
            entered or pasted. Please don't use your own .csv or .xls file.
          </div>
          <div className="btnWrap">
            <DownloadCSVButton />{" "}
          </div>
        </div>
        <div className="box instructions">
          <div className="steps">
            Steps for creating the Data File for upload
          </div>
          <ol>
            <li>Download the .csv template file.</li>
            <li>Open the template.</li>
            <li>Mandatory columns are indicated with "*" symbol.</li>
            <li>
              Populate each column/field in the CSV template from the
              corresponding EMR Data.
            </li>
            <li>
              For all rows, fill in the NPI of the physician for whom the data
              is loaded and the corresponding facility Name.
            </li>
            <li>
              Acceptable date format MM-DD-YYYY or MM/DD/YYYY, please use format
              cell custom to set this format in MS Excel.
            </li>
            <li>Secondary Insurance column accepts the value "Yes" or "No".</li>
            <li>Column labels must not be changed.</li>
            <li>
              Include at least one Program Name. For example CCM, AWV, RPM, DPP,
              PCM, BHI.
            </li>
            <li>
              Preferred language will accept anyone among "English, Spanish,
              Arabic, French, Italian, German, Russian, Chinese, Vietnamese,
              Armenian and Hindi".
            </li>
            <li>
              Save the file as{" "}
              <code>
                Physician NPI _&#123;&lt;Patient Enrollment Campaign
                Name&gt;&#125;.csv
              </code>
              . For example, if the physician NPI is 1020304050, then the .csv
              file should be created as 1020304050_&#123;&lt;Patient Enrollment
              Campaign Name&gt;&#125;.csv.
            </li>
            <li>
              Note: There is an "_" character between NPI and the Patient
              Enrollment Campaign Name in the Data file.
            </li>
          </ol>
        </div>
        <div className="box addFile">
          <LoadEligiblePatientForm />
        </div>
      </div>
    </div>
  );
};

export default LoadEligiblePatients;
