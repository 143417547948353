import { apiCollection } from "./apiCollection";

const billingApi = apiCollection.injectEndpoints({
  endpoints: (build) => ({
    getBillingHistory: build.mutation({
      query: (payload) => {
        return {
          url: "/timetracker/GetServiceLog",
          method: "POST",
          body: payload,
        };
      },
      //   transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
    addServices: build.mutation({
      query: (payload) => {
        return {
          url: "/timetracker/AddServiceLog",
          method: "POST",
          body: payload,
        };
      },
      //   transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
    editServices: build.mutation({
      query: (payload) => {
        return {
          url: "/timetracker/UpdateServiceLog",
          method: "POST",
          body: payload,
        };
      },
      //   transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
    addBulkService: build.mutation({
      query: (payload) => {
        return {
          url: "/timetracker/bulkAddService",
          method: "POST",
          body: payload,
        };
      },
      //   transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
    getServiceLog: build.mutation({
      query: (payload) => {
        return {
          url: "/timetracker/ServiceReport",
          method: "POST",
          body: payload,
        };
      },
      //   transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetBillingHistoryMutation,
  useAddServicesMutation,
  useAddBulkServiceMutation,
  useEditServicesMutation,
  useGetServiceLogMutation,
} = billingApi;
