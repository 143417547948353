import React, { useEffect, useState } from "react";
import "./patientDetailsView.scss";
import { useGetPatientSelectedProfileMutation } from "../../../app/api/patientsApi";

const PatientDetailsView = ({ Patientdetails }) => {
  const [selectedProfilefn, { data: Data }] =
    useGetPatientSelectedProfileMutation();
  useEffect(() => {
    if (!Patientdetails) return;

    selectedProfilefn({
      PatientId: Patientdetails?.PatientId,
    });
  }, [Patientdetails]);

  return (
    <div className="PatientDetailsViewPage">
      <div className="title">Patient Details</div>
      <div className="detailscontentBox">
        <div className="head">Patient Name</div>
        <div className="content">
          {Data?.FirstName} {Data?.MiddleName} {Data?.LastName}
        </div>
        <div className="head">Preferred Language</div>
        <div className="content">{Data?.PreferredLanguage}</div>
        <div className="head">External EMR Id</div>
        <div className="content"></div>
        <div className="head">Ethnicity 1</div>
        <div className="content"></div>
        <div className="head">Ethnicity 2</div>
        <div className="content"></div>
        <div className="head">Email Address</div>
        <div className="content">{Data?.email}</div>
        <div className="head">Medicare Number</div>
        <div className="content"></div>
        <div className="head">Medicare Enrollment Date</div>
        <div className="content">
          {Data?.MedicareEnrolmentDate?.slice(0, 10)}
        </div>
        <div className="head">Secondary Insurance Number</div>
        <div className="content">{Data?.secondInsuranceData?.insuranceID}</div>
        <div className="head">Address</div>
        <div className="content">
          {Data?.street1} {Data?.city} {Data?.state} {Data?.country}
        </div>
        <div className="head">Caregiver Details</div>
        <div className="content"></div>
        <div className="head">Carrier Details</div>
        <div className="content"></div>
        <div className="head">Contact Numbers</div>
        <div className="content">{Data?.phone}</div>
      </div>
    </div>
  );
};

export default PatientDetailsView;
