import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import "./eventViewer.scss";

import { Icon } from "@iconify/react";

const EventViewer = ({ viewEvent, closeFn, onClickCallPatient }) => {
  console.log(viewEvent);

  return (
    <div className="eventViewContainer">
      <OutsideClickHandler onOutsideClick={closeFn}>
        <div className="eventViewContent">
          <Icon
            onClick={closeFn}
            className="closeIcon"
            icon="iconamoon:close-bold"
            width="26"
            height="26"
          />
          <div className="popchild">
            <p className="title">CCM Schedule Information</p>
            <div className="childgrid">
              <div className="element">
                <div className="displayElement">
                  <p className="head">Patient Name</p>
                  <p className="sub">{viewEvent?.patientId?.PatientName}</p>
                </div>
                <div className="displayElement">
                  <p className="head">Care Plan Physician Name</p>
                  <p className="sub">{viewEvent?.AssigneeName}</p>
                </div>
                <div className="displayElement">
                  <p className="head">Schedule Type</p>
                  <p className="sub">{viewEvent?.scheduleType}</p>
                </div>
                <div className="displayElement">
                  <p className="head">Schedule Date & Time</p>
                  <p className="sub">
                    {viewEvent?.Date} {viewEvent?.StartDateTime}
                  </p>
                </div>
                <div className="displayElement">
                  <p className="head">Duration</p>
                  <p className="sub">{viewEvent?.callDuration} Min</p>
                </div>
                <div className="displayElement">
                  <p className="head">Appointment Type</p>
                  <p className="sub">{viewEvent?.appointmentType}</p>
                </div>
                <div className="displayElement">
                  <p className="head">Before Visit Instruction To Patient</p>
                  <p className="sub">{viewEvent?.patientInstructions}</p>
                </div>
              </div>
              <div className="element">
                <div className="displayElement">
                  <p className="head">Facility Name</p>
                  <p className="sub">fhejkjnkm</p>
                </div>
                <div className="displayElement">
                  <p className="head">Primary Care Coordinator</p>
                  <p className="sub">fhejkjnkm</p>
                </div>
                <div className="displayElement">
                  <p className="head">Schedule Status</p>
                  <p className="sub">fhejkjnkm</p>
                </div>
                <div className="displayElement">
                  <p className="head">Schedule Assigned To</p>
                  <p className="sub">{viewEvent?.assignTo}</p>
                </div>
                <div className="displayElement">
                  <p className="head">Purpose of the Call</p>
                  <p className="sub">{viewEvent?.callPurpose}</p>
                </div>
              </div>
            </div>
            <div className="eventViewButtons">
              <button onClick={onClickCallPatient}>Call Patient</button>
              <button>Reschedule</button>
              <button>Cancel Schedule</button>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default EventViewer;
