import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HMSRoomProvider } from "@100mslive/react-sdk";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container);

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    {/* <AuthProvider> */}
    <BrowserRouter>
      <Routes>
        <Route
          path="/*"
          element={
            <HMSRoomProvider>
              <App />
            </HMSRoomProvider>
          }
        />
      </Routes>
    </BrowserRouter>
    {/* </AuthProvider> */}
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
