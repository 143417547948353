import React from "react";
import SelectComponent from "../components/SelectComponent";

import "./scheduleCalls.scss";
const ScheduleCalls = () => {
  return (
    <div className="scheduleCalls">
      <p className="pageTitle">Schedule Calls</p>
      <div className="advanceSearchBox">
        <div className="box colSpan1">
          <p className="head">Show</p>
          <SelectComponent
            OptionsArray={["CCM"]}
            // fn={generalHandleValueChanges}
            // valueData={filter.programName}
            // setvalue={setFilter}
            // name={"programName"}
          />
        </div>
        <div className="box colSpan2">
          <p className="head">Program</p>
          <SelectComponent
            OptionsArray={["CCM"]}
            // fn={generalHandleValueChanges}
            // valueData={filter.programName}
            // setvalue={setFilter}
            // name={"programName"}
          />
        </div>
        <div className="box colSpan2">
          <p className="head">Schedule Type</p>
          <SelectComponent />
        </div>
        <div className="box colSpan1">
          <p className="head">Year</p>
          <SelectComponent />
        </div>
        <div className="box colSpan1">
          <p className="head">Month</p>
          <SelectComponent />
        </div>
        <div className="box colSpan1">
          <p className="head">Range</p>
          <SelectComponent />
        </div>
        <div className="box colSpan2">
          <p className="head">Total Billable Time (HH:MI:SS)</p>
          <input />
        </div>
        <div className="box colSpan2 ">
          <p className="head">Facility</p>
          <SelectComponent />
        </div>
        <div className="box colSpan2">
          <p className="head">Physician</p>
          <SelectComponent />
        </div>
        <div className="box colSpan2">
          <p className="head">Clinician</p>
          <SelectComponent />
        </div>
        <div className="box colSpan2">
          <p className="head">Clinician Role</p>
          <SelectComponent />
        </div>
        <div className="box colSpan2">
          <p className="head">Patient Name</p>
          <input />
        </div>
        {/* <div className="box">
          <p className="head">Bill only Active Patients</p>
          <span className="checkbox">
            <input type="checkbox" />
          </span>
        </div> */}
        {/* <div className="box">
          <p className="head">Month & Year</p>
          <div className="select2">
            <span>
              <SelectComponent
                OptionsArray={[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ]}
                // fn={generalHandleValueChanges}
                // valueData={filter.month}
                // setvalue={setFilter}
                name={"month"}
              />
            </span>
            <span>
              <SelectComponent
                OptionsArray={[2023]}
                // fn={generalHandleValueChanges}
                // valueData={filter.year}
                // setvalue={setFilter}
                name={"year"}
              />
            </span>
          </div>
        </div> */}
        {/* <div className="box">
          <p className="head">Total Time Spent (HH:MI:SS)</p>
          <SelectComponent
            OptionsArray={[
              " Greater Than ",
              "Less Than",
              " Equal To ",
              " Greater Than or Equal To ",
              " Less Than or Equal To ",
              "In Between",
            ]}
            placeholder="Select Range"
            // valueData={device.deviceProvider}
            // fn={generalHandleValueChanges}
            // setvalue={setDevices}
            // name={"deviceProvider"}
          />
        </div>
        <div className="box">
          <p className="head">Patient Name</p>
          <SelectComponent />
        </div> */}
      </div>
      <div className="callsBody">
        <div className="colSpan2 header">Patient</div>
        <div className="colSpan1 header">Physician</div>
        <div className="colSpan1 header">Primary CCM Clinician</div>
        <div className="colSpan1 header">Total Billable Time(HH:MI:SS)</div>
        <div className="colSpan2 header">Assign to</div>
        <div className="colSpan2 header">Purpose of the Call</div>
        <div className="colSpan4 header">
          Schedule date, time & duration in assignee time zone
        </div>
        <div className="colSpan2 callsElement">Ray Cooper (MST)</div>
        <div className="colSpan1 callsElement">Test Physician</div>
        <div className="colSpan1 callsElement">Testing Clinician</div>
        <div className="colSpan1 callsElement">00:00:00</div>
        <div className="colSpan2 callsElement">
          <div className="field">
            <select className="select100">
              <option>Test Clinician-PST</option>
            </select>
          </div>
        </div>
        <div className="colSpan2 callsElement">
          <div className="field">
            <input type="text" />
          </div>
        </div>
        <div className="colSpan4 callsElement">
          <div className="field">
            <input
              type="date"
              id="start"
              name="trip-start"
              value="2018-07-22"
              min="2018-01-01"
              max="2018-12-31"
            />

            <input
              type="time"
              id="appt"
              name="appt"
              min="09:00"
              max="18:00"
              required
              className="datePicker"
            />
            <select>
              <option>10</option>
              <option>15</option>
              <option>20</option>
              <option>30</option>
              <option>45</option>
              <option>60</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleCalls;
