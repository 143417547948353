import React, { useState, useEffect } from "react";
import TabWidget from "../../components/TabWidget";
import TabElement from "../../components/TabElement";
import TechnicianAlotmentTab from "../../components/TechnicianAlotmentTab";
import { useGetAccessLevelQuery } from "../../app/api/accessLevelApi";
import { useLocation } from "react-router-dom";

import { useGetSelectedFacilityMutation } from "../../app/api/facilityApi";
import "./facilityView.scss";
const tabChildrenList = ["Basics"];
const FacilityView = () => {
  const location = useLocation();
  const [id, setId] = useState(location?.state?.id);
  const [currentTab, setcurrentTab] = useState("Basics");

  const [accessLevelData, setAccessLevelData] = useState({});
  const [accessDataProvider, setAccessDataProvider] = useState();
  const [accessDataClinician, setAccessDataClinician] = useState();

  const { data: rawAccessLevelData } = useGetAccessLevelQuery();
  // console.log(res);
  const [selectedFacilityCallFn, { data: selectedFacility }] =
    useGetSelectedFacilityMutation();

  useEffect(() => {
    if (!rawAccessLevelData) return;
    setAccessLevelData(rawAccessLevelData);
    // console.log(rawAccessLevelData);
  }, [rawAccessLevelData]);

  useEffect(() => {
    if (!id) return;
    selectedFacilityCallFn({
      _id: id,
    });
  }, [id, selectedFacilityCallFn]);
  useEffect(() => {
    if (!selectedFacility) return;

    setAccessDataClinician(selectedFacility.ClinicianList);
    setAccessDataProvider(selectedFacility.ProviderList);
  }, [selectedFacility]);
  return (
    <div className="facility">
      <div className="facilityContainer">
        <div className="pageTitle">Facility</div>

        <>
          <div className="tabWidgetContainer">
            <TabWidget>
              {tabChildrenList.map((el) => (
                <TabElement
                  key={`facilityTab_${el}`}
                  title={el}
                  active={currentTab === el}
                  onClick={() => {
                    setcurrentTab(el);
                  }}
                />
              ))}
            </TabWidget>
          </div>
          {tabChildrenList[0] === currentTab && (
            <>
              <div className="viewGridContainer">
                <div className="viewSide">
                  <div className="viewElement">
                    <p className="viewtitle">NPI</p>
                    <p className="viewValue">{selectedFacility?.NPI}</p>
                  </div>
                  <div className="viewElement">
                    <p className="viewtitle">Facility Name</p>
                    <p className="viewValue">
                      {selectedFacility?.FacilityName}
                    </p>
                  </div>
                  <div className="viewElement">
                    <p className="viewtitle">Facility Type</p>
                    <p className="viewValue">
                      {selectedFacility?.FacilityType}
                    </p>
                  </div>
                  <div className="viewElement">
                    <p className="viewtitle">Business Type</p>
                    <p className="viewValue">
                      {selectedFacility?.BusinessType}
                    </p>
                  </div>
                  <div className="viewElement">
                    <p className="viewtitle">Phone Number</p>
                    <p className="viewValue">{selectedFacility?.phone}</p>
                  </div>
                  <div className="viewElement">
                    <p className="viewtitle">Time Zone</p>
                    <p className="viewValue">{selectedFacility?.TimeZone}</p>
                  </div>
                  <div className="viewElement">
                    <p className="viewtitle">Fax</p>
                    <p className="viewValue">{selectedFacility?.Fax}</p>
                  </div>
                  <div className="viewElement">
                    <p className="viewtitle">Effective Date</p>
                    <p className="viewValue">
                      {selectedFacility?.EffectiveDate}
                    </p>
                  </div>
                  <div className="viewElement">
                    <p className="viewtitle">Working days</p>
                    <p className="viewValue">
                      {selectedFacility?.WorkingDaysStart} &ensp; to &ensp;
                      {selectedFacility?.WorkingDaysEnd} (
                      {selectedFacility?.WorkingDaysStartTime} -{" "}
                      {selectedFacility?.WorkingDaysEndTime})
                    </p>
                  </div>
                  <div className="viewElement">
                    <p className="viewtitle">Notes</p>
                    <p className="viewValue">{selectedFacility?.Notes}</p>
                  </div>
                </div>
                <div className="viewSide">
                  <div className="viewElement">
                    <p className="viewtitle">Office Name</p>
                    <p className="viewValue">{selectedFacility?.OfficeName}</p>
                  </div>
                  <div className="viewElement">
                    <p className="viewtitle">Address Line 1</p>
                    <p className="viewValue">{selectedFacility?.street1}</p>
                  </div>
                  <div className="viewElement">
                    <p className="viewtitle">Address Line 2</p>
                    <p className="viewValue">{selectedFacility?.street2}</p>
                  </div>
                  <div className="viewElement">
                    <p className="viewtitle">City</p>
                    <p className="viewValue">{selectedFacility?.city}</p>
                  </div>
                  <div className="viewElement">
                    <p className="viewtitle">Zip Code</p>
                    <p className="viewValue">{selectedFacility?.postalCode}</p>
                  </div>
                  <div className="viewElement">
                    <p className="viewtitle">State</p>
                    <p className="viewValue">{selectedFacility?.state}</p>
                  </div>
                </div>
              </div>
              <div className="accessLevelComponenets">
                <div className="accessLevelContainers">
                  <TechnicianAlotmentTab
                    title="Physician"
                    accessData={accessDataProvider}
                    setAccessData={setAccessDataProvider}
                    dataFromApi={accessLevelData["ProviderList"] ?? []}
                    idKey={"ProviderId"}
                    nameKey={"ProviderName"}
                    disabled={true}
                  />
                </div>
                <div className="accessLevelContainers">
                  <TechnicianAlotmentTab
                    title="Clinicians"
                    accessData={accessDataClinician}
                    setAccessData={setAccessDataClinician}
                    dataFromApi={accessLevelData["ClinicianList"] ?? []}
                    idKey={"ClinicianId"}
                    nameKey={"ClinicianName"}
                    disabled={true}
                  />
                </div>
              </div>
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default FacilityView;
