import { apiCollection } from "./apiCollection";

const patientsOptionsApi = apiCollection.injectEndpoints({
  endpoints: (build) => ({
    getPatientsOptions: build.query({
      query: () => ({
        url: "patient/getFPClist",
      }),
    }),
  }),
});

export const { useGetPatientsOptionsQuery } = patientsOptionsApi;
