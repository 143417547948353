import React from "react";
import { useState, useEffect } from "react";
import DropDownContent from "../../../components/DropDownContent";
import ActiveHistoryDropDown2 from "../../../components/ActiveHistoryDropDown2";
import AddDetailGeneral from "../addDetailsGeneral/AddDetailGeneral";
import CarePlanDisplayGeneral from "../CarePlanDisplayGeneral/CarePlanDisplayGeneral";
import TabElement from "../../../components/TabElement";
import TabWidget from "../../../components/TabWidget";
import FloatingNotifications from "../../../utilities/FloatingNotifications";
import {
  resetfn,
  basicValidation,
  areArraysSame,
  careplanview,
  generalHandleValueChanges,
  generalSaveButton,
  cancleOperationFn,
  statusfinderFn,
} from "../helperfunctions";
import CarePlanErrorMessage from "../CarePlanErrorMessage";

const initialState = {
  RecordedDate: "",
  EndDate: "",
  Notes: "",
  Status: true,
};
const Concerns = (props) => {
  const { selectedPatient, id, updateDatafn } = props;
  const [concernsWindow, setConcernsWindow] = useState("");
  const addConcernsDetail = () => {
    setConcernsWindow("addDetails");
    resetfn(concerns, setConcerns);
  };
  const [editArray, setEditArray] = useState();

  const [concernsArray, setConcernsArray] = useState([]);
  const [active, setActive] = useState("Active");

  const [concerns, setConcerns] = useState(initialState);
  const onClickFn = () => {
    if (basicValidation(concerns)) {
      generalSaveButton(
        concernsWindow,
        setConcernsWindow,
        concerns,
        setConcernsArray,
        editArray,
        setEditArray
      );
    } else {
      FloatingNotifications("error", "Fill mandatory fields to proceed");
    }
  };
  useEffect(() => {
    if (selectedPatient) {
      setConcernsArray(selectedPatient.Concerns);
    }
  }, [selectedPatient]);
  useEffect(() => {
    if (
      basicValidation(concerns) &&
      !areArraysSame(selectedPatient?.Concerns, concernsArray)
    ) {
      updateDatafn({ PatientId: id, Concerns: concernsArray });
    }
  }, [concernsArray]);
  return (
    <div>
      <DropDownContent title={"Concerns"}>
        {" "}
        <div>
          <TabWidget>
            {["Active", "History"]?.map((el) => (
              <TabElement
                key={`patients_${el}`}
                title={el}
                active={active === el}
                onClick={() => {
                  setActive(el);
                }}
              />
            ))}
          </TabWidget>
          {active === "Active" && (
            <ActiveHistoryDropDown2
              type={"Active"}
              iconsList={[
                {
                  value: "ic:twotone-plus",
                  onClickFn: addConcernsDetail,
                },
                {
                  value: "mingcute:save-line",
                  onClickFn:
                    concernsWindow === "addDetails" ? () => onClickFn() : false,
                },
                {
                  value: "majesticons:multiply-line",
                  onClickFn: () => cancleOperationFn(setConcernsWindow),
                },
              ]}
            >
              <div>
                {concernsWindow === "addDetails" ? (
                  <AddDetailGeneral data={selectedPatient}>
                    <>
                      <p className="displayTitleGnrl">Concerns Information</p>
                      <div className="display-sec-Gnrl">
                        <div className="display-content-Gnrl">
                          <label className="display-element-Gnrl">
                            Recorded Date & Time*
                          </label>
                          <input
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            value={concerns.RecordedDate?.slice(0, 16)}
                            className="display-element-Gnrl "
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setConcerns,
                                "RecordedDate"
                              )
                            }
                          />

                          <label className="display-element-Gnrl">Notes*</label>
                          <div className="displayDescription-color">
                            <textarea
                              className="display-element-Gnrl"
                              value={concerns.Notes}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setConcerns,
                                  "Notes"
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="display-content-Gnrl">
                          <label className="display-element-Gnrl">
                            End Date & Time
                          </label>

                          <input
                            className="display-element-Gnrl "
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setConcerns,
                                "EndDate"
                              )
                            }
                            value={concerns?.EndDate?.slice(0, 16)}
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            min={concerns.RecordedDate ?? ""}
                          />
                        </div>
                      </div>
                    </>{" "}
                  </AddDetailGeneral>
                ) : (
                  <>
                    {statusfinderFn("active", selectedPatient.Concerns)
                      ?.length === 0 ? (
                      <CarePlanErrorMessage msg="Patient doesn’t have any Concerns yet!" />
                    ) : (
                      <CarePlanDisplayGeneral
                        updateDatafn={updateDatafn}
                        name={"Concerns"}
                        array={concernsArray}
                        id={id}
                        careplanview={careplanview}
                        values={[setConcernsWindow, setConcerns]}
                        data={statusfinderFn(
                          "active",
                          selectedPatient.Concerns
                        )}
                        dataSet={selectedPatient?.Concerns ?? []}
                        setEditArray={setEditArray}
                        contentArray={[
                          ["Recorded Date & Time:", "RecordedDate", "colSpan6"],
                          ["End Date & Time:", "EndDate", "colSpan6"],
                          ["Notes:", "Notes", "colSpan2"],
                        ]}
                      />
                    )}
                  </>
                )}
              </div>
            </ActiveHistoryDropDown2>
          )}
          {active === "History" && (
            <ActiveHistoryDropDown2 type={"History"}>
              <>
                {" "}
                {statusfinderFn("history", selectedPatient.Concerns)?.length ===
                0 ? (
                  <></>
                ) : (
                  <CarePlanDisplayGeneral
                    Type={"history"}
                    data={statusfinderFn("history", selectedPatient.Concerns)}
                    contentArray={[
                      ["Recorded Date & Time:", "RecordedDate", "colSpan6"],
                      ["End Date & Time:", "EndDate", "colSpan6"],
                      ["Notes:", "Notes", "colSpan2"],
                    ]}
                  />
                )}
              </>
            </ActiveHistoryDropDown2>
          )}
        </div>
      </DropDownContent>
    </div>
  );
};

export default Concerns;
