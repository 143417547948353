import React from "react";
import { useState } from "react";

import "./CarePlanDisplay.scss";
import IconContainer from "../iconContainer/iconContainer";
import { formatDate } from "../../../utilities/generalUtilities";
const CarePlanDisplay = (props) => {
  const { selecteddata, editfn, deletefn, type } = props;
  const [popUpDelete, setPopUpDelete] = useState(false);
  const [dataItemToDelete, setDataItemToDelete] = useState();

  const onClickDeleteBtn = (dataItem) => {
    setPopUpDelete(true);
    setDataItemToDelete(dataItem);
  };
  const onDeleteConfirmed = () => {
    deletefn(dataItemToDelete);
    setPopUpDelete(false);
    setDataItemToDelete();
  };

  return (
    <div className="careplandisplayContainer">
      {popUpDelete && (
        <div className="popUpDelete">
          <div className="popupContent">
            <p>Are you sure you want to delete this object?</p>
            <div className="popupActions">
              <button onClick={onDeleteConfirmed}>Delete</button>
              <button onClick={() => setPopUpDelete(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      {selecteddata?.Diagnosis?.slice()
        .reverse()
        .map(
          (el) =>
            el.Status === (type === "active" ? true : false) && (
              <div className="displayElement">
                <div className="elementGrid">
                  <div className="elementContent colSpan2">
                    <p className="eleTitle">ICD Code</p>
                    <p className="electnt">
                      {el.ICD_Code} ({el.Type})
                    </p>
                  </div>
                  <div className="elementContent colSpan2">
                    <p className="eleTitle">Recorded Date & Time</p>
                    <p className="electnt">{formatDate(el.RecordedDate)}</p>
                  </div>
                  <div className="elementContent colSpan2">
                    <p className="eleTitle">End Date & Time</p>
                    <p className="electnt">{formatDate(el.EndDate)}</p>
                  </div>

                  <div className="elementContent colSpan2 ICD_desc">
                    <p className="eleTitle">ICD Description</p>
                    <p className="electnt">{el.ICD_Description}</p>
                  </div>
                  <div className="elementContent colSpan2">
                    <p className="eleTitle">Last Assessment Date</p>
                    <p className="electnt">
                      {formatDate(el.LastAssessmentDate)}
                    </p>
                  </div>
                  <div className="elementContent colSpan2">
                    <p className="eleTitle">Assessment Summary</p>
                    <p className="electnt">{el.AssessmentSummary}</p>
                  </div>
                  <div className="elementContent colSpan3 Goal">
                    <p className="eleTitle">Goal</p>
                    {selecteddata?.GoalInformation.filter(
                      (x) => x.Diagnosis_id === el.Diagnosis_id
                    ).map((a) => (
                      <p className="electnt">{a.Goal}</p>
                    ))}
                  </div>
                  <div className="elementContent colSpan3">
                    <p className="eleTitle">LifeStyle</p>
                    {selecteddata?.LifeStyle.filter(
                      (x) => x.Diagnosis_id === el.Diagnosis_id
                    ).map((a) => (
                      <p className="electnt">{a.Type}</p>
                    ))}
                  </div>
                </div>
                <div className="BtnGrid">
                  {type === "active" ? (
                    <IconContainer
                      iconsList={
                        props.iconsList ?? [
                          {
                            value: "uil:edit",
                            onClickFn: () => editfn(el.Diagnosis_id),
                          },
                          {
                            value: "carbon:delete",
                            onClickFn: () => onClickDeleteBtn(el.Diagnosis_id),
                          },
                        ]
                      }
                      bckColorwhite={true}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )
        )}
    </div>
  );
};

export default CarePlanDisplay;
