import React from "react";

const DateComponent = ({ question, onClickFn, id, value }) => {
  return (
    <div className="inputComponent">
      <span>
        <input
          type="date"
          onChange={(e) => onClickFn(e.target.value, "answer")}
          value={value?.response}
        />
      </span>
    </div>
  );
};

export default DateComponent;
