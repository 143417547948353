import React, { useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";

const FoldableComponent = (props) => {
  const { open, title, children } = props;
  const [showContent, setShowContent] = useState(open ? true : false);
  const onClickHandler = () => {
    setShowContent((prev) => !prev);
  };
  return (
    <>
      <div className="notesContainer">
        <div className="title">
          <div className="title_icon" onClick={onClickHandler}>
            {showContent ? (
              <Icon
                className="icon"
                icon="gridicons:dropdown"
                vFlip={true}
                width="25"
                height="25"
              />
            ) : (
              <Icon
                className="icon"
                icon="gridicons:dropdown"
                width="25"
                height="25"
              />
            )}
            <p>{title}</p>
          </div>
        </div>
        {showContent && <div className="questionariebody">{children}</div>}
      </div>
    </>
  );
};

export default FoldableComponent;
