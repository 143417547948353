import { configureStore } from "@reduxjs/toolkit";
import loginDataReducer from "./features/loginDataSlice";
import navigationReducer from "./features/navigationSlice";
import facilityDataReducer from "./features/facilityDataSlice";
import providerDataReducer from "./features/providerDataSlice";
import clinicianDataReducer from "./features/clinicianDataSlice";
import patientsDataReducer from "./features/patientsDataSlice";
import patientsInsuranceDataReducer from "../app/features/patientsInsuranseDataSlice";
import accessLevelDataReducer from "./features/accessLevelDataSlice";
import callinPageDataReducer from "./features/callinPageDataSlice";

import authDataReducer from "./features/authSlice";
import { apiCollection } from "./api/apiCollection";
export const store = configureStore({
  reducer: {
    [apiCollection.reducerPath]: apiCollection.reducer,
    loginData: loginDataReducer,
    navigationData: navigationReducer,
    facilityData: facilityDataReducer,
    providerData: providerDataReducer,
    clinicianData: clinicianDataReducer,
    patientsData: patientsDataReducer,
    patientsInsuranceData: patientsInsuranceDataReducer,
    accessLevelData: accessLevelDataReducer,
    authData: authDataReducer,
    callingData: callinPageDataReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      apiCollection.middleware
    ),
  devTools: false,
});
