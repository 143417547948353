import React from "react";
import classNames from "classnames";
import "../sass/technicianWidget.scss";
const TechnicianWidget = ({
  data,
  disabled,
  idKey,
  nameKey,
  onClickSelect,
  onClickToggle,
}) => {
  const { AccessLevel, Selected, EffectiveDate } = data;
  var updatedDate = EffectiveDate.substring(0, 19).replace("T", "|");
  const id = data[idKey];
  const name = data[nameKey];

  return (
    <div className="TechnicianWidget">
      <div className="element-cont colSpan4">
        <input
          disabled={disabled}
          className="checkbox"
          type={"checkbox"}
          onClick={() => onClickSelect(id, idKey)}
          checked={Selected}
        />
        <p className="tabelement ">
          {name} <br />
          <span className="effect-date">Effective Date: {updatedDate}</span>
        </p>
      </div>
      <div className="toggle-btn">
        <button
          disabled={disabled || !Selected}
          onClick={() => onClickToggle(id, idKey)}
          className={classNames("toggle-cont", { active: AccessLevel })}
        >
          <div className="slider"></div>
        </button>
      </div>
      <p className="accessDisplay">
        {AccessLevel ? "All " : "Assigned "}
        {/* <br /> */}
        Patients
      </p>
    </div>
  );
};

export default TechnicianWidget;
