import React from "react";
import { useState, useEffect } from "react";
import DropDownContent from "../../../components/DropDownContent";
import ActiveHistoryDropDown2 from "../../../components/ActiveHistoryDropDown2";
import AddDetailGeneral from "../addDetailsGeneral/AddDetailGeneral";
import CarePlanDisplayGeneral from "../CarePlanDisplayGeneral/CarePlanDisplayGeneral";
import TabElement from "../../../components/TabElement";
import FloatingNotifications from "../../../utilities/FloatingNotifications";
import TabWidget from "../../../components/TabWidget";
import {
  resetfn,
  basicValidation,
  areArraysSame,
  careplanview,
  generalHandleValueChanges,
  generalSaveButton,
  cancleOperationFn,
  statusfinderFn,
} from "../helperfunctions";
import CarePlanErrorMessage from "../CarePlanErrorMessage";

const LifeStyle = (props) => {
  const { selectedPatient, id, updateDatafn } = props;
  const [lifestyleRemarksWindow, setLifestyleRemarksWindow] = useState("");
  const addLifestyleRemarksDetail = () => {
    setLifestyleRemarksWindow("addDetails");
    resetfn(lifestyleRemarks, setLifestyleRemarks);
  };
  const [lifestyleRemarksArray, setLifestyleRemarksArray] = useState([]);
  const [editArray, setEditArray] = useState();
  const [active, setActive] = useState("Active");

  const [lifestyleRemarks, setLifestyleRemarks] = useState({
    RecordedDate: "",
    Smoking: "",
    SmokingRemarks: "",
    RecreationalDrugs: "",
    RecreationalDrugsRemarks: "",
    Caffeine: "",
    CaffeineRemarks: "",
    Alcohol: "",
    AlcoholRemarks: "",
    PhysicalActivity: "",
    PhysicalActivityRemarks: "",
    Status: true,
  });
  const onClickFn = () => {
    if (basicValidation(lifestyleRemarks)) {
      generalSaveButton(
        lifestyleRemarksWindow,
        setLifestyleRemarksWindow,
        lifestyleRemarks,
        setLifestyleRemarksArray,
        editArray,
        setEditArray
      );
    } else {
      FloatingNotifications("error", "Fill mandatory fields to proceed");
    }
  };
  useEffect(() => {
    if (
      basicValidation(lifestyleRemarks) &&
      !areArraysSame(selectedPatient?.LifestyleRemarks, lifestyleRemarksArray)
    ) {
      updateDatafn({ PatientId: id, LifestyleRemarks: lifestyleRemarksArray });
    }
  }, [lifestyleRemarksArray]);

  useEffect(() => {
    if (selectedPatient) {
      setLifestyleRemarksArray(selectedPatient.LifestyleRemarks);
    }
  }, [selectedPatient]);
  return (
    <div>
      <DropDownContent
        title={"Lifestyle"}
        children={
          <div>
            <TabWidget>
              {["Active", "History"]?.map((el) => (
                <TabElement
                  key={`patients_${el}`}
                  title={el}
                  active={active === el}
                  onClick={() => {
                    setActive(el);
                  }}
                />
              ))}
            </TabWidget>
            {active === "Active" && (
              <ActiveHistoryDropDown2
                type={"Active"}
                iconsList={[
                  {
                    value: "ic:twotone-plus",
                    onClickFn: addLifestyleRemarksDetail,
                  },
                  {
                    value: "mingcute:save-line",
                    onClickFn:
                      lifestyleRemarksWindow === "addDetails"
                        ? () => onClickFn()
                        : false,
                  },
                  {
                    value: "majesticons:multiply-line",
                    onClickFn: () =>
                      cancleOperationFn(setLifestyleRemarksWindow),
                  },
                ]}
                children={
                  <div>
                    {lifestyleRemarksWindow === "addDetails" ? (
                      <AddDetailGeneral data={selectedPatient}>
                        <>
                          <p className="displayTitleGnrl">
                            Lifestyle Information
                          </p>
                          <div className="display-sec-Gnrl">
                            <div className="display-content-life">
                              <label className="display-element-Gnrl">
                                Recorded Date & Time*
                              </label>
                              <input
                                className="display-element-Gnrl "
                                type="datetime-local"
                                id="meeting-time"
                                name="meeting-time"
                                value={lifestyleRemarks.RecordedDate.slice(
                                  0,
                                  16
                                )}
                                onChange={(event) =>
                                  generalHandleValueChanges(
                                    event.target.value,
                                    setLifestyleRemarks,
                                    "RecordedDate"
                                  )
                                }
                              />

                              <div className="display-life-div">
                                <div className="life-head">
                                  <label className="display-element-Gnrl">
                                    Smoking*
                                  </label>
                                </div>
                                <div className="life-sub-element">
                                  <div className="life-subhead">
                                    <span className="life-span-element">
                                      <input
                                        type="radio"
                                        name="smokingStatus"
                                        id="SmokingYes"
                                        value="yes"
                                        checked={
                                          lifestyleRemarks.Smoking === "Yes"
                                        }
                                        onChange={() =>
                                          generalHandleValueChanges(
                                            "Yes",
                                            setLifestyleRemarks,
                                            "Smoking"
                                          )
                                        }
                                      />
                                      <label for="SmokingYes">Yes</label>
                                    </span>
                                    <span className="life-span-element">
                                      <input
                                        type="radio"
                                        name="smokingStatus"
                                        id="SmokingNo"
                                        value="No"
                                        checked={
                                          lifestyleRemarks.Smoking === "No"
                                        }
                                        onChange={() =>
                                          generalHandleValueChanges(
                                            "No",
                                            setLifestyleRemarks,
                                            "Smoking"
                                          )
                                        }
                                      />
                                      <label for="SmokingNo">No</label>
                                    </span>
                                    <span className="life-span-element">
                                      <input
                                        type="radio"
                                        name="smokingStatus"
                                        id="smokingNotAvailable"
                                        value="Not Available"
                                        checked={
                                          lifestyleRemarks.Smoking ===
                                          "Not Available"
                                        }
                                        onChange={() =>
                                          generalHandleValueChanges(
                                            "Not Available",
                                            setLifestyleRemarks,
                                            "Smoking"
                                          )
                                        }
                                      />
                                      <label for="smokingNotAvailable">
                                        Not Available
                                      </label>
                                    </span>
                                  </div>
                                  <div className="life-textarea">
                                    <textarea
                                      className="display-element-Gnrl"
                                      value={lifestyleRemarks.SmokingRemarks}
                                      onChange={(event) =>
                                        generalHandleValueChanges(
                                          event.target.value,
                                          setLifestyleRemarks,
                                          "SmokingRemarks"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="display-life-div">
                                <div className="life-head">
                                  <label className="display-element-Gnrl">
                                    Recreational Drugs*
                                  </label>
                                </div>
                                <div className="life-sub-element">
                                  <div className="life-subhead">
                                    <span className="life-span-element">
                                      <input
                                        type="radio"
                                        name="RecreationalDrugs"
                                        id="RecreationalDrugsYes"
                                        value="yes"
                                        checked={
                                          lifestyleRemarks.RecreationalDrugs ===
                                          "Yes"
                                        }
                                        onChange={() =>
                                          generalHandleValueChanges(
                                            "Yes",
                                            setLifestyleRemarks,
                                            "RecreationalDrugs"
                                          )
                                        }
                                      />
                                      <label for="RecreationalDrugsYes">
                                        Yes
                                      </label>
                                    </span>
                                    <span className="life-span-element">
                                      <input
                                        type="radio"
                                        name="RecreationalDrugs"
                                        id="RecreationalDrugsNo"
                                        value="No"
                                        checked={
                                          lifestyleRemarks.RecreationalDrugs ===
                                          "No"
                                        }
                                        onChange={() =>
                                          generalHandleValueChanges(
                                            "No",
                                            setLifestyleRemarks,
                                            "RecreationalDrugs"
                                          )
                                        }
                                      />
                                      <label for="RecreationalDrugsNo">
                                        No
                                      </label>
                                    </span>
                                    <span className="life-span-element">
                                      <input
                                        type="radio"
                                        name="RecreationalDrugs"
                                        id="RecreationalDrugsNot"
                                        value="Not Available"
                                        checked={
                                          lifestyleRemarks.RecreationalDrugs ===
                                          "Not Available"
                                        }
                                        onChange={() =>
                                          generalHandleValueChanges(
                                            "Not Available",
                                            setLifestyleRemarks,
                                            "RecreationalDrugs"
                                          )
                                        }
                                      />
                                      <label for="RecreationalDrugsNot">
                                        Not Available
                                      </label>
                                    </span>
                                  </div>
                                  <div className="life-textarea">
                                    <textarea
                                      className="display-element-Gnrl"
                                      value={
                                        lifestyleRemarks.RecreationalDrugsRemarks
                                      }
                                      onChange={(event) =>
                                        generalHandleValueChanges(
                                          event.target.value,
                                          setLifestyleRemarks,
                                          "RecreationalDrugsRemarks"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="display-life-div">
                                <div className="life-head">
                                  <label className="display-element-Gnrl">
                                    Caffeine*
                                  </label>
                                </div>
                                <div className="life-sub-element">
                                  <div className="life-subhead">
                                    <span className="life-span-element">
                                      <input
                                        type="radio"
                                        name="CaffeineStatus"
                                        id="CaffeineYes"
                                        value="yes"
                                        checked={
                                          lifestyleRemarks.Caffeine === "Yes"
                                        }
                                        onChange={() =>
                                          generalHandleValueChanges(
                                            "Yes",
                                            setLifestyleRemarks,
                                            "Caffeine"
                                          )
                                        }
                                      />
                                      <label for="CaffeineYes">Yes</label>
                                    </span>
                                    <span className="life-span-element">
                                      <input
                                        type="radio"
                                        name="CaffeineStatus"
                                        value="No"
                                        id="CaffeineNo"
                                        checked={
                                          lifestyleRemarks.Caffeine === "No"
                                        }
                                        onChange={() =>
                                          generalHandleValueChanges(
                                            "No",
                                            setLifestyleRemarks,
                                            "Caffeine"
                                          )
                                        }
                                      />
                                      <label for="CaffeineNo">No</label>
                                    </span>
                                    <span className="life-span-element">
                                      <input
                                        type="radio"
                                        name="CaffeineStatus"
                                        value="Not Available"
                                        id="CaffeineNot"
                                        checked={
                                          lifestyleRemarks.Caffeine ===
                                          "Not Available"
                                        }
                                        onChange={() =>
                                          generalHandleValueChanges(
                                            "Not Available",
                                            setLifestyleRemarks,
                                            "Caffeine"
                                          )
                                        }
                                      />
                                      <label for="CaffeineNot">
                                        Not Available
                                      </label>
                                    </span>
                                  </div>
                                  <div className="life-textarea">
                                    <textarea
                                      className="display-element-Gnrl"
                                      value={lifestyleRemarks.CaffeineRemarks}
                                      onChange={(event) =>
                                        generalHandleValueChanges(
                                          event.target.value,
                                          setLifestyleRemarks,
                                          "CaffeineRemarks"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="display-life-div">
                                <div className="life-head">
                                  <label className="display-element-Gnrl">
                                    Alcohol*
                                  </label>
                                </div>
                                <div className="life-sub-element">
                                  <div className="life-subhead">
                                    <span className="life-span-element">
                                      <input
                                        type="radio"
                                        name="Alcohol"
                                        id="AlcoholYes"
                                        value="yes"
                                        checked={
                                          lifestyleRemarks.Alcohol === "Yes"
                                        }
                                        onChange={() =>
                                          generalHandleValueChanges(
                                            "Yes",
                                            setLifestyleRemarks,
                                            "Alcohol"
                                          )
                                        }
                                      />
                                      <label for="AlcoholYes">Yes</label>
                                    </span>
                                    <span className="life-span-element">
                                      <input
                                        type="radio"
                                        name="Alcohol"
                                        id="AlcoholNo"
                                        value="No"
                                        checked={
                                          lifestyleRemarks.Alcohol === "No"
                                        }
                                        onChange={() =>
                                          generalHandleValueChanges(
                                            "No",
                                            setLifestyleRemarks,
                                            "Alcohol"
                                          )
                                        }
                                      />
                                      <label for="AlcoholNo">No</label>
                                    </span>
                                    <span className="life-span-element">
                                      <input
                                        type="radio"
                                        name="Alcohol"
                                        value="Not Available"
                                        id="AlcoholNot"
                                        checked={
                                          lifestyleRemarks.Alcohol ===
                                          "Not Available"
                                        }
                                        onChange={() =>
                                          generalHandleValueChanges(
                                            "Not Available",
                                            setLifestyleRemarks,
                                            "Alcohol"
                                          )
                                        }
                                      />
                                      <label for="AlcoholNot">
                                        Not Available
                                      </label>
                                    </span>
                                  </div>
                                  <div className="life-textarea">
                                    <textarea
                                      className="display-element-Gnrl"
                                      value={lifestyleRemarks.AlcoholRemarks}
                                      onChange={(event) =>
                                        generalHandleValueChanges(
                                          event.target.value,
                                          setLifestyleRemarks,
                                          "AlcoholRemarks"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="display-life-div">
                                <div className="life-head">
                                  <label className="display-element-Gnrl">
                                    Physical Activity*
                                  </label>
                                </div>
                                <div className="life-sub-element">
                                  <div className="life-subhead">
                                    <span className="life-span-element">
                                      <input
                                        type="radio"
                                        name="PhysicalActivity"
                                        id="PhysicalActivityYes"
                                        value="yes"
                                        checked={
                                          lifestyleRemarks.PhysicalActivity ===
                                          "Yes"
                                        }
                                        onChange={() =>
                                          generalHandleValueChanges(
                                            "Yes",
                                            setLifestyleRemarks,
                                            "PhysicalActivity",
                                            true
                                          )
                                        }
                                      />
                                      <label for="PhysicalActivityYes">
                                        Yes
                                      </label>
                                    </span>
                                    <span className="life-span-element">
                                      <input
                                        type="radio"
                                        name="PhysicalActivity"
                                        value="No"
                                        id="PhysicalActivityNo"
                                        checked={
                                          lifestyleRemarks.PhysicalActivity ===
                                          "No"
                                        }
                                        onChange={() =>
                                          generalHandleValueChanges(
                                            "No",
                                            setLifestyleRemarks,
                                            "PhysicalActivity"
                                          )
                                        }
                                      />
                                      <label for="PhysicalActivityNo">No</label>
                                    </span>
                                    <span className="life-span-element">
                                      <input
                                        type="radio"
                                        name="PhysicalActivity"
                                        value="Not Available"
                                        id="PhysicalActivityNot"
                                        checked={
                                          lifestyleRemarks.PhysicalActivity ===
                                          "Not Available"
                                        }
                                        onChange={() =>
                                          generalHandleValueChanges(
                                            "Not Available",
                                            setLifestyleRemarks,
                                            "PhysicalActivity"
                                          )
                                        }
                                      />
                                      <label for="PhysicalActivityNot">
                                        Not Available
                                      </label>
                                    </span>
                                  </div>
                                  <div className="life-textarea">
                                    <textarea
                                      className="display-element-Gnrl"
                                      value={
                                        lifestyleRemarks.PhysicalActivityRemarks
                                      }
                                      onChange={(event) =>
                                        generalHandleValueChanges(
                                          event.target.value,
                                          setLifestyleRemarks,
                                          "PhysicalActivityRemarks"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>{" "}
                      </AddDetailGeneral>
                    ) : (
                      <>
                        {statusfinderFn(
                          "active",
                          selectedPatient.LifestyleRemarks
                        )?.length === 0 ? (
                          <CarePlanErrorMessage msg="Patient doesn’t have any LifestyleRemarks yet!" />
                        ) : (
                          <CarePlanDisplayGeneral
                            updateDatafn={updateDatafn}
                            name={"LifestyleRemarks"}
                            array={lifestyleRemarksArray}
                            id={id}
                            careplanview={careplanview}
                            values={[
                              setLifestyleRemarksWindow,
                              setLifestyleRemarks,
                            ]}
                            dataSet={selectedPatient?.LifestyleRemarks ?? []}
                            data={statusfinderFn(
                              "active",
                              selectedPatient.LifestyleRemarks
                            )}
                            setEditArray={setEditArray}
                            contentArray={[
                              [
                                "Recorded Date & time:",
                                "RecordedDate",
                                "colSpan12",
                              ],
                              ["Smoking:", "Smoking", "colSpan6"],
                              ["Remarks:", "SmokingRemarks", "colSpan6"],
                              [
                                "Recreational Drugs:",
                                "RecreationalDrugs",
                                "colSpan6",
                              ],
                              [
                                "Remarks:",
                                "RecreationalDrugsRemarks",
                                "colSpan6",
                              ],
                              ["Caffeine:", "Caffeine", "colSpan6"],
                              ["Remarks:", "CaffeineRemarks", "colSpan6"],
                              ["Alcohol:", "Alcohol", "colSpan6"],
                              ["Remarks:", "AlcoholRemarks", "colSpan6"],
                              [
                                "PhysicalActivity:",
                                "PhysicalActivity",
                                "colSpan6",
                              ],
                              [
                                "Remarks:",
                                "PhysicalActivityRemarks",
                                "colSpan6",
                              ],
                            ]}
                          />
                        )}
                      </>
                    )}
                  </div>
                }
              />
            )}
            {active === "History" && (
              <ActiveHistoryDropDown2
                type={"History"}
                children={
                  <>
                    {" "}
                    {statusfinderFn("history", selectedPatient.LifestyleRemarks)
                      ?.length === 0 ? (
                      <></>
                    ) : (
                      <CarePlanDisplayGeneral
                        Type={"history"}
                        data={statusfinderFn(
                          "history",
                          selectedPatient.LifestyleRemarks
                        )}
                        contentArray={[
                          [
                            "Recorded Date & time:",
                            "RecordedDate",
                            "colSpan12",
                          ],
                          ["Smoking:", "Smoking", "colSpan6"],
                          ["Remarks:", "SmokingRemarks", "colSpan6"],
                          [
                            "Recreational Drugs:",
                            "RecreationalDrugs",
                            "colSpan6",
                          ],
                          ["Remarks:", "RecreationalDrugsRemarks", "colSpan6"],
                          ["Caffeine:", "Caffeine", "colSpan6"],
                          ["Remarks:", "CaffeineRemarks", "colSpan6"],
                          ["Alcohol:", "Alcohol", "colSpan6"],
                          ["Remarks:", "AlcoholRemarks", "colSpan6"],
                          ["PhysicalActivity:", "PhysicalActivity", "colSpan6"],
                          ["Remarks:", "PhysicalActivityRemarks", "colSpan6"],
                        ]}
                      />
                    )}
                  </>
                }
              />
            )}
          </div>
        }
      />
    </div>
  );
};

export default LifeStyle;
