import { apiCollection } from "./apiCollection";

export const accessLevelApi = apiCollection.injectEndpoints({
  endpoints: (build) => ({
    getAccessLevel: build.query({
      query: () => ({
        url: "/facility/accessLevel",
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("RLH_token")}`,
        // },
      }),
    }),
  }),
});

export const { useGetAccessLevelQuery } = accessLevelApi;
