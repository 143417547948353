import React from "react";
import { useState, useEffect } from "react";
import FloatingNotifications from "../../utilities/FloatingNotifications";
import SelectComponent from "../../components/SelectComponent";
import { useEditServicesMutation } from "../../app/api/billingApi";
import { useTimerfinalapiMutation } from "../../app/api/timerapifinal";
import {
  generalHandleValueChanges,
  basicValidation,
} from "../Careteam/helperfunctions";
import "../parientsPreviewPage/addServices.scss";
const AddService = ({
  patientDataForService,
  cancelfn,
  getBillingHistory,
  programName,
  editData,
}) => {
  const [loading, setLoading] = useState(false);
  const [addServicesFn, { data: serviceData, error }] =
    useTimerfinalapiMutation();
  const [updateServiceFn, { data: updatedData, error: updatingError }] =
    useEditServicesMutation();
  const initialState = {
    PatientId: patientDataForService?.PatientId,
    serviceName: "",
    programName: "CCM",
    serviceType: "Manual",
    startTime: "",
    acutalTimeSpent: "00:20:00",
    remark: "",
  };
  const [service, setService] = useState(initialState);

  const saveProgram = async () => {
    setLoading(true);
    setLoading(false);
    if (!basicValidation(service)) {
      FloatingNotifications(
        "error",
        "To proceed, all fields must be filled out."
      );
      setLoading(false);
      return;
    }
    let endTime = findEndServiceTimeFn(
      service?.startTime,
      service.acutalTimeSpent
    );

    if (editData) {
      updateServiceFn({
        PatientId: patientDataForService?.PatientId,
        programName: service.programName,
        acutalTimeSpent: service?.acutalTimeSpent,
        startTime: new Date(service.startTime).toISOString(),
        endTime: endTime,
        remark: service.remark,
        serviceType: editData?.serviceType,
        serviceName: editData?.serviceName,
        serviceId: editData?.serviceId,
      });

      return;
    }
    const updatedService = {
      ...service,
      startTime: new Date(service.startTime).toISOString(),
      endTime: endTime,
    };
    addServicesFn(updatedService);
  };

  const getCurrentDateTimeLocal = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = `${now.getMonth() + 1}`.padStart(2, "0"); // Months are zero-based
    const day = `${now.getDate()}`.padStart(2, "0");
    const hours = "00";
    const minutes = "00";
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  useEffect(() => {
    if (!serviceData) return;
    setService(initialState);
    if (getBillingHistory) {
      getBillingHistory();
    }
    FloatingNotifications("success", "Service added successfully");
    cancelfn();
  }, [serviceData]);

  useEffect(() => {
    if (!updatedData) return;
    setService(initialState);
    if (getBillingHistory) {
      getBillingHistory();
    }
    cancelfn();
  }, [updatedData]);

  useEffect(() => {
    if (!programName) return;
    setService((prev) => ({
      ...prev,
      programName: programName,
    }));
  }, [programName]);

  const formatToLocalDateTime = (isoDateString) => {
    const date = new Date(isoDateString);

    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, "0"); // Months are zero-based
    const day = `${date.getDate()}`.padStart(2, "0");
    const hours = `${date.getHours()}`.padStart(2, "0");
    const minutes = `${date.getMinutes()}`.padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const findEndServiceTimeFn = (originalTimeString, duration) => {
    const startTime = new Date(originalTimeString);

    const totalTimeSpent = duration;
    const [totalHours, totalMinutes, totalSeconds] = totalTimeSpent
      .split(":")
      .map(Number);

    const totalDuration =
      (totalHours * 60 * 60 + totalMinutes * 60 + totalSeconds) * 1000;

    const endTime = new Date(startTime.getTime() + totalDuration);

    return endTime.toISOString();
  };
  useEffect(() => {
    if (!editData) return;
    setService((prev) => ({
      PatientId: patientDataForService?.PatientId,
      startTime: formatToLocalDateTime(editData?.startTime),
      acutalTimeSpent: editData.acutalTimeSpent,
      remark: editData.remark,
      serviceType: editData.serviceType,
      serviceName: editData.serviceName,
      programName: editData.programName,
    }));
  }, [editData]);

  useEffect(() => {
    if (error) {
      FloatingNotifications("error", "Add service failed.");
    }
    if (updatingError) {
      FloatingNotifications("error", "Update service failed.");
    }
  }, [error, updatingError]);
  return (
    <div className="addServices">
      <p className="popTitle">{`Add Service for ${patientDataForService?.Name}`}</p>
      <div className="addContainer">
        <div>
          <div className="popElement">
            <p className="popLabel">Patient Name</p>
            <input
              type="text"
              className="viewElement "
              disabled={true}
              value={patientDataForService?.Name}
            />
          </div>
          <div className="popElement">
            <p className="popLabel">Service Entry Type</p>
            <input
              type="text"
              className="viewElement "
              disabled={true}
              value={"Manual"}
            />
          </div>
          <div className="popElement">
            <p className="popLabel">Program Name *</p>
            <SelectComponent
              disabled={editData ? true : false}
              OptionsArray={["CCM", "RPM"]}
              valueData={service.programName}
              fn={generalHandleValueChanges}
              setvalue={setService}
              name={"programName"}
            />
          </div>
          <div className="popElement">
            <p className="popLabel">Service Name *</p>
            <SelectComponent
              disabled={editData ? true : false}
              OptionsArray={[
                "Scheduling Care Management Call",
                "Non Complex CCM Service",
                "Complex CCM Service",
                "Complex CCM Add on",
                "Prepare Call Summary Notes",
                "Counsel Patient",
                "E-Mail Communication to Patient",
                "Communicating Physician Appointment Requests",
                "Care Plan Entry",
                "Get Encounter Details From EMR for Care Plan Data Entry",
                "Review Care Plan",
                "Review and Add Notes to Care Summary",
                "Review Medication and Modify Prescriptions",
                "Medium to Complex Medical Decision Making",
                "Referral to Other Physicians",
                "Appointment Scheduling for In Person Visit",
                "Phone Communication to Patient Tracked Manually",
                "Call Summary Recommendations",
                "Communicating Appointment Schedule",
                "Directions for Patient Interaction",
                "Communicate Over Phone",
                "Communicate Over E-Mail",
                "Care Plan Entry and Edit",
                "Phone Call With Physician About Patient",
                "E-Mail Communication With Physician Patient",
                "Call from Pharmacy to Discuss Medication Allergies Reported By Patient",
                "Approve Prescription Refill",
                "Share Care Plan",
                "Obtain Encounter Details for Patient Visits",
                "Phone Call With Physician",
                "E-Mail Communication to Physician",
                "Order Lab Test",
                "Review Lab Reports",
                "Conversation About Patient",
                "E-Mail Communication to Care Givers",
                "Phone Communication With Care Givers",
                "Review of Consultant’s Recommendation",
                "Review of Home Health Care Plan",
                "Durable Medical Equipment Review",
                "Review of PT/OT Plan",
                "Patient Vital Reading Measurement and Review",
                "Patient Diet Analysis",
                "Other",
              ]}
              valueData={service.serviceName}
              fn={generalHandleValueChanges}
              setvalue={setService}
              name={"serviceName"}
            />
          </div>

          <div className="popElement">
            <p className="popLabel">Service Date *</p>
            <input
              type="datetime-local"
              id="meeting-time"
              name="meeting-time"
              max={getCurrentDateTimeLocal()}
              value={service?.startTime}
              className="viewElement "
              onChange={(event) =>
                generalHandleValueChanges(event, setService, "startTime")
              }
            />
          </div>
        </div>
        <div>
          <div className="popElement">
            <p className="popLabel">Time Spent (HH:MI:SS) *</p>
            <input
              id="appt-time"
              type="time"
              name="appt-time"
              step="2"
              value={service?.acutalTimeSpent}
              className="viewElement "
              onChange={(event) =>
                generalHandleValueChanges(event, setService, "acutalTimeSpent")
              }
            />
          </div>
          <div className="popElement">
            <p className="popLabel">Remark *</p>
            <textarea
              type="textarea"
              value={service.remark}
              className="viewElement "
              onChange={(event) =>
                generalHandleValueChanges(event, setService, "remark")
              }
            />
          </div>
        </div>
      </div>
      <div className="popBtn">
        <button onClick={saveProgram} disabled={loading}>
          Save
        </button>
        {/* <button onClick={cancelfn}>Cancel</button> */}
      </div>
    </div>
  );
};

export default AddService;
