import { apiCollection } from "./apiCollection";

const patientsApi = apiCollection.injectEndpoints({
  endpoints: (build) => ({
    PatientsAddDetails: build.mutation({
      query: (payload) => {
        // const { patientPhoto, ...data } = payload;
        // console.log(patientPhoto, data);

        return { url: "/patient/addDetails", method: "POST", body: payload };
      },
    }),
    searchPatientsByFPC: build.mutation({
      query: (payload) => {
        return { url: "/patient/selectByFPC", method: "POST", body: payload };
      },
    }),
    updatePatients: build.mutation({
      query: (payload) => {
        // const { patientPhoto, ...data } = payload;
        // console.log(patientPhoto, data);

        return { url: "/patient/updateDetails", method: "PUT", body: payload };
      },
      transformResponse: (response, meta, arg) => {
        if (response.FacilityId && response.FacilityId._id) {
          response.FacilityId = response.FacilityId._id;
          delete response.FacilityId._id;
        }
        if (response.ProviderId && response.ProviderId._id) {
          response.ProviderId = response.ProviderId._id;
          delete response.ProviderId._id;
        }
        if (response.PrimaryCoordinator && response.PrimaryCoordinator._id) {
          response.PrimaryCoordinator = response.PrimaryCoordinator._id;
          delete response.PrimaryCoordinator._id;
        }
        return response;
      },
      invalidatesTags: ["Posts"],
    }),

    getPatientsAllProfiles: build.query({
      query: () => ({
        url: "/patient/allProfile",
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("RLH_token")}`,
        // },
      }),
    }),
    getListAllEligiblePatients: build.query({
      query: () => ({
        url: "patient/ListEligible",
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("RLH_token")}`,
        // },
      }),
      providesTags: (result) =>
        result
          ? result.map(({ PatientId }) => ({ type: "Posts", id: PatientId }))
          : ["Posts"],
    }),
    getPatientsCarePlan: build.mutation({
      query: (payload) => {
        return {
          url: "/careplan/selectedProfile",
          method: "POST",
          body: payload,
        };
      },
      // transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
    updateCarePlan: build.mutation({
      query: (payload) => {
        return { url: "/careplan/addDetails", method: "POST", body: payload };
      },
      // transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
    getPatientSelectedProfile: build.mutation({
      query: (payload) => {
        return {
          url: "patient/selectedProfile",
          method: "POST",
          body: payload,
        };
      },
      transformResponse: (response, meta, arg) => {
        if (response.FacilityId && response.FacilityId._id) {
          response.FacilityId = response.FacilityId._id;
          delete response.FacilityId._id;
        }

        if (response.ProviderId && response.ProviderId._id) {
          response.ProviderName = response.ProviderId.ProviderName;
          response.ProviderId = response.ProviderId._id;
          delete response.ProviderId._id;
        }

        if (response.PrimaryCoordinator && response.PrimaryCoordinator._id) {
          response.PrimaryCoordinatorName =
            response.PrimaryCoordinator.ClinicianName;
          response.PrimaryCoordinator = response.PrimaryCoordinator._id;
          delete response.PrimaryCoordinator._id;
        }
        return response;
      },
    }),
    updateFPC: build.mutation({
      query: (payload) => {
        return {
          url: "/patient/updateFPC",
          method: "POST",
          body: payload,
        };
      },

      // transformErrorResponse: (response, meta, arg) => response.data.message,
    }),

    getICDcode: build.mutation({
      query: (payload) => {
        return {
          url: "careplan/searchIcd",
          method: "POST",
          body: {
            searchValue: payload,
          },
        };
      },
      // transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
    getMedication: build.mutation({
      query: (payload) => {
        return {
          url: "careplan/searchMedication",
          method: "POST",
          body: {
            searchValue: payload,
          },
        };
      },
      // transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
    getSNOMED: build.mutation({
      query: (payload) => {
        return {
          url: "careplan/searchSnomedCt",
          method: "POST",
          body: {
            searchValue: payload,
          },
        };
      },
      // transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
    timerApi: build.mutation({
      query: (payload) => {
        return {
          url: "careplan/timer",
          method: "POST",
          body: payload,
        };
      },
      // transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
    StatusTimer: build.mutation({
      query: (payload) => {
        return {
          url: "careplan/getTimerStatusr",
          method: "POST",
          body: payload,
        };
      },
      // transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
    bulkUploadPatients: build.mutation({
      query: (payload) => {
        return {
          url: "/patient/uploadPatients",
          method: "POST",
          body: payload,
        };
      },
      // transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
  }),
  overrideExisting: false,
});

export const {
  usePatientsAddDetailsMutation,
  useGetPatientsAllProfilesQuery,
  useGetPatientsCarePlanMutation,
  useUpdateCarePlanMutation,
  useGetPatientSelectedProfileMutation,
  useGetICDcodeMutation,
  useGetMedicationMutation,
  useGetSNOMEDMutation,
  useUpdatePatientsMutation,
  useTimerApiMutation,
  useStatusTimerMutation,
  useGetListAllEligiblePatientsQuery,
  useBulkUploadPatientsMutation,
  useSearchPatientsByFPCMutation,
  useUpdateFPCMutation,
} = patientsApi;
