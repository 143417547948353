import React from "react";
import classNames from "classnames";
import "../sass/tabElement.scss";

const TabElement = ({ title, active, onClick }) => {
  return (
    <div className={classNames("tabElement", { active })} onClick={onClick}>
      {title}
    </div>
  );
};

export default React.memo(TabElement);
