import React, { useState } from "react";
import { Icon } from "@iconify/react";
import IconContainer from "../patients/Careteam/iconContainer/iconContainer";

import "../sass/activeHistoryDropDown.scss";

function ActiveHistoryDropDown(props) {
  const { children, iconsList, type, callList, clickForShedule, open } = props;
  const [showContent, setShowContent] = useState(open ?? true);
  const onClickHandler = () => {
    setShowContent((prev) => !prev);
  };
  return (
    <>
      <div className="Container">
        <div className="title">
          <div className="title_icon" onClick={onClickHandler}>
            {showContent ? (
              <Icon
                className="icon"
                icon="gridicons:dropdown"
                vFlip={true}
                width="25"
                height="25"
              />
            ) : (
              <Icon
                className="icon"
                icon="gridicons:dropdown"
                width="25"
                height="25"
              />
            )}
            <p>{type}</p>
          </div>

          <div>
            {!callList ? (
              <IconContainer iconsList={iconsList} />
            ) : (
              <button
                className="createSheduleButton"
                onClick={() => clickForShedule()}
              >
                <Icon icon="simple-line-icons:calender" />
                <p>Create Shedule</p>
              </button>
            )}
          </div>
        </div>
        {showContent && <div className="body">{children}</div>}
      </div>
    </>
  );
}

export default ActiveHistoryDropDown;
