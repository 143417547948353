import React from "react";
import "../sass/maintainance.scss";
import Img from "../images/maintenance/Maintenance.jpg";

const Maintenance = () => {
  return (
    <div className="maintainance">
      <img src={Img} alt="" />
    </div>
  );
};

export default Maintenance;
