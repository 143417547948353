import { useEffect, forwardRef, useImperativeHandle } from "react";
import classNames from "classnames";
import useInputText1 from "../hooks/use-inputText1";
import { ReactComponent as Info } from "../images/info.svg";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import "../widget-sass/inputTextField1.scss";

const InputTextField1 = forwardRef((props, ref) => {
  const {
    value,
    hasError,
    isValid,
    valueChangeHandler,
    submitClickHandler,
    reset,
    storeValue,
    storeDefaultValue,
  } = useInputText1(props.validation, props.charValidate);

  useImperativeHandle(ref, () => ({
    reset_data() {
      reset();
    },
    init_data(data) {
      storeValue(data);
    },
    default_data(data) {
      storeDefaultValue(data);
    },
    submit() {
      submitClickHandler();
    },
  }));

  useEffect(() => {
    props.storeData(value, isValid);
  }, [value, isValid]);
  return (
    <div
      className={classNames(
        "inputTextFieldContainer1",
        props.containerClassName
      )}
    >
      <label
        className={classNames("inputTextField", props.className, {
          invalid: hasError,
        })}
      >
        <div className="title">
          {props.title}
          <sup>{props.superTitle ?? ""}</sup>
          {hasError && (
            <Info className="error-icon" data-tooltip-id={props.element} />
          )}
        </div>
        <input
          tabIndex={props.tabIndex ?? ""}
          type={props.type ?? "text"}
          value={props.givenData ?? value}
          onChange={valueChangeHandler}
          placeholder={props.placeholder ?? ""}
          disabled={props.disabled ?? false}
          autoFocus={props.autoFocus ?? false}
        />
      </label>
      <Tooltip
        id={props.element}
        content={props.error_message}
        place="bottom"
      />
    </div>
  );
});

export default InputTextField1;
