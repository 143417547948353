import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLoginMutation } from "../app/api/loginApi";
import {
  goToForgotPasswordPage,
  goToTwoFA_Page,
  loginDataActions,
} from "../app/features/loginDataSlice";
import ButtonComponent from "../components/ButtonComponent";
import LoginInputTextboxField from "../fieldComponent/LoginInputTextboxField1";
import { isNotEmpty } from "../utilities/validationFunction";
import FloatingNotifications from "../utilities/FloatingNotifications";

const Login = () => {
  const dispatch = useDispatch();

  const [valid, setValid] = useState(false);
  const [validateLogin, { data, isLoading, error }] = useLoginMutation();
  const [misMatchText, setMisMatchText] = useState("");

  const storeData = useCallback((fieldName, value, validityStatus) => {
    setInputData((prevState) => {
      return { ...prevState, [fieldName]: value };
    });
    if (inputDataValidity.hasOwnProperty(fieldName)) {
      setInputDataValidity((prevState) => ({
        ...prevState,
        [fieldName]: validityStatus,
      }));
    }
  }, []);

  const resetRefs = {
    username: useRef(null),
    password: useRef(null),
  };

  const [inputData, setInputData] = useState({
    username: "",
    password: "",
  });

  const [inputDataValidity, setInputDataValidity] = useState({
    username: false,
    password: false,
  });

  useEffect(() => {
    const data = Object.keys(inputDataValidity).reduce(
      (prev, cur) => prev && inputDataValidity[cur],
      true
    );
    setValid(data);
  }, [inputDataValidity]);

  const onForgotPasswordClick = () => {
    dispatch(loginDataActions.switchPage(goToForgotPasswordPage()));
  };

  const onSubmitHandler = () => {
    if (valid) {
      validateLogin(inputData);
    }
  };
  useEffect(() => {
    // console.log(data);
    if (data?.code === 1000) {
      dispatch(loginDataActions.updateUsername(inputData.username));
      dispatch(loginDataActions.updatePassword(inputData.password));
      dispatch(loginDataActions.switchPage(goToTwoFA_Page()));
    } else {
      if (data?.msg) {
        setMisMatchText(data.msg);
      }
    }
  }, [data]);
  useEffect(() => {
    if (error) {
      FloatingNotifications("error", error?.data?.msg);
    }
  }, [error]);

  return (
    <>
      <div className="title">Login</div>
      <div className="desc">Please enter your username and password</div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmitHandler();
        }}
      >
        <LoginInputTextboxField
          ref={resetRefs["username"]}
          title="User Name"
          validation={isNotEmpty}
          storeData={storeData.bind(null, "username")}
          superText=""
          autoComplete="off"
          autoFocus={true}
        />
        {/* <div className="forgotUserName">Forgot username?</div> */}
        <LoginInputTextboxField
          ref={resetRefs["password"]}
          title="Password"
          type="password"
          validation={isNotEmpty}
          storeData={storeData.bind(null, "password")}
          superText=""
          autoComplete="new-password"
        />
        <div className="forgotPassword" onClick={onForgotPasswordClick}>
          Forgot password?
        </div>
        <ButtonComponent
          containerClassName="buttonContainer"
          onClick={onSubmitHandler}
          text="Login"
          disabled={isLoading}
        />
        {/* <div className="buttonContainer" onClick={onSubmitHandler}>
          <button>Login</button>
        </div> */}
      </form>
      <div className="issueNavigation">
        <span>Having trouble in Login</span>
      </div>
      <div
        className={classNames("errorLogin", { hidden: misMatchText === "" })}
      >
        {misMatchText === "" ? "hidden" : misMatchText}
      </div>
    </>
  );
};

export default Login;
