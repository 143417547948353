import React from "react";
import { Icon } from "@iconify/react";
import CircularProgress from "@mui/material/CircularProgress";
import "../sass/loaderComponent.scss";

const LoaderComponent = ({ isLoading }) => {
  return (
    isLoading && (
      <div className="loaderCont">
        <CircularProgress />
      </div>
    )
  );
};

export default LoaderComponent;
