import React, { useEffect, useState } from "react";
import SelectComponent from "../components/SelectComponent";

import { useAddAdminMutation, useGetAdminListQuery } from "../app/api/adminApi";
import "./adminPage.scss";
import { Icon } from "@iconify/react";
import { generalHandleValueChanges } from "../patients/Careteam/helperfunctions";

import SelectComponent2 from "../components/SelectCompoent2";
import PopUp from "../patients/patientViewPage/PopUp";
import LoaderComponent from "../components/LoaderComponent";
import AddAdmin from "./AddAdmin";
function utcToNormalDateTime(timestamp) {
  const utcDate = new Date(timestamp);

  const year = utcDate.getUTCFullYear();
  const month = utcDate.getUTCMonth() + 1;
  const day = utcDate.getUTCDate();

  const hours = utcDate.getUTCHours();
  const minutes = utcDate.getUTCMinutes();
  const seconds = utcDate.getUTCSeconds();

  const amOrPm = hours >= 12 ? "PM" : "AM";

  const formattedHours = hours % 12 || 12;

  const formattedDateTime = `${year}-${String(month).padStart(2, "0")}-${String(
    day
  ).padStart(2, "0")} ${formattedHours}:${String(minutes).padStart(
    2,
    "0"
  )}:${String(seconds).padStart(2, "0")} ${amOrPm}`;

  return formattedDateTime;
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const initialState = {
  role: "",
  Privilege: "",
  Status: "",
  Search: "",
};
const AdminPage = () => {
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [patientDataForService, setPatientDataForService] = useState("");
  const { data: adminList, isLoading, refetch } = useGetAdminListQuery();
  const [filter, setFilter] = useState(initialState);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [historyToDelete, setHistoryToDelete] = useState(false);
  const [data, setData] = useState("");
  const [filteredData, setFilteredData] = useState("");
  const [editData, setEditData] = useState();

  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const cancelfn = () => {
    setEditData();
    setPopUpOpen(false);
  };

  const delectePopUpcancelFn = () => {
    setHistoryToDelete("");
    setDeletePopUp(false);
  };
  const onClickDelete = (log) => {
    setHistoryToDelete(log);
    setDeletePopUp(true);
  };
  const onClickDeleteConfirmed = () => {
    if (!historyToDelete) return;
    // changeUserStatusFn({
    //   username: hostoryToDelete.email,
    //   userType: hostoryToDelete.userType.toLowerCase(),
    // });
    setDeletePopUp(false);
  };
  const openfn = (edit) => {
    if (edit) {
      setEditData(edit);
    }
    setPopUpOpen(true);
  };

  const setFilterDefault = () => {
    setFilter(initialState);
  };

  useEffect(() => {
    if (!data) return;
    let dummyData = data;
    // if (filter.role !== "") {
    //   dummyData = dummyData?.filter(
    //     (data) =>
    //       data?.patientInfo?.facilityInfo?.FacilityId === filter.Privilege
    //   );
    // }
    // if (filter.Privilege !== "") {
    //   dummyData = dummyData?.filter(
    //     (data) =>
    //       data?.patientInfo?.providerInfo?.ProviderId === filter.provider
    //   );
    // }
    // if (filter.Status !== "") {
    //   dummyData = dummyData?.filter(
    //     (data) =>
    //       data?.patientInfo?.coordinatorInfo?.CoordinatorId === filter.clinician
    //   );
    // }
    if (filter.Search) {
      dummyData = dummyData?.filter((data) =>
        data?.Name?.toLowerCase().includes(filter?.Search?.toLowerCase())
      );
    }

    setFilteredData(dummyData);
  }, [filter, data]);

  useEffect(() => {
    if (adminList) {
      setData(adminList);
    }
  }, [adminList]);

  useEffect(() => {
    if (selectedData.length > 0) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [selectedData]);
  return (
    <div className="billing">
      <LoaderComponent isLoading={isLoading} />

      {/* {deletePopUp && (
        <PopUp cancelfn={delectePopUpcancelFn}>
          <div>
            <p>
              Are you sure you want to deactivate the user{" "}
              {historyToDelete?.username}
            </p>
            <div className="buttonsContainer">
              <button onClick={onClickDeleteConfirmed}>Deactivate</button>
              <button onClick={delectePopUpcancelFn}>cancel</button>
            </div>
          </div>
        </PopUp>
      )} */}
      {popUpOpen && (
        <PopUp cancelfn={cancelfn}>
          <AddAdmin editData={editData} cancelfn={cancelfn} refetch={refetch} />
        </PopUp>
      )}

      <p className="title">Admins</p>

      <div className="advanceSearchBox">
        <div className="box">
          <p className="head">role</p>
          <SelectComponent
            OptionsArray={[]}
            fn={generalHandleValueChanges}
            valueData={filter.role}
            setvalue={setFilter}
            name={"role"}
          />
        </div>

        <div className="box">
          <p className="head">Admin Privilege</p>
          <SelectComponent2
            OptionsArray={[]}
            fn={generalHandleValueChanges}
            valueData={filter.Privilege}
            setvalue={setFilter}
            name={"Privilege"}
          />
        </div>
        <div className="box">
          <p className="head">Status</p>
          <SelectComponent2
            OptionsArray={[]}
            fn={generalHandleValueChanges}
            valueData={filter.Status}
            setvalue={setFilter}
            name={"Status"}
          />
        </div>

        <div className="box">
          <p className="head">Name</p>
          <input
            value={filter.Search}
            onChange={(e) => {
              setFilter((prev) => ({
                ...prev,
                Search: e.target.value,
              }));
            }}
          />
        </div>
      </div>
      <div className="buttons">
        <button onClick={() => setFilterDefault()}>Reset</button>
      </div>
      <div className="servicesBody">
        <div className="servicesButtons">
          <button onClick={() => openfn()}>Add Admin</button>
        </div>
        <div className="billTable">
          <>
            <div className="grid2">
              <div className="tableElement colSpan1">S.No</div>
              <div className="tableElement colSpan2">Name</div>
              <div className="tableElement colSpan2">admin Privilege</div>
              <div className="tableElement colSpan2">Email</div>
              <div className="tableElement colSpan2">Phone Number</div>
              <div className="tableElement colSpan2">
                Preferred Communication
              </div>
              <div className="tableElement colSpan2">Action</div>
            </div>
            {filteredData &&
              filteredData?.map((data, index) => (
                <div className="grid2">
                  <div className="tableElement colSpan1">{index + 1}</div>
                  <div className="tableElement colSpan2">{data.Name}</div>
                  <div className="tableElement colSpan2"></div>
                  <div className="tableElement colSpan2">{data.Email}</div>
                  <div className="tableElement colSpan2">
                    {data["Phone Number"]}
                  </div>
                  <div className="tableElement colSpan2"></div>
                  <div className="tableElement colSpan2">
                    <Icon
                      icon="uil:edit"
                      width="21"
                      height="21 "
                      className="icons"
                      //   onClick={() => openfn(data)}
                    />
                  </div>
                </div>
              ))}
          </>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
