import React, { useEffect, useState } from "react";
import {
  startOfWeek,
  isEqual,
  parseISO,
  endOfWeek,
  addDays,
  format,
  isAfter,
} from "date-fns";
import PopUp from "../patients/patientViewPage/PopUp";
import AddShedule from "./AddShedule";
import classNames from "classnames";
import EventViewer from "./EventViewer";
import { parseTimeString, changeTime } from "./WeekElement";
import "./weekElement.scss";

const dayTime = [
  ["12 AM", "1 AM"],
  ["1 AM", "2 AM"],
  ["2 AM", "3 AM"],
  ["3 AM", "4 AM"],
  ["4 AM", "5 AM"],
  ["5 AM", "6 AM"],
  ["6 AM", "7 AM"],
  ["7 AM", "8 AM"],
  ["8 AM", "9 AM"],
  ["9 AM", "10 AM"],
  ["10 AM", "11 AM"],
  ["11 AM", "12 PM"],
  ["12 PM", "1 PM"],
  ["1 PM", "2 PM"],
  ["2 PM", "3 PM"],
  ["3 PM", "4 PM"],
  ["4 PM", "5 PM"],
  ["5 PM", "6 PM"],
  ["6 PM", "7 PM"],
  ["7 PM", "8 PM"],
  ["8 PM", "9 PM"],
  ["9 PM", "10 PM"],
  ["10 PM", "11 PM"],
  ["11 PM", "12 AM", "last"],
];
const Daycomp = ({
  day,
  sheduleData,
  setSheduleData,
  setCallPatientPopUp,
  setEventData,
}) => {
  const [currentDate, setCurrentDate] = useState(day);
  const [viewEvent, setViewEvent] = useState();
  const [sheduleDate, setSheduleDate] = useState([]);
  const [timelimit, setTimeLimit] = useState([]);
  const presentDay = new Date();
  const [shedulePopUp, setShedulePopUp] = useState(false);
  const cancelfn = () => {
    setShedulePopUp(false);
  };
  const viewEventFn = (e, event) => {
    e.stopPropagation();
    setViewEvent(event);
    setEventData(event);
  };
  const closeFn = () => {
    setViewEvent();
  };
  const onClickCallPatient = () => {
    setCallPatientPopUp(true);

    closeFn();
  };

  const clickForShedule = (day, upper, lower) => {
    if (isAfter(parseISO(format(presentDay, "yyyy-MM-dd")), parseISO(day)))
      return;
    setSheduleDate(format(day, "yyyy-MM-dd"));
    setTimeLimit([upper, lower]);
    setShedulePopUp(true);
  };
  useEffect(() => {
    if (!day) return;
    setCurrentDate(day);
  }, [day]);
  return (
    <div className="weekCont">
      {shedulePopUp && (
        <PopUp cancelfn={cancelfn} width={"80vw"}>
          <AddShedule
            cancelfn={cancelfn}
            sheduleDate={sheduleDate}
            setSheduleData={setSheduleData}
            lower={timelimit[0]}
            upper={timelimit[1]}
            sheduleData={sheduleData}
          />
        </PopUp>
      )}
      {viewEvent && (
        <EventViewer
          viewEvent={viewEvent}
          closeFn={closeFn}
          onClickCallPatient={onClickCallPatient}
        />
      )}

      <div></div>
      <div className="daybody">
        {/* <div className="day">{parseISO(format(currentDate, "yyyy-MM-dd"))}</div> */}
        <div className="day">{format(currentDate, "E")}</div>
        <div className="day">{format(currentDate, "dd")}</div>
      </div>
      {dayTime.map((e) => (
        <>
          <div className="weekTime">
            <p className="upper">{e[0]} </p>
            {e[2] === "last" && <p className="lower">{e[1]}</p>}
          </div>
          <div className="weekElem weekelem2">
            <div
              className="ele"
              onClick={() => clickForShedule(currentDate, e[0], e[1])}
            >
              {sheduleData
                ?.filter(
                  (ele) =>
                    ele.Date === format(day, "yyyy-MM-dd") &&
                    changeTime(ele.time) < parseTimeString(e[1]) &&
                    changeTime(ele.time) >= parseTimeString(e[0])
                )
                ?.map((event) => (
                  <p
                    className="eventTitle"
                    onClick={(e) => viewEventFn(e, event)}
                  >
                    {event.callPurpose}
                  </p>
                ))}
            </div>
          </div>
        </>
      ))}
    </div>
  );
};

export default Daycomp;
