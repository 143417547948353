import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetPatientsCarePlanMutation,
  useStatusTimerMutation,
} from "../../../app/api/patientsApi";
import { selectIsConnectedToRoom, useHMSStore } from "@100mslive/react-sdk";
import Calling from "./Calling";
import { useGetNotesMutation } from "../../../app/api/scheduleCallApi";
import AddService from "../../parientsPreviewPage/AddService";
import SelectComponent from "../../../components/SelectComponent";
import { generalHandleValueChanges } from "../helperfunctions";
import { useGetBillingHistoryMutation } from "../../../app/api/billingApi";
import Footer from "./Footer";
import { BackDropOverlayByIdWithHide } from "../../../components/BackDrop";
import CallingCarePlan from "./CallingCarePlan";
import { callingPageDataActions } from "../../../app/features/callinPageDataSlice";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as Info } from "../../../images/callingImages/info_S.svg";
import { ReactComponent as NotesIcon } from "../../../images/callingImages/notes.svg";
import { ReactComponent as Plus } from "../../../images/callingImages/plus.svg";
import { ReactComponent as Profile } from "../../../images/callingImages/profile.svg";
import { ReactComponent as Schedule } from "../../../images/callingImages/schedule.svg";
import ReactPDF, { PDFDownloadLink } from "@react-pdf/renderer";
import "./callingMenu.scss";
import CallingTimer from "./CallingTimer";
import PatientDetailsView from "./PatientDetailsView";
import CallStatusPopUp from "./callStatusPopUp";
import Notes from "./Notes";
import PopUp from "../../patientViewPage/PopUp";
import Download from "./DownloadCallingSummaryCcm";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const CallingMenu = () => {
  const location = useLocation();
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [timerOpen, setTimerOpen] = useState(true);
  const [serviceTimerData, setServiceTimerData] = useState([]);
  const [addServicePopUpOpen, setAddServicePopUpOpen] = useState(false);
  const [patientDetailsPopUpOpen, setPatientDetailsPopUpOpen] = useState(false);
  const navigate = useNavigate();
  const id = location?.state?.id;
  const typeform = location?.state?.type;
  const dispatch = useDispatch();
  const [startTimer, setStartTimer] = useState(false);
  const [type, setType] = useState("");
  const modalOpen = useSelector((state) => state.callingData.openModal);
  const notesmodalOpen = useSelector(
    (state) => state.callingData.notesopenModal
  );
  const [getNotesfn, { data }] = useGetNotesMutation();

  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const [carePlanCallFn, { data: Patientdetails }] =
    useGetPatientsCarePlanMutation();
  const [getBillingHistoryFn, { data: billingHistory }] =
    useGetBillingHistoryMutation(undefined, {
      refetchOnMountOrArgChange: true,
    });
  const [programName, setProgramName] = useState({
    programName: "",
  });

  const [timerStatusFn, { data: timerStatus }] = useStatusTimerMutation();
  const cancelfn = () => {
    setPopUpOpen(false);
  };
  const cancelfn2 = () => {
    setTimerOpen(false);
  };
  const timerFn = (timerData) => {
    setServiceTimerData(timerData);
    setPopUpOpen(true);
  };
  const startTimerFn = () => {
    setStartTimer(true);
    setTimerOpen(false);
  };

  useEffect(() => {
    if (!id) {
      navigate("/");
      return;
    }
    carePlanCallFn({
      PatientId: id,
    });
  }, [carePlanCallFn, id]);

  useEffect(() => {
    if (!typeform) return;
    setType(typeform);
  }, [typeform]);

  const modalExit = (event) => {
    if (event.target !== event.currentTarget) return;
    dispatch(callingPageDataActions.closeModal());
    carePlanCallFn({
      PatientId: id,
    });
  };
  const notesmodalExit = (event) => {
    if (event.target !== event.currentTarget) return;
    dispatch(callingPageDataActions.closeModalnotes());
    getNotesfn({
      patientId: id,
      program: programName?.programName,
    });
  };
  // const navigateToCalendar = () => {
  //   navigate("/viewCalendar");
  // };
  useEffect(() => {
    if (id) return;
    timerStatusFn({ PatientId: id });
  }, [id]);

  const refreshBillingHistory = () => {
    getBillingHistoryFn({
      PatientId: id,
      programName: programName?.programName,
      month: monthNames[currentDate.getMonth()],
      year: currentDate.getFullYear(),
    });
  };

  const currentDate = new Date();
  useEffect(() => {
    if (!programName?.programName || !id) return;

    getNotesfn({
      patientId: id,
      program: programName?.programName,
    });
    refreshBillingHistory();
  }, [id, programName]);
  return (
    <>
      {popUpOpen && (
        <PopUp cancelfn={cancelfn}>
          <CallStatusPopUp
            cancelfn={cancelfn}
            programName={programName?.programName}
            serviceTimerData={serviceTimerData}
            PatientId={id}
            refreshBillingHistory={refreshBillingHistory}
          />
        </PopUp>
      )}
      {addServicePopUpOpen && (
        <PopUp cancelfn={() => setAddServicePopUpOpen(false)}>
          <AddService
            patientDataForService={Patientdetails}
            cancelfn={() => setAddServicePopUpOpen(false)}
            programName={programName.programName}
          />
        </PopUp>
      )}
      {patientDetailsPopUpOpen && (
        <PopUp cancelfn={() => setPatientDetailsPopUpOpen(false)}>
          <PatientDetailsView Patientdetails={Patientdetails} />
        </PopUp>
      )}
      {timerOpen && Patientdetails && (
        <PopUp cancelfn={programName?.programName ? cancelfn2 : () => {}}>
          <div className="timerNotification">
            <div className="timerContentPopUp">
              <p className="content-text">
                Select the program
                <span>
                  <SelectComponent
                    OptionsArray={Patientdetails?.programs}
                    fn={generalHandleValueChanges}
                    valueData={programName.programName}
                    setvalue={setProgramName}
                    name={"programName"}
                  />
                </span>
              </p>
            </div>
            <p> you wish to start the timer for {typeform} </p>
            <div className="popBtn">
              <button
                onClick={programName?.programName ? startTimerFn : () => {}}
              >
                Yes
              </button>
              <button onClick={programName?.programName ? cancelfn2 : () => {}}>
                No
              </button>
            </div>
          </div>
        </PopUp>
      )}
      {Patientdetails && (
        <div className="callPlanWrap">
          <div className="ccmHeading">
            <div className="callingPagetitle">
              {programName?.programName} Call
            </div>
            <div className="divbtn">
              <div className="download-button">
                <PDFDownloadLink
                  document={
                    <Download
                      data={Patientdetails}
                      notesData={data}
                      billingHistory={billingHistory}
                      date={`${
                        monthNames[currentDate.getMonth()]
                      } ${currentDate.getFullYear()}`}
                    />
                  }
                  fileName={`${Patientdetails?.Name}_CCMcallingsummary.pdf`}
                >
                  {({ blob, url, loading, error }) =>
                    loading
                      ? "Loading document..."
                      : "Download Service Summary!"
                  }
                </PDFDownloadLink>
              </div>
              {/* <button className="headerbtns">
                <Icon icon="ic:baseline-cloud-upload" color=" #7A5DDA" />
                <p className="btncntn">Send Fax</p>
              </button> */}
            </div>
          </div>
          <div className="detailsSection">
            <div className="deatailsHead">
              <div className="patientname">
                {Patientdetails?.Name}({Patientdetails?.["Date Of Birth"]})
              </div>
              <div className="detailsbtns">
                {/* <button className="btnWrap">
                  <Info className="icon" />
                </button> */}
                {/* <button className="btnWrap">
                  <NotesIcon className="icon" />
                </button> */}
                <button className="btnWrap">
                  <Profile
                    className="icon"
                    onClick={() => setPatientDetailsPopUpOpen(true)}
                  />
                </button>

                {/* <button className="btnWrap">
                  <Schedule
                    className="icon"
                    onClick={() => navigateToCalendar()}
                  />
                </button> */}

                <button className="btnWrap">
                  <Plus
                    className="icon"
                    onClick={() => setAddServicePopUpOpen(true)}
                  />
                </button>
              </div>
            </div>
            <div className="detailsSubComponent">
              <div className="detailsSubComponentLeft">
                <div className="element">
                  <p className="subelement">Care Plan Physician:</p>
                  {Patientdetails?.ProviderId?.ProviderName}
                </div>
                <div className="element ">
                  <p className="subelement">Primary Care Coordinator</p>
                  {Patientdetails?.PrimaryCoordinator?.ClinicianName}
                </div>
              </div>
              <div className="detailsSubComponentRight">
                <div className="title">Diagonsis (Chronic Conditions)</div>
                <div className="diagnosisContainer">
                  {Patientdetails?.Diagnosis?.map((el) => (
                    <div className="diagnosis">
                      <span className="icd-code">{`${el.ICD_Code} : `}</span>
                      {el.ICD_Description}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="Testing">
            <CallingTimer
              id={id}
              totalTime={billingHistory?.[0].totalTimeSpent}
              programName={programName?.programName}
              timerStatus={timerStatus}
              type={type}
              timerFn={timerFn}
              startTimer={startTimer}
              setStartTimer={setStartTimer}
            />
          </div>
          <Footer />
        </div>
      )}
      <BackDropOverlayByIdWithHide
        id="overlay-2"
        onClick={modalExit}
        hide={!modalOpen}
      >
        <CallingCarePlan id={id} />
      </BackDropOverlayByIdWithHide>
      <BackDropOverlayByIdWithHide
        id="overlay-2"
        onClick={notesmodalExit}
        hide={!notesmodalOpen}
      >
        <Notes id={id} programName={programName?.programName} />
      </BackDropOverlayByIdWithHide>
    </>
  );
};

export default CallingMenu;
