import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useAddServicesMutation } from "../app/api/billingApi";
// import { useUpdateCarePlanMutation } from '../app/api/patientsApi';
import { useTimerApiMutation } from "../app/api/patientsApi";
import { useTimerfinalapiMutation } from "../app/api/timerapifinal";
import "../sass/stopWatch.scss";

const Stopwatch = (props) => {
  const {
    id,
    updateFunction,
    serviceName,
    startTimer,
    programName,
    setStartTimer,
    callingpage,
    handlestopWatch,
    timerFn,
  } = props;
  // const [updateDatafn, { data: updatedData }] = useUpdateCarePlanMutation();
  const [isRunning, setIsRunning] = useState(false);
  // const [buttonClick, setButtonClick] = useState(1);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [startTime, setStartTime] = useState();
  const [TimerFn, { data: TimerData }] = useTimerfinalapiMutation();
  const [addServicesFn, { data: serviceData }] = useAddServicesMutation();
  useEffect(() => {
    let intervalId;
    if (isRunning) {
      // setButtonClick((prev) => prev + 1);
      intervalId = setInterval(() => {
        setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isRunning]);

  const handleStart = () => {
    if (handlestopWatch) {
      handlestopWatch(true);
    }
    setIsRunning(true);
    setStartTime(new Date());
  };

  const handleStop = () => {
    if (handlestopWatch) {
      handlestopWatch(false);
    }
    setIsRunning(false);
  };

  const handleReset = async () => {
    if (handlestopWatch) {
      handlestopWatch(false, true);
    }
    let endTime = new Date();
    if (updateFunction) {
      const total = parseTime(props?.totalTime);

      await updateFunction({
        PatientId: id,
        TotalTime: formatTime(elapsedTime + total),
      });
    }

    setStartTime("");
    setElapsedTime(0);
    setIsRunning(false);
    if (callingpage) {
      await timerFn({
        startTime: startTime,
        endTime: endTime,
        acutalTimeSpent: formatTime(elapsedTime),
      });
    } else {
      addServicesFn({
        PatientId: id,
        serviceName: serviceName ?? "CCM",
        programName: programName ?? "CCM",
        serviceType: "Timer",
        startTime: startTime,
        endTime: endTime,
        acutalTimeSpent: formatTime(elapsedTime),
        remark: "notes",
      });
    }
  };
  const parseTime = (timeString) => {
    const [hours, minutes, seconds] = timeString?.split(":")?.map(Number);
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };
  useEffect(() => {
    if (startTimer) {
      handleStart();
      setStartTimer(false);
    }
  }, [setStartTimer, startTimer]);

  return (
    <div className="stopwatchContainer">
      <div className="timerContainer">
        <div className="title">Timer</div>
        <div className="timer">{formatTime(elapsedTime)}</div>
      </div>
      <div className="timerbuttons">
        <button onClick={handleStart}>
          <Icon color="#fff" icon="ph:play-fill" width="25" height="25" />
        </button>
        <button onClick={handleStop}>
          <Icon
            color="#fff"
            icon="material-symbols:pause"
            width="25"
            height="25"
          />
        </button>
        <button onClick={handleReset}>
          <Icon color="#fff" icon="ic:sharp-stop" width="25" height="25" />
        </button>
      </div>
      {/* <div className="dialogBox">
        <CarePlanDialogBox buttonClick={buttonClick} startTimer={handleStart} />
      </div> */}
    </div>
  );
};

export default Stopwatch;
