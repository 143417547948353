import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useResetPasswordMutation } from "../app/api/loginApi";
import {
  goToLoginPage,
  loginDataActions,
} from "../app/features/loginDataSlice";
import ButtonComponent from "../components/ButtonComponent";
import LoginInputTextboxField from "../fieldComponent/LoginInputTextboxField1";
import { isNotEmpty } from "../utilities/validationFunction";
import FloatingNotifications from "./../utilities/FloatingNotifications";

const ResetPassword = () => {
  const username = useSelector((state) => state.loginData.username);
  const dispatch = useDispatch();
  const [valid, setValid] = useState(false);

  const [resetPassword, { data, loading, error }] = useResetPasswordMutation();
  const storeData = useCallback((fieldName, value, validityStatus) => {
    setInputData((prevState) => {
      return { ...prevState, [fieldName]: value };
    });
    if (inputDataValidity.hasOwnProperty(fieldName)) {
      setInputDataValidity((prevState) => ({
        ...prevState,
        [fieldName]: validityStatus,
      }));
    }
  }, []);

  const resetRefs = {
    otp: useRef(null),
    newPassword: useRef(null),
  };

  const [inputData, setInputData] = useState({
    otp: "",
    newPassword: "",
  });

  const [inputDataValidity, setInputDataValidity] = useState({
    otp: false,
    newPassword: false,
  });

  useEffect(() => {
    const data = Object.keys(inputDataValidity).reduce(
      (prev, cur) => prev && inputDataValidity[cur],
      true
    );
    setValid(data);
  }, [inputDataValidity]);

  const onSubmitHandler = () => {
    if (valid) {
      resetPassword({ username, ...inputData });
    } else {
      FloatingNotifications("error", "Invalid otp or password");
    }
  };

  useEffect(() => {
    if (data) {
      FloatingNotifications("success", "Password reset successfully");
      dispatch(loginDataActions.updateUsername(""));
      dispatch(loginDataActions.switchPage(goToLoginPage()));
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      FloatingNotifications("error", error?.data?.msg ?? "Unknown error");
    }
  }, [error]);

  return (
    <>
      <div className="title">Reset Password</div>
      <div className="desc">Please enter OTP and new password</div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <LoginInputTextboxField
          ref={resetRefs["otp"]}
          title="OTP"
          validation={isNotEmpty}
          storeData={storeData.bind(null, "otp")}
          superText=""
          autoComplete="off"
          autoFocus={true}
        />
        <LoginInputTextboxField
          ref={resetRefs["newPassword"]}
          title="New password"
          type="password"
          validation={isNotEmpty}
          storeData={storeData.bind(null, "newPassword")}
          superText=""
          autoComplete="new-password"
        />
        <div className="">&nbsp;</div>
        <div className="">&nbsp;</div>
        <ButtonComponent
          containerClassName="buttonContainer"
          onClick={onSubmitHandler}
          text="Submit"
          disabled={false}
        />
        {/* <div className="buttonContainer" onClick={onSubmitHandler}>
          <button>Login</button>
        </div> */}
      </form>
    </>
  );
};

export default ResetPassword;
