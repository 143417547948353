import React from "react";
import { useState, useEffect } from "react";
import { generalHandleValueChanges } from "../Careteam/helperfunctions";
import SelectComponent from "../../components/SelectComponent";
import TabWidget from "../../components/TabWidget";
import TabElement from "../../components/TabElement";
import FloatingNotifications from "../../utilities/FloatingNotifications";
const insuranceList = [
  "4 Ever Life Insurance Company",
  "Aetna Life Insurance Company",
  "American National Insurance Company",
  "American National Life Insurance of Texas",
  "American States Insurance Company",
  "Anthem Blue Cross Life and Health Insurance Company",
  "AXA Equitable Life Insurance Company",
  "Blue Shield of California Life and Health Insurance Company",
  "CIGNA Health and Life Insurance Company",
  "Cincinnati Life Insurance Company",
  "Connecticut General Life Insurance Company",
  "Delaware American Life Insurance Company",
  "Genworth Life Insurance Company",
  "Golden Rule Insurance Company",
  "Guardian Life Insurance Company of America",
  "Health Net Life Insurance Company",
  "John Hancock Life Insurance Company (U.S.A.)",
  "Kaiser Permanente Insurance Company",
  "Liberty Mutual Insurance Company",
  "Massachusetts Casualty Insurance Company",
  "Metropolitan Life Insurance Company",
  "Mony Life Insurance Company",
  "Mutual of Omaha Insurance Company",
  "National Benefit Life Insurance Company",
  "National Foundation Life Insurance Company of America",
  "National Health Insurance Company",
  "New York Life Insurance Company",
  "Nippon Life Insurance Company of America",
  "Prudential Insurance Company of America",
  "Sierra Health And Life Insurance Company, Inc.",
  "State Farm Mutual Automobile Insurance Company",
  "Teacher's Insurance and Annuity Association of America",
  "Thrivent Financial for Lutherans",
  "Transamerica Life Insurance Company",
  "Trustmark Insurance Company",
  "United HealthCare Insurance Company",
  "United States Life Insurance Company in the City of New Yor",
];
const Insurance = ({ selectedData, insuranceType, updatePatientfn, id }) => {
  const [dataObject, setDataObject] = useState({
    insuranceCompany: "",
    payerID: "",
    tplCode: "",
    insuranceID: "",
    groupName: "",
    groupNumber: "",
  });
  const Updatebtn = () => {
    if (dataObject?.insuranceCompany === "" || dataObject?.insuranceID === "") {
      FloatingNotifications("error", "Fill required fields to proceed");

      return;
    }
    const { MedicareEnrolmentDate, ...rem } = dataObject;
    updatePatientfn({
      PatientId: id,
      [insuranceType]: rem,
    });
  };

  useEffect(() => {
    if (!selectedData) return;
    let r = Object.keys(dataObject);
    r.map((el) => {
      setDataObject((prev) => ({
        ...prev,
        [el]: selectedData?.[insuranceType][el],
      }));
    });
  }, [selectedData, insuranceType]);
  return (
    <>
      <p className="TitleElement">
        Insurance Information
        <button className="Updatebtn" onClick={() => Updatebtn()}>
          Save
        </button>
      </p>

      <div className="container">
        <div className="grid">
          {/* <div className="lineElement">
            <label className="label">Insurance Type</label>
            <div className="field2">
              <SelectComponent
                OptionsArray={[
                  "firstInsuranceData",
                  "secondInsuranceData",
                  "thirdInsuranceData",
                ]}
                valueData={insurance.insuranceType}
                fn={generalHandleValueChanges}
                setvalue={setInsurance}
                name={"insuranceType"}
              />
            </div>
          </div> */}
          <div className="lineElement">
            <label className="label">Insurance Company*</label>
            <div className="field2">
              <SelectComponent
                OptionsArray={insuranceList}
                valueData={dataObject?.insuranceCompany}
                fn={generalHandleValueChanges}
                setvalue={setDataObject}
                name={"insuranceCompany"}
              />
            </div>
            {/* <select
              maxHeight="5rem"
              className="field"
              value={dataObject.insuranceCompany}
              onChange={(event) =>
                generalHandleValueChanges(
                  event,
                  setDataObject,
                  "insuranceCompany"
                )
              }
            >
              <option className="default" value=""></option>

              {insuranceList.map((el) => (
                <option value={el}>{el}</option>
              ))}
            </select> */}
          </div>
          <div className="lineElement">
            <label className="label">Payer ID</label>
            <input
              type="text"
              className="field"
              value={dataObject?.payerID}
              onChange={(event) =>
                generalHandleValueChanges(event, setDataObject, "payerID")
              }
            />
          </div>
          <div className="lineElement">
            <label className="label">TPL Code</label>
            <input
              type="text"
              className="field"
              value={dataObject?.tplCode}
              onChange={(event) =>
                generalHandleValueChanges(event, setDataObject, "tplCode")
              }
            />
          </div>

          <div className="lineElement">
            <label className="label">Insurance ID*</label>
            <input
              type="text"
              className="field"
              value={dataObject.insuranceID}
              onChange={(event) =>
                generalHandleValueChanges(event, setDataObject, "insuranceID")
              }
            />
          </div>
          <div className="lineElement">
            <label className="label">Group Name</label>
            <input
              type="text"
              className="field"
              value={dataObject.groupName}
              onChange={(event) =>
                generalHandleValueChanges(event, setDataObject, "groupName")
              }
            />
          </div>
          <div className="lineElement">
            <label className="label">Group Number</label>
            <input
              type="text"
              className="field"
              value={dataObject.groupNumber}
              onChange={(event) =>
                generalHandleValueChanges(event, setDataObject, "groupNumber")
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Insurance;
