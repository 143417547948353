export const resetfn = (object, setObject, uniqueId) => {
  let r = {};
  for (let key in object) {
    if (key === "Status") {
      r[key] = true;
      continue;
    }
    if (key === "Diagnosis_id") {
      r[key] = uniqueId;
      continue;
    }
    if (key === "ConditionExistsForRelationship") {
      r[key] = [];
      continue;
    }
    if (key === "RecordedDate") {
      r[key] = getCurrentDateAndTime();
      continue;
    }

    r[key] = "";
  }
  if (r.hasOwnProperty("_id")) {
    let { _id, ...updatedArray } = r;
    setObject(updatedArray);
    return;
  }
  setObject(r);
};

export const getCurrentDateAndTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
  return formattedDateTime;
};

export const basicValidation = (obj) => {
  for (let key in obj) {
    if (key === "EndDate") {
      continue;
    }
    if (obj[key] === "") {
      return false;
    }
  }
  return true;
};

export const cancleOperationFn = (fn) => {
  fn("");
};
const VitalsArray = [
  "Weight",
  "Height",
  "Pulse",
  "O2Saturation",
  "BloodPressure",
  "PostprandialGlucose",
  "LDLCholesterol",
  "RespirationRate",
  "BodyPain",
  "HeadCircumference",
  "Temperature",
  "FastingPlasmaGlucose",
  "Hemoglobin",
];
export const generalHandleValueChanges = (event, setValue, key, lifeStyle) => {
  if (VitalsArray.includes(key)) {
    if (event.target.value > 999) return;
  }
  if (typeof event === "string") {
    setValue((prev) => ({
      ...prev,
      [key]: event,
    }));
    return;
  }
  if (typeof event === "number") {
    setValue((prev) => ({
      ...prev,
      [key]: event,
    }));
    return;
  }

  if (lifeStyle) {
    setValue((prev) => ({
      ...prev,
      [key]: event,
    }));
    return;
  }

  if (event === "Status") {
    setValue((prev) => ({
      ...prev,
      [key]: false,
    }));
  } else {
    setValue((prev) => ({
      ...prev,
      [key]: event?.target?.value,
    }));
  }
};
export const generalSaveButton = (
  window,
  setWindow,
  object,
  setArray,
  editArray,
  setEditArray
) => {
  if (window === "") return;

  const isValid = basicValidation(object);
  if (!isValid) return;

  if (editArray) {
    const updatedArray = editArray.map((obj) => {
      const { _id, ...rest } = obj;
      return rest;
    });
    const { _id, ...updatedObject } = object;
    setArray([...updatedArray, updatedObject]);
    setEditArray(null); // It's better to set it to null explicitly for clarity
    setWindow("");
  } else {
    setArray((prev) => (Array.isArray(prev) ? [...prev, object] : [object]));
    setWindow("");
  }
};

export function areArraysSame(arr1, arr2) {
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  for (let i = 0; i < arr1?.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

export const careplanview = (
  window,
  setObject,
  value,
  dataArray,
  setEditArray
) => {
  window("addDetails");
  setObject(value);
  let r = dataArray?.filter((el) => !areObjectsEqual(el, value));
  setEditArray(r);
};

export const statusfinderFn = (type, value) => {
  switch (type) {
    case "active":
      return value?.filter((el) => el.Status === true) ?? [];

    case "history":
      return value?.filter((el) => el.Status === false) ?? [];

    case "true":
      return value?.filter((el) => el.Status === true) ?? [];

    default:
      break;
  }
};

export function areObjectsEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}
