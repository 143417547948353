import React, { useState, useId, useCallback } from "react";
import { Icon } from "@iconify/react";
import classNames from "classnames";
import RadioComponent from "./inputComponents/RadioComponent";
import InputComponent from "./inputComponents/InputComponent";
import CheckBoxComponent from "./inputComponents/CheckBoxComponent";
import DateComponent from "./inputComponents/DateComponent";
import HistoryOfNotes from "./HistoryOfNotes";

const Questionarie = ({ question, title, fieldHead, setNotes, notes }) => {
  const id = useId();
  const [showNotes, setShowNotes] = useState(false);
  const [showHistoryNotes, setShowHistoryNotes] = useState(false);

  const getQuestionValue = useCallback(
    () =>
      notes
        ?.find((section) => section?.Group === title)
        ?.subGroupList?.find((subGroup) => subGroup?.subGroupName === fieldHead)
        ?.quesList?.find((q) => q.qId === question.qId)
        ?.Response?.find((r) => r.month_yr === getCurrentMonthAndYear()),
    [notes, title, fieldHead, question.qId]
  );
  const getHistory = useCallback(
    () =>
      notes
        ?.find((section) => section?.Group === title)
        ?.subGroupList?.find((subGroup) => subGroup?.subGroupName === fieldHead)
        ?.quesList?.find((q) => q.qId === question.qId)
        ?.Response?.filter((r) => r.month_yr !== getCurrentMonthAndYear()),
    [notes, title, fieldHead, question.qId]
  );

  const value = getQuestionValue();
  const history = getHistory();

  const [checkBoxData, setCheckBoxData] = useState([]);
  function getCurrentMonthAndYear() {
    const currentDate = new Date();

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const currentMonth = monthNames[currentDate.getMonth()];
    const currentYear = currentDate.getFullYear();

    return `${currentMonth} ${currentYear}`;
  }
  const updateAnswer = useCallback(
    (newAnswer, type) => {
      setNotes((prevStructure) => {
        const newStructure = prevStructure.map((g) => {
          if (g.Group === title) {
            return {
              ...g,
              subGroupList: g.subGroupList.map((subGroup) => {
                if (subGroup.subGroupName === fieldHead) {
                  return {
                    ...subGroup,
                    quesList: subGroup.quesList.map((questionSection) => {
                      if (questionSection.qId === question.qId) {
                        const currentMonthAndYear = getCurrentMonthAndYear();

                        const existingResponse = questionSection.Response.find(
                          (r) => r.month_yr === currentMonthAndYear
                        );

                        if (existingResponse) {
                          return {
                            ...questionSection,
                            Response: questionSection.Response.map((r) =>
                              r.month_yr === currentMonthAndYear
                                ? { ...r, [type]: newAnswer }
                                : r
                            ),
                          };
                        } else {
                          return {
                            ...questionSection,
                            Response: [
                              ...questionSection.Response,
                              {
                                month_yr: currentMonthAndYear,
                                [type]: newAnswer,
                              },
                            ],
                          };
                        }
                      }
                      return questionSection;
                    }),
                  };
                }
                return subGroup;
              }),
            };
          }
          return g;
        });
        return newStructure;
      });
    },
    [setNotes, title, fieldHead, question.qId]
  );

  const CheckBoxFn = useCallback(
    (option, checked) => {
      let dummy = [...checkBoxData];
      if (checked) {
        dummy.push(option);
      } else {
        dummy = dummy.filter((item) => item !== option);
      }
      setCheckBoxData(dummy);
      updateAnswer(dummy, "answer");
    },
    [checkBoxData, updateAnswer]
  );

  return (
    <div className="questionarie">
      <div className="buttonsCont">
        <Icon
          icon="material-symbols:history"
          onClick={() => setShowHistoryNotes((prev) => !prev)}
        />

        <Icon
          icon="pixelarticons:notes-plus"
          onClick={() => setShowNotes((prev) => !prev)}
        />
      </div>
      <p>{question?.que}</p>
      {question?.type === "checkBox" && (
        <CheckBoxComponent
          question={question}
          CheckBoxFn={CheckBoxFn}
          id={id}
          value={value?.answer || ""}
        />
      )}
      {question?.type === "date" && (
        <DateComponent
          question={question}
          onClickFn={updateAnswer}
          id={id}
          value={value?.answer}
        />
      )}
      {question?.type === "radio" && (
        <RadioComponent
          question={question}
          onClickFn={updateAnswer}
          id={id}
          value={value?.answer || ""}
        />
      )}
      {question?.type === "txt" && (
        <InputComponent
          question={question}
          onClickFn={updateAnswer}
          id={id}
          type={"answer"}
          value={value?.answer}
        />
      )}
      {
        <div className={classNames("", showNotes ? "" : "hidden")}>
          <InputComponent
            value={value?.notes || ""}
            onClickFn={updateAnswer}
            type={"notes"}
          />
        </div>
      }
      <div
        className={classNames(
          "responceHistory",
          showHistoryNotes ? "" : "hidden"
        )}
      >
        <HistoryOfNotes data={history} />
      </div>
    </div>
  );
};

export default Questionarie;
