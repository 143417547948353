import { Fragment, useEffect } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import "../sass/backDrop.scss";
const BackDrop = ({ children, onClick = (e) => {} }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);
  return (
    <div className="backDrop" onClick={onClick}>
      {children}
    </div>
  );
};

const BackDropHide = ({ children, hide, onClick = (e) => {} }) => {
  useEffect(() => {
    if (hide) {
      document.body.style.overflow = "unset";
    } else {
      document.body.style.overflow = "hidden";
    }
    return () => (document.body.style.overflow = "unset");
  }, [hide]);
  return (
    <div
      className={classNames("backDropHide", { hide: hide })}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
export default BackDrop;

export const BackDropOverlayById = ({ id, children, onClick = (e) => {} }) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <BackDrop onClick={onClick}>{children}</BackDrop>,
        document.getElementById(id)
      )}
    </Fragment>
  );
};

export const BackDropOverlayByIdWithHide = ({
  id,
  children,
  hide,
  onClick = (e) => {},
}) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <BackDropHide onClick={onClick} hide={hide}>
          {children}
        </BackDropHide>,
        document.getElementById(id)
      )}
    </Fragment>
  );
};
