import React from "react";
import { useState, useEffect } from "react";
import { generalHandleValueChanges } from "../Careteam/helperfunctions";
import { useGetPatientsOptionsQuery } from "../../app/api/patientSelctorOptionApi";
import FloatingNotifications from "../../utilities/FloatingNotifications";
const Important = (props) => {
  const { selectedData, updatePatientfn, id } = props;
  const [dataObject, setDataObject] = useState({
    FacilityId: "",
    PrimaryCoordinator: "",
    ProviderId: "",
    Status: "",
    PatientEHRId: "",
    Suffix: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
  });
  const { data: fpcList } = useGetPatientsOptionsQuery();

  const FacilityList = fpcList?.FacilityList ?? [];
  const ProviderList = fpcList?.ProviderList ?? [];
  const ClinicianList = fpcList?.ClinicianList ?? [];

  const Updatebtn = () => {
    if (
      dataObject.FacilityId === "" ||
      dataObject.PrimaryCoordinator === "" ||
      dataObject.ProviderId === "" ||
      dataObject.FirstName === "" ||
      dataObject.LastName === ""
    ) {
      FloatingNotifications("error", "Fill required fields to proceed");

      return;
    }
    const { _id, ...res } = selectedData;
    updatePatientfn({
      PatientId: id,
      ...dataObject,
    });
  };

  useEffect(() => {
    if (!selectedData) return;
    if (!dataObject) return;
    let r = Object.keys(dataObject);
    r.map((el) => {
      setDataObject((prev) => ({
        ...prev,
        [el]: selectedData?.[el],
      }));
    });
  }, [selectedData]);

  return (
    <>
      <p className="TitleElement">
        Important Information{" "}
        <button className="Updatebtn" onClick={Updatebtn}>
          Save
        </button>
      </p>
      <div className="container">
        <div className="grid">
          <div className="lineElement">
            <label className="label">Facility*</label>

            <select
              type="text"
              className="field"
              value={dataObject.FacilityId}
              onChange={(event) =>
                generalHandleValueChanges(event, setDataObject, "FacilityId")
              }
            >
              <option className="default" value=""></option>

              {FacilityList.map((el) => (
                <option value={el.FacilityId}>{el.FacilityName}</option>
              ))}
            </select>
          </div>
          <div className="lineElement">
            <label className="label">Patient ID</label>
            <input
              type="text"
              className="field"
              value={dataObject.PatientEHRId}
              onChange={(event) =>
                generalHandleValueChanges(event, setDataObject, "PatientEHRId")
              }
            />
          </div>
          <div className="lineElement">
            <label className="label">Primary Coordinator*</label>
            <select
              type="text"
              className="field"
              value={dataObject.PrimaryCoordinator}
              onChange={(event) =>
                generalHandleValueChanges(
                  event,
                  setDataObject,
                  "PrimaryCoordinator"
                )
              }
            >
              <option className="default" value=""></option>

              {ClinicianList.map((el) => (
                <option value={el.ClinicianId}>{el.ClinicianName}</option>
              ))}
            </select>
          </div>
          <div className="lineElement">
            <label className="label">Provider*</label>
            <select
              type="text"
              className="field"
              value={dataObject.ProviderId}
              onChange={(event) =>
                generalHandleValueChanges(event, setDataObject, "ProviderId")
              }
            >
              <option className="default" value=""></option>

              {ProviderList.map((el) => (
                <option value={el.ProviderId}>{el.ProviderName}</option>
              ))}
            </select>
          </div>
          <div className="lineElement">
            <label className="label">Status</label>
            <select
              className="field"
              value={dataObject.Status}
              onChange={(event) =>
                generalHandleValueChanges(event, setDataObject, "Status")
              }
            >
              <option className="default" value=""></option>
              <option className="options" value="true">
                True
              </option>
              <option className="options" value="false">
                False
              </option>
            </select>
          </div>
          <div className="lineElement">
            <label className="label">Suffix</label>
            <select
              className="field"
              value={dataObject.Suffix}
              onChange={(event) =>
                generalHandleValueChanges(event, setDataObject, "Suffix")
              }
            >
              <option className="default" value=""></option>
              <option className="options" value="Mr">
                Mr
              </option>
              <option className="options" value="Mrs">
                Mrs
              </option>
            </select>
          </div>
        </div>
        <div className="grid">
          <div className="lineElement">
            <label className="label">First Name*</label>
            <input
              type="text"
              value={dataObject.FirstName}
              className="field"
              onChange={(event) =>
                generalHandleValueChanges(event, setDataObject, "FirstName")
              }
            />
          </div>
          <div className="lineElement">
            <label className="label">Middle Name</label>
            <input
              type="text"
              className="field"
              value={dataObject.MiddleName}
              onChange={(event) =>
                generalHandleValueChanges(event, setDataObject, "MiddleName")
              }
            />
          </div>

          <div className="lineElement">
            <label className="label">Last Name*</label>
            <input
              type="text"
              className="field"
              value={dataObject.LastName}
              onChange={(event) =>
                generalHandleValueChanges(event, setDataObject, "LastName")
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Important;
