import { apiCollection } from "./apiCollection";

const clinicianApi = apiCollection.injectEndpoints({
  endpoints: (build) => ({
    clinicianCreation: build.mutation({
      query: (payload) => {
        console.log(payload);
        return { url: "/clinician/create", method: "POST", body: payload };
      },
      transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
    updateClinician: build.mutation({
      query: (payload) => {
        return {
          url: "/clinician/update",
          method: "POST",
          body: payload,
        };
      },
      transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
    getClinicianAllProfiles: build.query({
      query: () => ({
        url: "clinician/allProfile",
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("RLH_token")}`,
        // },
      }),
    }),
    getSelectedClinician: build.mutation({
      query: (payload) => {
        return {
          url: "/clinician/selectedProfile",
          method: "POST",
          body: payload,
        };
      },
      transformResponse: (response, meta, arg) => {
        const { phone, workPhone, ...data } = response;

        data["phone"] = phone?.toString();
        data["workPhone"] = workPhone?.toString();
        return data;
      },
      // transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
  }),
  overrideExisting: false,
});

export const {
  useClinicianCreationMutation,
  useGetClinicianAllProfilesQuery,
  useGetSelectedClinicianMutation,
  useUpdateClinicianMutation,
} = clinicianApi;
