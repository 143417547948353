import React from "react";
import { useState, useEffect } from "react";
import DropDownContent from "../../../components/DropDownContent";
import ActiveHistoryDropDown2 from "../../../components/ActiveHistoryDropDown2";
import AddDetailGeneral from "../addDetailsGeneral/AddDetailGeneral";
import CarePlanDisplayGeneral from "../CarePlanDisplayGeneral/CarePlanDisplayGeneral";
import TabElement from "../../../components/TabElement";
import FloatingNotifications from "../../../utilities/FloatingNotifications";
import TabWidget from "../../../components/TabWidget";
import {
  resetfn,
  basicValidation,
  areArraysSame,
  careplanview,
  generalHandleValueChanges,
  generalSaveButton,
  cancleOperationFn,
  statusfinderFn,
} from "../helperfunctions";
import CarePlanErrorMessage from "../CarePlanErrorMessage";

const MedicalEvents = (props) => {
  const { selectedPatient, id, updateDatafn } = props;
  const [medicalEventsWindow, setMedicalEventsWindow] = useState("");
  const addMedicalEventsDetail = () => {
    setMedicalEventsWindow("addDetails");
    resetfn(medicalEvents, setMedicalEvents);
  };
  const [active, setActive] = useState("Active");

  const [medicalEventsArray, setMedicalEventsArray] = useState([]);
  const [editArray, setEditArray] = useState();

  const [medicalEvents, setMedicalEvents] = useState({
    MedicalEvents: "",
    OtherEventType: "",
    Notes: "",
    EventDate: "",
    Description: "",
    Status: true,
  });
  const onClickFn = () => {
    if (basicValidation(medicalEvents)) {
      generalSaveButton(
        medicalEventsWindow,
        setMedicalEventsWindow,
        medicalEvents,
        setMedicalEventsArray,
        editArray,
        setEditArray
      );
    } else {
      FloatingNotifications("error", "Fill mandatory fields to proceed");
    }
  };
  useEffect(() => {
    if (selectedPatient) {
      setMedicalEventsArray(selectedPatient.MedicalEvents);
    }
  }, [selectedPatient]);
  useEffect(() => {
    if (
      basicValidation(medicalEvents) &&
      !areArraysSame(selectedPatient?.MedicalEvents, medicalEventsArray)
    ) {
      updateDatafn({ PatientId: id, MedicalEvents: medicalEventsArray });
    }
  }, [medicalEventsArray]);
  return (
    <div>
      <DropDownContent
        title={"MedicalEvents"}
        children={
          <div>
            <TabWidget>
              {["Active", "History"]?.map((el) => (
                <TabElement
                  key={`patients_${el}`}
                  title={el}
                  active={active === el}
                  onClick={() => {
                    setActive(el);
                  }}
                />
              ))}
            </TabWidget>
            {active === "Active" && (
              <ActiveHistoryDropDown2
                type={"Active"}
                iconsList={[
                  {
                    value: "ic:twotone-plus",
                    onClickFn: addMedicalEventsDetail,
                  },
                  {
                    value: "mingcute:save-line",
                    onClickFn:
                      medicalEventsWindow === "addDetails"
                        ? () => onClickFn()
                        : false,
                  },
                  {
                    value: "majesticons:multiply-line",
                    onClickFn: () => cancleOperationFn(setMedicalEventsWindow),
                  },
                ]}
              >
                <div>
                  {medicalEventsWindow === "addDetails" ? (
                    <AddDetailGeneral data={selectedPatient}>
                      <>
                        <p className="displayTitleGnrl">
                          MedicalEvents Information
                        </p>
                        <div className="display-sec-Gnrl">
                          <div className="display-content-Gnrl">
                            <label className="display-element-Gnrl">
                              Event Type*
                            </label>
                            <input
                              type="text"
                              className="display-element-Gnrl "
                              value={medicalEvents.MedicalEvents}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setMedicalEvents,
                                  "MedicalEvents"
                                )
                              }
                            />
                            <label className="display-element-Gnrl">
                              Other Event Type*
                            </label>
                            <input
                              type="text"
                              className="display-element-Gnrl "
                              value={medicalEvents.OtherEventType}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setMedicalEvents,
                                  "OtherEventType"
                                )
                              }
                            />

                            <label className="display-element-Gnrl">
                              Description*
                            </label>
                            <div className="displayDescription-color">
                              <textarea
                                className="display-element-Gnrl"
                                value={medicalEvents.Description}
                                onChange={(event) =>
                                  generalHandleValueChanges(
                                    event,
                                    setMedicalEvents,
                                    "Description"
                                  )
                                }
                              />
                            </div>
                          </div>

                          <div className="display-content-Gnrl">
                            <label className="display-element-Gnrl">
                              Event Date & Time*
                            </label>
                            <input
                              className="display-element-Gnrl "
                              type="datetime-local"
                              id="meeting-time"
                              name="meeting-time"
                              value={medicalEvents.EventDate?.slice(0, 16)}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setMedicalEvents,
                                  "EventDate"
                                )
                              }
                            />

                            <label className="display-element-Gnrl">
                              Notes*
                            </label>
                            <div className="displayDescription-color">
                              <textarea
                                className="display-element-Gnrl"
                                value={medicalEvents.Notes}
                                onChange={(event) =>
                                  generalHandleValueChanges(
                                    event,
                                    setMedicalEvents,
                                    "Notes"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </>{" "}
                    </AddDetailGeneral>
                  ) : (
                    <>
                      {statusfinderFn("active", selectedPatient.MedicalEvents)
                        ?.length === 0 ? (
                        <CarePlanErrorMessage msg="Patient doesn’t have any MedicalEvents yet!" />
                      ) : (
                        <CarePlanDisplayGeneral
                          updateDatafn={updateDatafn}
                          name={"MedicalEvents"}
                          array={medicalEventsArray}
                          id={id}
                          careplanview={careplanview}
                          dataSet={selectedPatient?.MedicalEvents ?? []}
                          values={[setMedicalEventsWindow, setMedicalEvents]}
                          data={statusfinderFn(
                            "active",
                            selectedPatient.MedicalEvents
                          )}
                          setEditArray={setEditArray}
                          contentArray={[
                            ["Event Type:", "EventType", "colSpan6"],
                            [
                              "Event Date & Time:",
                              "OtherEventType",
                              "colSpan6",
                            ],
                            ["Other Event Type:", "EventDate", "colSpan6"],
                            ["Description:", "Description", "colSpan2"],
                            ["Notes:", "Notes", "colSpan2"],
                          ]}
                        />
                      )}
                    </>
                  )}
                </div>
              </ActiveHistoryDropDown2>
            )}
            {active === "History" && (
              <ActiveHistoryDropDown2 type={"History"}>
                {" "}
                <>
                  {" "}
                  {statusfinderFn("history", selectedPatient.MedicalEvents)
                    ?.length === 0 ? (
                    <></>
                  ) : (
                    <CarePlanDisplayGeneral
                      Type={"history"}
                      data={statusfinderFn(
                        "history",
                        selectedPatient.MedicalEvents
                      )}
                      contentArray={[
                        ["Event Type:", "EventType", "colSpan6"],
                        ["Event Date & Time:", "OtherEventType", "colSpan6"],
                        ["Other Event Type:", "EventDate", "colSpan6"],
                        ["Description:", "Description", "colSpan2"],
                        ["Notes:", "Notes", "colSpan2"],
                      ]}
                    />
                  )}
                </>
              </ActiveHistoryDropDown2>
            )}
          </div>
        }
      />
    </div>
  );
};

export default MedicalEvents;
