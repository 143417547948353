import { apiCollection } from "./apiCollection";

const facilityApi = apiCollection.injectEndpoints({
  endpoints: (build) => ({
    facilityCreation: build.mutation({
      query: (payload) => {
        const {
          WorkingDaysStart,
          WorkingDaysEnd,
          WorkingDaysStartTime,
          WorkingDaysEndTime,
          ...result
        } = payload;
        result["WorkingDays"] = [
          WorkingDaysStart,
          WorkingDaysEnd,
          WorkingDaysStartTime,
          WorkingDaysEndTime,
        ];

        return { url: "/facility/create", method: "POST", body: result };
      },
      transformErrorResponse: (response, meta, arg) => response.data.message,
    }),

    updateFacility: build.mutation({
      query: (payload) => {
        const {
          WorkingDaysStart,
          WorkingDaysEnd,
          WorkingDaysStartTime,
          WorkingDaysEndTime,
          ...result
        } = payload;

        result["WorkingDays"] = [
          WorkingDaysStart,
          WorkingDaysEnd,
          WorkingDaysStartTime,
          WorkingDaysEndTime,
        ];

        return {
          url: "/facility/update",
          method: "POST",
          body: result,
        };
      },
      transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
    getFacilityAllProfiles: build.query({
      query: () => ({
        url: "/facility/allProfile",
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("RLH_token")}`,
        // },
      }),
    }),
    getSelectedFacility: build.mutation({
      query: (payload) => {
        return {
          url: "/facility/selectedProfile",
          method: "POST",
          body: payload,
        };
      },
      transformResponse: (response, meta, arg) => {
        // console.log(response);
        const { WorkingDays, phone, postalCode, Fax, ...data } = response;
        data["WorkingDaysStart"] = WorkingDays[0];
        data["WorkingDaysEnd"] = WorkingDays[1];
        data["WorkingDaysStartTime"] = WorkingDays[2];
        data["WorkingDaysEndTime"] = WorkingDays[3];
        data["postalCode"] = postalCode?.toString();
        data["phone"] = phone?.toString();
        data["Fax"] = Fax?.toString();
        return data;
      },
      // transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
    getFacilityAccessLevelById: build.mutation({
      query: (payload) => {
        return {
          url: "/facility/accessLevelById",
          method: "POST",
          body: payload,
        };
      },

      // transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
  }),
  overrideExisting: false,
});

export const {
  useFacilityCreationMutation,
  useGetFacilityAllProfilesQuery,
  useGetSelectedFacilityMutation,
  useUpdateFacilityMutation,
  useGetFacilityAccessLevelByIdMutation,
  useUpdateFPCMutation,
} = facilityApi;
