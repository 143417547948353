import React from "react";
import classNames from "classnames";
import "../sass/tabElement2.scss";

const TabElement2 = ({ title, active, onClick, services }) => {
  return (
    <div className={classNames("tabElement2", { active })} onClick={onClick}>
      {title === "firstInsuranceData"
        ? "Insurance1"
        : title === "secondInsuranceData"
        ? "Insurance2"
        : "Insurance3"}
      {services}
    </div>
  );
};

export default React.memo(TabElement2);
