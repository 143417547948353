import { apiCollection } from "./apiCollection";

const UserManagementApi = apiCollection.injectEndpoints({
  endpoints: (build) => ({
    getUserList: build.mutation({
      query: (payload) => {
        return {
          url: "userManagement/getUserLIst",
          method: "POST",
          body: payload,
        };
      },
    }),
    resendLogin: build.mutation({
      query: (payload) => {
        return {
          url: "login/resendLogin",
          method: "POST",
          body: payload,
        };
      },
    }),
    updateUserStatus: build.mutation({
      query: (payload) => {
        return {
          url: "userManagement/updateStatus",
          method: "POST",
          body: payload,
        };
      },
    }),
    updatePatientList: build.mutation({
      query: (payload) => {
        return {
          url: "userManagement/getPatientList",
          method: "POST",
          body: payload,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserListMutation,
  useUpdateUserStatusMutation,
  useUpdatePatientListMutation,
  useResendLoginMutation,
} = UserManagementApi;
