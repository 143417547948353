import React, { useState, useEffect } from "react";
import { generalHandleValueChanges } from "../helperfunctions";
import SelectComponent from "../../../components/SelectComponent";
import { useGetClinicianAllProfilesQuery } from "../../../app/api/clinicianApi";
import { useAddServicesMutation } from "../../../app/api/billingApi";
import { useCreateScheduleMutation } from "../../../app/api/scheduleCallApi";
import "../../../sass/callStatusPopUp.scss";
import FloatingNotifications from "../../../utilities/FloatingNotifications";

const CallStatusPopUp = ({
  cancelfn,
  programName,
  serviceTimerData,
  PatientId,
  refreshBillingHistory,
}) => {
  const [popUpData, setPopUpData] = useState({ statusType: "Call completed" });
  const [addServicesFn, { data: serviceData, isLoading, error }] =
    useAddServicesMutation();

  const [followUpData, setFollowUpData] = useState({
    assignTo: "",
    callPurpose: `${programName} General Follow Up`,
    StartDateTime: "",
    callDuration: "10",
    scheduleType: "Phone Call",
    appointmentType: "Follow-Up",
  });
  const [createScheduleFn, { data: scheduleDataLatest }] =
    useCreateScheduleMutation();
  const [clinicianList, setClinicianList] = useState([]);

  const { data: ClinicianListLatest } = useGetClinicianAllProfilesQuery();

  useEffect(() => {
    if (ClinicianListLatest) {
      const clinicianOptions = ClinicianListLatest.map((el) => [
        el.Name,
        el.ClinicianId,
      ]);
      setClinicianList(clinicianOptions);
    }
  }, [ClinicianListLatest]);

  const saveProgram = () => {
    const servicePayload = {
      PatientId,
      programName,
      serviceName: "Phone Communication to Patient",
      serviceType: "Timer",
      startTime: serviceTimerData?.startTime,
      endTime: serviceTimerData?.endTime,
      acutalTimeSpent: serviceTimerData?.acutalTimeSpent,
      callStatus: popUpData.statusType,
      remark: "notes",
    };

    if (popUpData.statusType === "Call follow up") {
      addServicesFn(servicePayload);
      createScheduleFn({
        ...followUpData,
        patientId: PatientId,
        patientInstructions: " ",
      });
    } else {
      addServicesFn(servicePayload);
    }
  };

  useEffect(() => {
    if (serviceData) {
      refreshBillingHistory();
      cancelfn();
    }
  }, [serviceData]);

  useEffect(() => {
    if (error) {
      FloatingNotifications("error", "Failed to add service");
    }
  }, [error]);

  return (
    <div className="callStatusPopUp">
      <div className="popUp">
        <p className="popTitle">Update {programName} call status</p>
        <div className="popElementHead">
          <p className="popLabelBig">Select Status</p>
          <div className="popElemenSelectt">
            <SelectComponent
              OptionsArray={[
                "Call completed",
                "Call follow up",
                "Call not answered",
                "Left Message",
                "Do not Call",
                "Wrong number",
              ]}
              valueData={popUpData.statusType}
              fn={generalHandleValueChanges}
              setvalue={setPopUpData}
              name={"statusType"}
            />
          </div>
        </div>

        {popUpData.statusType === "Call follow up" && (
          <>
            <div className="popElementHead">
              <p className="popLabelBig">Assignee Name *</p>
              <div className="popElemenSelectt">
                <SelectComponent
                  OptionsDict={clinicianList}
                  fn={generalHandleValueChanges}
                  setvalue={setFollowUpData}
                  name={"assignTo"}
                />
              </div>
            </div>
            <div className="popElementHead">
              <p className="popLabelBig">Schedule Date & Time *</p>
              <div className="popElemenDate">
                <span>
                  <input
                    type="datetime-local"
                    onChange={(event) =>
                      generalHandleValueChanges(
                        event,
                        setFollowUpData,
                        "StartDateTime"
                      )
                    }
                  />
                </span>
                <SelectComponent
                  OptionsArray={["10", "15", "20", "30", "45", "60"]}
                  fn={generalHandleValueChanges}
                  setvalue={setFollowUpData}
                  name={"callDuration"}
                />
              </div>
            </div>
            <div className="popElementHead">
              <p className="popLabelBig">Purpose of the call</p>
              <div className="popElement">
                <input
                  type="text"
                  value={followUpData.callPurpose}
                  onChange={(event) =>
                    generalHandleValueChanges(
                      event,
                      setFollowUpData,
                      "callPurpose"
                    )
                  }
                />
              </div>
            </div>
          </>
        )}

        {["Call not answered", "Do not Call", "Wrong number"].includes(
          popUpData.statusType
        ) && (
          <p className="warning">
            Warning: This call duration will not be added to the total time
            spent for the current month.
          </p>
        )}

        <div className="popBtn">
          <button onClick={saveProgram} disabled={isLoading}>
            {isLoading ? "Saving..." : "Save"}
          </button>
          <button onClick={cancelfn}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default CallStatusPopUp;
