import React from "react";
import { useState, useEffect } from "react";
import SelectWithSearch from "../../../searchandselect component/SearchAndSelect";

import { useGetICDcodeMutation } from "../../../../app/api/patientsApi";
import {
  resetfn,
  basicValidation,
  areArraysSame,
  careplanview,
  generalHandleValueChanges,
  getCurrentDateAndTime,
} from "../../helperfunctions";

const Diagnosis = (props) => {
  const {
    selectedPatient,
    id,
    selectedId,
    updateDatafn,
    changeWindow,
    uniqueId,
    icdfn,
  } = props;

  const [getICDfn, { data: ICDcodeData }] = useGetICDcodeMutation();

  const [diagnosisArray, setDiagnosisArray] = useState([]);
  const [diagnosis, setDiagnosis] = useState({
    ICD_Code: "",
    ICD_Description: "",
    Type: "",
    RecordedDate: getCurrentDateAndTime(),
    EndDate: "",
    LastAssessmentDate: "",
    AssessmentSummary: "",
    Status: true,
    Diagnosis_id: uniqueId,
  });
  // console.log(diagnosisArray);

  const SaveButton = () => {
    if (basicValidation(diagnosis)) {
      updateDatafn({
        PatientId: id,
        Diagnosis: [...diagnosisArray, diagnosis],
      });
      changeWindow(2);
    }
  };

  useEffect(() => {
    if (selectedId === undefined) return;
    let { _id, ...updatedcode } = selectedId;
    setDiagnosis({
      ...updatedcode,
      LastAssessmentDate: selectedId?.LastAssessmentDate?.slice(0, 16),
      RecordedDate: selectedId?.RecordedDate?.slice(0, 16),
    });
    let newArray = selectedPatient?.Diagnosis?.filter(
      (el) => el.Diagnosis_id !== selectedId.Diagnosis_id
    );
    let updatedArray = newArray.map((obj) => {
      const { _id, ...rest } = obj;
      return rest;
    });

    setDiagnosisArray(updatedArray);
  }, [selectedId]);

  // useEffect(() => {
  //   if (!basicValidation(diagnosis)) return;
  //   console.log(selectedId, editSelected);
  //   if (selectedId || editSelected) {
  //     changeWindow(2);
  //   }
  // }, [changeWindow, diagnosis, diagnosisArray, editSelected, selectedId]);

  useEffect(() => {
    if (selectedPatient && !selectedId) {
      setDiagnosisArray(selectedPatient?.Diagnosis ?? []);
    }
  }, [selectedPatient]);

  // useEffect(() => {
  //   if (
  //     basicValidation(diagnosis) &&
  //     !areArraysSame(selectedPatient?.Diagnosis, diagnosisArray)
  //   ) {
  //     // console.log("upds");
  //     updateDatafn({ PatientId: id, Diagnosis: diagnosisArray });
  //   }
  // }, [diagnosisArray]);

  return (
    <div>
      <p className="title">Diagnosis Information</p>
      <>
        <div className="details-sec">
          <div className="detail-content">
            <label className="details-element">
              Search by ICD Code or Description*
            </label>
            <SelectWithSearch
              options={ICDcodeData}
              valuedata={diagnosis?.["ICD_Code"]}
              fn={generalHandleValueChanges}
              setvalue={setDiagnosis}
              name={"ICD_Code"}
              decription={"ICD_Description"}
              ApiOption={true}
              Apicallfn={getICDfn}
              selectedPatient={selectedPatient}
              icdfn={icdfn}
            />
            {/* <select
              className="details-element "
              onChange={(event) =>
                generalHandleValueChanges(event, setDiagnosis, "ICD_Code")
              }
              value={diagnosis["ICD_Code"]}
            >
              <option className="default" value=""></option>
              {ICDcodeData?.map((el) => (
                <option value={el._id}>{el.full.split(" ")[0]}</option>
              ))}
            </select> */}

            {/* <label className="details-element">Description</label>
            <div className="descri-color">
              <textarea
                className="details-element "
                onChane={(event) =>
                  generalHandleValueChanges(
                    event,
                    setDiagnosis,
                    "ICD_Description"
                  )
                }
                value={diagnosis["ICD_Description"]}
              />
            </div> */}
            <label className="details-element">Description*</label>
            <textarea
              className="details-element "
              disabled={true}
              // onChange={(event) =>
              //   generalHandleValueChanges(
              //     event,
              //     setDiagnosis,
              //     "ICD_Description"
              //   )
              // }
              placeholder={diagnosis["ICD_Description"] ?? ""}
              value={diagnosis?.["ICD_Description"]}
            />

            <label className="details-element">
              Last Assessment Date & Time*
            </label>
            <input
              className="details-element "
              type="datetime-local"
              id="meeting-time"
              name="meeting-time"
              value={diagnosis?.LastAssessmentDate?.slice(0, 16)}
              onChange={(event) =>
                generalHandleValueChanges(
                  event,
                  setDiagnosis,
                  "LastAssessmentDate"
                )
              }
            />
            <label className="details-element">Assessment Summary*</label>
            <textarea
              className="details-element "
              onChange={(event) =>
                generalHandleValueChanges(
                  event,
                  setDiagnosis,
                  "AssessmentSummary"
                )
              }
              value={diagnosis?.["AssessmentSummary"]}
            />
          </div>
          <div className="detail-content">
            <label className="details-element">Type*</label>
            <select
              className="details-element "
              onChange={(event) =>
                generalHandleValueChanges(event, setDiagnosis, "Type")
              }
              value={diagnosis["Type"]}
            >
              <option className="default" value=""></option>
              <option value={"Acute"}>Acute</option>
              <option value={"Chronic"}>Chronic</option>
            </select>
            <label className="details-element">Recorded Date & Time*</label>
            <input
              className="details-element "
              type="datetime-local"
              id="meeting-time"
              name="meeting-time"
              value={diagnosis?.RecordedDate?.slice(0, 16)}
              onChange={(event) =>
                generalHandleValueChanges(event, setDiagnosis, "RecordedDate")
              }
            />
            <label className="details-element">End Date & Time</label>
            <input
              className="details-element "
              type="datetime-local"
              id="meeting-time"
              name="meeting-time"
              value={diagnosis?.EndDate?.slice(0, 16)}
              onChange={(event) =>
                generalHandleValueChanges(event, setDiagnosis, "EndDate")
              }
              min={diagnosis.RecordedDate ?? ""}
            />
          </div>
          <button onClick={SaveButton}>Next</button>
        </div>
      </>
    </div>
  );
};

export default Diagnosis;
