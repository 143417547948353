import React, { useState, useEffect } from "react";
import Stopwatch from "../../../components/StopWatch";
import { Icon } from "@iconify/react";
import "../../../sass/callingTimer.scss";
const CallingTimer = ({
  programName,
  id,
  startTimer,
  setStartTimer,
  type,
  timerFn,
  totalTime,
  timerStatus,
}) => {
  const [timer, setTimer] = useState("");
  const [elapsedTime, setElapsedTime] = useState(0);
  const handlestopWatch = (data, reset) => {
    setTimer(data);
    if (reset) {
      setElapsedTime(0);
    }
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };
  const parseTime = (formattedTime) => {
    if (!formattedTime) return 0;
    const [hours, minutes, seconds] = formattedTime?.split(":")?.map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };
  useEffect(() => {
    let intervalId;
    if (timer) {
      // setButtonClick((prev) => prev + 1);
      intervalId = setInterval(() => {
        setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);
  return (
    <div className="CallingTimer">
      <div className="left">
        <div className="top first">
          <p className="head">
            Previous {programName} serviced time for the month
          </p>
          <p className="time">{totalTime ?? "00:00:00"}</p>
        </div>
        <div className="top second">
          <p className="head">Total service time for the month</p>
          <p className="time">
            {formatTime(parseTime(totalTime) + elapsedTime)}
            {/* {formatTime(parseTime(totalTime) + elapsedTime)} */}
            {/* {timerStatus?.serviceLog?.acutalTimeSpent ?? "00:00:00"} */}
          </p>
        </div>
      </div>
      <div className="rigth">
        <div className="headTitle">
          {(() => {
            switch (type) {
              case "Phone":
                return (
                  <>
                    <Icon icon="ion:call" />
                    <p>Call Duration</p>
                  </>
                );
              case "In-Person":
                return (
                  <>
                    <Icon icon="fa6-solid:user-doctor" /> <p>In-Person Visit</p>{" "}
                  </>
                );
              case "Documentation":
                return (
                  <>
                    <Icon icon="mingcute:paper-line" />{" "}
                    <p>Call Documentation</p>{" "}
                  </>
                );
              default:
                return <p>Default Content</p>;
            }
          })()}
        </div>
        <Stopwatch
          id={id}
          startTimer={startTimer}
          setStartTimer={setStartTimer}
          // programName={programName.programName}
          // serviceName={"Care Plan Entry"}
          // totalTime={totalTime}
          timerFn={timerFn}
          callingpage={true}
          programName={"CCM"}
          serviceName={"monthly call"}
          handlestopWatch={handlestopWatch}
          // totalTime={totalTime}
          // updateFunction={updateFunction}
        />
      </div>
    </div>
  );
};

export default CallingTimer;
