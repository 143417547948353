import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Icon } from "@iconify/react";
import "./SearchAndSelect.scss";
import { useEffect, useMemo } from "react";

const SelectSearchGeneral = ({
  options,
  Apicallfn,
  placeHolder,
  type,
  setvalue,
  name,
  fn,
  medicationCode,
  valuedata,
  selectedPatient,
  icdfn,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  //   const filteredOptions = useMemo(() => {
  //     // if (ApiOption) {
  //     //   return options?.filter((option) =>
  //     //     option.full.toLowerCase().includes(searchTerm.toLowerCase())
  //     //   );
  //     // } else {
  //     //   return options?.filter((option) =>
  //     //     option.toLowerCase().includes(searchTerm.toLowerCase())
  //     //   );
  //     // }
  //   }, [options, searchTerm, ApiOption]);
  const handleInputChange = (e) => {
    if (e.target.value.length >= 4) {
      Apicallfn(e.target.value);
    }
    setSearchTerm(e.target.value);
  };
  const handleOptionClick = (option) => {
    if (type === "Medication") {
      let r = `${option?.brand_name} ${option?.substance_name}( ${option?.route} )`;
      setSelectedOption(r);
      setSearchTerm(r);
      fn(r, setvalue, name);
      fn(option._id, setvalue, medicationCode);
    }
    if (type === "familyHistory") {
      setSelectedOption(option);
      setSearchTerm(option);
      fn(option, setvalue, name);
    }
    setIsOpen(false);

    // setSelectedOption(option);
    // setSearchTerm(option);
    // setIsOpen(false);
    // fn(option, setvalue, name);
    // fn(option.full, setvalue, decription);
  };
  const firstClick = () => {
    setIsOpen(true);
  };
  const clear = () => {
    setIsOpen(true);
    setSearchTerm("");
    setSelectedOption("");
    Apicallfn();
  };
  useEffect(() => {
    if (!valuedata) return;
    if (!selectedPatient) return;
    setSelectedOption(valuedata);
    setSearchTerm(valuedata);
  }, [valuedata]);
  return (
    <div className="select-with-search">
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsOpen(false);
        }}
      >
        <div className="dropdown">
          <div className="dropFlex">
            <input
              type="text"
              placeholder={placeHolder ?? "Search..."}
              value={searchTerm}
              onChange={handleInputChange}
              onClick={() => firstClick()}
            />
            <button className="close" onClick={clear}>
              {selectedOption ? (
                <Icon icon="iconoir:cancel" />
              ) : (
                <Icon icon="ic:sharp-search" />
              )}
            </button>
          </div>
          {isOpen && (
            <ul className="options">
              {type === "Medication" &&
                options?.map((option, index) => (
                  <li key={index} onClick={() => handleOptionClick(option)}>
                    {`${option.brand_name} ${option.substance_name}( ${option.route} )`}
                  </li>
                ))}
              {type === "familyHistory" &&
                options?.map((option, index) => (
                  <li
                    key={index}
                    onClick={() => handleOptionClick(option.desc)}
                  >
                    {option.desc}
                  </li>
                ))}
            </ul>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

const SelectWithSearchGeneral = React.memo(SelectSearchGeneral);
export default SelectWithSearchGeneral;
