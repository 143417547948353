import { apiCollection } from "./apiCollection";

const loginApi = apiCollection.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation({
      query: (payload) => ({
        url: "/login/credentials",
        method: "POST",
        body: payload,
      }),
    }),
    forgotPassword: build.mutation({
      query: (payload) => ({
        url: "/login/forgotpassword",
        method: "POST",
        body: payload,
      }),
    }),
    resetPassword: build.mutation({
      query: (payload) => ({
        url: "/login/resetpassword",
        method: "POST",
        body: payload,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = loginApi;
