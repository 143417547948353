import React, { useEffect, useState } from "react";
import {
  startOfWeek,
  isEqual,
  parseISO,
  endOfWeek,
  addDays,
  format,
} from "date-fns";
import WeekElement from "./WeekElement";
import classNames from "classnames";
import "./weekcomp.scss";
const dayTime = [
  ["12 AM", "1 AM"],
  ["1 AM", "2 AM"],
  ["2 AM", "3 AM"],
  ["3 AM", "4 AM"],
  ["4 AM", "5 AM"],
  ["5 AM", "6 AM"],
  ["6 AM", "7 AM"],
  ["7 AM", "8 AM"],
  ["8 AM", "9 AM"],
  ["9 AM", "10 AM"],
  ["10 AM", "11 AM"],
  ["11 AM", "12 PM"],
  ["12 PM", "1 PM"],
  ["1 PM", "2 PM"],
  ["2 PM", "3 PM"],
  ["3 PM", "4 PM"],
  ["4 PM", "5 PM"],
  ["5 PM", "6 PM"],
  ["6 PM", "7 PM"],
  ["7 PM", "8 PM"],
  ["8 PM", "9 PM"],
  ["9 PM", "10 PM"],
  ["10 PM", "11 PM"],
  ["11 PM", "12 AM", "last"],
];
const Weekcomp = ({
  day,
  sheduleData,
  setSheduleData,
  setCallPatientPopUp,
  setEventData,
}) => {
  const [currentDate, setCurrentDate] = useState(day);
  const presentDay = new Date();
  const [weekDates, setWeekDates] = useState([]);

  useEffect(() => {
    let startDateOfWeek = startOfWeek(currentDate);
    let endDateOfWeek = endOfWeek(currentDate);
    let week = [];
    let date = startDateOfWeek;
    while (date <= endDateOfWeek) {
      week.push(format(date, "yyyy-MM-dd"));
      date = addDays(date, 1);
    }
    setWeekDates(week);
  }, [currentDate]);
  useEffect(() => {
    if (!day) return;
    setCurrentDate(day);
  }, [day]);
  return (
    <div className="weekCont">
      <div></div>
      <div className="calendarBody">
        <div className="day">Sunday</div>
        <div className="day">Monday</div>
        <div className="day">Tuesday</div>
        <div className="day">Wednesday</div>
        <div className="day">Thursday</div>
        <div className="day"> Friday</div>
        <div className="day"> Saturday</div>
        {weekDates.map((day) => (
          <div
            className={classNames(
              "weekComponent",

              {
                present: isEqual(
                  parseISO(format(presentDay, "yyyy-MM-dd")),
                  parseISO(day)
                ),
              }
            )}
            //   onClick={() => clickForShedule(day)}
          >
            {day.slice(-2)}
          </div>
        ))}
      </div>
      {dayTime.map((e) => (
        <WeekElement
          setCallPatientPopUp={setCallPatientPopUp}
          setEventData={setEventData}
          sheduleData={sheduleData}
          setSheduleData={setSheduleData}
          lower={e[1]}
          upper={e[0]}
          dif={e[2]}
          weekDates={weekDates}
        />
      ))}
    </div>
  );
};

export default Weekcomp;
