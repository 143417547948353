import { useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router";
import TopBar from "../TopBar/TopBar";
import SideBar from "../SideBar/SideBar";
import { BackDropOverlayById } from "./BackDrop";

import { useSelector, useDispatch } from "react-redux";
import { navigationActions } from "../app/features/navigationSlice";

const RequiredAuth = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navBarState = useSelector((state) => state.navigationData.navBarOpen);
  const userState = useSelector((state) => state.authData.user);
  const [showNavBar, setShowNavBar] = useState(false);
  console.log("Going through protected route");
  const onClick = () => {
    dispatch(navigationActions.closeNavBar());
  };
  console.log(navBarState);
  return (
    <>
      {userState ? (
        <>
          <TopBar />
          <SideBar />
          {navBarState && (
            <BackDropOverlayById id="overlay-1" onClick={onClick} />
          )}
          <Outlet />
        </>
      ) : (
        <Navigate to="/login" state={{ from: location }} replace />
      )}
    </>
  );
};

export default RequiredAuth;
