import React from "react";
import { Icon } from "@iconify/react";
import classNames from "classnames";
import { useState } from "react";

import "./iconContainer.scss";

const IconContainer = (props) => {
  // console.log(props.iconsList)
  // const{onClickHandler} =props.clickFn
  const [popUpSave, setPopUpSave] = useState(false);
  const SaveButton = () => {
    setPopUpSave(true);
  };
  const onSaveConfirmed = () => {
    const fn = props.iconsList?.find((el) => el.value === "mingcute:save-line");
    if (fn && typeof fn.onClickFn === "function") {
      fn.onClickFn();
    }
    setPopUpSave(false);
  };
  return (
    <div>
      {popUpSave && (
        <div className="popUpDelete">
          <div className="popupContent">
            <p>Are you sure you want to Save this object?</p>
            <div className="popupActions">
              <button onClick={() => onSaveConfirmed()}>Save</button>
              <button onClick={() => setPopUpSave(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      <div className="iconContainer">
        {props.iconsList?.map((el) =>
          el.value === "mingcute:save-line" ? (
            <div
              key={el.value}
              className={classNames("btncontainer", {
                btnContainer2: props.bckColorwhite,
              })}
            >
              <Icon
                icon={`${el.value}`}
                width={props.bckColorwhite ? "18px" : "20px"}
                height={props.bckColorwhite ? "18px" : "20px"}
                onClick={el.onClickFn ? () => SaveButton() : () => {}}
              />
            </div>
          ) : (
            <div
              key={el.value}
              className={classNames("btncontainer", {
                btnContainer2: props.bckColorwhite,
              })}
            >
              <Icon
                icon={`${el.value}`}
                width={props.bckColorwhite ? "18px" : "20px"}
                height={props.bckColorwhite ? "18px" : "20px"}
                onClick={el.onClickFn ? el.onClickFn : () => {}}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default IconContainer;
