import React from "react";
import { useState } from "react";
import PopUp from "../patients/patientViewPage/PopUp";
import AddShedule from "../schedule/AddShedule";
import classNames from "classnames";
import CallTaskListViewTable from "./CallTaskListViewTable";
import ActiveHistoryDropDown from "../components/ActiveHistoryDropDown";
import "./callTaskList.scss";

const CallTaskList = () => {
  const [header, setHeader] = useState("CCM");
  const [shedulePopUp, setShedulePopUp] = useState(false);
  const cancelfn = () => {
    setShedulePopUp(false);
  };
  const clickForShedule = () => {
    setShedulePopUp(true);
  };
  return (
    <div className="callTaskList">
      {shedulePopUp && (
        <PopUp cancelfn={cancelfn} width={"80vw"}>
          <AddShedule
            // sheduleDate={sheduleDate}
            // setSheduleData={setSheduleData}
            cancelfn={cancelfn}
            // sheduleData={sheduleData}
          />
        </PopUp>
      )}
      <div className="RTMtitle">Call Task List</div>
      <div className="nav">
        <div
          className={classNames(
            "navHeader",
            header === "CCM" ? "navHeaderSelected" : ""
          )}
          onClick={() => setHeader("CCM")}
        >
          CCM
        </div>
        <div
          className={classNames(
            "navHeader",
            header === "RPM" ? "navHeaderSelected" : ""
          )}
          onClick={() => setHeader("RPM")}
        >
          RPM
        </div>
      </div>
      <div className="body">
        <ActiveHistoryDropDown
          type={"Planned Monthly CCM Shedules,August 2023"}
          callList={true}
          clickForShedule={clickForShedule}
        >
          <div className="divWrap">
            <ActiveHistoryDropDown type={"Not Called"}>
              <CallTaskListViewTable />
            </ActiveHistoryDropDown>
            <ActiveHistoryDropDown type={"Incomplete Calls"} open={false}>
              <CallTaskListViewTable />
            </ActiveHistoryDropDown>
            <ActiveHistoryDropDown type={"Completed Calls"} open={false}>
              <CallTaskListViewTable />
            </ActiveHistoryDropDown>
          </div>
        </ActiveHistoryDropDown>
        <ActiveHistoryDropDown
          type={"Unplanned Monthly CCM Shedules,August 2023"}
          open={false}
        >
          <div className="divWrap">
            <ActiveHistoryDropDown type={"Not Called"} open={false}>
              <CallTaskListViewTable />
            </ActiveHistoryDropDown>
            <ActiveHistoryDropDown type={"Incomplete Calls"} open={false}>
              <CallTaskListViewTable />
            </ActiveHistoryDropDown>
            <ActiveHistoryDropDown type={"Completed Calls"} open={false}>
              <CallTaskListViewTable />
            </ActiveHistoryDropDown>
          </div>
        </ActiveHistoryDropDown>
      </div>
    </div>
  );
};

export default CallTaskList;
