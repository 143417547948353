import React from "react";
import { Icon } from "@iconify/react";

import "./historyDisplay.scss";

const HistoryOfNotes = ({ data }) => {
  return (
    <div className="notesHistory">
      <p>History</p>
      <div className="notesContent">
        {data && data.length > 0 ? (
          data.map((d, index) => (
            <div className="historyDisplay" key={d.id || index}>
              <p>Answer : {d?.answer}</p>
              <p>Notes : {d?.notes}</p>
              <p>Date : {d?.month_yr}</p>
            </div>
          ))
        ) : (
          <div className="emptyDisplay">
            <Icon icon="ri:information-line" />
            <p>No history found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default HistoryOfNotes;
