import { createSlice } from "@reduxjs/toolkit";
import { apiCollection } from "../api/apiCollection";
// import { accessLevelApi } from "../api/accessLevelApi";
const accessLevelDataSlice = createSlice({
  name: "accessLevelData",
  initialState: {
    ProviderList: [],
    ClinicianList: [],
  },
  // extraReducers: (builder) => {
  //   builder.addMatcher(
  //     accessLevelApi.endpoints.getAccessLevel.matchFulfilled,
  //     (state, action) => {
  //       console.log(action);
  //     }
  //   );
  // },
});

export const accessLevelDataActions = accessLevelDataSlice.actions;

export default accessLevelDataSlice.reducer;
