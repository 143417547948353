import React from "react";
import TechnicianWidget from "./TechnicianWidget";
import "../sass/technicianAlotmentTab.scss";
import { useEffect, useState } from "react";
// import { useGetAccessLevelQuery } from "../app/api/accessLevelApi";

const TechnicianAlotmentTab = ({
  title,
  disabled,
  dataFromApi,
  dataName,
  accessData,
  setAccessData,
  idKey,
  nameKey,
  trueValueOnly,
}) => {
  // const data = dataFromApi[dataName] ?? [];
  // const [data, setData] = useState([]);
  // const [accessData, setAccessData] = useState();
  // const [dataForTrueValues,setDataForTrueValues]=useState()
  // useEffect(() => {
  //   setData(dataFromApi);
  // }, [dataFromApi]);
  // useEffect(() => {
  //   if (!data) return;
  //   setAccessData(data);
  // }, [data]);

  const onClickToggle = (id, idKey) => {
    const updatedList = accessData.map((el) =>
      el[idKey] === id ? { ...el, AccessLevel: !el.AccessLevel } : el
    );
    setAccessData(updatedList);
  };
  const onClickSelect = (id, idKey) => {
    const updatedList = accessData.map((el) =>
      el[idKey] === id ? { ...el, Selected: !el.Selected } : el
    );
    setAccessData(updatedList);
  };
  const selectAll = accessData?.reduce(
    (prev, curr) => prev && curr.Selected,
    true
  );
  const onClickSelectAll = () => {
    const updatedList = accessData.map((el) => ({
      ...el,
      Selected: !selectAll,
    }));
    setAccessData(updatedList);
  };

  return (
    <div className="alotmentTab">
      <div className="alotmentContainer">
        <div className="element-container">
          <div className="element colSpan4">
            <input
              className="checkbox"
              type={"checkbox"}
              onClick={() => onClickSelectAll()}
              checked={selectAll}
              disabled={disabled}
            />

            <p className="TabTitle-head"> {title}</p>
          </div>
          <p className="TabTitle">All Patients</p>
          <p className="TabTitle">Access</p>
        </div>
        <div className="overflow-container">
          <>
            {accessData?.map((el) => (
              <TechnicianWidget
                key={el[idKey]}
                idKey={idKey}
                nameKey={nameKey}
                disabled={disabled}
                onClickSelect={onClickSelect}
                onClickToggle={onClickToggle}
                data={el}
              />
            ))}
          </>
        </div>
      </div>
    </div>
  );
};

export default TechnicianAlotmentTab;
