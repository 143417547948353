import { useReducer } from "react";

const initialInputState = {
  value: "",
  submitClicked: false,
};

const inputStateReducer = (state, action) => {
  if (action.type === "INPUT") {
    return { value: action.value, submitClicked: state.submitClicked };
  } else if (action.type === "SUBMIT") {
    return { value: state.value, submitClicked: true };
  } else if (action.type === "RESET") {
    return { value: false, submitClicked: false };
  } else if (action.type === "SET") {
    return { value: action.value, submitClicked: state.submitClicked };
  } else if (action.type === "DEFAULT") {
    return { value: action.value, submitClicked: state.submitClicked };
  }
  return initialInputState;
};

/**
 *
 * @param {func} validateValue
 * @returns
 */
const useInputSelector1 = (validateValue) => {
  const [inputState, dispatch] = useReducer(
    inputStateReducer,
    initialInputState
  );

  const valueIsValid = validateValue(inputState.value);
  const hasError = !valueIsValid && inputState.submitClicked;

  const valueChangeHandler = (event) => {
    dispatch({ type: "INPUT", value: event.target.value });
  };

  const submitClickHandler = () => {
    dispatch({ type: "SUBMIT" });
  };

  const reset = () => {
    dispatch({ type: "RESET" });
  };

  const storeValue = (value) => {
    dispatch({ type: "SET", value: value });
  };
  const storeDefaultValue = (value) => {
    dispatch({ type: "DEFAULT", value: value });
  };
  // console.log(inputState, valueIsValid);

  return {
    value: inputState.value,
    isValid: valueIsValid,
    hasError,
    valueChangeHandler,
    submitClickHandler,
    reset,
    storeValue,
    storeDefaultValue,
  };
};
export default useInputSelector1;
