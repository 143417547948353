import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import InputSelectorField1 from "../../widgets/InputSelectorField1";
import InputTextArea1 from "../../widgets/InputTextArea1";
import InputTextField1 from "../../widgets/InputTextField1";
import { patientsInsuranceDataActions } from "../../app/features/patientsInsuranseDataSlice";
import "./patientsInsSub.scss";

import {
  isNotEmpty,
  alwaysValidated,
  noCharValidateCheck,
} from "../../utilities/validationFunction";

const insuranceList = [
  "4 Ever Life Insurance Company",
  "Aetna Life Insurance Company",
  "American National Insurance Company",
  "American National Life Insurance of Texas",
  "American States Insurance Company",
  "Anthem Blue Cross Life and Health Insurance Company",
  "AXA Equitable Life Insurance Company",
  "Blue Shield of California Life and Health Insurance Company",
  "CIGNA Health and Life Insurance Company",
  "Cincinnati Life Insurance Company",
  "Connecticut General Life Insurance Company",
  "Delaware American Life Insurance Company",
  "Genworth Life Insurance Company",
  "Golden Rule Insurance Company",
  "Guardian Life Insurance Company of America",
  "Health Net Life Insurance Company",
  "John Hancock Life Insurance Company (U.S.A.)",
  "Kaiser Permanente Insurance Company",
  "Liberty Mutual Insurance Company",
  "Massachusetts Casualty Insurance Company",
  "Metropolitan Life Insurance Company",
  "Mony Life Insurance Company",
  "Mutual of Omaha Insurance Company",
  "National Benefit Life Insurance Company",
  "National Foundation Life Insurance Company of America",
  "National Health Insurance Company",
  "New York Life Insurance Company",
  "Nippon Life Insurance Company of America",
  "Prudential Insurance Company of America",
  "Sierra Health And Life Insurance Company, Inc.",
  "State Farm Mutual Automobile Insurance Company",
  "Teacher's Insurance and Annuity Association of America",
  "Thrivent Financial for Lutherans",
  "Transamerica Life Insurance Company",
  "Trustmark Insurance Company",
  "United HealthCare Insurance Company",
  "United States Life Insurance Company in the City of New Yor",
];

const error_message = {
  insuranceCompany: "InsuranceCompany should not be empty",
  insuranceID: "insurance ID should not be empty",
};
const PatientsInsSubcomponent = React.forwardRef((props, ref) => {
  const { insuranceType, loadedData } = props;
  const insuranceData = useSelector((state) => state.patientsInsuranceData);
  const dispatch = useDispatch();

  const storeData = useCallback((fieldName, value, validityStatus) => {
    dispatch(
      patientsInsuranceDataActions.updateData({
        insuranceType,
        fieldName,
        value,
        validityStatus,
      })
    );
  }, []);

  useEffect(() => {
    if (!loadedData) return;
    Object.keys(ref).forEach((value) => {
      if (loadedData?.[insuranceType]?.[value]) {
        ref[value].current?.init_data?.(loadedData[insuranceType][value]);
      }
    });
  }, [insuranceType, loadedData, ref]);

  useEffect(() => {
    console.log(insuranceData.insurance[insuranceType]);
    Object.keys(ref).forEach((value) => {
      if (insuranceData.insurance[insuranceType]?.[value]) {
        ref[value].current?.init_data?.(
          insuranceData.insurance[insuranceType][value]
        );
      }
    });
  }, []);
  return (
    <div className="patientsInsuranceContainer">
      <div className="patientsBasicInfoContainer4">
        <InputSelectorField1
          tabIndex="1"
          element={"insuranceCompany"}
          ref={ref["insuranceCompany"]}
          containerClassName="colSpan4"
          superTitle="*"
          title={"Insurance Company"}
          validation={isNotEmpty}
          charValidate={noCharValidateCheck}
          optionsArray={insuranceList}
          autoFocus={true}
          error_message={error_message["insuranceCompany"]}
          storeData={storeData.bind(null, "insuranceCompany")}
        />

        <InputTextField1
          element={"payerID"}
          tabIndex="1"
          ref={ref["payerID"]}
          containerClassName="colSpan3"
          title="Payer ID"
          validation={alwaysValidated}
          charValidate={noCharValidateCheck}
          storeData={storeData.bind(null, "payerID")}
        />
        <InputTextField1
          element={"tplCode"}
          tabIndex="1"
          ref={ref["tplCode"]}
          containerClassName="colSpan3"
          title="TPL Code "
          validation={alwaysValidated}
          charValidate={noCharValidateCheck}
          storeData={storeData.bind(null, "tplCode")}
        />
        <InputTextField1
          element={"insuranceID"}
          tabIndex="1"
          ref={ref["insuranceID"]}
          containerClassName="colSpan2"
          superTitle="*"
          title="Insurance ID"
          validation={isNotEmpty}
          charValidate={noCharValidateCheck}
          error_message={error_message["insuranceID"]}
          storeData={storeData.bind(null, "insuranceID")}
        />
        <InputTextField1
          element={"groupName"}
          tabIndex="1"
          ref={ref["groupName"]}
          containerClassName="colSpan3"
          title="Group Name"
          validation={alwaysValidated}
          charValidate={noCharValidateCheck}
          storeData={storeData.bind(null, "groupName")}
        />
        <InputTextField1
          element={"groupNumber"}
          tabIndex="1"
          ref={ref["groupNumber"]}
          containerClassName="colSpan2 "
          title="Group Number"
          validation={alwaysValidated}
          charValidate={noCharValidateCheck}
          storeData={storeData.bind(null, "groupNumber")}
        />
        <InputTextArea1
          element={"notes"}
          tabIndex="1"
          ref={ref["notes"]}
          containerClassName="colSpan3 colStart1"
          heightMultiplier={3}
          title="Notes"
          validation={alwaysValidated}
          charValidate={noCharValidateCheck}
          storeData={storeData.bind(null, "notes")}
        />
      </div>
    </div>
  );
});

export default PatientsInsSubcomponent;
