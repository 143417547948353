import React from "react";
import "./patientTransferBox.scss";

const PatientTransferBox = ({ data, type, checkBoxFn, selectedData }) => {
  return (
    <div className="PatientTransferBox">
      <div className="headingTitle">Patient Details</div>
      <div className="cheackBoxAll">
        <input
          className="transfercheckbox"
          type="checkbox"
          onChange={(event) => checkBoxFn(type, event, true)}
          checked={data.every((obj) => selectedData.includes(obj.PatientId))}
        />
      </div>
      <div className="detailsSec">
        {data?.length > 0 ? (
          data?.map((patient) => (
            <>
              <div className="details">
                <div className="nameAndDob">
                  {patient.Name}
                  <br />
                  <span className="extraBold">Dob: </span>
                  {patient["Date Of Birth"]}
                </div>
                <div className="addr">
                  <span className="extraBold">Address: </span>

                  {patient.Address}
                </div>
              </div>
              <div className="patientCheckBox">
                <input
                  className="transfercheckbox"
                  type="checkbox"
                  onChange={(event) =>
                    checkBoxFn(type, event, false, patient.PatientId)
                  }
                  checked={selectedData.includes(patient.PatientId)}
                />
              </div>
            </>
          ))
        ) : (
          <div className="emptyDisplay">No data Found</div>
        )}
      </div>
    </div>
  );
};

export default PatientTransferBox;
