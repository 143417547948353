import React from 'react'
import "./addDetailGeneral.scss"


const AddDetailGeneral=({children})=> {
  return (
    <div className='detailsContainer'>
      {children}
    </div>
  )
}

export default AddDetailGeneral
