import React, { useState } from "react";
import { Icon } from "@iconify/react";
import "../sass/dropDownContent.scss";

function DropDownContent({ title, children }) {
  const [showContent, setShowContent] = useState(true);
  const onClickHandler = () => {
    setShowContent((prev) => !prev);
  };
  return (
    <>
      <div className="dropDownContentContainer">
        <div className="title" onClick={onClickHandler}>
          <p> {title}</p>
        </div>
        <div className="body">{children}</div>
      </div>
    </>
  );
}

export default DropDownContent;
