import React, { useState } from "react";
import { Icon } from "@iconify/react";
import OutsideClickHandler from "react-outside-click-handler";

import "../sass/remarksViewer.scss";

const RemarksViewer = ({ content }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="remarksViewer">
      <button className="buttonremarks" onClick={() => setOpen(true)}>
        <Icon icon="ph:book-fill" />
      </button>
      {open && (
        <div className="overlayComponent">
          <OutsideClickHandler
            onOutsideClick={() => {
              setOpen(false);
            }}
          >
            <div className="remarksSection">
              <div className="remarkstitle">
                Remarks for "{content?.PatientName}"
              </div>
              <div className="remarksTable">
                <div className="remarksGrid header">
                  <div className="Element colSpan1">S.No</div>
                  <div className="Element colSpan2">Service Type</div>
                  <div className="Element colSpan2">Remark</div>
                </div>
                {content?.remarks?.map((el, index) => (
                  <div key={index} className="remarksGrid contentBody">
                    <div className="Element colSpan1">{index + 1}</div>
                    <div className="Element colSpan2">{el?.service}</div>
                    <div className="Element colSpan2">{el?.remark}</div>
                  </div>
                ))}
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      )}
    </div>
  );
};

export default RemarksViewer;
