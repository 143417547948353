import { createSlice } from "@reduxjs/toolkit";

export const FOLD = {
  1: "FOLD 1",
  2: "FOLD 2",
  3: "FOLD 3",
};

const navigationSlice = createSlice({
  name: "navigationSlice",
  initialState: {
    navBarOpen: false,
    openedSection: {},
  },
  reducers: {
    closeNavBar(state, action) {
      state.navBarOpen = false;
      state.openedSection = {};
    },
    openNavBar(state, action) {
      state.navBarOpen = true;
    },
    updateOpenedSection(state, action) {
      const { type, data } = action.payload;
      if (type === FOLD[1]) {
        state.openedSection = { [FOLD[1]]: data };
      } else if (type == FOLD[2]) {
        state.openedSection = {
          [FOLD[1]]: state.openedSection[FOLD[1]],
          [FOLD[2]]: data,
        };
      } else if (type == FOLD[3]) {
        state.openedSection = {
          [FOLD[1]]: state.openedSection[FOLD[1]],
          [FOLD[2]]: state.openedSection[FOLD[2]],
          [FOLD[3]]: data,
        };
      }
    },
  },
});

export const navigationActions = navigationSlice.actions;

export default navigationSlice.reducer;
