import { useState, useEffect } from "react";
import { selectPeers, useHMSStore } from "@100mslive/react-sdk";
import React from "react";
import Peer from "./Peer";
import classNames from "classnames";

function Conference() {
  const peers = useHMSStore(selectPeers);
  const [localFound, setLocalFound] = useState(false);
  const [selectedPeer, setSelectedPeer] = useState();
  const onClick = (peerId) => {
    setSelectedPeer(peerId);
  };
  useEffect(() => {
    if (!localFound) {
      const val = peers.find((el) => el.isLocal)?.id;
      if (val) {
        setSelectedPeer(val);
        setLocalFound(true);
      }
      return;
    }
    if (peers.length === 1) {
      if (selectedPeer !== peers[0].id) {
        setSelectedPeer(peers[0].id);
      }
    }
  }, [peers]);
  return (
    <div className="conference-section">
      <div
        className={classNames("peers-container", {
          // equalSplit: equalSize,
          unEqualSplit: true,
        })}
      >
        {peers.map((peer) => (
          <Peer
            key={peer.id}
            peer={peer}
            onClick={onClick}
            selectedPeer={selectedPeer}
          />
        ))}
      </div>
    </div>
  );
}

export default Conference;
